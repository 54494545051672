import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Row from "./Row";
import Switch, { Props as SwitchProps } from "./Switch";

const LabeledSwitch: FC<SwitchProps> = props => {
  const { checked, onChange, children, ...rest } = props;

  const handleClick = useCallback(() => {
    if (onChange) {
      onChange(!checked);
    }
  }, [checked, onChange]);

  return (
    <Row {...rest} onClick={handleClick}>
      <Switch checked={checked} />
      <Label>{children}</Label>
    </Row>
  );
};

export default styled(LabeledSwitch)`
  align-items: center;
  cursor: pointer;
`;

const Label = styled("label")`
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
  margin-left: 8px;
  cursor: pointer;
`;
