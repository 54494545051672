interface HasIndex {
  index: number;
}

export default function swapBetweenIndexedArrays<T extends HasIndex>(
  sourceArray: T[],
  destArray: T[],
  sourceIndex: number,
  destIndex: number
): [T[], T[]] {
  const sourceAfterSwap = [...sourceArray];
  const [removed] = sourceAfterSwap.splice(sourceIndex, 1);

  const destAfterSwap = [...destArray];
  destAfterSwap.splice(destIndex, 0, removed);

  return [sourceAfterSwap.map(updateIndex), destAfterSwap.map(updateIndex)];
}

function updateIndex<T extends HasIndex>(value: T, index: number): T {
  return { ...value, index };
}
