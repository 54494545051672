import React, { FC, ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import Column from "./Column";
import { AssignmentTakeWithStudent } from "shared/lib/types/AssignmentTake";
import Chevron from './Chevron';
import BlankButton from "./BlankButton";
import { formatFirstLast } from "shared/lib/utils/formatName";

interface Props {
  label: ReactNode;
  ungradedTakes: readonly AssignmentTakeWithStudent[];
  gradedTakes: readonly AssignmentTakeWithStudent[];
  onTakeSelected(value: AssignmentTakeWithStudent): any;
  readOnly?: boolean;
}

function formatOptionText(take: AssignmentTakeWithStudent) {
  return formatFirstLast(take.student)
}

const TakeDropdown: FC<Props> = props => {
  const { label, ungradedTakes, gradedTakes, onTakeSelected, readOnly, ...rest } = props;
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const handleTakeSelected = useCallback((take: AssignmentTakeWithStudent) => {
    setOpen(false);
    onTakeSelected(take);
  }, [onTakeSelected]);

  return (
    <Column {...rest}>
      <Header onClick={toggleOpen} disabled={readOnly}>
        {label}
        {!readOnly && <Chevron flipped={open} />}
      </Header>
      {open && (
        <Menu>
          <OptionList takes={ungradedTakes} heading='Not yet graded' onTakeClicked={handleTakeSelected} />
          <OptionList takes={gradedTakes} heading='Graded' onTakeClicked={handleTakeSelected} />
        </Menu>
      )}
    </Column>
  );
};

export default styled(TakeDropdown)`
  min-width: 191px;
  position: relative;
  cursor: pointer;
  margin-bottom: 33px;
  max-width: 300px;
`;

const Header = styled(BlankButton)`
  color: #000;
  font-size: 14px;
  font-weight: 900;
  position: relative;
  padding-right: 40px;
  ${Chevron} {
    z-index: 5;
  }
`;


const Menu = styled(Column)`
  overflow: visible;
  width: calc(100% + 30px);
  background-color: #ececec;
  padding: 14px;
  position: absolute;
  bottom: 0;
  left: -15px;
  transform: translate(0, 100%);
  z-index: 10;
  border: 1px solid black;

  &::after {
    content: "";
    background-color: #ececec;
    border-top: 1px solid black;
    border-left: 1px solid black;
    transform: rotate(45deg);
    position: absolute;
    top: -12px;
    right: 27px;
    width: 21px;
    height: 21px;
    pointer-events: none;
  }
`;

const Option = styled(BlankButton)`
  & + & {
    margin-top: 10px;
  }
  &:hover {
    background-color: black;
    color: white;
  }
  padding: 0.5rem;
`;

const TakeOptionSubList = styled(Column)`
`;

interface OptionListProps {
  heading: string;
  takes: readonly AssignmentTakeWithStudent[];
  onTakeClicked(take: AssignmentTakeWithStudent): void;
}

const OptionList: FC<OptionListProps> = ({ heading, onTakeClicked, takes, ...rest }) => {
  if (takes.length < 1) {
    return null;
  }

  return (
    <Column {...rest}>
      <h4>{heading}</h4>
      <TakeOptionSubList>
        {takes.map(take => (
          <Option
            key={take.id}
            onClick={() => onTakeClicked(take)}
          >
            {formatOptionText(take)}
          </Option>
        ))}
      </TakeOptionSubList>
    </Column>
  );
}