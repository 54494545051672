import { useState } from "react";
import { StudentImport } from "shared/lib/types/import/StudentImport";
import { TeacherImport } from "shared/lib/types/import/TeacherImport";
import { EnrollmentImport } from "shared/lib/types/import/EnrollmentImport";
import { RosterImportType } from "shared/src/types/import/RosterImportType";
import { ClassImport } from "shared/lib/types/import/ClassImport";
import { importRowError } from "shared/lib/utils/import/importRowError";
import { validateHeaderRow } from "shared/lib/constants/import/validateHeaderRow";
import useAsyncEffect from "../useAsyncEffect";
import { parseImportCSV } from "./parseImportCSV";

type RosterImport =
  | StudentImport
  | TeacherImport
  | EnrollmentImport
  | ClassImport;

type ValidateRow<T extends RosterImport> = (
  unvalidatedRow: unknown,
  index: number
) => T;

async function attemptParse<T extends RosterImport>(
  importType: RosterImportType,
  file: File,
  validate: ValidateRow<T>,
  validAliases: Record<keyof RosterImport, string[]>,
  mode: RosterImportType
) {
  try {
    const { data, errors } = await parseImportCSV(file);
    if (errors.length) {
      const [{ message, row: rowIndex }] = errors;
      importRowError(rowIndex + 1, message, importType);
    }

    // Get header row from first parsed row object
    const headerRow = data.length
      ? Object.keys(data[0] as object).map((key) => key)
      : [];

    // Validate header row
    if (headerRow.length) {
      validateHeaderRow(headerRow, validAliases, mode);
    }

    return {
      // Offset index to account for header row
      result: data.map((row, index) => validate(row, index + 1)),
      error: "",
    };
  } catch (e) {
    return { result: [], error: e.message };
  }
}

interface UseRosterCSVFileParams<T extends RosterImport> {
  importType: RosterImportType;
  rosterFile: File | null;
  validateRow: ValidateRow<T>;
  validAliases: Record<keyof RosterImport, string[]>;
  mode: RosterImportType;
}
export function useRosterCSVFile<T extends RosterImport>({
  importType,
  rosterFile,
  validateRow,
  validAliases,
  mode,
}: UseRosterCSVFileParams<T>) {
  const [rosterImport, setRosterImport] = useState<T[]>([]);
  const [fileError, setFileError] = useState("");
  const [processing, setProcessing] = useState(false);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setProcessing(true);
        setRosterImport([]);
        setFileError("");
      }
      if (rosterFile) {
        const { result, error } = await attemptParse(
          importType,
          rosterFile,
          validateRow,
          validAliases,
          mode
        );
        if (!isCancelled()) {
          setRosterImport(result);
          setFileError(error);
        }
      }
      if (!isCancelled()) {
        setProcessing(false);
      }
    },
    [rosterFile, validateRow]
  );

  return {
    rosterImport,
    fileError,
    processing,
  };
}
