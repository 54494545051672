import React, {FC} from "react";
import styled from "styled-components/macro";
import Column from "./Column";

const AssessmentImageUploadLabel: FC = (props) => (
    <Column {...props}>
        <div>Upload photo of your work</div>
        <OptionalLabel>(optional)</OptionalLabel>
    </Column>
);

export default styled(AssessmentImageUploadLabel)`
  align-items: flex-end;
  font-size: 0.75rem;
  margin-bottom: -1rem;
`;

const OptionalLabel = styled.div`font-weight: 400;`