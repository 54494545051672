import React, { FC, useCallback, ReactNode } from "react";
import styled from "styled-components/macro";
import AddImageButton from "./AddImageButton";
import ImagePreview from "./ImagePreview";
import Label from "./Label";
import Column from "./Column";

interface Props {
  label?: ReactNode;
  minimal?: boolean;
  alwaysShowLabel?: boolean;
  value: File | string | null;
  alt: string;
  onFileChange(file: File | null): any;
}

const ImageInput: FC<Props> = props => {
  const {
    label,
    value,
    alt,
    onFileChange,
    minimal,
    alwaysShowLabel,
    ...rest
  } = props;

  const handleRemoveClick = useCallback(() => {
    onFileChange(null);
  }, [onFileChange]);

  if (value) {
    return (
      <Column {...rest}>
        {label && alwaysShowLabel && <Label>{label}</Label>}
        <ImagePreview src={value} alt={alt} onRemoveClick={handleRemoveClick} />
      </Column>
    );
  } else {
    return (
      <Column {...rest}>
        {label && <Label>{label}</Label>}
        <AddImageButton onFileChange={onFileChange} minimal={minimal} />
      </Column>
    );
  }
};

export default styled(ImageInput)`
  margin-bottom: 1.5rem;

  ${Label} {
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;
