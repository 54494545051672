import React, { FC, useState, useMemo, useCallback } from "react";
import styled from "styled-components/macro";
import { isQuestionTextBlank } from "shared/lib/types/AssignmentQuestion";
import useAsyncEffect from "../utils/useAsyncEffect";
import getAssignmentById from "../api/assignments/getAssignmentById";
import { AssignmentWithSets } from "shared/lib/types/Assignment";
import { AssignmentTakeWithStudent } from "shared/lib/types/AssignmentTake";
import noop from "shared/lib/utils/noop";
import Row from "./Row";
import Column from "./Column";
import isPracticeQuestion, {
  isNotPracticeQuestion,
} from "../utils/isPracticeQuestion";
import getUngradedTakes from "../api/assignmentTakes/getUngradedTakes";
import indexSorter from "../utils/indexSorter";
import S3Image from "./S3Image";
import FormOverlay from "./FormOverlay";
import MathInputPreview from "./MathInputPreview";
import { fixSuperNumbers } from "../utils/fixSuperNumbers";
import getGradedTakes from "../api/assignmentTakes/getGradedTakes";
import TakeDropdown from "./TakeDropdown";

interface Props {
  assignmentId: number;
  classroomId?: number;
  onTakeClick?(studentId: number, takeId: number): any;
  onClose(): any;
}

const AssignmentGuide: FC<Props> = (props) => {
  const {
    assignmentId,
    classroomId,
    onTakeClick = noop,
    onClose,
    ...rest
  } = props;
  const [assignment, setAssignment] = useState<AssignmentWithSets | null>(null);
  const [ungradedTakes, setUngradedTakes] = useState<
    AssignmentTakeWithStudent[]
  >([]);
  const [gradedTakes, setGradedTakes] = useState<
    AssignmentTakeWithStudent[]
  >([]);

  const sets = useMemo(
    () => assignment && [...assignment.sets].sort(indexSorter),
    [assignment]
  );

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedAssignment = await getAssignmentById(assignmentId);

      if (!fetchedAssignment) {
        onClose();
        return;
      }

      if (!isCancelled()) {
        setAssignment(fetchedAssignment);
      }
    },
    [assignmentId]
  );

  useAsyncEffect(
    async (isCancelled) => {
      if (!classroomId) {
        return;
      }
      const [fetchedUngradedTakes, fetchedGradedTakes] = await Promise.all([
        getUngradedTakes({
          assignmentId,
          classroomId,
        }),
        getGradedTakes({
          assignmentId,
          classroomId
        })
      ]);
      if (!isCancelled()) {
        setUngradedTakes(fetchedUngradedTakes);
        setGradedTakes(fetchedGradedTakes);
      }
    },
    [assignmentId, classroomId]
  );

  const handleTakeClick = useCallback(
    (take: AssignmentTakeWithStudent) => {
      onTakeClick(take.studentId, take.id);
    },
    [onTakeClick]
  );

  if (!assignment || !sets) {
    // Loading
    return null;
  }

  return (
    <FormOverlay {...rest} withLogo onClose={onClose}>
      <Content>
        <AssignmentTitle>
          {assignment.title}
          <AssignmentSubTitle>({assignment.subTitle})</AssignmentSubTitle>
        </AssignmentTitle>
        <TakeDropdown
          label={
            ungradedTakes.length > 0 || gradedTakes.length > 0
              ? "Select to see a student's work"
              : "Teacher Guide"
          }
          ungradedTakes={ungradedTakes}
          gradedTakes={gradedTakes}
          onTakeSelected={handleTakeClick}
          readOnly={ungradedTakes.length === 0 && gradedTakes.length === 0}
        />
        {sets.map((set, setIndex) => {
          const setNumber = setIndex + 1;
          const nonPracticeQuestions = set.questions
            .filter(isNotPracticeQuestion)
            .sort(indexSorter);
          const practiceQuestion = set.questions.find(isPracticeQuestion);

          return (
            <SetRow key={set.id}>
              <ExampleColumn>
                <SetTitle>
                  SET {setNumber} OF {sets.length}
                </SetTitle>
                <ExampleImageContainer>
                  {set.exampleImage && (
                    <ExampleImage
                      imageKey={set.exampleImage}
                      alt={`Set ${setNumber} example`}
                    />
                  )}
                </ExampleImageContainer>
              </ExampleColumn>
              <SampleColumn>
                <LearningTargetLabel>Learning Target</LearningTargetLabel>
                <LearningTargetText>{set.learningTarget}</LearningTargetText>
                <StepLabel>
                  1. Students study the{" "}
                  {set.isExampleCorrect ? "correct" : "incorrect"} sample work.
                </StepLabel>
                <StepLabel>
                  2. Students respond to questions related to the sample work.
                </StepLabel>
                {nonPracticeQuestions.map((question, questionIndex) => (
                  <Question key={question.id}>
                    {!isQuestionTextBlank(question) && (
                      <QuestionText>
                        <MathInputPreview value={question.questionText} />
                      </QuestionText>
                    )}
                    {question.questionImage && (
                      <QuestionImage imageKey={question.questionImage} />
                    )}
                    <SampleTextLabel>Sample response</SampleTextLabel>
                    {question.sampleResponseText && (
                      <SampleText>
                        <pre>
                          {fixSuperNumbers(question.sampleResponseText)}
                        </pre>
                      </SampleText>
                    )}
                    {question.sampleResponseImage && (
                      <SampleImage
                        imageKey={question.sampleResponseImage}
                        alt={`Set ${setNumber} question ${questionIndex + 1
                          } sample response`}
                      />
                    )}
                  </Question>
                ))}
                {practiceQuestion && (
                  <>
                    <StepLabel>
                      3. Students do a similar problem on their own.
                    </StepLabel>
                    <Question>
                      {!isQuestionTextBlank(practiceQuestion) && (
                        <QuestionText>
                          <MathInputPreview
                            value={practiceQuestion.questionText}
                          />
                        </QuestionText>
                      )}
                      {practiceQuestion.questionImage && (
                        <QuestionImage
                          imageKey={practiceQuestion.questionImage}
                          overlayHeight={40}
                        />
                      )}
                      <SampleTextLabel>Sample response</SampleTextLabel>
                      {practiceQuestion.sampleResponseText && (
                        <SampleText>
                          <pre>
                            {fixSuperNumbers(
                              practiceQuestion.sampleResponseText
                            )}
                          </pre>
                        </SampleText>
                      )}

                      {practiceQuestion.sampleResponseImage && (
                        <SampleImage
                          imageKey={practiceQuestion.sampleResponseImage}
                          alt={`Set ${setNumber} practice question`}
                        />
                      )}
                    </Question>
                  </>
                )}
              </SampleColumn>
            </SetRow>
          );
        })}
      </Content>
    </FormOverlay>
  );
};

export default styled(AssignmentGuide)`
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;

  ${MathInputPreview} {
    margin-bottom: 0;
  }
`;

const Content = styled(Column)`
  width: 860px;
  max-width: 100%;
`;

const AssignmentTitle = styled("h1")`
  color: #4a4a4a;
  width: 100%;
  font-size: 16px;
  line-height: 12px;
  padding: 15px 0 15px 26px;
  background-color: #f9f9f9;
  font-weight: normal;
  margin-bottom: 21px;
`;

const AssignmentSubTitle = styled("span")`
  font-size: 12px;
  margin-left: 5px;
`;

const SetRow = styled(Row)`
  & + & {
    margin-top: 20px;
  }
`;

const SetTitle = styled("h2")`
  color: #4a90e2;
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 20px;
`;

const ExampleColumn = styled(Column)`
  padding-right: 60px;
`;

const ExampleImageContainer = styled(Column)`
  flex-shrink: 0;
  width: 324px;
`;

const ExampleImage = styled(S3Image).attrs({ previewable: true })`
  width: 100%;
`;

const SampleColumn = styled(Column)`
  background-color: #f7f7f7;
  min-width: 344px;
  padding: 17px 24px 23px 24px;
  margin-top: 47px;
`;

const LearningTargetLabel = styled("h3")`
  color: #3a3a3a;
  font-size: 20px;
  font-weight: 900;
  margin: 0 0 5px 0;
`;

const LearningTargetText = styled("p")`
  font-size: 16px;
  line-height: 19px;
`;

const StepLabel = styled("h3")`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const Question = styled(Column)`
  & + & {
    margin-top: 10px;
  }
`;

const QuestionText = styled("p")`
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 10px;
  padding-left: 20px;
`;

const SampleTextLabel = styled("h4")`
  font-size: 13px;
  font-style: italic;
  font-weight: 900;
  line-height: 16px;
  padding-left: 30px;
  margin-bottom: 5px;
`;

const SampleText = styled("p")`
  font-size: 14px;
  line-height: 17px;
  padding-left: 30px;
  font-family: verveine, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 12px;
`;

const QuestionImage = styled(S3Image).attrs({ previewable: true })`
  width: calc(100% - 30px);
  height: auto;
  margin-top: 8px;
  margin-left: 30px;
  margin-bottom: 15px;
`;

const SampleImage = styled(S3Image).attrs({ previewable: true })`
  width: calc(100% - 30px);
  height: auto;
  margin-top: 8px;
  margin-left: 30px;
`;
