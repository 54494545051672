import React, { FC } from "react";
import styled from "styled-components/macro";
import Row from "./Row";
import Column from "./Column";
import ProgressSegmentColumn, { SegmentLink, SegmentName } from "./ProgressSegmentColumn";
import getS3ImageUrl from "../utils/getS3ImageUrl";
import { tabletMax } from "../constants/responsiveBreakpoints";

interface AssessmentSetProgress {
  currentSet: number;
  totalSets: number;
}

export interface Props {
  takeableAssignmentId: number;
  assignmentImageSrc: string | null;
  slideIndex: number;
  slideCount: number;
  segmentNames: readonly string[];
  assessmentSetProgress: AssessmentSetProgress;
}

const AssignmentSlide: FC<Props> = props => {
  const {
    takeableAssignmentId,
    assignmentImageSrc,
    slideIndex,
    slideCount,
    segmentNames,
    assessmentSetProgress,
    ...rest
  } = props;
  const imageSrc = assignmentImageSrc
    ? getS3ImageUrl(assignmentImageSrc)
    : null;

  const { currentSet, totalSets } = assessmentSetProgress;

  return (
    <Root>
      <SetCountAndContent>
        <SetIndicator>
          SET {currentSet} OF {totalSets}
        </SetIndicator>
        <ContainerAndContent>
          {assignmentImageSrc && imageSrc && (
            <AssignmentImageContainer imageSrc={imageSrc} />
          )}
          <Content {...rest} />
        </ContainerAndContent>
      </SetCountAndContent>
      <ProgressSegmentColumn
        takeableAssignmentId={takeableAssignmentId}
        index={slideIndex}
        total={slideCount}
        segmentNames={segmentNames}
      />
    </Root>
  );
};

export default styled(AssignmentSlide)``;

const Root = styled(Row)`
  padding: 0.5rem;
  @media screen and ${tabletMax} {
    flex-direction: column;
    justify-content: space-between;
    ${ProgressSegmentColumn} {
      flex-direction: row;
      width: 100%;
      height: 7px;
      padding: 0;
      order: 1;
      ${SegmentLink} {
        margin-top: 0;
      }
      ${SegmentLink} + ${SegmentLink} {
        margin-left: 4px;
      }
      ${SegmentName} {
        // At a point where we probably don't have ability to hover and/or focus the element
        opacity: 0;
      }
    }
  }
`;

const ContainerAndContent = styled(Row)`
  flex: 1 0 auto;
  @media screen and ${tabletMax} {
    flex-direction: column;
    align-items: center;
    order: 2;
    padding-top: 1rem;
  }
`;

const AssignmentImageContainer = styled.div<{ imageSrc: string }>`
  position: relative;
  width: 505px;
  max-width: 505px;
  height: 638px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
  box-shadow: 3px 5px 7px 0 rgba(0, 0, 0, 0.5);
  flex: 1 0 auto;
  ${props => `background-image: url('${props.imageSrc}');`}
`;

const Content = styled(Column)`
  flex: 1 0 auto;
  margin: 2rem 2rem 1rem 2rem;
`;

const SetCountAndContent = styled(Column)`
  flex: 1 0 auto;
`;


const SetIndicator = styled(Row)`
  color: #4A90E2;
  font-size: 1.25rem;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0.75rem 0 0.75rem 0.75rem;
`;
