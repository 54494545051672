import reorderArray from './reorderArray';

interface HasIndex {
  index: number;
}

export default function reoderIndexedArray<T extends HasIndex>(
  array: T[],
  startIndex: number,
  endIndex: number
): T[] {
  return reorderArray(array, startIndex, endIndex).map((value, index) => ({
    ...value,
    index
  }));
}
