import React, { FC, FormEvent, useState, useCallback, useMemo } from "react";
import styled from "styled-components/macro";
import { navigate } from "@reach/router";
import RouteProps from "../../RouteProps";
import TeacherPage from "../../components/TeacherPage";
import Teacher from "shared/lib/types/Teacher";
import getTakeFromId from "../../api/assignmentTakes/getTakeFromId";
import useAsyncEffect from "../../utils/useAsyncEffect";
import {
  AssignmentTakeWithAssignment,
  DetailedAssignmentTake
} from "shared/lib/types/AssignmentTake";
import AssignmentSetReviewForm from "../../components/AssignmentSetReviewForm";
import AssignmentTakeReview from "shared/lib/types/AssignmentTakeReview";
import Row from "../../components/Row";
import TeacherCommentForm from "../../components/TeacherCommentForm";
import Column from "../../components/Column";
import reviewAssignmentTake from "../../api/assignmentTakes/reviewAssignmentTake";
import * as teacherRoutes from "../../teacherRoutes";
import formatDate from "../../utils/formatDate";
import PrintIconButton from "../../components/PrintIconButton";
import BackArrowButton from "../../components/BackArrowButton";
import hideInPrint from "../../utils/hideInPrint";
import getStudentTakes from "../../api/assignmentTakes/getStudentTakes";
import Link from "../../components/Link";
import ConfirmModal from "../../components/ConfirmModal";
import Student from "shared/lib/types/Student";
import getStudentById from "../../api/students/getStudentById";
import Dropdown from "../../components/Dropdown";
import showConfirm from "../../utils/showConfirm";
import reopenAssignmentTake from "../../api/assignmentTakes/reopenAssignmentTake";
import getClassroomLessonPlan from "../../api/lessonPlan/getClassroomLessonPlan";
import LessonPlan from "shared/lib/types/LessonPlan";
import { formatFirstLast } from "shared/lib/utils/formatName";
import showAlert from "../../utils/showAlert";

interface Props extends RouteProps {
  takeId: string;
  studentId: string;
  teacher: Teacher;
  reload(): any;
  setTeacher(teacher: Teacher): any;
}

const TeacherTakeReviewPage: FC<Props> = props => {
  const takeId = parseInt(props.takeId, 10);
  const studentId = parseInt(props.studentId, 10);
  const { teacher, location, ...rest } = props;
  const [student, setStudent] = useState<Student | null>(null);
  const [takes, setTakes] = useState<AssignmentTakeWithAssignment[]>([]);
  const [take, setTake] = useState<DetailedAssignmentTake | null>(null);
  const [review, setReview] = useState<AssignmentTakeReview | null>(null);
  const [showEditWarning, setShowEditWarning] = useState(false);
  const [editWarningDisplayed, setEditWarningDisplayed] = useState(false);
  const [warningFocusEl, setWarningFocusEl] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);
  const shouldShowEditWarning = !!(
    !editWarningDisplayed &&
    take &&
    take.gradedAt
  );
  const [classroomLessonPlan, setClassroomLessonPlan] = useState<LessonPlan | null>(null);
  const [transitioning, setTransitioning] = useState(true);

  useAsyncEffect(
    async isCancelled => {
      if (!student) {
        return;
      }
      const [fetchedTake, fetchedTakes, lessonPlan] = await Promise.all([
        getTakeFromId(takeId),
        getStudentTakes(studentId),
        getClassroomLessonPlan(student.classroomId),
      ]);

      if (!fetchedTake) {
        navigate(teacherRoutes.classrooms);
        return;
      }

      const questions = fetchedTake.takeableAssignment.assignment.sets.flatMap(
        set => set.questions
      );
      const responses = fetchedTake.responses || [];

      if (!isCancelled()) {
        setTakes(fetchedTakes || []);
        setTake(fetchedTake);
        setReview({
          assignmentTakeId: fetchedTake.id,
          comment: fetchedTake.comment || "",
          grade: fetchedTake.grade || "",
          questionReviews: questions.map(question => {
            const response = responses.find(
              response => response.questionId === question.id
            );

            return {
              questionId: question.id,
              responseId: (response && response.id) || null,
              comment: (response && response.comment) || "",
              grade: (response && response.grade) || ""
            };
          })
        });
        setClassroomLessonPlan(lessonPlan);
        setTransitioning(false);
      }
    },
    [takeId, student]
  );

  useAsyncEffect(
    async isCancelled => {
      const fetchedStudent = await getStudentById(studentId);
      if (!fetchedStudent) {
        navigate(teacherRoutes.classrooms);
        return;
      }
      if (!isCancelled()) {
        setStudent(fetchedStudent);
      }
    },
    [studentId]
  );

  const navigateBack = useCallback(() => {
    window.history.back();
  }, []);

  const handleSaveClick = useCallback(async () => {
    if (!review || !take) {
      return;
    }

    await reviewAssignmentTake(take.id, review);
    navigateBack();
  }, [review, take, navigateBack]);

  const handleCommentChange = useCallback((comment: string) => {
    setReview(review => (review ? { ...review, comment } : null));
  }, []);

  const handleGradeChange = useCallback((grade: string) => {
    setReview(review => (review ? { ...review, grade } : null));
  }, []);

  const handlePrintClick = useCallback(() => {
    window.print();
  }, []);

  const handleTakeChange = useCallback(
    (takeId: number) => {
      navigate(teacherRoutes.assignmentTakeReview(studentId, takeId), { replace: true });
    },
    [studentId]
  );

  const handleReviewFocus = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (shouldShowEditWarning) {
        setWarningFocusEl(event.currentTarget);
        setShowEditWarning(true);
        setEditWarningDisplayed(true);
      }
    },
    [shouldShowEditWarning]
  );

  const handleConfirmEditWarning = useCallback(() => {
    setShowEditWarning(false);
    // Re-focus the input
    if (warningFocusEl) {
      warningFocusEl.focus();
    }
  }, [warningFocusEl]);

  const handleCancelEditWarning = useCallback(() => {
    setShowEditWarning(false);
    setEditWarningDisplayed(false);
  }, []);

  const handleSaveAndReopen = useCallback(async () => {
    if (!take || !review) {
      return;
    }
    const hasGradeEntered = !!review.grade;
    if (!await showConfirm({
      title: 'Reopen Assignment',
      message: `Are you sure you wish to save and reopen this assignment for revisions?${hasGradeEntered ? ' The grade will be removed.' : ''}`
    })
    ) {
      return;
    }
    await reviewAssignmentTake(take.id, review);
    await reopenAssignmentTake(take.id);
    navigateBack();
  }, [take, review, navigateBack]);

  const takeDropdownOptions = useMemo(() => {
    if (!classroomLessonPlan) {
      return [];
    }
    const { teacherUnits, globalUnits } = classroomLessonPlan;
    const units = [...globalUnits, ...teacherUnits];
    return units.flatMap(unit => {
      const unitTakes = takes.filter(take => take.takeableAssignment.assignment.unitId === unit.id);
      return unitTakes.map(unitTake => ({
        value: unitTake.id,
        label: `${unitTake.takeableAssignment.assignment.title} (${unitTake.takeableAssignment.assignment.subTitle})`
      }))
    })
  }, [takes, classroomLessonPlan]);

  const onOtherStudentClicked = useCallback(async (otherStudentId: number) => {
    if (!classroomLessonPlan || !take) {
      return null;
    }
    if (!await showConfirm({
      title: 'Change Student?',
      message: 'Are you sure you wish to view another student? Changes will not be saved.'
    })) {
      return;
    }
    const { takes: classroomTakes } = classroomLessonPlan;
    const otherStudentTake = classroomTakes
      .find(classroomTake =>
        classroomTake.studentId === otherStudentId && classroomTake.takeableAssignmentId === take.takeableAssignmentId
      );

    if (!otherStudentTake) {
      await showAlert({
        title: 'Not Taken',
        message: 'This student has not taken this assignment.'
      });
      return;
    }
    setTransitioning(true);
    return navigate(
      teacherRoutes.assignmentTakeReview(otherStudentId, otherStudentTake!.id), { replace: true }
    );
  }, [classroomLessonPlan, take]);

  if (!take || !review || !student || !classroomLessonPlan) {
    // Loading
    return null;
  }

  const { students, takes: classroomTakes } = classroomLessonPlan;
  const otherTakesOfThisAssignment = classroomTakes
    .filter(classroomTake => classroomTake.takeableAssignmentId === take.takeableAssignmentId);
  const studentsThatHaveTakenAssignment = students.filter(classroomStudent => otherTakesOfThisAssignment.some(otherTake => otherTake.studentId === classroomStudent.id))
  const { takeableAssignment, responses = [] } = take;
  const { assignment } = takeableAssignment;
  const { sets } = assignment;


  return (
    <TeacherPage {...rest} teacher={teacher} location={location}>
      {showEditWarning && (
        <ConfirmModal
          message="You are editing a graded assignment that the student may have already viewed."
          onConfirm={handleConfirmEditWarning}
          onCancel={handleCancelEditWarning}
        />
      )}
      <BackArrowButton onClick={navigateBack} />
      <StudentNameBar>
        <Dropdown
          options={
            studentsThatHaveTakenAssignment
              .filter(otherStudent => otherStudent.id !== student?.id)
              .map(otherStudent => ({ value: otherStudent.id, label: formatFirstLast(otherStudent) }))
          }
          onChange={onOtherStudentClicked}
          label={formatFirstLast(student)}
          disabled={!studentsThatHaveTakenAssignment.length}
        />
      </StudentNameBar>
      <ControlBar>
        <Dropdown
          label={`${take.takeableAssignment.assignment.title} (${take.takeableAssignment.assignment.subTitle})`}
          onChange={handleTakeChange}
          options={takeDropdownOptions}
        />
        <CenteredRow>
          {take.completedAt && (
            <CompleteDateText>
              Submitted {formatDate(take.completedAt)}
            </CompleteDateText>
          )}
          <PrintIconButton onClick={handlePrintClick} />
          <PresentButton
            to={teacherRoutes.assignmentTakePresent(studentId, takeId)}
          >
            Presentation Mode
          </PresentButton>
        </CenteredRow>
      </ControlBar>
      {transitioning ? null : (
        <>
          {sets.map((set, index) => (
            <AssignmentSetReviewForm
              key={set.id}
              takeId={takeId}
              takeableAssignmentId={takeableAssignment.id}
              setIndex={index}
              setCount={sets.length}
              student={student}
              assignment={assignment}
              set={set}
              responses={responses}
              review={review}
              onReviewChange={setReview}
              onReviewFocus={handleReviewFocus}
            />
          ))}
          <AssignmentGradeTitle>ASSIGNMENT SCORE</AssignmentGradeTitle>
          <FooterRow>
            <AssignmentGradeContainer>
              <TeacherCommentForm
                gradeLabel="Grade Assignment"
                comment={review.comment}
                grade={review.grade}
                onCommentChange={handleCommentChange}
                onGradeChange={handleGradeChange}
                onFocus={handleReviewFocus}
              />
            </AssignmentGradeContainer>
            <SaveButtons>
              <SaveButton onClick={handleSaveClick}>Return Scored Assignment to Student</SaveButton>
              <SaveButton
                onClick={handleSaveAndReopen}
                hide={take?.completedAt === null}
                disabled={take?.completedAt === null}
              >
                Return Unscored Assignment for Revisions
              </SaveButton>
            </SaveButtons>
          </FooterRow>
        </>
      )}

    </TeacherPage>
  );
};

export default styled(TeacherTakeReviewPage)`
  ${BackArrowButton} {
    margin-bottom: 10px;
    margin-left: 25px;
  }
`;

const AssignmentGradeTitle = styled("h1")`
  color: #4a90e2;
  font-size: 1.4rem;
  font-weight: 900;
  margin: 20px 0 20px 0;
`;

const StudentNameBar = styled(Row)`
  height: 54px;
  background-color: #f9f9f9;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  padding-left: 1.5rem;
  margin-bottom: 14px;
  z-index: 12;
`;

const ControlBar = styled(Row)`
  margin-bottom: 19px;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;

  > select {
    ${hideInPrint}
  }
`;

const CompleteDateText = styled("div")`
  color: #c0c0c0;
  font-size: 14px;
  font-style: italic;
  margin-right: 18px;
`;

const FooterRow = styled(Row)`
  align-items: center;
`;

const AssignmentGradeContainer = styled(Column)`
  min-height: 167px;
  width: 344px;
  background-color: #f7f7f7;
  padding: 20px 20px 20px 45px;
`;

const SaveButton = styled("button") <{ hide?: boolean }>`
  ${hideInPrint}
  height: 40px;
  width: 200px;
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  border-radius: 3px;
  background-color: #000000;
  border: none;
  margin: 0 0 0 144px;
  padding: 0 1.1875rem 0 1.1875rem;
  cursor: pointer;
  opacity: ${props => props.hide ? 0 : 1};
`;

const SaveButtons = styled(Column)`
  ${SaveButton} + ${SaveButton} {
    margin-top: 1rem;
  }
`;

const PresentButton = styled(Link)`
  ${hideInPrint}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 157px;
  border-radius: 3px;
  background-color: #000000;
  border: none;
  color: #ffffff;
  font-size: 14px;
  margin-left: 22px;
  cursor: pointer;
`;

const CenteredRow = styled(Row)`
  align-items: center;
`;
