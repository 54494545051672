import { useCallback, useMemo, useState } from "react";
import { TeacherImport } from "shared/lib/types/import/TeacherImport";
import { StudentImport } from "shared/lib/types/import/StudentImport";
import { EnrollmentImport } from "shared/lib/types/import/EnrollmentImport";
import {
  validateStudentImportRow,
  VALID_ALIASES as VALID_STUDENT_ALIASES,
} from "shared/lib/constants/import/schema/StudentImportSchema";
import {
  validateTeacherImportRow,
  VALID_ALIASES as VALID_TEACHER_ALIASES,
} from "shared/lib/constants/import/schema/TeacherImportSchema";
import {
  validateEnrollmentImportRow,
  VALID_ALIASES as VALID_ENROLLMENT_ALIASES,
} from "shared/lib/constants/import/schema/EnrollmentImportSchema";
import {
  validateClassImportRow,
  VALID_ALIASES as VALID_CLASS_ALIASES,
} from "shared/lib/constants/import/schema/ClassImportSchema";
import { useRosterCSVFile } from "./useRosterCSVFile";
import { ClassImport } from "shared/lib/types/import/ClassImport";
import { validateRosterImport } from "shared/lib/utils/import/validateRosterImport";
import { RosterImportValidation } from "shared/lib/types/import/validation/RosterImportValidation";
import { RosterImport } from "shared/lib/types/import/RosterImport";
import { importRoster } from "../../api/import/importRoster";
import showAlert from "../showAlert";
import getErrorMessage from "../getErrorMessage";

export function useRosterFileForm() {
  const [studentRosterFile, setStudentRosterFile] = useState<File | null>(null);
  const [teacherRosterFile, setTeacherRosterFile] = useState<File | null>(null);
  const [classroomRosterFile, setClassroomRosterFile] = useState<File | null>(
    null
  );
  const [enrollmentRosterFile, setEnrollmentRosterFile] = useState<File | null>(
    null
  );
  const [submitting, setSubmitting] = useState(false);

  const onTeacherRosterSelected = useCallback((file: File) => {
    setTeacherRosterFile(file);
  }, []);

  const onTeacherRosterFileRemoved = useCallback(() => {
    setTeacherRosterFile(null);
  }, []);

  const onStudentRosterSelected = useCallback((file: File) => {
    setStudentRosterFile(file);
  }, []);

  const onClassroomRosterSelected = useCallback((file: File) => {
    setClassroomRosterFile(file);
  }, []);

  const onEnrollmentRosterSelected = useCallback((file: File) => {
    setEnrollmentRosterFile(file);
  }, []);

  const onStudentRosterFileRemoved = useCallback(() => {
    setStudentRosterFile(null);
  }, []);

  const onClassroomRosterRemoved = useCallback(() => {
    setClassroomRosterFile(null);
  }, []);

  const onEnrollmentRosterFileRemoved = useCallback(() => {
    setEnrollmentRosterFile(null);
  }, []);

  const {
    fileError: studentFileError,
    rosterImport: studentImports,
    processing: studentImportProcessing,
  } = useRosterCSVFile<StudentImport>({
    importType: "student",
    rosterFile: studentRosterFile,
    validateRow: validateStudentImportRow,
    validAliases: VALID_STUDENT_ALIASES,
    mode: "student",
  });

  const {
    fileError: teacherFileError,
    rosterImport: teacherImports,
    processing: teacherImportProcessing,
  } = useRosterCSVFile<TeacherImport>({
    importType: "teacher",
    rosterFile: teacherRosterFile,
    validateRow: validateTeacherImportRow,
    validAliases: VALID_TEACHER_ALIASES,
    mode: "teacher",
  });

  const {
    fileError: enrollmentFileError,
    rosterImport: enrollmentImports,
    processing: enrollmentImportProcessing,
  } = useRosterCSVFile<EnrollmentImport>({
    importType: "enrollment",
    rosterFile: enrollmentRosterFile,
    validateRow: validateEnrollmentImportRow,
    validAliases: VALID_ENROLLMENT_ALIASES,
    mode: "enrollment",
  });

  const {
    fileError: classFileError,
    rosterImport: classImports,
    processing: classImportProcessing,
  } = useRosterCSVFile<ClassImport>({
    importType: "classroom",
    rosterFile: classroomRosterFile,
    validateRow: validateClassImportRow,
    validAliases: VALID_CLASS_ALIASES,
    mode: "classroom",
  });

  const rosterImport: RosterImport = {
    studentImports,
    classImports,
    teacherImports,
    enrollmentImports,
  };

  const combinedErrors: RosterImportValidation[] = useMemo(() => {
    if (Object.values(rosterImport).every((array) => array.length)) {
      return validateRosterImport(rosterImport);
    }
    return [];
  }, [rosterImport]);

  const onSubmit = useCallback(async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      await importRoster(rosterImport);
      await showAlert({
        title: "Import Success",
        message: "Roster was imported successfully",
      });
      setEnrollmentRosterFile(null);
      setClassroomRosterFile(null);
      setStudentRosterFile(null);
      setTeacherRosterFile(null);
    } catch (e) {
      await showAlert({
        title: "Error",
        message: `Failed to process roster import: ${getErrorMessage(e)}`,
      });
    } finally {
      setSubmitting(false);
    }
  }, [submitting, rosterImport]);

  const submitDisabled =
    !studentRosterFile ||
    !teacherRosterFile ||
    !classroomRosterFile ||
    !enrollmentRosterFile ||
    !!studentFileError ||
    !!teacherFileError ||
    !!classFileError ||
    !studentImports.length ||
    !teacherImports.length ||
    !enrollmentImports.length ||
    !classImports.length ||
    !!combinedErrors.length ||
    studentImportProcessing ||
    teacherImportProcessing ||
    enrollmentImportProcessing ||
    classImportProcessing ||
    submitting;

  return {
    studentRosterFile,
    classroomRosterFile,
    enrollmentRosterFile,
    teacherRosterFile,
    onStudentRosterSelected,
    onTeacherRosterSelected,
    onEnrollmentRosterFileRemoved,
    onStudentRosterFileRemoved,
    onTeacherRosterFileRemoved,
    onClassroomRosterRemoved,
    onClassroomRosterSelected,
    onEnrollmentRosterSelected,
    onSubmit,
    submitDisabled,
    studentImportProcessing,
    classImportProcessing,
    teacherImportProcessing,
    enrollmentImportProcessing,
    studentFileError,
    teacherFileError,
    enrollmentFileError,
    classFileError,
    combinedErrors,
    submitting,
  };
}
