import styled from 'styled-components/macro';

const FormSuccessText = styled('div')`
  color: #060;
  margin-bottom: 2rem;
  height: 2rem;
  text-align: center;
`;

export default FormSuccessText;
