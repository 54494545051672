import apiAxios from "../apiAxios";
import Classroom from "shared/lib/types/Classroom";

interface Props {
  name: string;
  classroomCode: string;
  startDate: string;
  endDate: string;
}

export default async function createClassroom(
  props: Props
): Promise<Classroom> {
  const response = await apiAxios.post("/classrooms", props);
  return response.data;
}
