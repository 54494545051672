import React, { FC } from "react";
import styled from "styled-components/macro";
import RouteProps from "../../RouteProps";
import StudentPage from "../../components/StudentPage";

interface Props extends RouteProps {
  studentId: number;
  reload(): any;
}

const StudentDevPage: FC<Props> = props => {
  const { studentId, reload, children, ...rest } = props;

  return <StudentPage {...rest} reload={reload}></StudentPage>;
};

export default styled(StudentDevPage)``;
