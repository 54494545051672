import styled from 'styled-components/macro';

const FilledButton = styled('button')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 0.4rem;
  border: none;
  background-color: #f7af30;
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
`;

export default FilledButton;
