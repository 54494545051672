import { ZwibblerClass } from "zwibbler/lib/zwibbler2";

/**
 * Loads math live.
 * Allows math equations to be rendered
 * and inserted inside the sketchpad.
 */
export default function loadZwibblerMathLive(Zwibbler: ZwibblerClass) {
  Zwibbler.component("MathLive", {
    style: `.zmf {
                display: flex;
                /* Emberex edit */
                position: absolute; 
                font-size: 30px;
                }
                .zmf math-field, .masker {
                flex: 1 1 auto;
                }
                .masker {
                /*background: rgba(0,0,128,0.1);*/
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0
                }
                `,
    template: `
                <div class="zmf">
                <math-field>
                    
                </math-field>
                <div class="masker"></div>
            </div>`,

    defaults: {
      text: "",
    },

    controller: function (scope) {
      let el = scope.$element;
      let ctx = scope.ctx;

      let mathlive = el.querySelector("math-field");
      mathlive.setOptions({
        virtualKeyboardMode: "onfocus",
        virtualKeyboards: "numeric symbols",
      });

      let masker = el.querySelector(".masker");
      masker.addEventListener("click", () => {
        scope.ctx.globals.clearAllMasks();
        masker.style.display = "none";
        mathlive.focus();
      });

      scope.showMask = (show: boolean) => {
        masker.style.display = show ? "block" : "";

        if (!show) {
          mathlive.blur();
          mathlive.executeCommand("hideVirtualKeyboard");
        }
      };

      scope.startEditing = () => {
        mathlive.focus();
      };

      // whenever Zwibbler chnages the property, eg, due to undo/redo,
      // update the screen.
      scope.$watch("props.text", (newvalue: string) => {
        if (mathlive.value !== newvalue) {
          mathlive.setValue(newvalue, { suppressChangeNotifications: true });
        }
      });

      // When user types, wait 500ms and then tell Zwibbler
      // about the propery.
      let timeout: any = 0;
      mathlive.addEventListener("input", () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          console.log("Input received; change value to ", mathlive.value);
          console.log("Value was: %s", scope.props.text);
          scope.props.text = mathlive.value;
          scope.ctx.digest();
        }, 500);
      });

      mathlive.addEventListener("mount", () => {
        // force zwibbler to recaculate the text area's size.
        setTimeout(() => {
          ctx.beginTransaction();
          ctx.setNodeProperty(scope.id, "_mounts", true);
          ctx.commit(true);
        }, 100);
      });
    },
  });
}
