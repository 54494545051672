import styled from "styled-components/macro";

const AssignmentSlideText = styled("div")`
  color: #787878;
  font-family: Lato;
  font-size: 19px;
  line-height: 23px;
  margin-bottom: 0.5rem;
  max-width: 430px;
  overflow-x: auto;
  overflow-y: hidden;
`;

export default AssignmentSlideText;
