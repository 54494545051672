import { AssignmentTakeWithStudent } from "shared/lib/types/AssignmentTake";
import apiAxios from "../apiAxios";

interface Props {
  classroomId: number;
  assignmentId: number;
}

export default async function getUngradedTakes(
  props: Props
): Promise<AssignmentTakeWithStudent[]> {
  const { classroomId, assignmentId } = props;
  const response = await apiAxios.get(
    `/classrooms/${classroomId}/assignments/${assignmentId}/ungraded-takes`
  );

  return response.data;
}
