import React, { FC } from "react";
import styled from "styled-components/macro";
import TopMenu, { Props as TopMenuProps } from "./TopMenu";
import Link from "./Link";
import TextButton from "./TextButton";
import * as adminRoutes from "../adminRoutes";

interface Props extends TopMenuProps {
  teacherId: number;
  isPending: boolean;
  onRemoveTeacherClick(): any;
  onSendInviteClick?(): any;
}

const TeacherTopMenu: FC<Props> = props => {
  const {
    teacherId,
    isPending,
    onRemoveTeacherClick,
    onSendInviteClick,
    ...rest
  } = props;

  return (
    <TopMenu {...rest}>
      <Link to={adminRoutes.editTeacher(teacherId)}>Edit Teacher</Link>
      <TextButton onClick={onRemoveTeacherClick}>Remove Teacher</TextButton>
      {isPending && (
        <TextButton onClick={onSendInviteClick}>Resend Invite</TextButton>
      )}
    </TopMenu>
  );
};

export default styled(TeacherTopMenu)`
  ${Link},
  ${Link} + ${TextButton} {
    margin-right: 2rem;
  }
`;
