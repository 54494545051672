import styled from 'styled-components/macro';

const FormErrorText = styled('div')`
  color: #a00;
  margin-bottom: 2rem;
  height: 2rem;
  text-align: center;
`;

export default FormErrorText;
