import React, { FC } from "react";
import styled from "styled-components/macro";
import AssignmentSlide, {
  Props as AssignmentSlideProps
} from "./AssignmentSlide";
import AssignmentArrowButton from "./AssignmentArrowLink";
import Column from "./Column";
import AssignmentCharacter from "shared/lib/types/AssignmentCharacter";
import AssignmentCharacterImage from "./AssignmentCharacterImage";

interface Props extends AssignmentSlideProps {
  buttonText: string;
  buttonLink: string;
  character: AssignmentCharacter | null;
  isExampleCorrect: boolean;
}

const IntroAssignmentSlide: FC<Props> = props => {
  const {
    buttonText,
    buttonLink,
    character,
    isExampleCorrect,
    ...rest
  } = props;

  return (
    <AssignmentSlide {...rest}>
      <Column>
        {character !== null && (
          <CharacterImage character={character} scale={1.5} />
        )}
        <CorrectLabel>
          Study the student's {isExampleCorrect ? "correct" : "incorrect"} work.
        </CorrectLabel>
        <AssignmentArrowButton to={buttonLink}>
          {buttonText}
        </AssignmentArrowButton>
      </Column>
    </AssignmentSlide>
  );
};

export default styled(IntroAssignmentSlide)`
  justify-content: flex-end;
`;

const CharacterImage = styled(AssignmentCharacterImage)`
  margin-bottom: 1rem;
`;

const CorrectLabel = styled("div")`
  color: #7e7e7e;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
`;
