import apiAxios from "../apiAxios";
import { DetailedAssignmentTake } from "shared/lib/types/AssignmentTake";

export default async function getTakeFromTakeableId(
  takeableAssignmentId: number
): Promise<DetailedAssignmentTake | null> {
  const response = await apiAxios.get(
    `/takes/from-takeable-assignment/${takeableAssignmentId}`
  );
  return response.data;
}
