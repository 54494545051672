import AssignmentSlide, {isIntroSlide} from "shared/lib/types/AssignmentSlide";

export function getAssignmentSlideName(slide: AssignmentSlide): string {
    const { set, } = slide;
    const { index: setIndex } = set;
    const setName = `Set ${setIndex + 1}`
    if(isIntroSlide(slide)) {
        return `${setName} - Study Example`
    } else if (slide.question.isPractice) {
        return `${setName} - Practice`;
    } else {
        return `${setName} - Question ${slide.questionIndex + 1}`;
    }
}