import React, {FC, ReactNode, useCallback, useMemo, useState} from "react";
import styled from "styled-components/macro";
import Student from "shared/lib/types/Student";
import capitalizeFirst from "shared/lib/utils/capitalizeFirst";
import alphabetizeCompare from "shared/lib/utils/alphabetizeCompare";
import {Match, navigate, RouteComponentProps, Router} from "@reach/router";
import useAsyncEffect from "../utils/useAsyncEffect";
import getClassroomStudents from "../api/classrooms/getClassroomStudents";
import ClassroomNav from "./ClassroomNav";
import Column from "./Column";
import FormOverlay from "./FormOverlay";
import CreateStudentForm, {Value as CreateStudentFormValue,} from "./CreateStudentForm";
import createStudent from "../api/students/createStudent";
import addStudentToClassroom from "../api/classrooms/addStudentToClassroom";
import List from "./List";
import useSort from "../utils/useSort";
import StudentTopMenu from "./StudentTopMenu";
import Route from "./Route";
import EditStudentForm, {Value as EditStudentFormValue,} from "./EditStudentForm";
import updateStudent from "../api/students/updateStudent";
import replaceWhere from "shared/lib/utils/replaceWhere";
import MoveStudentForm, {Value as MoveStudentFormValue,} from "./MoveStudentForm";
import getClassroomById from "../api/classrooms/getClassroomById";
import getClassroomByCode from "../api/classrooms/getClassroomByCode";
import deleteStudent from "../api/students/deleteStudent";
import * as teacherRoutes from "../teacherRoutes";
import {classroomStudent, classroomStudents} from "../teacherRoutes";
import useUrlState from "../utils/useUrlState";
import ExpandListItem from "./ExpandListItem";
import RadioCircle from "./RadioCircle";
import Row from "./Row";
import CompletedIcon from "./CompletedIcon";
import InProgressIcon from "./InProgressIcon";
import MaybeLink from "./MaybeLink";
import ReviewedIcon from "./ReviewedIcon";
import showConfirm from "../utils/showConfirm";
import RadioCircleButton from "./RadioCircleButton";
import getClassroomLessonPlan from "../api/lessonPlan/getClassroomLessonPlan";
import LessonPlan from "shared/lib/types/LessonPlan";
import {sort} from "shared/lib/utils/sort";
import {titleAscending} from "shared/lib/utils/assignmentComparators";

interface Props extends RouteComponentProps {
  classroomId: number;
  selectedStudentId?: string;
  showAddStudentModal?: boolean;
}

interface Data {
  students: Student[];
  lessonPlan: LessonPlan | null;
}

const ClassroomStudents: FC<Props> = (props) => {
  const { classroomId, path, navigate: _, location, ...rest } = props;
  const selectedStudentId = props.selectedStudentId
    ? parseInt(props.selectedStudentId, 10)
    : null;
  const [data, setData] = useState<Data>({
    students: [],
    lessonPlan: null,
  });

  const { students, lessonPlan } = data;

  const takes = lessonPlan?.takes ?? [];
  const takeableAssignments = lessonPlan?.takeableAssignments ?? [];

  const sortedStudents = useSort(students, (a, b) =>
    alphabetizeCompare(
      `${a.lastName} ${a.firstName}`,
      `${b.lastName} ${b.firstName}`
    )
  );
  const [showAddStudentModal, setShowAddStudentModal] = useUrlState(
    location,
    "add-student"
  );

  useAsyncEffect(async () => {
    const [
      fetchedStudents,
      fetchedLessonPlan,
    ] = await Promise.all([
      getClassroomStudents(classroomId),
      getClassroomLessonPlan(classroomId)
    ]);

    setData({
      students: fetchedStudents,
      lessonPlan: fetchedLessonPlan,
    });
  }, [classroomId]);

  const handleAddStudentCancel = useCallback(() => {
    setShowAddStudentModal();
  }, [setShowAddStudentModal]);

  const handleAddStudentSubmit = useCallback(
    async (value: CreateStudentFormValue) => {
      const student = await createStudent({ ...value, classroomId });
      setData((data) => ({ ...data, students: [...data.students, student] }));
      navigate(classroomStudent(classroomId, student.id));
    },
    [classroomId]
  );

  const handleCloseStudentMenu = useCallback(() => {
    navigate(classroomStudents(classroomId), { replace: true });
  }, [classroomId]);

  const handleCloseEditStudentModal = useCallback(() => {
    navigate(classroomStudent(classroomId, selectedStudentId), {
      replace: true,
    });
  }, [classroomId, selectedStudentId]);

  const handleEditStudentSubmit = useCallback(
    async (value: EditStudentFormValue) => {
      if (!selectedStudentId) {
        return;
      }
      await updateStudent(selectedStudentId, value);
      setData((data) => ({
        ...data,
        students: replaceWhere(
          data.students,
          (other) => other.id === selectedStudentId,
          (student) => ({
            ...student,
            ...value,
          })
        ),
      }));
      navigate(classroomStudent(classroomId, selectedStudentId));
    },
    [selectedStudentId, classroomId]
  );

  const handleMoveStudentSubmit = useCallback(
    async (value: MoveStudentFormValue) => {
      const [currentClassroom, newClassroom] = await Promise.all([
        getClassroomById(classroomId),
        getClassroomByCode(value.classroomCode),
      ]);

      if (!newClassroom) {
        throw new Error("No class exists with that id.");
      }

      // Do nothing if the student is being moved into the class they're already in
      if (currentClassroom && currentClassroom.id === newClassroom.id) {
        navigate(classroomStudents(classroomId), { replace: true });
        return;
      }
      const studentHasTakenAssignments = takeableAssignments.some(takeable => takes.some(take =>
          take.studentId === value.studentId &&
          take.takeableAssignmentId === takeable.id
      ));

      if(studentHasTakenAssignments) {
        const confirmed = await showConfirm({
            title: 'Are you sure you want to move this student?',
            message: 'This students has assessments that are taken for this class and their progress will not be transferred to the new class.',
        });
        if(!confirmed) {
            return;
        }
      }

      await addStudentToClassroom(newClassroom.id, value.studentId);
      setData((data) => ({
        ...data,
        students: data.students.filter((other) => other.id !== value.studentId),
      }));
      navigate(classroomStudents(classroomId), { replace: true });
    },
    [classroomId, takeableAssignments, takes]
  );

  const handleRemoveStudentClick = useCallback(async () => {
    const confirmed = await showConfirm({
      title: "Are you sure you want to delete this student?",
      message:
        "This action cannot be undone and will delete all data related to the student including assignment takes.",
    });
    if (confirmed && selectedStudentId) {
      await deleteStudent(selectedStudentId);
      setData((data) => ({
        ...data,
        students: data.students.filter(
          (other) => other.id !== selectedStudentId
        ),
      }));
      navigate(classroomStudents(classroomId));
    }
  }, [selectedStudentId, classroomId]);

  const units = useMemo(() => {
      if(lessonPlan === null) {
          return [];
      }
      const { teacherUnits, globalUnits } = lessonPlan;
      return [...globalUnits, ...teacherUnits];
  }, [lessonPlan]);

  const takeableAssignmentByUnitId = useMemo(() =>{
      return units.map(unit => {
          const { id, assignments = [] } = unit;
          return {
              unitId: id,
              unitTakableAssignments: takeableAssignments
                .filter(takeable => assignments.some(assignment => assignment.id === takeable.assignmentId)),
          }
      });
  }, [takeableAssignments, units]);

  return (
    <Column {...rest}>
      <Router>
        <Route
          path="/edit"
          render={() => {
            const student = students.find(
              (other) => other.id === selectedStudentId
            );
            if (!student) {
              return null;
            }
            return (
              <FormOverlay onClose={handleCloseEditStudentModal}>
                <EditStudentForm
                  student={student}
                  onSubmit={handleEditStudentSubmit}
                />
              </FormOverlay>
            );
          }}
        />
        <Route
          path="/move"
          render={() => {
            const student = students.find(
              (other) => other.id === selectedStudentId
            );
            if (!student) {
              return null;
            }
            return (
              <FormOverlay onClose={handleCloseEditStudentModal}>
                <MoveStudentForm
                  student={student}
                  onSubmit={handleMoveStudentSubmit}
                />
              </FormOverlay>
            );
          }}
        />
      </Router>
      {showAddStudentModal && (
        <FormOverlay onClose={handleAddStudentCancel}>
          <CreateStudentForm onSubmit={handleAddStudentSubmit} />
        </FormOverlay>
      )}
      {selectedStudentId && (
        <StudentTopMenu
          onClose={handleCloseStudentMenu}
          classroomId={classroomId}
          studentId={selectedStudentId}
          onRemoveStudentClick={handleRemoveStudentClick}
        />
      )}
      <ClassroomNav showAddStudentButton classroomId={classroomId} />
      <IconDescriptionRow>
        <CompletedIcon />
        <span>Completed</span>
        <InProgressIcon />
        <span>In Progress</span>
      </IconDescriptionRow>
      <List>
        {sortedStudents.map((student) => {
          const linkPath = classroomStudent(classroomId, student.id);

          return (
            <ExpandListItem
              key={student.id}
              header={
                <StudentListItemHeader>
                  <Match path={linkPath}>
                    {(props) => (
                      <RadioCircleButton
                        onClick={(event) => {
                          event.stopPropagation();
                          if (props.match) {
                            navigate(
                              teacherRoutes.classroomStudents(classroomId)
                            );
                          } else {
                            navigate(linkPath);
                          }
                        }}
                      >
                        <RadioCircle checked={!!props.match} />
                      </RadioCircleButton>
                    )}
                  </Match>
                  {capitalizeFirst(student.lastName)},{" "}
                  {capitalizeFirst(student.firstName)}
                </StudentListItemHeader>
              }
            >
            {units.map(unit => {
                const takableInUnit = takeableAssignmentByUnitId
                    .find(unitTakeable => unitTakeable.unitId === unit.id)?.unitTakableAssignments;
                // If there are no takeable assignments in this unit, don't show on the list
                if(!takableInUnit?.length) {
                    return null;
                }
                const { assignments: assignmentsInUnit = [] } = unit;
                return (
                    <Column key={unit.id}>
                      <UnitListItem>
                        <UnitSubList>
                          {sort(assignmentsInUnit, titleAscending).map((unitAssignment) => {
                           const takeableAssignmentInUnit = takableInUnit.find(takeableAssignment =>
                               takeableAssignment.assignmentId === unitAssignment.id
                           );
                           if(!takeableAssignmentInUnit) {
                               return null;
                           }
                            const assignmentTakes = takes.filter(
                                (take) =>
                                  take.takeableAssignmentId === takeableAssignmentInUnit.id &&
                                  take.studentId === student.id
                            );
                            const isCompleted = assignmentTakes.some(
                                (take) => !!take.completedAt
                            );
                            const isGraded = assignmentTakes.some(
                                (take) => !!take.gradedAt && take.reopenedAt === null
                            );
                            const isStarted = assignmentTakes.length > 0;
                            let linkTo: string | null = null;
                            let rightIcon: ReactNode = null;
                            let leftIcon: ReactNode = null;

                            if (isCompleted) {
                                rightIcon = <CompletedIcon />;
                            } else if (isStarted) {
                                rightIcon = <InProgressIcon />;
                            }

                            if (isGraded) {
                                leftIcon = <ReviewedIcon />;
                            }

                            if (isStarted || isCompleted) {
                                linkTo = teacherRoutes.assignmentTakeReview(
                                    student.id,
                                    assignmentTakes[0].id
                                );
                            }

                            return (
                              <AssignmentListItem key={unitAssignment.id}>
                                <AssignmentListItemLink to={linkTo}>
                                  <AssignmentLeftIconContainer>
                                    {leftIcon}
                                  </AssignmentLeftIconContainer>
                                  <AssignmentTitle>
                                    {unitAssignment.title}
                                  </AssignmentTitle>
                                  <AssignmentSubTitle>
                                    ({unitAssignment.subTitle})
                                  </AssignmentSubTitle>
                                  <AssignmentRightIconContainer>
                                    {rightIcon}
                                  </AssignmentRightIconContainer>
                                </AssignmentListItemLink>
                              </AssignmentListItem>
                            );
                          })}
                      </UnitSubList>
                    </UnitListItem>
                  </Column>
                );
            })}
            </ExpandListItem>
          );
        })}
      </List>
    </Column>
  );
};

export default styled(ClassroomStudents)``;

const StudentListItemHeader = styled(Row)`
  position: relative;
  align-items: center;
  flex: 1 1 auto;
  height: 100%;
  > ${RadioCircle} {
    margin: 0 0.75rem 0 0.75rem;
  }
`;

const IconDescriptionRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.75rem;

  span + img {
    margin-left: 1rem;
  }

  img + span {
    margin-left: 0.5rem;
  }
`;

const AssignmentListItem = styled("li")`
  background-color: #f9f9f9;
  color: #4a4a4a;
  font-size: 1rem;

  & + & {
    border-top: 1px solid #d8d8d8;
  }
`;

const UnitSubList = styled(List)`
`;

const UnitListItem = styled.li`
  background-color: #f9f9f9;
  color: #4a4a4a;
  font-size: 1rem;
  border-top: 1px solid #d8d8d8;
`


const AssignmentListItemLink = styled(MaybeLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
`;

const AssignmentTitle = styled("div")`
  align-items: center;
`;

const AssignmentSubTitle = styled("div")`
  font-size: 10px;
  margin-left: 0.5rem;
`;

const AssignmentLeftIconContainer = styled(Row)`
  width: 70px;
  padding-left: 14px;
  align-items: center;
`;

const AssignmentRightIconContainer = styled(Row)`
  width: 70px;
  padding-right: 14px;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;
