import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import useAsyncEffect from "../utils/useAsyncEffect";
import ClassroomAssignmentProgress from "shared/lib/types/ClassroomAssignmentProgress";
import Column from "./Column";
import getTeacherAssignmentProgress from "../api/assignments/getTeacherAssignmentProgress";
import ProgressBar from "./ProgressBar";
import Link from "./Link";
import * as teacherRoutes from "../teacherRoutes";

const AssignmentProgressReport: FC = props => {
  const [assignmentProgress, setAssignmentProgress] = useState<
    ClassroomAssignmentProgress[] | null
  >(null);

  useAsyncEffect(async isCancelled => {
    const fetchedAssignmentProgress = await getTeacherAssignmentProgress();
    if (!isCancelled()) {
      setAssignmentProgress(fetchedAssignmentProgress);
    }
  }, []);

  if (!assignmentProgress) {
    return (
      <Column {...props}>
        <Title>Assignment Progress</Title>
      </Column>
    );
  }

  return (
    <Column {...props}>
      <Title>Assignment Progress</Title>
      {assignmentProgress.map(ap => (
        <ClassGroup key={ap.classroom.id}>
          {ap.currentAssignment ? (
            <ClassHeader>
              <ClassNameLink to={teacherRoutes.classroom(ap.classroom.id)}>
                {ap.classroom.name}
              </ClassNameLink>{" "}
              is currently on {ap.currentAssignment.title}
            </ClassHeader>
          ) : (
            <ClassHeader>
              <ClassNameLink to={teacherRoutes.classroom(ap.classroom.id)}>
                {ap.classroom.name}
              </ClassNameLink>{" "}
              has no assigned assignments
            </ClassHeader>
          )}
          <ProgressBar progress={ap.completedCount} total={ap.assignedCount} />
          <ClassCompletedLabel>
            {ap.completedCount} of {ap.assignedCount} assignments completed
          </ClassCompletedLabel>
        </ClassGroup>
      ))}
    </Column>
  );
};

export default styled(AssignmentProgressReport)`
  width: 320px;
  max-width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.5);
  padding: 19px 35px 37px 35px;
  color: #000000;
`;

const Title = styled("h2")`
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 0;
  text-align: center;
`;

const ClassNameLink = styled(Link)`
  font-weight: bold;
  display: inline;
`;

const ClassGroup = styled(Column)``;

const ClassHeader = styled("p")`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 11px;
  margin-top: 28px;
`;

const ClassCompletedLabel = styled("p")`
  font-size: 11px;
  line-height: 14px;
  margin-top: 5px;
`;
