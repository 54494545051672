import apiAxios from "../apiAxios";

export default async function addStudentToClassroom(
  classroomId: number,
  studentId: number
): Promise<void> {
  await apiAxios.post(`/classrooms/${classroomId}/students/move`, {
    studentId
  });
}
