import apiAxios from "../apiAxios";
import Teacher from "shared/lib/types/Teacher";

interface Props {
  email: string;
  firstName: string;
  lastName: string;
  assignedCategoryIds: number[];
}

export default async function createTeacher(props: Props): Promise<Teacher> {
  const response = await apiAxios.post("/teachers", props);
  return response.data;
}
