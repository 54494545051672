import styled from "styled-components/macro";

const ListItem = styled("li")`
  position: relative;
  height: 2.5rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  background-color: white;

  & + & {
    border-top: 1px solid #979797;
  }
`;

export default ListItem;
