import React, { FC } from "react";
import { Router } from "@reach/router";
import StudentDashboardPage, {
  Tab as StudentDashboardTab
} from "../pages/student/StudentDashboardPage";
import StudentEditProfilePage from "../pages/student/StudentEditProfilePage";
import StudentDevPage from "../pages/student/StudentDevPage";
import StudentAssignmentPage from "../pages/student/StudentAssignmentPage";
import StudentAssignmentReviewPage from "../pages/student/StudentAssignmentReviewPage";

interface Props {
  studentId: number;
  reload(): Promise<void>;
}

const StudentRoot: FC<Props> = ({ studentId, reload }) => (
  <Router>
    <StudentDashboardPage
      default
      path="/"
      studentId={studentId}
      reload={reload}
      tab={StudentDashboardTab.assigned}
    />
    <StudentDashboardPage
      path="/completed"
      studentId={studentId}
      reload={reload}
      tab={StudentDashboardTab.completed}
    />
    <StudentEditProfilePage
      path="/profile"
      studentId={studentId}
      reload={reload}
    />
    <StudentAssignmentReviewPage
      path="/assignment/:takeableAssignmentId/review"
      studentId={studentId}
      reload={reload}
      takeableAssignmentId=""
    />
    <StudentAssignmentPage
      path="/assignment/:takeableAssignmentId/:slideIndex"
      studentId={studentId}
      reload={reload}
      takeableAssignmentId=""
      slideIndex=""
    />
    <StudentDevPage path="/dev" studentId={studentId} reload={reload} />
  </Router>
);

export default StudentRoot;
