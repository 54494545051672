import React, { useState, useCallback, useEffect } from "react";
import Session, {
  isAdminSession,
  isTeacherSession,
  isStudentSession
} from "shared/lib/types/Session";
import { ZwibblerClass } from "zwibbler/lib/zwibbler2";
import getSession from "./api/auth/getSession";
import AdminRoot from "./roots/AdminRoot";
import StudentRoot from "./roots/StudentRoot";
import TeacherRoot from "./roots/TeacherRoot";
import UnauthorizedRoot from "./roots/UnauthorizedRoot";

declare global {
  interface Window { Zwibbler: ZwibblerClass }
}

const App: React.FC = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);

  const loadSession = useCallback(async () => {
    const session = await getSession();
    setSession(session);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadSession();
  }, [loadSession]);

  if (loading) {
    return null;
  } else if (isStudentSession(session)) {
    return <StudentRoot studentId={session.studentId} reload={loadSession} />;
  } else if (isTeacherSession(session)) {
    return <TeacherRoot teacherId={session.teacherId} reload={loadSession} />;
  } else if (isAdminSession(session)) {
    return <AdminRoot adminId={session.adminId} reload={loadSession} />;
  } else {
    return <UnauthorizedRoot reload={loadSession} />;
  }
};

export default App;
