import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { WindowLocation } from "@reach/router";
import RouteProps from "../RouteProps";
import TeacherNavRow from "./TeacherNavRow";
import Page from "./Page";
import Column from "./Column";
import FormOverlay from "./FormOverlay";
import EditTeacherForm, {
  Value as EditTeacherFormValue
} from "./EditTeacherForm";
import updateTeacher from "../api/teachers/updateTeacher";
import useUrlState from "../utils/useUrlState";
import Teacher from "shared/lib/types/Teacher";

interface Props extends RouteProps {
  teacher: Teacher;
  location: WindowLocation | undefined;
  setTeacher(teacher: Teacher): any;
  reload(): any;
}

const TeacherPage: FC<Props> = props => {
  const { teacher, setTeacher, location, reload } = props;
  const [editProfileOpen, setEditProfileOpen] = useUrlState<boolean>(
    location,
    "edit-profile"
  );

  const closeEditProfileModal = useCallback(() => {
    setEditProfileOpen();
  }, [setEditProfileOpen]);

  const handleEditProfileSubmit = useCallback(
    async (value: EditTeacherFormValue) => {
      await updateTeacher(teacher.id, value);
      setTeacher({ ...teacher, ...value });
      closeEditProfileModal();
    },
    [closeEditProfileModal, teacher, setTeacher]
  );

  return (
    <Page {...props}>
      {editProfileOpen && teacher && (
        <FormOverlay onClose={closeEditProfileModal}>
          <EditTeacherForm
            title="Edit Profile"
            teacher={teacher}
            onSubmit={handleEditProfileSubmit}
            canAssignProducts={false}
          />
        </FormOverlay>
      )}
      <TeacherNavRow
        location={props.location}
        teacher={teacher}
        reload={reload}
      />
      <Content>{props.children}</Content>
    </Page>
  );
};

export default styled(TeacherPage)``;

const Content = styled(Column)`
  padding: 3rem 0 3rem 0;
  min-height: 100%;
  width: 800px;
  max-width: 100%;
  align-self: center;
  flex: 1;
`;
