import React, {
  FC,
  ComponentPropsWithoutRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components/macro";
import ImageOverlay from "./ImageOverlay";
import getImageUrl from "../utils/getImageUrl";

export interface ExpandableImageProps
  extends Omit<ComponentPropsWithoutRef<"img">, "src"> {
  src: string | File;
  previewable?: boolean;
  overlayHeight?: number;
}

const ExpandableImage: FC<ExpandableImageProps> = (props) => {
  const {
    src: rawSrc,
    alt,
    previewable = true,
    overlayHeight,
    ...rest
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const src = useMemo(() => getImageUrl(rawSrc), [rawSrc]);

  const handleClick = useCallback(() => {
    if (!previewable) {
      return;
    }
    setPreviewOpen(true);
  }, [previewable]);

  const handleOverlayClick = useCallback(() => {
    setPreviewOpen(false);
  }, []);

  return (
    <>
      <Image
        {...rest}
        src={src}
        alt={alt}
        onClick={handleClick}
        clickable={previewable}
      />
      {previewable && previewOpen && (
        <ImageOverlay
          open
          imageUrl={src}
          alt={alt || ""}
          onClick={handleOverlayClick}
          overlayHeight={overlayHeight}
        />
      )}
    </>
  );
};

export default styled(ExpandableImage)``;

const Image = styled("img")<{ clickable?: boolean }>`
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  flex-shrink: 0;
`;
