import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { navigate, WindowLocation, LinkGetProps } from "@reach/router";
import Admin from "shared/lib/types/Admin";
import Link from "./Link";
import NavRow from "./NavRow";
import logout from "../api/auth/logout";
import * as adminRoutes from "../adminRoutes";

interface Props {
  location: WindowLocation | undefined;
  admin: Admin;
  reload(): any;
}

const AdminNavRow: FC<Props> = props => {
  const { admin, location, reload, ...rest } = props;

  const handleLogoutClick = useCallback(async () => {
    await logout();
    await reload();
    navigate(adminRoutes.login);
  }, [reload]);

  return (
    <NavRow
      {...rest}
      isAdmin
      location={location}
      onLogoutClick={handleLogoutClick}
    >
      <li>
        <Link to={adminRoutes.teachers} getProps={getLinkProps}>
          Teacher Management
        </Link>
      </li>
      <li>
        <Link to={adminRoutes.assignments} getProps={getLinkProps}>
          Assignments Management
        </Link>
      </li>
      <li>
        <Link to={adminRoutes.data} getProps={getLinkProps}>
          Data
        </Link>
      </li>
    </NavRow>
  );
};

export default styled(AdminNavRow)``;

function getLinkProps(props: LinkGetProps) {
  return {
    style: {
      borderBottom: `5px solid ${props.isCurrent ? "black" : "transparent"}`
    }
  };
}
