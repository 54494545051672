import { ComponentProps } from "react";
import styled from "styled-components/macro";

const TextButton = styled("button")<ComponentProps<"button">>`
  border: none;
  outline: none;
  color: inherit;
  font-size: 100%;
  font-family: inherit;
  font-weight: inherit;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  display: inline-block;
  width: auto;
  height: auto;
  text-align: left;
  background-color: inherit;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export default TextButton;
