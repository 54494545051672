import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { Router, navigate } from "@reach/router";
import RouteProps from "../../RouteProps";
import TeacherPage from "../../components/TeacherPage";
import ClassesNav from "../../components/ClassesNav";
import CreateClassForm, {
  Value as CreateClassFormValue
} from "../../components/CreateClassForm";
import createClassroom from "../../api/classrooms/createClassroom";
import useAsyncEffect from "../../utils/useAsyncEffect";
import getTeacherClassrooms from "../../api/classrooms/getTeacherClassrooms";
import Classroom from "shared/lib/types/Classroom";
import ClassroomStudents from "../../components/ClassroomStudents";
import ClassroomLessonPlan from "../../components/ClassroomLessonPlan";
import FormOverlay from "../../components/FormOverlay";
import useUrlState from "../../utils/useUrlState";
import Teacher from "shared/lib/types/Teacher";
import Column from "../../components/Column";
import * as teacherRoutes from "../../teacherRoutes";
import EditClassForm, {
  Value as EditClassFormValue
} from "../../components/EditClassForm";
import deleteClassroom from "../../api/classrooms/deleteClassroom";
import updateClassroom from "../../api/classrooms/updateClassroom";
import replaceWhere from "shared/lib/utils/replaceWhere";
import ClassHistoryDropdown from "../../components/ClassHistoryDropdown";
import Row from "../../components/Row";
import isClassroomCompleted, {
  isClassroomCurrent
} from "shared/lib/utils/isClassroomCompleted";

interface Props extends RouteProps {
  teacher: Teacher;
  classroomId?: string;
  reload(): any;
  setTeacher(teacher: Teacher): any;
}

const TeacherClassesPage: FC<Props> = props => {
  const classroomId = props.classroomId
    ? parseInt(props.classroomId, 10)
    : null;
  const { teacher, location, ...rest } = props;
  const [showingCreateClassModal, setShowingCreateClassModal] = useUrlState<
    boolean
  >(location, "create-class");
  const [showCompletedClasses] = useUrlState<boolean>(
    location,
    "completed-classes"
  );
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [editClassId, setEditClassId] = useState<number | null>(null);
  const editClass = editClassId
    ? classrooms.find(classroom => classroom.id === editClassId)
    : null;
  const currentClassroom =
    classroomId ? classrooms.find(classroom => classroom.id === classroomId) ?? null : null;
  const isCurrentComplete =
    currentClassroom && isClassroomCompleted(currentClassroom);
  const filteredClassrooms = useMemo(() => {
    return classrooms.filter(
      showCompletedClasses || isCurrentComplete
        ? isClassroomCompleted
        : isClassroomCurrent
    );
  }, [classrooms, showCompletedClasses, isCurrentComplete]);

  useAsyncEffect(async () => {
    setClassrooms(await getTeacherClassrooms(teacher.id));
  }, [teacher.id]);

  useEffect(() => {
    if (classroomId === null && filteredClassrooms.length) {
      const [firstClassroom] = filteredClassrooms;
      navigate(teacherRoutes.classroom(firstClassroom.id), { replace: true });
    }
  }, [classroomId, filteredClassrooms]);

  const handleCreateClassClick = useCallback(() => {
    setShowingCreateClassModal(true);
  }, [setShowingCreateClassModal]);

  const handleCreateClassCancel = useCallback(() => {
    setShowingCreateClassModal(false);
  }, [setShowingCreateClassModal]);

  const handleCreateClassSubmit = useCallback(
    async (value: CreateClassFormValue) => {
      const classroom = await createClassroom(value);
      setClassrooms([...classrooms, classroom]);
      setShowingCreateClassModal(false);
      navigate(teacherRoutes.classroom(classroom.id));
    },
    [classrooms, setShowingCreateClassModal]
  );

  const handleEditClassCancel = useCallback(() => {
    setEditClassId(null);
  }, []);

  const handleEditClassSubmit = useCallback(
    async (value: EditClassFormValue) => {
      const { id, ...rest } = value;
      setClassrooms(classrooms =>
        replaceWhere(
          classrooms,
          classroom => classroom.id === id,
          classroom => ({ ...classroom, ...rest })
        )
      );
      await updateClassroom(id, rest);
      setEditClassId(null);
    },
    []
  );

  const handleDeleteClass = useCallback(async () => {
    if (!editClassId) {
      return;
    }
    setClassrooms(classrooms =>
      classrooms.filter(other => other.id !== editClassId)
    );
    navigate(teacherRoutes.classrooms);
    await deleteClassroom(editClassId);
  }, [editClassId]);

  const handleHistoryDropdownChange = useCallback(() => {
    if (showCompletedClasses || isCurrentComplete) {
      navigate(teacherRoutes.classrooms);
    } else {
      navigate(teacherRoutes.classrooms + "?completed-classes=true");
    }
  }, [showCompletedClasses, isCurrentComplete]);

  return (
    <TeacherPage {...rest} teacher={teacher} location={location}>
      <Header>
        <h1>Teacher Dashboard</h1>
        <ClassHistoryDropdown
          showingCompleted={!!(showCompletedClasses || isCurrentComplete)}
          onChange={handleHistoryDropdownChange}
        />
      </Header>
      <ClassesNav
        classrooms={filteredClassrooms}
        onEditClassClick={() => {
          if (currentClassroom){
            setEditClassId(currentClassroom.id)
          }
        }}
        onCreateClassClick={handleCreateClassClick}
        selectedClassroom={currentClassroom}
      />
      {showingCreateClassModal && (
        <FormOverlay withLogo onClose={handleCreateClassCancel}>
          <CreateClassForm onSubmit={handleCreateClassSubmit} />
        </FormOverlay>
      )}
      {editClass && (
        <FormOverlay withLogo onClose={handleEditClassCancel}>
          <EditClassForm
            classroom={editClass}
            onDelete={handleDeleteClass}
            onSubmit={handleEditClassSubmit}
          />
        </FormOverlay>
      )}
      <Content>
        {currentClassroom && (
          <Router primary={false}>
            <ClassroomLessonPlan
              default
              classroomId={currentClassroom.id}
              path="/assignments/*"
            />
            <ClassroomStudents
              classroomId={currentClassroom.id}
              path="/students/:selectedStudentId/*"
            />
            <ClassroomStudents
              classroomId={currentClassroom.id}
              path="/students/*"
            />
          </Router>
        )}
      </Content>
    </TeacherPage>
  );
};

export default styled(TeacherClassesPage)``;

const Content = styled(Column)`
  > div {
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled(Row)`
  padding-bottom: 4rem;
  align-items: center;
  h1 {
    margin-right: 35px;
    font-weight: normal;
  }
`;
