import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import Row from "./Row";
import Label from "./Label";
import RadioCircle from "./RadioCircle";

interface Props {
  checked: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  checkedColor?: string;
  borderColor?: string;
}

const LabeledRadio: FC<Props> = props => {
  const { checked, children, checkedColor, borderColor, ...rest } = props;

  return (
    <Row {...rest}>
      <RadioCircle
        checked={checked}
        checkedColor={checkedColor}
        borderColor={borderColor}
      />
      <Label>{children}</Label>
    </Row>
  );
};

export default styled(LabeledRadio)`
  align-items: center;
  cursor: pointer;

  ${Label} {
    margin-left: 0.5rem;
  }
`;
