import styled from "styled-components/macro";
import DateInput from "./DateInput";

const OutlinedInput = styled("input")`
  height: 50px;
  padding: 0 0 0 22px;
  margin: 0;
  border-radius: 3px;
  border: 2px solid #3a3a3a;
  background-color: #ffffff;
  font-size: 1rem;
  font-family: Lato;
`;

export const OutlinedDateInput = styled(DateInput)`
  input {
    height: 50px;
    padding: 0 0 0 22px;
    margin: 0;
    border-radius: 3px;
    border: 2px solid #3a3a3a;
    background-color: #ffffff;
    font-size: 1rem;
    font-family: Lato;
  }
`;

export default OutlinedInput;
