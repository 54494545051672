import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { DEFAULT_ADMIN_USERNAME, DEFAULT_ADMIN_PASSWORD } from "../../env";
import RouteProps from "../../RouteProps";
import useInputState from "../../utils/useInputState";
import adminLogin from "../../api/auth/adminLogin";
import getErrorMessage from "../../utils/getErrorMessage";
import MathByExampleLogo from "../../components/MathByExampleLogo";
import SerpLogo from "../../components/SerpLogo";
import Link from "../../components/Link";
import OutlinedInput from "../../components/OutlinedInput";
import BlockButton from "../../components/BlockButton";
import Form from "../../components/Form";
import Page from "../../components/Page";
import LinkRow from "../../components/LinkRow";
import FormErrorText from "../../components/FormErrorText";

interface Props extends RouteProps {
  onLogin(): any;
}

const AdminLoginPage: FC<Props> = props => {
  const { onLogin, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [username, handleUsernameChange] = useInputState(
    DEFAULT_ADMIN_USERNAME
  );
  const [password, handlePasswordChange] = useInputState(
    DEFAULT_ADMIN_PASSWORD
  );

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await adminLogin({ username, password });
      await onLogin();
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }, [username, password, onLogin]);

  return (
    <Page {...rest}>
      <MathByExampleLogo />
      <h1>Admin login</h1>
      <Form onSubmit={handleSubmit}>
        <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
        <OutlinedInput
          value={username}
          onChange={handleUsernameChange}
          autoComplete="email"
          placeholder="Login Email"
        />
        <OutlinedInput
          value={password}
          onChange={handlePasswordChange}
          type="password"
          autoComplete="current-password"
          placeholder="Password"
        />
        <ForgotPasswordRow>
          <Link to="/admin/forgot-password">Forgot password</Link>
        </ForgotPasswordRow>
        <BlockButton disabled={submitting}>Login</BlockButton>
      </Form>

      <LinkRow>
        <Link to="/">Student login</Link>
        <Link to="/teacher">Teacher login</Link>
      </LinkRow>
      <SerpLogo />
    </Page>
  );
};

export default styled(AdminLoginPage)`
  align-items: center;
  background-color: #F9F9F9;
  min-height: 100vh;
  padding: 12rem 0 3rem 0;

  h1 {
    margin-bottom: 2rem;
  }

  ${MathByExampleLogo} {
    width: 320px;
    height: 58px;
  }

  ${SerpLogo} {
    width: 107px;
    height: 50px;
  }

  ${Form} {
    display: flex;
    flex-direction: column;
    width: 19rem;
    max-width: 100%;
  }

  ${OutlinedInput} + ${OutlinedInput} {
    margin-top: 0.6rem;
  }

  ${BlockButton} {
    margin-top: 2rem;
    margin-bottom: 6rem;
  }

  ${LinkRow} {
    margin-bottom: 6rem;
  }
`;

const ForgotPasswordRow = styled(LinkRow)`
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;
