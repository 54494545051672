import React, { FC } from "react";
import styled from "styled-components/macro";
import Overlay from "./Overlay";
import getImageUrl from "../utils/getImageUrl";

interface Props {
  imageSrc?: File | string;
  imageUrl?: string;
  alt: string;
  open?: boolean;
  onClick(): any;
  overlayHeight?: number;
}

const ImageOverlay: FC<Props> = (props) => {
  const {
    imageSrc,
    imageUrl = getImageUrl(imageSrc || ""),
    alt,
    open = true,
    overlayHeight,
    ...rest
  } = props;

  if (!open) {
    return null;
  }

  return (
    <Overlay {...rest} inRoot fixed hideBodyScrollbar>
      {imageUrl && (
        <OverlayImage src={imageUrl} alt={alt} overlayHeight={overlayHeight} />
      )}
    </Overlay>
  );
};

export default styled(ImageOverlay)`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 200;
`;

const OverlayImage = styled("img")<{ overlayHeight?: number }>`
  width: auto;
  height: ${(props) =>
    props.overlayHeight ? `${props.overlayHeight}%` : "80%"};
  align-self: center;
`;
