import apiAxios from "../apiAxios";

interface Props {
  name: string;
  classroomCode: string;
  startDate: string;
  endDate: string;
}

export default async function updateClassroom(
  classroomId: number,
  props: Props
): Promise<void> {
  await apiAxios.put(`/classrooms/${classroomId}`, props);
}
