import React, { FC, FormEvent, MouseEvent, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Student from "shared/lib/types/Student";
import isBlank from "shared/lib/utils/isBlank";
import { validate as isValidEmail } from "email-validator";
import useInputState from "../utils/useInputState";
import bannerImageSrc from "../images/dashboard_banner.png";
import Form from "./Form";
import Column from "./Column";
import Row from "./Row";
import TextButton from "./TextButton";
import FormErrorText from "./FormErrorText";
import getErrorMessage from "../utils/getErrorMessage";
import CircleX from "./CircleX";

interface Props {
  student: Student;
  onSubmit(value: Value): any;
  onClose(): any;
}

export interface Value {
  firstName: string;
  lastName: string;
  email: string | null;
  password?: string;
}

const StudentEditProfileForm: FC<Props> = props => {
  const { student, onSubmit, onClose, ...rest } = props;
  const [error, setError] = useState<Error | null>(null);
  const [showingResetPassword, setShowingResetPassword] = useState(false);
  const [firstName, handleFirstNameChange] = useInputState(student.firstName);
  const [lastName, handleLastNameChange] = useInputState(student.lastName);
  const [email, handleEmailChange] = useInputState(student.email || "");
  const [password, handlePasswordChange] = useInputState("");
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState("");
  const [passwordChanged, setPasswordChanged] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        const value: Value = {
          firstName,
          lastName,
          email: null
        };

        if (!isBlank(email)) {
          if (!isValidEmail(email)) {
            throw new Error("Invalid email address.");
          }
          value.email = email;
        }

        if (!isBlank(password) || !isBlank(passwordConfirm)) {
          if (password !== passwordConfirm) {
            throw new Error(`Passwords don't match.`);
          }
          value.password = password;
        }

        await onSubmit(value);

        if (isBlank(password)) {
          onClose();
        } else {
          // Show "password changed successfully" message
          setPasswordChanged(true);
        }
      } catch (error) {
        setError(error);
      }
    },
    [email, firstName, lastName, password, passwordConfirm, onSubmit, onClose]
  );

  const handleResetPasswordClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setShowingResetPassword(true);
    },
    []
  );

  const handleCancelClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClose();
    },
    [onClose]
  );

  if (passwordChanged) {
    return (
      <Column {...rest}>
        <BannerImage />
        <PasswordChangedBox>
          <PasswordChangedCloseButton onClick={onClose}>
            <CircleX iconSize={24} />
          </PasswordChangedCloseButton>
          <p>You have successfully changed your password!</p>
        </PasswordChangedBox>
      </Column>
    );
  }

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <BannerImage />
      <Title>My Profile</Title>
      <FormContent>
        {error && <FormErrorText>{getErrorMessage(error)}</FormErrorText>}
        <InputRow>
          <InputColumn>
            <label>First Name</label>
            <Input value={firstName} onChange={handleFirstNameChange} />
          </InputColumn>
          <InputColumn>
            <label>Last Name</label>
            <Input value={lastName} onChange={handleLastNameChange} />
          </InputColumn>
        </InputRow>
        <InputRow>
          <InputColumn>
            <label>Email</label>
            <Input value={email} onChange={handleEmailChange} />
          </InputColumn>
        </InputRow>
        {showingResetPassword ? (
          <InputRow>
            <InputColumn>
              <label>New Password</label>
              <Input
                value={password}
                onChange={handlePasswordChange}
                type="password"
                autoComplete="new-password"
              />
            </InputColumn>
            <InputColumn>
              <label>Confirm Password</label>
              <Input
                value={passwordConfirm}
                onChange={handlePasswordConfirmChange}
                type="password"
                autoComplete="new-password"
              />
            </InputColumn>
          </InputRow>
        ) : (
          <ResetPasswordButton onClick={handleResetPasswordClick}>
            Reset Password
          </ResetPasswordButton>
        )}
        <ButtonRow>
          <SubmitButton>
            Save Changes
          </SubmitButton>
          <SubmitButton onClick={handleCancelClick}>
            Cancel
          </SubmitButton>
        </ButtonRow>
      </FormContent>
    </Form>
  );
};

export default styled(StudentEditProfileForm)`
  max-width: 100%;

  label {
    padding-left: 8px;
    color: #19579f;
    font-size: 1rem;
    font-weight: 900;
    margin-bottom: 4px;
  }
`;

const FormContent = styled(Column)`
  padding: 0 1rem 0 1rem;
`;

const BannerImage = styled("img").attrs({ alt: "", src: bannerImageSrc })`
  height: 182px;
  width: 498px;
  margin-bottom: 6px;
`;

const Title = styled("h1")`
  color: #19579f;
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 10px;
`;

const Input = styled("input")`
  height: 31px;
  border: 2px solid #9ce0fd;
  border-radius: 7px;
  margin-bottom: 20px;
  width: 100%;
  padding-left: 8px;
  color: #19579f;
  font-size: 16px;
  font-weight: 500;
`;

const InputColumn = styled(Column)`
  flex: 1;
`;

const InputRow = styled(Row)`
  > ${InputColumn} + ${InputColumn} {
    margin-left: 19px;
  }
`;

const ResetPasswordButton = styled(TextButton)`
  color: #19579f;
  font-size: 1rem;
  font-weight: 900;
  margin-left: 8px;
  margin-bottom: 20px;
`;

const ButtonRow = styled(Row)`
  margin-top: 1rem;
`;

const SubmitButton = styled("button")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-radius: 7px;
  background-color: #19579f;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 900;
  text-align: center;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  flex: 1;
  border: none;
  padding: 0 0 0 0;
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }
`;


const PasswordChangedBox = styled(Column)`
  position: relative;
  height: 311px;
  width: 413px;
  border-radius: 7px;
  background-color: #19579f;
  color: #ffffff;
  font-size: 36px;
  font-weight: 900;
  line-height: 44px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 37px;

  > p {
    width: 220px;
  }

  ${CircleX} {
    background-color: rgba(216, 216, 216, 0.3);
    color: black;
    width: 2rem;
    height: 2rem;
  }
`;

const PasswordChangedCloseButton = styled(TextButton)`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`;
