import React, { FC, useState, useCallback, useEffect } from "react";
import styled from "styled-components/macro";

interface Props {
  width?: number;
  min: number;
  max: number;
  value: number;
  onChange(value: number): any;
  title?: string;
}

const Slider: FC<Props> = (props) => {
  const { width = 78, min, max, value, onChange, ...rest } = props;
  const [active, setActive] = useState(false);
  const [sliderEl, setSliderEl] = useState<HTMLDivElement | null>(null);
  const left = 90 * ((value - min) / (max - min));

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      setActive(true);

      if (!sliderEl) {
        return;
      }
      const { left } = sliderEl.getBoundingClientRect();
      const xDistance = event.clientX - left;
      const offsetRatio = Math.max(Math.min(width, xDistance), 0) / width;
      const newValue = min + (max - min) * offsetRatio;
      onChange(newValue);
    },
    [onChange, min, max, sliderEl, width]
  );

  const handleMouseUp = useCallback(() => {
    setActive(false);
  }, []);

  useEffect(() => {
    if (!active || !sliderEl) {
      return;
    }

    function handleMouseUp() {
      setActive(false);
    }

    function handleMouseMove(event: MouseEvent) {
      if (!active || !sliderEl) {
        return;
      }
      const { left } = sliderEl.getBoundingClientRect();
      const xDistance = event.clientX - left;
      const offsetRatio = Math.max(Math.min(width, xDistance), 0) / width;
      const newValue = min + (max - min) * offsetRatio;
      onChange(newValue);
    }

    document.body.addEventListener("mouseup", handleMouseUp);
    document.body.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.body.removeEventListener("mouseup", handleMouseUp);
      document.body.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active, sliderEl, min, max, width, onChange]);

  return (
    <Root
      {...rest}
      ref={setSliderEl}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      width={width}
      aria-valuenow={value}
    >
      <Handle style={{ left: `${left}%` }} />
    </Root>
  );
};

export default styled(Slider)``;

const Root = styled("div")<{ width: number }>`
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 15px;
  width: ${(props) => props.width}px;
  border: 2px solid #ffffff;
  border-radius: 7.5px;
  background-color: #9ce0fd;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
`;

const Handle = styled("div")`
  position: absolute;
  top: -3px;
  box-sizing: border-box;
  height: 19px;
  width: 19px;
  border: 2px solid #19579f;
  background-color: #19579f;
  border-radius: 50%;
`;
