import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import FormLayout from "./FormLayout";
import useInputState from "../utils/useInputState";
import isBlank from "shared/lib/utils/isBlank";
import UnderlinedInput from "./UnderlinedInput";
import Admin from "shared/lib/types/Admin";

interface Props {
  title: string;
  admin: Admin;
  onSubmit(value: Value): any;
}

export interface Value {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
}

const EditAdminForm: FC<Props> = props => {
  const { title, admin, onSubmit, ...rest } = props;
  const [firstName, handleFirstNameChange] = useInputState(admin.firstName);
  const [lastName, handleLastNameChange] = useInputState(admin.lastName);
  const [email, handleEmailChange] = useInputState(admin.email);
  const [password, handlePasswordChange] = useInputState("");
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState("");

  const handleSubmit = useCallback(async () => {
    const value: Value = {
      firstName,
      lastName,
      email
    };

    if (isBlank(firstName)) {
      throw new Error("First name is required.");
    }
    if (isBlank(lastName)) {
      throw new Error("Last name is required.");
    }
    if (isBlank(email)) {
      throw new Error("Email is required.");
    }
    if (!isBlank(password)) {
      if (password !== passwordConfirm) {
        throw new Error(`Passwords don't match.`);
      }
      value.password = password;
    }

    await onSubmit(value);
  }, [firstName, lastName, email, password, passwordConfirm, onSubmit]);

  return (
    <FormLayout
      {...rest}
      onSubmit={handleSubmit}
      title={title}
      submitButtonText="Done"
    >
      <UnderlinedInput
        placeholder="Email"
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
      />
      <UnderlinedInput
        placeholder="First Name"
        autoComplete="given-name"
        value={firstName}
        onChange={handleFirstNameChange}
      />
      <UnderlinedInput
        placeholder="Last Name"
        autoComplete="family-name"
        value={lastName}
        onChange={handleLastNameChange}
      />
      <UnderlinedInput
        placeholder="Password"
        type="password"
        autoComplete="new-password"
        value={password}
        onChange={handlePasswordChange}
      />
      <UnderlinedInput
        placeholder="Password confirm"
        type="password"
        autoComplete="new-password"
        value={passwordConfirm}
        onChange={handlePasswordConfirmChange}
      />
    </FormLayout>
  );
};

export default styled(EditAdminForm)`
  padding-top: 100px;
`;
