import React, { FC, useMemo } from "react";
import styled from "styled-components/macro";
import AssignmentCategory from "shared/lib/types/AssignmentCategory";
import List from "./List";
import Row from "./Row";
import TextButton from "./TextButton";
import PlusButton from "./PlusButton";
import writeIconSrc from "../images/write_icon.png";

interface Props {
  selectedCategoryId: number | null;
  categories: AssignmentCategory[];
  onCategoryClick(categoryId: number): any;
  onAddCategoryClick(): any;
  onEditCategoryClick(categoryId: number): any;
}

const CategoriesNav: FC<Props> = props => {
  const {
    selectedCategoryId,
    categories,
    onCategoryClick,
    onAddCategoryClick,
    onEditCategoryClick,
    ...rest
  } = props;
  const sortedCategories = useMemo(
    () => [...categories].sort(compareCategories),
    [categories]
  );

  return (
    <Row {...rest}>
      <CategoryList>
        {sortedCategories.map(category => {
          const isActive = selectedCategoryId === category.id;
          return (
            <li key={category.id}>
              <CategoryButton
                active={isActive}
                onClick={() => onCategoryClick(category.id)}
              >
                {category.name}

                {isActive && (
                  <EditCategoryButton
                    onClick={() => onEditCategoryClick(category.id)}
                  >
                    <EditCategoryIcon />
                  </EditCategoryButton>
                )}
              </CategoryButton>
            </li>
          );
        })}
      </CategoryList>
      <PlusButton onClick={onAddCategoryClick}>Product</PlusButton>
    </Row>
  );
};

export default styled(CategoriesNav)`
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 1rem;

  ${PlusButton} {
    font-weight: bold;
  }
`;

const CategoryList = styled(List)`
  flex-direction: row;
  width: 650px;
  overflow-x: auto;

  li {
    position: relative;
    flex-shrink: 0;
  }

  li + li {
    margin-left: 2rem;
  }
`;

const CategoryButton = styled(TextButton)<{ active: boolean }>`
  font-weight: ${props => (props.active ? "bold" : "normal")};
`;

const EditCategoryButton = styled(TextButton)`
  position: absolute;
  right: -5px;
  transform: translate(100%, 2px);

  margin-left: 5px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const EditCategoryIcon = styled("img").attrs({
  src: writeIconSrc,
  alt: "Edit class"
})`
  height: 16px;
  width: 16px;
`;

function compareCategories(a: AssignmentCategory, b: AssignmentCategory) {
  return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
}
