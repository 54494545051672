import { useMemo, useCallback } from "react";
import { WindowLocation, NavigateOptions } from "@reach/router";
import getSearchParam from "./getSearchParam";
import setSearchParam from "./setSearchParam";

type UrlStateHook<T> = [
  T | null,
  (value?: T, overrideNavOptions?: NavigateOptions<any>) => void
];

/**
 * Hook for easily storing state as URL search params and updating when
 */
export default function useUrlState<T = string>(
  location: WindowLocation | undefined,
  key: string,
  navigateOptions?: NavigateOptions<any>
): UrlStateHook<T> {
  const searchString = location && location.search;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value: T | null = useMemo(() => getSearchParam(key), [
    searchString,
    key
  ]);

  const setValue = useCallback(
    (newValue?: T, overrideNavOptions?: NavigateOptions<any>) => {
      if (newValue) {
        setSearchParam(window.location, key, newValue, {
          ...navigateOptions,
          ...overrideNavOptions
        });
      } else {
        setSearchParam(window.location, key, undefined, {
          ...navigateOptions,
          ...overrideNavOptions
        });
      }
    },
    [key, navigateOptions]
  );

  return [value, setValue];
}
