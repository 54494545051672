import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import { UnitWithAssignments } from "shared/lib/types/Unit";
import getTeacherUnits from "../api/teachers/getTeacherUnits";
import useAsyncEffect from "../utils/useAsyncEffect";
import Column from "./Column";
import ExpandListItem from "./ExpandListItem";
import List from "./List";
import Row from "./Row";

interface Props {
  teacherId: number;
  onAssignmentClick(assignmentId: number): any;
}

const ReadonlyTeacherUnitList: FC<Props> = props => {
  const { teacherId, onAssignmentClick, ...rest } = props;
  const [teacherUnits, setTeacherUnits] = useState<UnitWithAssignments[]>([]);

  useAsyncEffect(
    async isCancelled => {
      const fetchedTeacherUnits = await getTeacherUnits(teacherId);
      if (!isCancelled()) {
        setTeacherUnits(fetchedTeacherUnits);
      }
    },
    [teacherId]
  );

  return (
    <Column {...rest}>
      <Title>Teacher-Created Materials</Title>
      <UnitList>
        {teacherUnits.map(unit => {
          const assignmentCount = unit.assignments.length;

          return (
            <UnitListItem
              key={unit.id}
              header={
                <UnitHeader>
                  {unit.name}{" "}
                  <AssignmentCountLabel>
                    {assignmentCount} Assignment
                    {assignmentCount === 1 ? "" : "s"}
                  </AssignmentCountLabel>
                </UnitHeader>
              }
            >
              {unit.assignments.map(assignment => (
                <AssignmentListItem
                  key={assignment.id}
                  onClick={() => onAssignmentClick(assignment.id)}
                >
                  {assignment.title}
                </AssignmentListItem>
              ))}
            </UnitListItem>
          );
        })}
      </UnitList>
    </Column>
  );
};

export default styled(ReadonlyTeacherUnitList)`
  padding-left: 20px;
  padding-bottom: 27px;
  border-bottom: 1px solid #d8d8d8;
`;

const Title = styled("h2")`
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  margin-bottom: 11px;
  margin-top: 12px;
`;

const UnitListItem = styled(ExpandListItem)`
  border-left: 1px solid #d8d8d8;
`;

const UnitHeader = styled(Row)`
  padding-left: 20px;
`;

const UnitList = styled(List)``;

const AssignmentListItem = styled(Row)`
  align-items: center;
  height: 40px;
  background-color: #ffffff;
  padding-left: 50px;
  cursor: pointer;

  & + & {
    border-top: 1px solid #d8d8d8;
  }
`;

const AssignmentCountLabel = styled("div")`
  color: #7e7e7e;
  font-size: 16px;
  font-style: italic;
  margin-left: 19px;
`;
