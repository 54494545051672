import Assignment from "shared/lib/types/Assignment";
import apiAxios from "../apiAxios";
import { AssignmentQuestionPart } from "shared/lib/types/AssignmentQuestion";
import AssignmentCharacter from "shared/lib/types/AssignmentCharacter";
import QuestionInputType from "shared/lib/types/QuestionInputType";
import uploadFile from "../media/uploadFile";

interface Props {
  title: string;
  subTitle: string;
  unitId: number;
  sets: Array<{
    questions: Array<{
      isPractice: boolean;
      questionText: AssignmentQuestionPart[];
      inputType: QuestionInputType;
      questionImage: File | string | null;
      sampleResponseImage: File | string | null;
      sampleResponseText: string;
    }>;
    learningTarget: string;
    isExampleCorrect: boolean;
    exampleImage: File | string | null;
    character: AssignmentCharacter | null;
  }>;
}

export default async function createAssignment(
  assignment: Props
): Promise<Assignment> {
  // Upload set/question images to S3
  const sets = await Promise.all(
    assignment.sets.map(async set => ({
      ...set,
      exampleImage:
        set.exampleImage instanceof File
          ? (await uploadFile(set.exampleImage)).key
          : null,
      questions: await Promise.all(
        set.questions.map(async question => ({
          ...question,
          questionImage:
            question.questionImage instanceof File
              ? (await uploadFile(question.questionImage)).key
              : null,
          sampleResponseImage:
            question.sampleResponseImage instanceof File
              ? (await uploadFile(question.sampleResponseImage)).key
              : null
        }))
      )
    }))
  );
  const response = await apiAxios.post("/assignments", { ...assignment, sets });
  return response.data;
}
