import React, { FC } from "react";
import styled from "styled-components/macro";
import {LinkGetProps, WindowLocation} from "@reach/router";
import Row from "./Row";
import Link from "./Link";

interface Props {
    location?: WindowLocation;
}

const AssignmentTabBar: FC<Props> = props => {
  const { location, ...rest } = props;
  const search = location?.search ?? '';

  return (
    <Row {...rest}>
      <RowLink getProps={linkProps => getLinkProps(linkProps, '/')} to={`/${search}`}>
        Assigned
      </RowLink>
      <RowLink getProps={linkProps => getLinkProps(linkProps, '/completed')}  to={`/completed${search}`}>
        Completed
      </RowLink>
    </Row>
  );
};

export default styled(AssignmentTabBar)`
  width: 530px;
  max-width: 100%;
`;

const RowLink = styled(Link)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  height: 2rem;
  width: 10rem;
  color: #19579f;
  border: 2px solid #9ce0fd;
  background-color: white;
  font-size: 16px;
  font-weight: 900;

  &:first-of-type {
    border-radius: 7px 0 0 7px;
    border-right: none;
  }

  &:last-of-type {
    border-radius: 0 7px 7px 0;
    border-left: none;
  }
`;

function getLinkProps(props: LinkGetProps, basePath: string) {
  const { location } = props;
  return {
    style: {
      backgroundColor: location.pathname === basePath ? "#9ce0fd" : "white"
    }
  };
}
