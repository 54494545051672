import React, { FC, FormEvent, ReactNode, useCallback, useRef } from "react";
import styled from "styled-components/macro";
import BlockButton from "./BlockButton";
import photoIconSrc from "../images/photo_icon.png";
import TextButton from "./TextButton";
import showAlert from "../utils/showAlert";

const validFileTypes = new Set(["image/png", "image/x-png", "image/jpeg"]);

interface SingleProps {
  multi?: false;
  minimal?: boolean;
  label?: ReactNode;
  onFileChange(file: File | null): any;
}

interface MultiProps {
  multi: true;
  minimal?: boolean;
  label?: ReactNode;
  onFileChange(files: Array<File | string>): any;
}

type Props = MultiProps | SingleProps;

const AddImageButton: FC<Props> = props => {
  const {
    multi,
    minimal,
    label = "UPLOAD IMAGE",
    onFileChange,
    ...rest
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const Root = minimal ? MinimalRoot : BlockRoot;

  const handleInputRef = useCallback((el: HTMLInputElement) => {
    inputRef.current = el;
  }, []);

  const handleClick = useCallback(() => {
    const inputEl = inputRef.current;
    if (!inputEl) {
      return;
    }
    inputEl.click();
  }, []);

  const handleFileChange = useCallback(
    async (event: FormEvent<HTMLInputElement>) => {
      const { files } = event.currentTarget;
      if (!files) {
        return;
      }

      const isValid = [...files].every(file => validFileTypes.has(file.type));
      if (!isValid) {
        await showAlert({
          title: "Invalid file type",
          message: "Only images can be uploaded."
        });
        return;
      }

      if (multi) {
        (onFileChange as MultiProps["onFileChange"])([...(files || [])]);
      } else {
        const file = (files && files[0]) || null;
        (onFileChange as SingleProps["onFileChange"])(file);
      }
    },
    [onFileChange, multi]
  );

  return (
    <Root as="div" role="button" {...rest} onClick={handleClick}>
      <input
        type="file"
        accept="image/x-png,image/png,image/jpeg"
        onChange={handleFileChange}
        ref={handleInputRef}
        multiple={multi}
      />
      <img alt="" src={photoIconSrc} />
      {label}
    </Root>
  );
};

export default styled(AddImageButton)``;

const BlockRoot = styled(BlockButton)`
  justify-content: flex-start;
  box-sizing: border-box;
  height: 48px;
  max-width: 225px;
  border: 2px solid #414141;
  border-radius: 3px;
  background-color: #ececec;
  color: #333333;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  transition: background-color 0.1s;

  &:hover {
    background-color: #dbdbdb;
  }

  img {
    height: 35px;
    width: 35px;
    margin: 0 13px 0 18px;
  }

  input {
    display: none;
  }
`;

const MinimalRoot = styled(TextButton)`
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 24px;
    width: 24px;
    margin: 0 8px 0 0;
  }

  input {
    display: none;
  }
`;
