import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Droppable } from 'react-beautiful-dnd';
import ExpandListItem, { Props as ExpandListItemProps } from './ExpandListItem';

interface Props extends ExpandListItemProps {
  droppableId: string | number;
}

const ExpandListItemDroppable: FC<Props> = props => {
  const { children, droppableId, ...rest } = props;
  return (
    <Droppable droppableId={droppableId.toString()}>
      {drag => {
        return (
          <ExpandListItem
            innerRef={drag.innerRef}
            {...rest}
            {...drag.droppableProps}
          >
            {children}
            {drag.placeholder}
          </ExpandListItem>
        );
      }}
    </Droppable>
  );
};

export default styled(ExpandListItemDroppable)``;
