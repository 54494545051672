import React, { FC } from "react";
import styled from "styled-components/macro";
import TopMenu, { Props as TopMenuProps } from "./TopMenu";
import Link from "./Link";
import TextButton from "./TextButton";
import * as teacherRoutes from "../teacherRoutes";
import DotDotDotIcon from "./DotDotDotIcon";
import MinusIcon from "./MinusIcon";
import MoveIcon from "./MoveIcon";

interface Props extends TopMenuProps {
  classroomId: number;
  studentId: number;
  onRemoveStudentClick(): any;
}

const StudentTopMenu: FC<Props> = props => {
  const { classroomId, studentId, onRemoveStudentClick, ...rest } = props;

  return (
    <TopMenu {...rest}>
      <Link to={teacherRoutes.editClassroomStudent(classroomId, studentId)}>
        <DotDotDotIcon size={24} />
        Edit Student
      </Link>
      <Link to={teacherRoutes.moveClassroomStudent(classroomId, studentId)}>
        <MoveIcon size={20} />
        Change Class
      </Link>
      <TextButton onClick={onRemoveStudentClick}>
        <MinusIcon size={24} />
        Remove Student
      </TextButton>
    </TopMenu>
  );
};

export default styled(StudentTopMenu)`
  ${Link}, ${TextButton} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${Link} + ${TextButton},
  ${TextButton} + ${Link},
  ${Link} + ${Link},
  ${TextButton} + ${TextButton} {
    margin-left: 2rem;
  }

  svg {
    margin-right: 0.5rem;
  }
`;
