import React, { FC } from "react";
import RouteProps from "../../RouteProps";
import sendStudentPasswordReset from "../../api/forgotPassword/sendStudentPasswordReset";
import ForgotPasswordPage from "../../components/ForgotPasswordPage";

const StudentForgotPasswordPage: FC<RouteProps> = props => {
  return (
    <ForgotPasswordPage
      {...props}
      title="Forgot Password - Student"
      backPath="/student"
      onSubmit={sendStudentPasswordReset}
    />
  );
};

export default StudentForgotPasswordPage;
