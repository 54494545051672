import AssignmentQuestion from "shared/lib/types/AssignmentQuestion";

export default function isPracticeQuestion(
  question: AssignmentQuestion
): boolean {
  return question.isPractice;
}

export function isNotPracticeQuestion(question: AssignmentQuestion): boolean {
  return !question.isPractice;
}
