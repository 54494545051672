import React, { Component } from "react";
import styled from "styled-components/macro";
import onClickOutside from "react-onclickoutside";
import Column from "./Column";
import List from "./List";
import Link from "./Link";
import * as studentRoutes from "../studentRoutes";
import TextButton from "./TextButton";
import Student from "shared/lib/types/Student";
import hamburgerIconSrc from "../images/hamburger_icon.svg"; 

interface Props {
  student: Student | null;
  onSignoutClick(): any;
}

interface State {
  open: boolean;
}

class StudentMenu extends Component<Props, State> {
  state = { open: false };

  handleButtonClick = () => this.setState({ open: !this.state.open });
  handleClickOutside = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  render() {
    const { student, onSignoutClick, ...rest } = this.props;
    const { open } = this.state;

    return (
      <Column {...rest}>
        <MenuContainer>
          <StudentNameLabel>
            {student && `${student.firstName} ${student.lastName}`}
          </StudentNameLabel>
          <div>
            <MenuButton onClick={this.handleButtonClick}>
              <img src={hamburgerIconSrc} alt="Hamburger Menu" />
            </MenuButton>
          </div>
        </MenuContainer>
        {open && (
          <Menu>
            <MenuList>
              <MenuListItem>
                <Link to={studentRoutes.editProfile}>
                  <span>My Profile</span>
                </Link>
              </MenuListItem>
              <MenuListItem>
                <Link to={studentRoutes.dashboard}>
                  <span>My Assignments</span>
                </Link>
              </MenuListItem>
              <MenuListItem>
                <TextButton onClick={onSignoutClick}>
                  <span>Sign Out</span>
                </TextButton>
              </MenuListItem>
            </MenuList>
          </Menu>
        )}
      </Column>
    );
  }
}

export default styled(onClickOutside(StudentMenu))``;

const MenuButton = styled("button")`
  height: 41px;
  width: 41px;
  background-color: #f6a218;
  display: flex;
  flex-direction: column;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Menu = styled(Column)`
  position: absolute;
  top: 41px;
  right: 0;
  z-index: 100;
  background-color: #f6a218;
  color: white;

  box-sizing: border-box;
  width: 187px;
  border: 3px solid #f5a623;
  border-radius: 7px 0 0 7px;
  background-color: #ffffff;
`;

const MenuList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 11px 51px 11px 0;
`;

const MenuListItem = styled("li")`
  display: flex;
  flex-direction: Row;
  text-align: right;
  height: 17px;
  color: #19579f;
  font-size: 14px;
  font-weight: 900;

  span {
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
  }

  &:hover span {
    border-bottom: 2px solid #19579f;
  }

  > ${Link}, > ${TextButton} {
    width: 120px;
    text-align: inherit;
  }

  & + & {
    margin-top: 5px;
  }
`;

const StudentNameLabel = styled("div")`
  color: #19579f;
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  line-height: 40px;
  padding-right: 10px;
`;

const MenuContainer = styled("div")`
  display: inline-flex;
`;
