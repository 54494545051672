import React, { FC, useCallback, useState, useRef } from "react";
import styled, { keyframes } from "styled-components/macro";
import Overlay from "./Overlay";
import XButton from "./XButton";
import Row from "./Row";
import useUnmount from "../utils/useUnmount";

export interface Props {
  onClose(): any;
}

const TopMenu: FC<Props> = props => {
  const { children, onClose, ...rest } = props;
  const [closing, setClosing] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCloseClick = useCallback(() => {
    setClosing(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      onClose();
    }, 200);
  }, [onClose]);

  useUnmount(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  });

  return (
    <Root {...rest} inRoot color="#3a3a3a" closing={closing}>
      <MenuBar>
        <CloseButton onClick={handleCloseClick} />
        {children}
      </MenuBar>
    </Root>
  );
};

export default styled(TopMenu)``;

const openAnimation = keyframes`
  from {
    transform: translate(0, -100%);
  } to {
    transform: translate(0, 0);
  }
`;

const closeAnimation = keyframes`
  from {
    transform: translate(0, 0);
  } to {
    transform: translate(0, -100%);
  }
`;

const Root = styled(Overlay)<{ closing: boolean }>`
  position: fixed;
  height: 2.5rem;
  bottom: auto;
  animation-name: ${props => (props.closing ? closeAnimation : openAnimation)};
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
`;

const MenuBar = styled(Row)`
  max-width: 100%;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  z-index: 99;
  color: white;
`;

const CloseButton = styled(XButton)`
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translate(0, -50%);
`;
