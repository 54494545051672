import { FC } from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps } from '@reach/router';

type Params = { [key: string]: string };

interface Props extends RouteComponentProps {
  render(routeProps: RouteComponentProps & { params: Params }): any;
}

const Route: FC<Props> = props => {
  const {
    render,
    path,
    navigate,
    location,
    uri,
    children,
    default: defaultRoute,
    ...params
  } = props;

  return render({
    path,
    navigate,
    location,
    params: params as Params
  });
};

export default styled(Route)``;
