import React, { FC, useState, useCallback } from "react";
import styled, { css } from "styled-components/macro";
import { navigate } from "@reach/router";
import Link from "../../components/Link";
import RouteProps from "../../RouteProps";
import Row from "../../components/Row";
import StudentPage from "../../components/StudentPage";
import useAsyncEffect from "../../utils/useAsyncEffect";
import getTakeFromTakeableId from "../../api/assignmentTakes/getTakeFromTakeableId";
import createAssignmentTake from "../../api/assignmentTakes/createAssignmentTake";
import { DetailedAssignmentTake } from "shared/lib/types/AssignmentTake";
import AssignmentSetReview from "../../components/AssignmentSetReview";
import { AssignmentSetWithQuestions } from "shared/lib/types/AssignmentSet";
import completeAssignmentTake from "../../api/assignmentTakes/completeAssignmentTake";
import * as studentRoutes from "../../studentRoutes";
import AssignmentSyncModal from "../../components/AssignmentSyncModal";
import chevronDownImageSrc from "../../images/chevron_down.png";
import formatDate from "../../utils/formatDate";
import AssignmentArrowButton from "../../components/AssignmentArrowButton";
import BackArrowButton from "../../components/BackArrowButton";

interface Props extends RouteProps {
  studentId: number;
  takeableAssignmentId: string;
  reload(): any;
}

const StudentAssignmentReviewPage: FC<Props> = props => {
  const { studentId, reload, children, ...rest } = props;
  const takeableAssignmentId = parseInt(props.takeableAssignmentId, 10);
  const [take, setTake] = useState<DetailedAssignmentTake | null>(null);

  useAsyncEffect(
    async isCancelled => {
      let take = await getTakeFromTakeableId(takeableAssignmentId);
      if (!take) {
        take = await createAssignmentTake(takeableAssignmentId);
      }
      if (!isCancelled()) {
        setTake(take);
      }
    },
    [takeableAssignmentId]
  );

  const handleDoneClick = useCallback(async () => {
    if (!take) {
      return;
    }
    if (!take.completedAt) {
      await completeAssignmentTake(take.id);
    }
    navigate(studentRoutes.dashboard);
  }, [take]);

  const handleBackClicked = () => navigate(studentRoutes.completedAssignments);

  if (!take) {
    return null;
  }

  const { completedAt, grade, reopenedAt } = take;
  const { sets } = take.takeableAssignment.assignment;
  const setCount = sets.length;

  let title = "Review and Submit Your Work";
  const isComplete = completedAt !== null;
  const isGraded = isComplete && grade !== null && reopenedAt === null;

  if (isComplete) {
    title = `${isGraded ? "Graded " : ""}Work Submitted ${formatDate(
      completedAt!
    )}`;
  }

  return (
    <StudentPage {...rest} reload={reload}>
      <AssignmentSyncModal />
      <Title>
        {isComplete && (
          <BackButtonContainer>
            <BackArrowButton onClick={handleBackClicked}/>
          </BackButtonContainer>
        )}
        {title}
      </Title>
      {sets.map((set, setIndex) => {
        const isLastSet = setIndex === sets.length - 1;
        const hasOverallFeedback = !!(take.grade || take.comment);
        const showOverallFeedback = isLastSet && hasOverallFeedback;

        return (
          <AssignmentSetReview
            key={set.id}
            isCompleted={!!take.completedAt}
            takeId={take.id}
            slideIndex={getSetSlideIndex(sets, set)}
            set={set}
            takeableAssignmentId={takeableAssignmentId}
            responses={take.responses || []}
            setIndex={setIndex}
            setCount={setCount}
            overallFeedback={
              showOverallFeedback
                ? { grade: take.grade || "", comment: take.comment || "" }
                : null
            }
          />
        );
      })}
      <ButtonRow>
        {!isComplete
          ? sets.map((set, setIndex) => {
              return (
                <LeftArrowButtonLink
                  key={setIndex}
                  to={studentRoutes.assignmentSlide(
                    takeableAssignmentId,
                    getSetSlideIndex(sets, set)
                  )}
                >
                  <img src={chevronDownImageSrc} alt="Go Back" />
                  {`Go back to Set ${setIndex + 1}`}
                </LeftArrowButtonLink>
              );
            })
          : null}
        {isComplete && !isGraded && (
          <BackDoneButton onClick={handleBackClicked}>
            Done
          </BackDoneButton>
        )}
        {!isComplete && (
          <DoneButton onClick={handleDoneClick}>Submit!</DoneButton>
        )}
        {isGraded && (
          <ReviewCompleteButton onClick={handleBackClicked} hideArrow>
            Done
          </ReviewCompleteButton>
        )}
      </ButtonRow>
    </StudentPage>
  );
};

export default styled(StudentAssignmentReviewPage)``;

const Title = styled("h1")`
  color: #f5a623;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const ButtonRow = styled(Row)`
  justify-content: center;
  overflow-x: auto;
  padding: 2.6875rem 0 4.1875rem 0;
`;

const leftArrowButtonStyles = css`
  text-align: center;
  height: 80px;
  width: 204px;
  border-radius: 7px;
  background-color: #19579f;
  color: #ffffff;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 43px 10px 67px 0;
  padding-right: 1.4375rem;
  img {
    height: 32px;
    width: 52px;
    transform: rotate(90deg);
  }
`;

const LeftArrowButtonLink = styled(Link)`
  ${leftArrowButtonStyles}
`;

const BackDoneButton = styled.button`
  ${leftArrowButtonStyles}
  padding-right: 0;
`;

const DoneButton = styled("button")`
  height: 80px;
  width: 369px;
  max-width: 100%;
  border-radius: 7px;
  align-self: center;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 900;
  line-height: 22px;
  text-align: center;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  background-color: #f5a623;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 0;
  margin: 43px 0 67px 0;
  cursor: pointer;
`;

function getSetSlideIndex(
  sets: AssignmentSetWithQuestions[],
  set: AssignmentSetWithQuestions
): number {
  let index = 0;
  for (const other of sets) {
    if (set === other) {
      return index;
    }
    index += 1 + other.questions.length;
  }
  return index;
}

const BackButtonContainer = styled(Row)`
  padding: 1.4375rem 0 0 4.75rem;
  color: #4a4a4a;
  font-size: 0.875rem;
`;

const ReviewCompleteButton = styled(AssignmentArrowButton)`
  width: 369px;
`;
