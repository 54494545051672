export const home = "/";

export const classrooms = `/classes`;

export const createClassroom = () =>
  `${window.location.pathname}?create-class=true`;

export const classroom = (classroomId: number) =>
  `${classrooms}/${classroomId}/`;

export const classroomStudents = (classroomId: number) =>
  `${classroom(classroomId)}students`;

export const classroomStudent = (
  classroomId: number,
  studentId?: number | null
) => `${classroomStudents(classroomId)}/${studentId || ""}`;

export const editClassroomStudent = (classroomId: number, studentId: number) =>
  `${classroomStudent(classroomId, studentId)}/edit`;

export const moveClassroomStudent = (classroomId: number, studentId: number) =>
  `${classroomStudent(classroomId, studentId)}/move`;

export const classroomAssignments = classroom;

export const addClassroomStudent = () =>
  `${window.location.pathname}?add-student=true`;

export const units = `/units`;

export const unitCategory = (categoryId: number) => `${units}/${categoryId}`;

export const unit = (unitId: number) => `${units}/${unitId}`;

export const createUnit = () => `${window.location.pathname}?create-unit=true`;

export const editUnit = (unitId: number) => `${unit(unitId)}/edit`;

export const editProfile = () =>
  `${window.location.pathname}?edit-profile=true`;

export const createAssignment = (unitId: number) =>
  `${unit(unitId)}/add-assignment`;

export const assignment = (unitId: number, assignmentId: number) =>
  `${unit(unitId)}/assignments/${assignmentId}`;

export const editAssignment = (unitId: number, assignmentId: number) =>
  `${assignment(unitId, assignmentId)}/edit`;

export const lessonPlanAssignment = (
  classroomId: number,
  assignmentId: number
) => `${classroomAssignments(classroomId)}assignments/${assignmentId}`;

export const assignmentTakeReview = (
  studentId: number,
  assignmentTakeId: number
) => `/students/${studentId}/takes/${assignmentTakeId}`;

export const assignmentTakePresent = (
  studentId: number,
  assignmentTakeId: number
) => `${assignmentTakeReview(studentId, assignmentTakeId)}/present`;
