import React, { FC, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import RouteProps from '../RouteProps';
import useInputState from '../utils/useInputState';
import getErrorMessage from '../utils/getErrorMessage';
import Link from './Link';
import Page from './Page';
import LinkRow from './LinkRow';
import Form from './Form';
import FormErrorText from './FormErrorText';
import FormSuccessText from './FormSuccessText';
import OutlinedInput from './OutlinedInput';
import BlockButton from './BlockButton';
import fail from '../utils/fail';

interface Props extends RouteProps {
  title: string;
  backPath: string;
  onSubmit(value: Value): any;
}

export interface Value {
  password: string;
  resetToken: string;
}

const ResetPasswordPage: FC<Props> = props => {
  const { title, backPath, onSubmit, ...rest } = props;
  const location = props.location || fail('Missing location.');
  const [resetToken, expiresAt] = useMemo<[string, Date]>(() => {
    const qs = new URLSearchParams(location.search);
    return [qs.get('resetToken') || '', new Date(qs.get('expiresAt') || '')];
  }, [location.search]);

  const expired = Date.now() >= expiresAt.getTime();
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [password, handlePasswordChange] = useInputState('');
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState('');
  const error = expired
    ? new Error('This password reset is expired.')
    : submitError;

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      if (password !== passwordConfirm) {
        throw new Error(`Passwords don't match.`);
      }

      await onSubmit({
        resetToken,
        password
      });
      setSubmitSuccess(true);
    } catch (error) {
      setSubmitError(error);
      setSubmitSuccess(false);
    } finally {
      setSubmitting(false);
    }
  }, [password, passwordConfirm, resetToken, onSubmit]);

  return (
    <Page {...rest}>
      <h1>{title}</h1>
      <Form onSubmit={handleSubmit}>
        {submitSuccess ? (
          <FormSuccessText>Password changed.</FormSuccessText>
        ) : (
          <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
        )}
        <OutlinedInput
          placeholder="New password"
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={handlePasswordChange}
        />
        <OutlinedInput
          placeholder="New password (confirmed)"
          type="password"
          autoComplete="new-password"
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
        />
        <BlockButton disabled={submitting || expired}>
          Change password
        </BlockButton>
      </Form>
      <LinkRow>
        <Link to={backPath}>Back</Link>
      </LinkRow>
    </Page>
  );
};

export default styled(ResetPasswordPage)`
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;

  h1 {
    margin-bottom: 2rem;
  }

  ${Form} {
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  ${OutlinedInput} {
    margin-bottom: 1rem;
  }

  ${BlockButton} {
    margin-top: 1rem;
  }
`;
