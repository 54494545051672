import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components/macro";
import { navigate } from "@reach/router";
import RouteProps from "../../RouteProps";
import useInputState from "../../utils/useInputState";
import getErrorMessage from "../../utils/getErrorMessage";
import MathByExampleLogo from "../../components/MathByExampleLogo";
import SerpLogo from "../../components/SerpLogo";
import OutlinedInput from "../../components/OutlinedInput";
import BlockButton from "../../components/BlockButton";
import Form from "../../components/Form";
import Column from "../../components/Column";
import FormErrorText from "../../components/FormErrorText";
import acceptTeacherInvitation from "../../api/invitations/acceptTeacherInvitation";
import isBlank from "shared/lib/utils/isBlank";
import showAlert from "../../utils/showAlert";

const TeacherLoginPage: FC<RouteProps> = props => {
  const inviteToken = useMemo(
    () => new URLSearchParams(window.location.search).get("invite_token"),
    []
  );
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [password, handlePasswordChange] = useInputState();
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState();

  const handleSubmit = useCallback(async () => {
    try {
      if (isBlank(password)) {
        throw new Error("You must enter a password.");
      }
      if (password !== passwordConfirm) {
        throw new Error(`Passwords don't match.`);
      }

      setSubmitting(true);
      await acceptTeacherInvitation({
        inviteToken: inviteToken as string,
        password
      });
      await showAlert({
        title: "Success!",
        message: "Your password has been set."
      });
      navigate("/teacher");
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }, [password, passwordConfirm, inviteToken]);

  useEffect(() => {
    // Can't accept an invite if there's no token
    if (!inviteToken) {
      navigate("/");
    }
  }, [inviteToken]);

  return (
    <Column {...props}>
      <MathByExampleLogo />
      <h1>Finish Registering</h1>
      <Form onSubmit={handleSubmit}>
        <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
        <OutlinedInput
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          type="password"
          autoComplete="new-password"
        />
        <OutlinedInput
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
          placeholder="Password Confirmed"
          type="password"
          autoComplete="new-password"
        />
        <BlockButton disabled={submitting}>Register</BlockButton>
      </Form>
      <SerpLogo />
    </Column>
  );
};

export default styled(TeacherLoginPage)`
  align-items: center;
  background-color: #F9F9F9;
  min-height: 100vh;
  padding: 6rem 0 3rem 0;

  h1 {
    margin-bottom: 2rem;
  }

  ${MathByExampleLogo} {
    width: 320px;
    height: 58px;
  }

  ${SerpLogo} {
    width: 107px;
    height: 50px;
  }

  ${Form} {
    display: flex;
    flex-direction: column;
    width: 19rem;
    max-width: 100%;
  }

  ${OutlinedInput} + ${OutlinedInput} {
    margin-top: 0.6rem;
  }

  ${BlockButton} {
    margin-top: 2rem;
    margin-bottom: 6rem;
  }
`;
