import React from "react";
import ReactDOM from "react-dom";
import AlertModal from "../components/AlertModal";

export interface AlertOptions {
  title: string;
  message: string;
}

export default async function showAlert(options: AlertOptions): Promise<void> {
  const root = document.createElement("div");
  root.classList.add("alert-root");
  document.body.appendChild(root);

  try {
    await new Promise<boolean>(resolve => {
      ReactDOM.render(
        <AlertModal
          title={options.title}
          message={options.message}
          onClose={resolve}
        />,
        root
      );
    });
  } finally {
    ReactDOM.unmountComponentAtNode(root);
    document.body.removeChild(root);
  }
}
