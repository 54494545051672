import React, { FC } from "react";
import styled from "styled-components/macro";
import bannerImage from "../../images/dashboard_banner.png";
import RouteProps from "../../RouteProps";
import AdminPage from "../../components/AdminPage";
import Admin from "shared/lib/types/Admin";
import Row from "../../components/Row";
import Link from "../../components/Link";
import * as adminRoutes from "../../adminRoutes";
import capitalizeFirst from "shared/lib/utils/capitalizeFirst";

interface Props extends RouteProps {
  admin: Admin;
  setAdmin(admin: Admin): any;
  reload(): any;
}

const AdminDashboardPage: FC<Props> = props => {
  const { location, admin, ...rest } = props;

  return (
    <AdminPage {...rest} location={location} admin={admin}>
      <WelcomeText>
        Welcome back, {capitalizeFirst(admin.firstName)}
      </WelcomeText>
      <BannerImage />
      <Links>
        <BlockLink to={adminRoutes.teachers}>Teachers</BlockLink>
        <BlockLink to={adminRoutes.assignments}>Assignments</BlockLink>
        <BlockLink to={adminRoutes.data}>Data</BlockLink>
        <BlockLink to={adminRoutes.rosterImport}>Import</BlockLink>
      </Links>
    </AdminPage>
  );
};

export default AdminDashboardPage;

const WelcomeText = styled("h1")`
  padding-bottom: 4rem;
  margin-bottom: 5rem;
  font-weight: normal;
  width: 800px;
  max-width: 100%;
  align-self: center;
`;

const BannerImage = styled("img").attrs({
  src: bannerImage,
  alt: ""
})`
  height: 182px;
  width: 498px;
  align-self: center;
  margin-bottom: 2.5rem;
`;

const Links = styled(Row)`
  justify-content: space-between;
  width: 700px;
  max-width: 100%;
  align-self: center;
`;

const BlockLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 10rem;
  font-size: 1rem;
  border-radius: 3px;
  color: white;
  background-color: #3a3a3a;
  text-align: center;
`;
