import React, { FC } from "react";
import RouteProps from "../../RouteProps";
import submitStudentPasswordReset from "../../api/forgotPassword/submitStudentPasswordReset";
import ResetPasswordPage from "../../components/ResetPasswordPage";

const StudentResetPasswordPage: FC<RouteProps> = props => {
  return (
    <ResetPasswordPage
      {...props}
      title="Reset Password - Student"
      backPath="/student"
      onSubmit={submitStudentPasswordReset}
    />
  );
};

export default StudentResetPasswordPage;
