import React, { FC } from 'react';
import RouteProps from '../../RouteProps';
import sendAdminPasswordReset from '../../api/forgotPassword/sendAdminPasswordReset';
import ForgotPasswordPage from '../../components/ForgotPasswordPage';

const AdminForgotPasswordPage: FC<RouteProps> = props => {
  return (
    <ForgotPasswordPage
      {...props}
      title="Forgot Password - Admin"
      backPath="/admin"
      onSubmit={sendAdminPasswordReset}
    />
  );
};

export default AdminForgotPasswordPage;
