import styled from "styled-components/macro";
import imageSrc from "../images/reviewed_icon.png";

const ReviewedIcon = styled("img").attrs({ src: imageSrc, alt: "Reviewed" })`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export default ReviewedIcon;
