import apiAxios from "../apiAxios";
import AssignmentCharacter from "shared/lib/types/AssignmentCharacter";
import QuestionInputType from "shared/lib/types/QuestionInputType";
import Assignment from "shared/lib/types/Assignment";
import { AssignmentQuestionPart } from "shared/lib/types/AssignmentQuestion";

interface Props {
  assignmentId: number;
  title: string;
  subTitle: string;
  sets: Array<{
    id?: number;
    learningTarget: string;
    isExampleCorrect: boolean;
    exampleImage: string | null;
    character: AssignmentCharacter | null;
    questions: Array<{
      id?: number;
      questionText: AssignmentQuestionPart[];
      isPractice: boolean;
      inputType: QuestionInputType;
      questionImage: string | null;
      sampleResponseText: string;
      sampleResponseImage: string | null;
    }>;
  }>;
}

export default async function updateAssignment(
  props: Props
): Promise<Assignment> {
  const { assignmentId, ...rest } = props;
  const response = await apiAxios.put(`/assignments/${assignmentId}`, rest);

  return response.data;
}
