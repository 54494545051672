import React, { FC, FormEvent, useCallback } from "react";
import styled from "styled-components/macro";
import { isQuestionTextBlank } from "shared/lib/types/AssignmentQuestion";
import Column from "./Column";
import Assignment from "shared/lib/types/Assignment";
import Student from "shared/lib/types/Student";
import { AssignmentSetWithQuestions } from "shared/lib/types/AssignmentSet";
import QuestionResponse from "shared/lib/types/QuestionResponse";
import QuestionInputType from "shared/lib/types/QuestionInputType";
import SketchpadPreview from "./SketchpadPreview";
import TeacherCommentForm from "./TeacherCommentForm";
import replaceWhere from "shared/lib/utils/replaceWhere";
import AssignmentTakeReview from "shared/lib/types/AssignmentTakeReview";
import Row from "./Row";
import PhotoIcon from "./PhotoIcon";
import S3Image from "./S3Image";
import MathInputPreview from "./MathInputPreview";
import ExpandableImage from "./ExpandableImage";
import { fixSuperNumbers } from "../utils/fixSuperNumbers";
import { BLANK_SKETCH } from "../constants/sketch/blankSketches";

interface Props {
  takeId: number;
  takeableAssignmentId: number;
  setIndex: number;
  setCount: number;
  assignment: Assignment;
  student: Student;
  set: AssignmentSetWithQuestions;
  responses: QuestionResponse[];
  review: AssignmentTakeReview;
  onReviewChange(review: AssignmentTakeReview): any;
  onReviewFocus?(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>): any;
}

const AssignmentSetReviewForm: FC<Props> = props => {
  const {
    takeId,
    takeableAssignmentId,
    setIndex,
    setCount,
    student,
    assignment,
    set,
    responses,
    review,
    onReviewChange,
    onReviewFocus,
    ...rest
  } = props;

  const handleCommentChange = useCallback(
    (questionId: number, comment: string) => {
      onReviewChange({
        ...review,
        questionReviews: replaceWhere(
          review.questionReviews,
          review => review.questionId === questionId,
          review => ({ ...review, comment })
        )
      });
    },
    [onReviewChange, review]
  );

  const handleGradeChange = useCallback(
    (questionId: number, grade: string) => {
      onReviewChange({
        ...review,
        questionReviews: replaceWhere(
          review.questionReviews,
          review => review.questionId === questionId,
          review => ({ ...review, grade })
        )
      });
    },
    [onReviewChange, review]
  );

  return (
    <Column {...rest}>
      <Title>
        SET {setIndex + 1} OF {setCount}
      </Title>
      <LearningTargetLabel>Learning Target</LearningTargetLabel>
      <LearningTargetText>{set.learningTarget}</LearningTargetText>
      <Column>
        <RowQuestion addSpaceBetween>
          <QuestionContainer>
            <ColumnTitle>Your Student's Responses</ColumnTitle>
            <ReviewSectionTitle>
              1. Study the student’s{" "}
              {set.isExampleCorrect ? "correct" : "incorrect"} work.
            </ReviewSectionTitle>
            {set.exampleImage && <ExampleImage imageKey={set.exampleImage} />}
          </QuestionContainer>
          <QuestionContainer>
            <ColumnTitle>Sample Responses (Key)</ColumnTitle>
            <GuideSectionHeader>
              1. Study the student’s{" "}
              {set.isExampleCorrect ? "correct" : "incorrect"} work.
            </GuideSectionHeader>
            {set.exampleImage && <ExampleImage imageKey={set.exampleImage} />}
          </QuestionContainer>
        </RowQuestion>

        {set.questions.map((question, questionIndex) => {
          const questionNumber = questionIndex + 1;
          const response = responses.find(
            response => response.questionId === question.id
          );
          const questionReview = review.questionReviews.find(
            review => review.questionId === question.id
          );
          const imagesFileName =
            response && response.images.length > 0
              ? getResponseImageFilename(
                student,
                assignment,
                response,
                setIndex,
                questionIndex,
                question.isPractice
              )
              : null;

          return (
            <RowQuestion
              key={question.id}
              addSpaceBetween={question.isPractice || questionIndex === 0}
            >
              <QuestionContainer>
                {questionIndex === 0 && (
                  <ReviewSectionTitle>
                    2. Answer these questions.
                  </ReviewSectionTitle>
                )}
                <QuestionReview key={question.id}>
                  {question.isPractice && (
                    <ReviewSectionTitle>
                      3. Now complete your own.
                    </ReviewSectionTitle>
                  )}
                  <QuestionReviewContent>
                    {!isQuestionTextBlank(question) && (
                      <ReviewQuestionText>
                        <MathInputPreview value={question.questionText} />
                      </ReviewQuestionText>
                    )}
                    <StudentResponseContainer>
                      {question.inputType === QuestionInputType.textarea ? (
                        <StudentTextResponse>
                          {response?.textResponse}
                        </StudentTextResponse>
                      ) : (
                        <StudentSketchResponse
                          value={response?.drawingResponse ?? BLANK_SKETCH}
                          backgroundImage={question.questionImage}
                          width={300}
                        />
                      )}
                    </StudentResponseContainer>
                    {response && response.images.length > 0 && (
                      <DownloadContainer>
                        <DownloadLabel>
                          <DownloadIcon />
                          This student has added an image
                        </DownloadLabel>
                        <StudentUploads>
                          {response &&
                            response.images.map(image => (
                              <StudentUploadImage key={image} src={image} />
                            ))}
                        </StudentUploads>
                        <DownloadLink
                          download={imagesFileName}
                          href={`/api/takes/${takeId}/responses/${response.id}/images`}
                        >
                          Download All
                        </DownloadLink>
                      </DownloadContainer>
                    )}
                    {questionReview && (
                      <TeacherCommentForm
                        comment={questionReview.comment}
                        grade={questionReview.grade}
                        onFocus={onReviewFocus}
                        onCommentChange={comment =>
                          handleCommentChange(question.id, comment)
                        }
                        onGradeChange={grade =>
                          handleGradeChange(question.id, grade)
                        }
                        gradeLabel={
                          question.isPractice
                            ? `Score Step ${questionNumber}`
                            : `Score Question ${questionNumber}`
                        }
                      />
                    )}
                  </QuestionReviewContent>
                </QuestionReview>
              </QuestionContainer>
              <QuestionContainer>
                {questionIndex === 0 && (
                  <ReviewSectionTitle>
                    2. Answer these questions.
                  </ReviewSectionTitle>
                )}
                {question.isPractice && (
                  <ReviewSectionTitle>
                    3. Now complete your own.
                  </ReviewSectionTitle>
                )}
                <GuideQuestions>
                  <Column key={question.id}>
                    {!isQuestionTextBlank(question) && (
                      <GuideQuestionText>
                        <MathInputPreview value={question.questionText} />
                      </GuideQuestionText>
                    )}
                    {question.questionImage && (
                      <QuestionImage
                        imageKey={question.questionImage}
                        alt={`Question ${questionIndex + 1}`}
                        overlayHeight={40}
                      />
                    )}
                    {question.sampleResponseText.length > 0 ||
                      question.sampleResponseImage ? (
                      <GuideSampleResponseLabel>
                        Sample response
                      </GuideSampleResponseLabel>
                    ) : null}
                    {question.sampleResponseText.length > 0 ? (
                      <GuideSampleResponseText>
                        <pre>
                          {fixSuperNumbers(question.sampleResponseText)}
                        </pre>
                      </GuideSampleResponseText>
                    ) : null}
                    {question.sampleResponseImage && (
                      <GuideSampleResponseImage
                        imageKey={question.sampleResponseImage}
                        alt={`Sample response for question "${question.index +
                          1}"`}
                      />
                    )}
                  </Column>
                </GuideQuestions>
              </QuestionContainer>
            </RowQuestion>
          );
        })}
      </Column>
    </Column>
  );
};

function getResponseImageFilename(
  student: Student,
  assignment: Assignment,
  response: QuestionResponse,
  setIndex: number,
  questionIndex: number,
  isPractice: boolean
): string {
  const multipleImages = response.images.length > 1;
  const extension = multipleImages ? "zip" : "png";
  const plural = multipleImages ? "s" : "";
  const studentLabel = `${student.firstName}_${student.lastName}`;
  const assignmentLabel = `${assignment.title}_${assignment.subTitle}`;
  const setLabel = `set_${setIndex + 1}`;
  const questionLabel = isPractice
    ? "practice_question"
    : `question_${questionIndex + 1}`;

  return `${studentLabel}-${assignmentLabel}-${setLabel}-${questionLabel}-image${plural}.${extension}`;
}

export default styled(AssignmentSetReviewForm)`
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;

    * {
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
`;

const ColumnTitle = styled("h1")`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const ExampleImage = styled(S3Image).attrs({ previewable: true })`
  width: 318px;
  height: auto;
  margin-bottom: 10px;
`;

const GuideQuestions = styled(Column)`
  padding-left: 1.25rem;
`;

const LearningTargetLabel = styled("h3")`
  color: #3a3a3a;
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 5px;
`;

const LearningTargetText = styled("p")`
  color: #000000;
  font-size: 1rem;
  line-height: 1.25rem;
`;

const GuideSectionHeader = styled("h4")`
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin-bottom: 10px;
`;

const GuideQuestionText = styled("p")`
  margin-bottom: 5px;
  color: #000;
  font-size: 0.75rem;
  line-height: 1rem;
`;

const GuideSampleResponseLabel = styled("h5")`
  color: #000000;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 900;
  margin-bottom: 6px;
`;

const GuideSampleResponseText = styled("p")`
  margin-bottom: 20px;
  font-family: verveine, sans-serif;
  font-weight: 400;
  font-style: normal;
`;

const GuideSampleResponseImage = styled(S3Image).attrs({ previewable: true })`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
`;

const QuestionImage = styled(S3Image).attrs({ previewable: true })`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
`;

const Title = styled("h2")`
  color: #4a90e2;
  font-size: 22px;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const QuestionReview = styled(Column)`
  & + & {
    margin-top: 1.25rem;
  }
`;

const QuestionReviewContent = styled(Column)`
  padding-left: 1.25rem;
`;

const ReviewSectionTitle = styled("h3")`
  color: #000000;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

const ReviewQuestionText = styled("p")`
  color: #000000;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
`;

const StudentResponseContainer = styled(Column)`
  margin-bottom: 1.25rem;
`;

const StudentTextResponse = styled("p")`
  font-family: stix-two-math, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1rem;
`;

const StudentSketchResponse = styled(SketchpadPreview)`
  background-color: #ffffff;
  canvas {
    border: 1px solid #979797;
  }
`;

const DownloadContainer = styled(Column)`
  align-items: flex-end;
  margin-bottom: 20px;
`;

const DownloadLabel = styled("p")`
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DownloadLink = styled("a")`
  height: 40px;
  width: 157px;
  border-radius: 3px;
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const DownloadIcon = styled(PhotoIcon)`
  margin-right: 8px;
  opacity: 0.6;
`;

const StudentUploads = styled(Column)`
  padding: 20px 0 20px 0;
`;

const StudentUploadImage = styled(ExpandableImage)`
  width: 100px;
  height: 100px;
  object-fit: contain;

  & + & {
    margin-top: 10px;
  }
`;

const RowQuestion = styled(Row) <{ addSpaceBetween?: boolean }>`
  justify-content: space-between;
  margin-top: ${props => (props.addSpaceBetween ? "20px" : "")};
`;

const QuestionContainer = styled(Column)`
  background-color: #f7f7f7;
  max-width: 366px;
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;
  flex: 1 0 auto;
`;
