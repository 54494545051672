import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import download from "js-file-download";
import RouteProps from "../../RouteProps";
import AdminPage from "../../components/AdminPage";
import Admin from "shared/lib/types/Admin";
import CsvExportForm, {
  Value as CsvExportValue
} from "../../components/CsvExportForm";
import Column from "../../components/Column";
import getResponseCsvRows from "../../api/data/getResponseCsvRows";

interface Props extends RouteProps {
  admin: Admin;
  setAdmin(admin: Admin): any;
  reload(): any;
}

const AdminDataPage: FC<Props> = props => {
  const { location, admin, ...rest } = props;

  const handleSubmit = useCallback(async (value: CsvExportValue) => {
    const data = await getResponseCsvRows({
      ...value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    download(data, "data.csv", "csv");
  }, []);

  return (
    <AdminPage {...rest} location={location} admin={admin}>
      <Title>Data</Title>
      <FormWrapper>
        <CsvExportForm onSubmit={handleSubmit} />
      </FormWrapper>
    </AdminPage>
  );
};

export default AdminDataPage;

const Title = styled("h1")`
  padding-bottom: 4rem;
  font-weight: normal;
  width: 797px;
  padding-bottom: 68px;
  border-bottom: 1px solid black;
  margin: 0 auto;
`;

const FormWrapper = styled(Column)`
  align-items: center;
  padding-left: 80px;
  padding-top: 48px;
`;
