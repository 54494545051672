import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import UnderlinedInput from "./UnderlinedInput";
import useInputState from "../utils/useInputState";
import isBlank from "shared/lib/utils/isBlank";
import FormLayout from "./FormLayout";
import CategoryListInput from "./CategoryListInput";

export interface Props {
  onSubmit(value: Value): any;
}

export interface Value {
  email: string;
  firstName: string;
  lastName: string;
  assignedCategoryIds: number[];
}

const CreateTeacherForm: FC<Props> = props => {
  const { onSubmit, ...rest } = props;
  const [email, handleEmailChange] = useInputState("");
  const [firstName, handleFirstNameChange] = useInputState("");
  const [lastName, handleLastNameChange] = useInputState("");
  const [assignedCategoryIds, setAssignedCategoryIds] = useState<number[]>([]);

  const handleSubmit = useCallback(async () => {
    if (isBlank(email)) {
      throw new Error("Email is required.");
    }
    if (isBlank(firstName)) {
      throw new Error("First name is required.");
    }
    if (isBlank(lastName)) {
      throw new Error("last name is required.");
    }

    await onSubmit({
      email,
      firstName,
      lastName,
      assignedCategoryIds
    });
  }, [email, firstName, lastName, assignedCategoryIds, onSubmit]);

  return (
    <FormLayout
      {...rest}
      title="Invite a Teacher"
      submitButtonText="Send Invite"
      onSubmit={handleSubmit}
    >
      <UnderlinedInput
        placeholder="First Name"
        autoComplete="given-name"
        value={firstName}
        onChange={handleFirstNameChange}
      />
      <UnderlinedInput
        placeholder="Last Name"
        autoComplete="family-name"
        value={lastName}
        onChange={handleLastNameChange}
      />
      <UnderlinedInput
        placeholder="Email"
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
      />
      <h3>Assigned Products</h3>
      <CategoryListInput
        value={assignedCategoryIds}
        onChange={setAssignedCategoryIds}
      />
    </FormLayout>
  );
};

export default styled(CreateTeacherForm)`
  padding-top: 100px;

  > h3 {
    margin-bottom: 2rem;
  }

  ${CategoryListInput} {
    margin-bottom: 2rem;
  }
`;
