import Sketch from "shared/lib/SketchPad/Sketch";
import { NodeType } from "shared/lib/SketchPad/Node";
import Drawable from "shared/lib/SketchPad/Drawable";

export const BLANK_SKETCH: Sketch = {
  drawables: [],
  width: 370,
  height: 412,
};

export const BLANK_PRACTICE_SKETCH: Sketch = {
  drawables: [],
  width: 830,
  height: 412,
};

const SKETCHPAD_TOOLS_WIDTH_OFFSET = 61;

export const BLANK_PRACTICE_SKETCH_CONTAINER_WIDTH =
  BLANK_PRACTICE_SKETCH.width + SKETCHPAD_TOOLS_WIDTH_OFFSET;

export const BLANK_SKETCH_CONTAINER_WIDTH =
  BLANK_SKETCH.width + SKETCHPAD_TOOLS_WIDTH_OFFSET;

// Should only be used in read only mode
export const getBlankDrawable = (width: number, height: number): Drawable[] => {
  return [
    {
      id: 0,
      type: NodeType.BaseNode,
      width,
      height,
    },
    {
      id: 1,
      type: NodeType.PageNode,
      parent: 0,
    },
  ];
};
