import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { Match } from "@reach/router";
import Column from "./Column";
import ClassroomNav from "./ClassroomNav";
import LessonPlan from "shared/lib/types/LessonPlan";
import ExpandListItem from "./ExpandListItem";
import useAsyncEffect from "../utils/useAsyncEffect";
import getClassroomLessonPlan from "../api/lessonPlan/getClassroomLessonPlan";
import List from "./List";
import LessonPlanListItem from "./LessonPlanListItem";
import * as teacherRoutes from "../teacherRoutes";
import LessonPlanAssignmentTopMenu from "./LessonPlanAssignmentTopMenu";
import enableTakingAssignment from "../api/assignments/enableTakingAssignment";
import disableTakingAssignment from "../api/assignments/disableTakingAssignment";
import UnitList, { AssignmentMoveEvent } from "./UnitList";
import useUrlState from "../utils/useUrlState";
import RouteProps from "../RouteProps";
import UnitTopMenu from "./UnitTopMenu";
import deleteUnit from "../api/units/deleteUnit";
import FormOverlay from "./FormOverlay";
import EditUnitForm, { Value as EditUnitFormValue } from "./EditUnitForm";
import replaceWhere from "shared/lib/utils/replaceWhere";
import updateUnit from "../api/units/updateUnit";
import AssignmentTopMenu from "./AssignmentTopMenu";
import deleteAssignment from "../api/assignments/deleteAssignment";
import AssignmentForm, { Value as AssignmentFormValue } from "./AssignmentForm";
import createAssignment from "../api/assignments/createAssignment";
import reorderIndexedArray from "../utils/reorderIndexedArray";
import reorderUnitAssignments from "../api/units/reorderUnitAssignments";
import swapBetweenIndexedArrays from "../utils/swapBetweenIndexedArrays";
import moveAssignmentToUnit from "../api/units/moveAssignmentToUnit";
import CreateUnitForm, { Value as CreateUnitFormValue } from "./CreateUnitForm";
import createUnit from "../api/units/createUnit";
import Row from "./Row";
import EditAssignmentForm, {
  Value as EditAssignmentFormValue,
} from "./EditAssignmentForm";
import updateAssignment from "../api/assignments/updateAssignment";
import uploadFile from "../api/media/uploadFile";
import TakeableAssignment from "shared/lib/types/TakeableAssignment";
import capitalizeFirst from "shared/lib/utils/capitalizeFirst";
import AssignmentTake from "shared/lib/types/AssignmentTake";
import PlusButton from "./PlusButton";
import AssignedIcon from "./AssignedIcon";
import CompletedIcon from "./CompletedIcon";
import ConfirmModal from "./ConfirmModal";
import showConfirm from "../utils/showConfirm";
import AssignmentGuide from "./AssignmentGuide";
import Assignment from "shared/lib/types/Assignment";

interface Props extends RouteProps {
  classroomId: number;
  path?: string;
  navigate?: any;
  location?: any;
}

const ClassroomLessonPlan: FC<Props> = (props) => {
  const classroomId = +props.classroomId;
  const { path, navigate, location, ...rest } = props;
  const [unassignAssignmentIds, setUnassignAssignmentIds] = useState<
    number[] | null
  >(null);
  const [lessonPlan, setLessonPlan] = useState<LessonPlan | null>(null);
  const [selectedUnitIdString, setSelectedUnitId] = useUrlState<
    string | number
  >(location, "unit");
  const [
    selectedAssignmentIdsString,
    setSelectedAssignmentIds,
  ] = useUrlState<string>(location, "assignment");
  const [assignmentGuideId, setAssignmentGuideId] = useUrlState<
    string | number
  >(location, "guide");
  const [editUnitId, setEditUnitId] = useState<number | null>(null);
  const editUnit =
    (editUnitId &&
      lessonPlan &&
      lessonPlan.teacherUnits.find((unit) => unit.id === editUnitId)) ||
    null;
  const [showCreateUnitForm, setShowCreateUnitForm] = useState(false);
  const [showCreateAssignmentForm, setShowCreateAssignmentForm] = useState(
    false
  );
  const [editAssignmentIdString, setEditAssignmentId] = useUrlState<
    string | number
  >(location, "edit-assignment");
  const editAssignmentId = editAssignmentIdString
    ? parseInt(editAssignmentIdString as string, 10)
    : null;

  const selectedUnitId = selectedUnitIdString
    ? parseInt(selectedUnitIdString.toString(), 10)
    : null;

  const selectedUnit =
    selectedUnitId && lessonPlan
      ? lessonPlan.teacherUnits.find((unit) => unit.id === selectedUnitId)
      : null;

  const selectedAssignmentIds = selectedAssignmentIdsString
    ? selectedAssignmentIdsString
        .toString()
        .split(",")
        .map((idString) => +idString)
    : null;

  const areOwnAssignments =
    selectedAssignmentIds &&
    selectedAssignmentIds.length > 0 &&
    lessonPlan &&
    selectedAssignmentIds.every((assignmentId) =>
      lessonPlan.teacherUnits.some(
        (unit) =>
          unit.assignments &&
          unit.assignments.some((assignment) => assignment.id === assignmentId)
      )
    );

  const areAllAssigned =
    lessonPlan &&
    selectedAssignmentIds &&
    selectedAssignmentIds.every((assignmentId) =>
      lessonPlan.takeableAssignments.some(
        (takeable) => takeable.assignmentId === assignmentId
      )
    );

  const areAllUnassigned =
    !areAllAssigned &&
    lessonPlan &&
    selectedAssignmentIds &&
    !selectedAssignmentIds.some((assignmentId) =>
      lessonPlan.takeableAssignments.some(
        (takeable) => takeable.assignmentId === assignmentId
      )
    );

  useAsyncEffect(
    async (isCancelled) => {
      setLessonPlan(null);
      const classroomLessonPlan = await getClassroomLessonPlan(classroomId);

      if (!isCancelled()) {
        setLessonPlan(classroomLessonPlan);
      }
    },
    [classroomId]
  );

  const handleAssignMenuClose = useCallback(() => {
    if (navigate) {
      navigate(teacherRoutes.classroomAssignments(classroomId));
    }
  }, [navigate, classroomId]);

  const handleSelectUnit = useCallback(
    (unitId: number) => {
      setSelectedUnitId(unitId);
      setSelectedAssignmentIds();
    },
    [setSelectedUnitId, setSelectedAssignmentIds]
  );

  const handleSelectAssignment = useCallback(
    (assignmentId: number) => {
      if (
        selectedAssignmentIds &&
        selectedAssignmentIds.includes(assignmentId)
      ) {
        const newIds = selectedAssignmentIds.filter(
          (id) => id !== assignmentId
        );
        if (newIds.length > 0) {
          setSelectedAssignmentIds(newIds.join(","));
        } else {
          setSelectedAssignmentIds();
        }
      } else {
        setSelectedAssignmentIds(
          [...(selectedAssignmentIds || []), assignmentId].join(",")
        );
      }
      setSelectedUnitId();
    },
    [setSelectedUnitId, setSelectedAssignmentIds, selectedAssignmentIds]
  );

  const handleAddUnitClick = useCallback(() => {
    setShowCreateUnitForm(true);
  }, []);

  const handleAddUnitCancel = useCallback(() => {
    setShowCreateUnitForm(false);
  }, []);

  const handleAddUnitSubmit = useCallback(
    async (value: CreateUnitFormValue) => {
      if (!lessonPlan) {
        return;
      }
      const unit = await createUnit({
        name: value.name,
        categoryId: null,
      });
      setLessonPlan({
        ...lessonPlan,
        teacherUnits: [...lessonPlan.teacherUnits, unit],
      });
      setShowCreateUnitForm(false);
    },
    [lessonPlan]
  );

  const handleUnitMenuClose = useCallback(() => {
    setSelectedUnitId();
  }, [setSelectedUnitId]);

  const handleAssignmentMenuClose = useCallback(() => {
    setSelectedAssignmentIds();
  }, [setSelectedAssignmentIds]);

  const handleEditUnitClick = useCallback(() => {
    setEditUnitId(selectedUnitId);
  }, [selectedUnitId]);

  const handleEditUnitCancel = useCallback(() => {
    setEditUnitId(null);
  }, []);

  const handleEditUnitSubmit = useCallback(
    async (value: EditUnitFormValue) => {
      if (!lessonPlan) {
        return;
      }

      const { unitId, name } = value;
      await updateUnit(unitId, { name, categoryId: null });
      setLessonPlan({
        ...lessonPlan,
        teacherUnits: replaceWhere(
          lessonPlan.teacherUnits,
          (unit) => unit.id === unitId,
          (unit) => ({ ...unit, name })
        ),
      });
      setEditUnitId(null);
    },
    [lessonPlan]
  );

  const handleRemoveUnitClick = useCallback(async () => {
    if (!selectedUnitId || !lessonPlan) {
      return;
    }

    const confirmed = await showConfirm({
      title: "Are you sure you want to delete this unit?",
      message:
        "This action cannot be undone and will delete all assignments and assignment takes in the unit.",
    });
    if (confirmed) {
      await deleteUnit(selectedUnitId);
      setSelectedUnitId(selectedUnitId);
      setLessonPlan({
        ...lessonPlan,
        teacherUnits: lessonPlan.teacherUnits.filter(
          (unit) => unit.id !== selectedUnitId
        ),
      });
    }
  }, [lessonPlan, selectedUnitId, setSelectedUnitId]);

  const handleRemoveAssignmentClick = useCallback(async () => {
    if (
      !lessonPlan ||
      !selectedAssignmentIds ||
      selectedAssignmentIds.length === 0
    ) {
      return;
    }
    const confirmed = await showConfirm(
      selectedAssignmentIds.length === 1
        ? {
            title: "Are you sure you want to delete this assignment?",
            message:
              "This action cannot be undone and will delete all assignment takes for this assignment.",
          }
        : {
            title: "Are you sure you want to delete these assignments?",
            message:
              "This action cannot be undone and will delete all assignment takes for these assignments.",
          }
    );
    if (confirmed) {
      for (const assignmentId of selectedAssignmentIds) {
        await deleteAssignment(assignmentId);
      }
      setLessonPlan({
        ...lessonPlan,
        teacherUnits: lessonPlan.teacherUnits.map((unit) => ({
          ...unit,
          assignments: (unit.assignments || []).filter(
            (assignment) => !selectedAssignmentIds.includes(assignment.id)
          ),
        })),
      });
      setSelectedAssignmentIds();
    }
  }, [lessonPlan, selectedAssignmentIds, setSelectedAssignmentIds]);

  const handleAddUnitAssignmentClick = useCallback(
    (unitId: number) => {
      setSelectedUnitId(unitId);
      setShowCreateAssignmentForm(true);
    },
    [setSelectedUnitId]
  );

  const handleAddAssignmentClick = useCallback(() => {
    setShowCreateAssignmentForm(true);
  }, []);

  const handleAddAssignmentCancel = useCallback(() => {
    setShowCreateAssignmentForm(false);
  }, []);

  const handleAddAssignmentSubmit = useCallback(
    async (value: AssignmentFormValue) => {
      if (!lessonPlan || !selectedUnitId) {
        return;
      }

      const assignment = await createAssignment({
        unitId: selectedUnitId,
        title: value.assignment.title,
        subTitle: value.assignment.subTitle,
        sets: value.sets.map((set) => ({
          ...set,
          isExampleCorrect: !!set.isExampleCorrect,
        })),
      });

      setLessonPlan({
        ...lessonPlan,
        teacherUnits: replaceWhere(
          lessonPlan.teacherUnits,
          (other) => other.id === selectedUnitId,
          (other) => ({
            ...other,
            assignments: [...(other.assignments || []), assignment],
          })
        ),
      });
      setShowCreateAssignmentForm(false);
    },
    [lessonPlan, selectedUnitId]
  );

  const handleTakeableChange = useCallback(
    async (assignmentIds: number[], takeable: boolean) => {
      if (!lessonPlan) {
        return;
      }

      if (takeable) {
        const takeableAssignments = [];
        for (const assignmentId of assignmentIds) {
          takeableAssignments.push(
            await enableTakingAssignment({
              assignmentId,
              classroomId,
            })
          );
        }
        setLessonPlan({
          ...lessonPlan,
          takeableAssignments: [
            ...lessonPlan.takeableAssignments,
            ...takeableAssignments,
          ],
        });
      } else {
        setUnassignAssignmentIds(assignmentIds);
      }

      if (navigate) {
        navigate(teacherRoutes.classroomAssignments(classroomId));
      }
    },
    [navigate, classroomId, lessonPlan]
  );

  const handleUnassignConfirm = useCallback(async () => {
    if (!unassignAssignmentIds || !lessonPlan) {
      return;
    }
    setUnassignAssignmentIds(null);
    for (const assignmentId of unassignAssignmentIds) {
      await disableTakingAssignment({
        assignmentId,
        classroomId,
      });
    }
    setLessonPlan({
      ...lessonPlan,
      takeableAssignments: lessonPlan.takeableAssignments.filter(
        (other) =>
          !unassignAssignmentIds.includes(other.assignmentId) ||
          other.classroomId !== classroomId
      ),
    });
  }, [lessonPlan, unassignAssignmentIds, classroomId]);

  const handleUnassignCancel = useCallback(() => {
    setUnassignAssignmentIds(null);
  }, []);

  // Handle moving assignments around in and between units.
  const handleAssignmentMove = useCallback(
    async (event: AssignmentMoveEvent) => {
      if (!lessonPlan) {
        return;
      }

      const { teacherUnits } = lessonPlan;
      const { sourceUnitId, destUnitId, sourceIndex, destIndex } = event;

      const sourceUnit = teacherUnits.find((unit) => unit.id === sourceUnitId);
      const destUnit = teacherUnits.find((unit) => unit.id === destUnitId);

      if (!sourceUnit || !destUnit) {
        return;
      }

      const assignment = (sourceUnit.assignments || [])[sourceIndex];

      // Moving an assignment around in a unit
      if (sourceUnitId === destUnitId) {
        if (sourceIndex === destIndex) {
          // nothing changed
          return;
        }
        const newAssignments = reorderIndexedArray(
          sourceUnit.assignments || [],
          sourceIndex,
          destIndex
        );

        setLessonPlan({
          ...lessonPlan,
          teacherUnits: replaceWhere(
            teacherUnits,
            (unit) => unit.id === sourceUnitId,
            (unit) => ({
              ...unit,
              assignments: newAssignments,
            })
          ),
        });

        // Fire and forget
        reorderUnitAssignments(
          sourceUnit.id,
          newAssignments.map((assignment) => assignment.id)
        ).catch((error) => {
          console.error(`Failed to save assignment reorder`, error);
        });
      } else {
        // Moving an assignment between units

        const sourceUnitAssignments = sourceUnit.assignments || [];
        const destUnitAssignments = destUnit.assignments || [];
        const [
          newSourceUnitAssignments,
          newDestUnitAssignments,
        ] = swapBetweenIndexedArrays(
          sourceUnitAssignments,
          destUnitAssignments,
          sourceIndex,
          destIndex
        );

        const newSourceUnit = {
          ...sourceUnit,
          assignments: newSourceUnitAssignments,
        };
        const newDestUnit = {
          ...destUnit,
          assignments: newDestUnitAssignments,
        };

        setLessonPlan({
          ...lessonPlan,
          teacherUnits: replaceWhere(
            teacherUnits,
            (unit) => unit.id === sourceUnitId || unit.id === destUnitId,
            (unit) => {
              if (unit.id === sourceUnitId) {
                return newSourceUnit;
              }
              if (unit.id === destUnitId) {
                return newDestUnit;
              }
              return unit;
            }
          ),
        });

        // Fire and forget
        moveAssignmentToUnit({
          unitId: destUnitId,
          assignmentId: assignment.id,
        })
          .then(async () => {
            await reorderUnitAssignments(
              newDestUnit.id,
              (newDestUnit.assignments || []).map((assignment) => assignment.id)
            ).catch((error) => {
              console.error(`Failed to save assignment reorder`, error);
            });

            await reorderUnitAssignments(
              newSourceUnit.id,
              (newSourceUnit.assignments || []).map(
                (assignment) => assignment.id
              )
            ).catch((error) => {
              console.error(`Failed to save assignment reorder`, error);
            });
          })
          .catch((error) => {
            console.error("Failed to save assignment move", error);
          });
      }
    },
    [lessonPlan]
  );

  const handleEditAssignmentSubmit = useCallback(
    async (value: EditAssignmentFormValue) => {
      const valueWithImages = {
        assignmentId: value.assignmentId,
        title: value.assignment.title,
        subTitle: value.assignment.subTitle,
        sets: await Promise.all(
          value.sets.map(async (set) => ({
            ...set,
            isExampleCorrect: !!set.isExampleCorrect,
            exampleImage:
              set.exampleImage instanceof File
                ? (await uploadFile(set.exampleImage)).key
                : set.exampleImage,
            questions: await Promise.all(
              set.questions.map(async (question) => ({
                ...question,
                questionImage:
                  question.questionImage instanceof File
                    ? (await uploadFile(question.questionImage)).key
                    : question.questionImage,
                sampleResponseImage:
                  question.sampleResponseImage instanceof File
                    ? (await uploadFile(question.sampleResponseImage)).key
                    : question.sampleResponseImage,
              }))
            ),
          }))
        ),
      };

      const updatedAssignment = await updateAssignment(valueWithImages);
      setLessonPlan((lessonPlan) => {
        if (!lessonPlan) {
          return null;
        }
        return {
          ...lessonPlan,
          teacherUnits: replaceWhere(
            lessonPlan.teacherUnits,
            (unit) =>
              !!(
                unit.assignments &&
                unit.assignments.some(
                  (assignment) => assignment.id === value.assignmentId
                )
              ),
            (unit) => ({
              ...unit,
              assignments: replaceWhere(
                unit.assignments || [],
                (assignment) => assignment.id === updatedAssignment.id,
                () => updatedAssignment
              ),
            })
          ),
        };
      });
      setEditAssignmentId();
    },
    [setEditAssignmentId]
  );

  const handleAssignToggle = useCallback(async () => {
    if (!selectedAssignmentIds || !selectedAssignmentIds.length) {
      return;
    }

    await handleTakeableChange(selectedAssignmentIds, !!areAllUnassigned);
  }, [handleTakeableChange, selectedAssignmentIds, areAllUnassigned]);

  if (!lessonPlan) {
    return null;
  }

  const { categories, globalUnits, teacherUnits } = lessonPlan;

  return (
    <Column {...rest}>
      {unassignAssignmentIds && (
        <ConfirmModal
          message={`${
            unassignAssignmentIds.length > 1
              ? "These assignments"
              : "This assignment"
          } may have been started by students. Their progress will be lost if unassigned.`}
          onConfirm={handleUnassignConfirm}
          onCancel={handleUnassignCancel}
        />
      )}
      {selectedUnitId ? (
        <UnitTopMenu
          unitId={selectedUnitId}
          onClose={handleUnitMenuClose}
          onRemoveUnitClick={handleRemoveUnitClick}
          onEditUnitClick={handleEditUnitClick}
          onCreateAssignmentClick={handleAddAssignmentClick}
        />
      ) : null}
      {selectedAssignmentIds && selectedAssignmentIds.length > 0 ? (
        <AssignmentTopMenu
          multipleAssignments={
            !!(selectedAssignmentIds && selectedAssignmentIds.length > 1)
          }
          assigned={!!areAllAssigned}
          onAssignClick={handleAssignToggle}
          onEditAssignmentClick={() =>
            setEditAssignmentId(selectedAssignmentIds[0])
          }
          hideAssignButton={!areAllAssigned && !areAllUnassigned}
          onClose={handleAssignmentMenuClose}
          onRemoveAssignmentClick={handleRemoveAssignmentClick}
          hideRemoveButton={!areOwnAssignments}
          hideEditButton={
            !areOwnAssignments || selectedAssignmentIds.length > 1
          }
        />
      ) : null}
      {editUnit ? (
        <FormOverlay onClose={handleEditUnitCancel} inRoot>
          <EditUnitForm unit={editUnit} onSubmit={handleEditUnitSubmit} />
        </FormOverlay>
      ) : null}
      {editAssignmentId && (
        <FormOverlay withLogo onClose={() => setEditAssignmentId()}>
          <EditAssignmentForm
            assignmentId={editAssignmentId}
            title="Edit Assignment"
            submitButtonText="Save"
            onSubmit={handleEditAssignmentSubmit}
            onCancel={() => setEditAssignmentId()}
          />
        </FormOverlay>
      )}
      {showCreateAssignmentForm && (
        <FormOverlay withLogo onClose={handleAddAssignmentCancel}>
          <AssignmentForm
            onSubmit={handleAddAssignmentSubmit}
            title="Create a New Assignment"
            submitButtonText="FINISH AUTHORING THIS ASSIGNMENT"
            unitName={selectedUnit && selectedUnit.name}
          />
        </FormOverlay>
      )}
      {showCreateUnitForm && (
        <FormOverlay onClose={handleAddUnitCancel}>
          <CreateUnitForm onSubmit={handleAddUnitSubmit} />
        </FormOverlay>
      )}
      {assignmentGuideId && (
        <AssignmentGuide
          assignmentId={+assignmentGuideId}
          classroomId={classroomId}
          onClose={() => setAssignmentGuideId()}
          onTakeClick={(studentId, takeId) =>
            navigate(teacherRoutes.assignmentTakeReview(studentId, takeId))
          }
        />
      )}
      <Match
        path={`/classes/${classroomId}/assignments/assignment/:assignmentId`}
      >
        {({ match }) => {
          const params: Record<string, string | undefined> | null = match;
          const assignmentId =
            params && params.assignmentId && parseInt(params.assignmentId, 10);

          if (!assignmentId) {
            return null;
          }

          return (
            <LessonPlanAssignmentTopMenu
              takeable={isAssignmentTakeable(
                assignmentId,
                classroomId,
                lessonPlan
              )}
              onClose={handleAssignMenuClose}
              onTakeableChange={(takeable) =>
                handleTakeableChange([assignmentId], takeable)
              }
            />
          );
        }}
      </Match>
      <ClassroomNav classroomId={classroomId} />

      <UnitLists>
        {categories.map((category, i) => {
          const categoryUnits = globalUnits.filter(
            (unit) => unit.categoryId === category.id
          );

          if (categoryUnits.length === 0) {
            return null;
          }

          return (
            <CategoryContainer key={category.id}>
              <CategoryHeader>
                <CategoryTitle>{category.name}</CategoryTitle>
                {i === 0 && (
                  <IconKey>
                    <CompletedIcon />
                    <div>Completed</div>
                    <AssignedIcon />
                    <div>Assigned</div>
                  </IconKey>
                )}
              </CategoryHeader>
              <List>
                {categoryUnits.map((unit, index) => (
                  <ExpandListItem
                    key={unit.id}
                    header={
                      <UnitHeader>
                        {index + 1}. {unit.name}
                      </UnitHeader>
                    }
                  >
                    {(unit.assignments || [])
                      .sort((a, b) => a.index - b.index)
                      .map((assignment) => {
                        const takeableAssignment = getTakeableAssignmentFromAssignment(
                          assignment.id,
                          classroomId,
                          lessonPlan
                        );
                        const takes = takeableAssignment
                          ? getTakeableAssignmentTakes(
                              takeableAssignment.id,
                              lessonPlan
                            )
                          : [];
                        const completedTakes = takes.filter(
                          (take) => !!take.completedAt
                        );
                        const isComplete =
                          lessonPlan.students.length === completedTakes.length;
                        const areAllTakesGraded = !!(
                          isComplete &&
                          takeableAssignment &&
                          takes.every((take) => !!take.gradedAt)
                        );

                        return (
                          <LessonPlanListItemWrapper key={assignment.id}>
                            <LessonPlanListItem
                              classroomId={classroomId}
                              assignment={assignment}
                              takeable={!!takeableAssignment}
                              graded={areAllTakesGraded}
                              progress={
                                takeableAssignment
                                  ? {
                                      current: completedTakes.length,
                                      total: lessonPlan.students.length,
                                      incompleteNames: lessonPlan.students
                                        .filter((student) => {
                                          return completedTakes.every(
                                            (take) =>
                                              take.studentId !== student.id
                                          );
                                        })
                                        .map(
                                          (student) =>
                                            `${capitalizeFirst(
                                              student.lastName
                                            )}, ${capitalizeFirst(
                                              student.firstName
                                            )}`
                                        ),
                                    }
                                  : null
                              }
                              selected={
                                !!(
                                  selectedAssignmentIds &&
                                  selectedAssignmentIds.includes(assignment.id)
                                )
                              }
                              onTitleClick={() =>
                                setAssignmentGuideId(assignment.id)
                              }
                              onSelectClick={() =>
                                handleSelectAssignment(assignment.id)
                              }
                              disabled={!canSelectAssignment({
                                  assignment,
                                  areAllAssigned: areAllAssigned ?? false,
                                  areAllUnassigned: areAllUnassigned ?? false,
                                  selectedAssignmentIds,
                                  lessonPlan,
                                  classroomId
                              })}
                            />
                          </LessonPlanListItemWrapper>
                        );
                      })}
                  </ExpandListItem>
                ))}
              </List>
            </CategoryContainer>
          );
        })}

        <TeacherAssignmentsHeader>
          <CategoryTitle>Teacher-Created Materials</CategoryTitle>
          <PlusButton onClick={handleAddUnitClick}>Create Unit</PlusButton>
        </TeacherAssignmentsHeader>
        <UnitList
          showAddAssignmentButtons
          selectedUnitId={selectedUnitId}
          units={teacherUnits}
          onAssignmentMove={handleAssignmentMove}
          onSelectUnit={handleSelectUnit}
          onAddAssignmentClick={handleAddUnitAssignmentClick}
          renderAssignmentListItem={(assignment) => {
            const takeableAssignment = getTakeableAssignmentFromAssignment(
              assignment.id,
              classroomId,
              lessonPlan
            );
            const takes = takeableAssignment
              ? getTakeableAssignmentTakes(takeableAssignment.id, lessonPlan)
              : [];
            const completedTakes = takes.filter((take) => take.completedAt);
            const isComplete =
              lessonPlan.students.length === completedTakes.length;
            const areAllTakesGraded = !!(
              isComplete &&
              takeableAssignment &&
              takes.every((take) => !!take.gradedAt)
            );

            return (
              <LessonPlanListItem
                classroomId={classroomId}
                assignment={assignment}
                takeable={!!takeableAssignment}
                graded={areAllTakesGraded}
                progress={
                  takeableAssignment
                    ? {
                        current: completedTakes.length,
                        total: lessonPlan.students.length,
                        incompleteNames: lessonPlan.students
                          .filter((student) => {
                            return completedTakes.every(
                              (take) => take.studentId !== student.id
                            );
                          })
                          .map(
                            (student) =>
                              `${capitalizeFirst(
                                student.lastName
                              )}, ${capitalizeFirst(student.firstName)}`
                          ),
                      }
                    : null
                }
                selected={
                  !!(
                    selectedAssignmentIds &&
                    selectedAssignmentIds.includes(assignment.id)
                  )
                }
                onTitleClick={() => setAssignmentGuideId(assignment.id)}
                onSelectClick={() => handleSelectAssignment(assignment.id)}
              />
            );
          }}
        />
      </UnitLists>
    </Column>
  );
};

export default styled(ClassroomLessonPlan)``;

const UnitLists = styled(Column)`
  width: 660px;
  max-width: 100%;
  align-self: center;
`;

const UnitHeader = styled(Row)`
  padding-left: 0.75rem;
`;

const CategoryHeader = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const CategoryTitle = styled("h3")`
  color: #000000;
  font-size: 0.9rem;
  font-weight: bold;
`;

const TeacherAssignmentsHeader = styled(CategoryHeader)`
  margin-top: 4rem;

  > ${CategoryTitle} {
    margin-bottom: 0;
  }
`;

const CategoryContainer = styled(Column)`
  & + & {
    margin-top: 4rem;
  }
`;

const LessonPlanListItemWrapper = styled("li")`
  & + & {
    border-top: 1px solid #979797;
  }
`;

const IconKey = styled(Row)`
  align-items: center;
  color: #7e7e7e;
  font-size: 10px;

  > ${AssignedIcon}, > ${CompletedIcon} {
    margin-right: 0.5rem;
  }

  > ${AssignedIcon} {
    margin-left: 1rem;
  }
`;

function getTakeableAssignmentFromAssignment(
  assignmentId: number,
  classroomId: number,
  lessonPlan: LessonPlan
): TakeableAssignment | null {
  const takeableAssignment = lessonPlan.takeableAssignments.find(
    (takeableAssignment) =>
      takeableAssignment.classroomId === classroomId &&
      takeableAssignment.assignmentId === assignmentId
  );
  return takeableAssignment || null;
}

function isAssignmentTakeable(
  assignmentId: number,
  classroomId: number,
  lessonPlan: LessonPlan
): boolean {
  return !!getTakeableAssignmentFromAssignment(
    assignmentId,
    classroomId,
    lessonPlan
  );
}

function getTakeableAssignmentTakes(
  takeableAssignmentId: number,
  lessonPlan: LessonPlan
): AssignmentTake[] {
  return lessonPlan.takes.filter(
    (take) => take.takeableAssignmentId === takeableAssignmentId
  );
}

interface CanSelectAssignmentParams {
    assignment: Assignment;
    lessonPlan: LessonPlan;
    selectedAssignmentIds: number[] | null;
    classroomId: number;
    areAllAssigned: boolean;
    areAllUnassigned: boolean;
}

function canSelectAssignment({
     selectedAssignmentIds,
     assignment,
     areAllAssigned,
     areAllUnassigned,
     lessonPlan,
     classroomId
}: CanSelectAssignmentParams) {
    // If there are no assessments selected then selecting the assignment is enabled
    if(selectedAssignmentIds?.length === 0) {
        return true;
    }

    const takeableAssignment = getTakeableAssignmentFromAssignment(
        assignment.id,
        classroomId,
        lessonPlan
    );

    // If all selected assignments are unassigned, this assignment cannot be selected because it is assigned
    if(areAllUnassigned) {
        return takeableAssignment === null;
    }

    // If all the selected assignments are assigned, this assignment cannot be selected because its unassigned.
    if(areAllAssigned) {
        return takeableAssignment !== null;
    }

    // This assignment can be selected.
    return true;
}
