import RouteProps from "../../RouteProps";
import Admin from "shared/lib/types/Admin";
import React, { FC } from "react";
import RosterFileForm from "../../components/RosterFileForm";
import styled from "styled-components/macro";
import Column from "../../components/Column";
import { useRosterFileForm } from "../../utils/roster/useRosterFileForm";
import Page from "../../components/Page";
import MathByExampleLogo from "../../components/MathByExampleLogo";
import Row from "../../components/Row";
import Link from "../../components/Link";
import XButton from "../../components/XButton";
import { navigate } from "@reach/router";
import {
  SAMPLE_CLASSROOM_CSV,
  SAMPLE_ENROLLMENT_CSV,
  SAMPLE_STUDENT_CSV,
  SAMPLE_TEACHER_CSV
} from "../../constants/import/csvImportSamples";

const isDev = process.env.NODE_ENV === "development";

interface Props extends RouteProps {
  admin: Admin;
  setAdmin(admin: Admin): void;
  reload(): void;
}

const AdminRosterImportPage: FC<Props> = props => {
  const { location } = props;
  const {
    submitting,
    studentFileError,
    teacherFileError,
    enrollmentFileError,
    classFileError,
    combinedErrors,

    // Pull out the processing keys so that they're not passed to RosterFileForm
    studentImportProcessing,
    classImportProcessing,
    teacherImportProcessing,
    enrollmentImportProcessing,

    ...formProps
  } = useRosterFileForm();

  return (
    <Page {...props} location={location}>
      <HeadRow>
        <LogoLink to="/">
          <MathByExampleLogo />
        </LogoLink>
        <XButton iconSize={28} onClick={() => navigate(-1)} disabled={submitting} />
      </HeadRow>
      <RosterImportContainer>
        {submitting ? <Row>Submitting. Please wait...</Row> : <RosterFileForm {...formProps} />}
        {isDev && (
          <ul>
            <li>
              <SampleCSVLink
                importType="teachers"
                csvTemplate={SAMPLE_TEACHER_CSV}
              />
            </li>
            <li>
              <SampleCSVLink
                importType="students"
                csvTemplate={SAMPLE_STUDENT_CSV}
              />
            </li>
            <li>
              <SampleCSVLink
                importType="classes"
                csvTemplate={SAMPLE_CLASSROOM_CSV}
              />
            </li>
            <li>
              <SampleCSVLink
                importType="enrollments"
                csvTemplate={SAMPLE_ENROLLMENT_CSV}
              />
            </li>
          </ul>
        )}
        <ErrorList>
          <li>{teacherFileError}</li>
          <li>{studentFileError}</li>
          <li>{enrollmentFileError}</li>
          <li>{classFileError}</li>
          {combinedErrors.map(({ importType, row, message }) => (
            <li key={`${importType}_${row}`}>
              {importType}.csv, row {row}: {message}
            </li>
          ))}
        </ErrorList>
      </RosterImportContainer>
    </Page>
  );
};

export default AdminRosterImportPage;

const RosterImportContainer = styled(Column)`
  padding: 3rem 0 3rem 0;
  min-height: 100%;
  max-width: 100%;
  flex: 1;
  align-items: center;
  ul {
    margin-top: 30px;
    list-style: none;
  }
`;

const LogoLink = styled(Link)`
  padding-left: 1rem;
  ${MathByExampleLogo} {
    width: 162.68px;
    height: 29.4px;
  }
`;

const HeadRow = styled(Row)`
  width: 950px;
  max-width: 100%;
  height: 4rem;
  flex-shrink: 0;
  font-size: 0.75rem;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 0 2rem 0 2rem;
  ${XButton} {
    height: 48px;
    width: 48px;
    background-color: rgba(216, 216, 216, 0.3);
    border-radius: 50%;
  }
`;

const SampleCSVLink: FC<{ importType: string; csvTemplate: string }> = ({
  importType,
  csvTemplate
}) => (
  <a href={`data:text/csv,${csvTemplate}`} download={`${importType}.csv`}>
    Download sample {importType}.csv
  </a>
);

const ErrorList = styled.ul`
  color: red;
`;
