import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';

import UnderlinedInput from './UnderlinedInput';
import useInputState from '../utils/useInputState';
import isBlank from 'shared/lib/utils/isBlank';
import Student from 'shared/lib/types/Student';
import capitalizeFirst from 'shared/lib/utils/capitalizeFirst';
import FormLayout from './FormLayout';

export interface Props {
  student: Student;
  onSubmit(value: Value): any;
}

export interface Value {
  studentId: number;
  classroomCode: string;
}

const MoveStudentForm: FC<Props> = props => {
  const { onSubmit, student, ...rest } = props;
  const [classroomCode, handleClassroomCodeChange] = useInputState('');

  const handleSubmit = useCallback(async () => {
    if (isBlank(classroomCode)) {
      throw new Error('Class Id is required.');
    }

    await onSubmit({
      classroomCode,
      studentId: student.id
    });
  }, [student, classroomCode, onSubmit]);

  return (
    <FormLayout
      {...rest}
      title="Student Class Change"
      submitButtonText="Done"
      onSubmit={handleSubmit}
    >
      <UnderlinedInput
        readOnly
        value={`${capitalizeFirst(student.firstName)} ${capitalizeFirst(
          student.lastName
        )}`}
      />
      <UnderlinedInput
        placeholder="Class Id"
        value={classroomCode}
        onChange={handleClassroomCodeChange}
      />
    </FormLayout>
  );
};

export default styled(MoveStudentForm)``;
