import React, { FC, useState, useMemo } from "react";
import styled from "styled-components/macro";
import chunk from "lodash.chunk";
import { DetailedAssignmentTake } from "shared/lib/types/AssignmentTake";
import capitalizeFirst from "shared/lib/utils/capitalizeFirst";
import { IoMdArrowRoundForward } from "react-icons/io";
import useAsyncEffect from "../utils/useAsyncEffect";
import Column from "./Column";
import getRecentTakes from "../api/assignmentTakes/getRecentTakes";
import Row from "./Row";
import Link from "./Link";
import * as teacherRoutes from "../teacherRoutes";

const RecentlySubmittedTakeReport: FC = props => {
  const [recentTakes, setRecentTakes] = useState<
    DetailedAssignmentTake[] | null
  >(null);
  const chunkedTakes = useMemo(() => {
    if (recentTakes) {
      return chunk(recentTakes, 2);
    }
    return null;
  }, [recentTakes]);

  useAsyncEffect(async isCancelled => {
    const fetchedRecentTakes = await getRecentTakes();
    if (!isCancelled()) {
      setRecentTakes(fetchedRecentTakes);
    }
  }, []);

  if (!chunkedTakes) {
    return (
      <Column {...props}>
        <Title>Recently Submitted</Title>
      </Column>
    );
  }

  return (
    <Column {...props}>
      <Title>Recently Submitted</Title>
      {chunkedTakes.map((takes, i) => (
        <TakeRow key={i}>
          {takes.map(take => (
            <TakeBox
              key={take.id}
              to={teacherRoutes.assignmentTakeReview(take.studentId, take.id)}
            >
              <StudentName>
                {capitalizeFirst(take.student.firstName)}{" "}
                {capitalizeFirst(take.student.lastName)}
              </StudentName>
              <AssignmentName>
                {take.takeableAssignment.assignment.title}
              </AssignmentName>
              <ViewText>
                View Work
                <IoMdArrowRoundForward />
              </ViewText>
            </TakeBox>
          ))}
        </TakeRow>
      ))}
    </Column>
  );
};

export default styled(RecentlySubmittedTakeReport)`
  width: 320px;
  max-width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.5);
  padding: 19px 19px 33px 19px;
  color: #000000;
`;

const Title = styled("h2")`
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 0;
  text-align: center;
  margin-bottom: 32px;
`;

const TakeRow = styled(Row)`
  & + & {
    margin-top: 11px;
  }
`;

const TakeBox = styled(Link)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 132px;
  border: 1px solid #b8b8b8;
  padding: 7px 10px 5px 10px;

  & + & {
    margin-left: 17px;
  }
`;

const StudentName = styled("h3")`
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin: 0 0 5px 0;
`;

const AssignmentName = styled("h4")`
  font-size: 10px;
  line-height: 13px;
  margin: 0 0 17px 0;
`;

const ViewText = styled(Row)`
  font-size: 8px;
  line-height: 10px;
  align-self: flex-end;
  align-items: center;

  svg {
    margin-left: 7px;
  }
`;
