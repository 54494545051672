import React, { FC, useCallback, FormEvent } from "react";
import styled from "styled-components/macro";
import uuid from "uuid/v4";
import {
  AssignmentQuestionPart,
  AssignmentQuestionPartKind
} from "shared/lib/types/AssignmentQuestion";
import Column from "./Column";
import ImageInput from "./ImageInput";
import InputWindow from "./InputWindow";
import QuestionInputType from "shared/lib/types/QuestionInputType";
import OutlinedTextarea from "./OutlinedTextarea";
import MathInput from "./MathInput";

interface Props {
  index: number;
  value: Value;
  onChange(value: Value): any;
}

export interface Value {
  id?: number;
  uuid: string;
  isPractice: boolean;
  questionText: AssignmentQuestionPart[];
  inputType: QuestionInputType;
  questionImage: File | string | null;
  sampleResponseText: string;
  sampleResponseImage: File | string | null;
}

export function getDefaultValue(): Value {
  return {
    uuid: uuid(),
    isPractice: true,
    questionText: [
      { id: uuid(), kind: AssignmentQuestionPartKind.text, value: "" }
    ],
    inputType: QuestionInputType.sketchpad,
    questionImage: null,
    sampleResponseText: "",
    sampleResponseImage: null
  };
}

const PracticeAssignmentStepForm: FC<Props> = props => {
  const { index, value, onChange, ...rest } = props;

  const handleQuestionTextChange = useCallback(
    (questionText: AssignmentQuestionPart[]) => {
      onChange({ ...value, questionText });
    },
    [onChange, value]
  );

  const handleQuestionImageChange = useCallback(
    (questionImage: File | null) => {
      onChange({ ...value, questionImage });
    },
    [onChange, value]
  );

  const handleSampleResponsesTextChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      onChange({ ...value, sampleResponseText: event.currentTarget.value });
    },
    [onChange, value]
  );

  const handleSampleResponsesImageChange = useCallback(
    (file: File | null) => {
      onChange({ ...value, sampleResponseImage: file });
    },
    [onChange, value]
  );

  return (
    <Column {...rest}>
      <Title>C. Enter Student Practice Problem</Title>
      <Content>
        <Column>
          <MathInput
            value={value.questionText}
            onChange={handleQuestionTextChange}
          />
        </Column>
        <DownloadLink
          download
          href="https://serpinstitute.box.com/shared/static/sgumhr9iimxyfgwjrdu0koczs7nub4wt.pptx"
        >
          download template
        </DownloadLink>
        <ImageInput
          value={value.questionImage}
          onFileChange={handleQuestionImageChange}
          alt="Entry field image"
        />
        <InputWindow title="TEACHER’S GUIDE: SAMPLE ANSWER OR TEACHER NOTES">
          <OutlinedTextarea
            value={value.sampleResponseText}
            onChange={handleSampleResponsesTextChange}
          />
          <ImageInput
            value={value.sampleResponseImage}
            onFileChange={handleSampleResponsesImageChange}
            alt="Sample responses"
          />
        </InputWindow>
      </Content>
    </Column>
  );
};

export default styled(PracticeAssignmentStepForm)``;

const Title = styled("h5")`
  color: #333333;
  font-size: 18px;
  line-height: 23px;
  font-weight: 900;
  margin-bottom: 1.25rem;
`;

const Content = styled(Column)`
  padding-left: 1rem;
`;

const DownloadLink = styled("a")`
  margin-bottom: 10px;
`;
