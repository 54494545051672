import isUrl from "./isUrl";
import getS3ImageUrl from "./getS3ImageUrl";

/**
 * Takes a file, image URL, or S3 image key and returns an image URL for displaying the image.
 */
export default function getImageUrl(image: File | string): string {
  if (image instanceof File) {
    // Local file
    return URL.createObjectURL(image);
  } else if (isUrl(image)) {
    // Image URL
    return image;
  } else {
    // S3 Image Key
    return getS3ImageUrl(image);
  }
}
