import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import MathByExampleLogo from "./MathByExampleLogo";
import Page from "./Page";
import Row from "./Row";
import lineImage from "../images/line.png";
import gridBackgroundImage from "../images/grid_background.png";
import Link from "./Link";
import logout from "../api/auth/logout";
import StudentMenu from "./StudentMenu";
import Student from "shared/lib/types/Student";
import getCurrentStudent from "../api/students/getCurrentStudent";
import useAsyncEffect from "../utils/useAsyncEffect";

interface Props {
  hideMenu?: boolean;
  reload?(): any;
}

const StudentPage: FC<Props> = props => {
  const [student, setStudent] = useState<Student | null>(null);
  const { reload, children, hideMenu, ...rest } = props;

  useAsyncEffect(
    async isCancelled => {
      if (hideMenu) {
        // No need to load student if the menu isn't visible
        return;
      }
      const student = await getCurrentStudent();

      if (!isCancelled()) {
        setStudent(student);
      }
    },
    [hideMenu]
  );

  const handleSignoutClick = useCallback(async () => {
    if (reload) {
      await logout();
      await reload();
    }
  }, [reload]);

  return (
    <Root>
      <Header>
        <Link to="/">
          <MathByExampleLogo />
        </Link>
        {hideMenu ? null : (
          <StudentMenu student={student} onSignoutClick={handleSignoutClick} />
        )}
      </Header>
      <Content {...rest}>{children}</Content>
    </Root>
  );
};

export default styled(StudentPage)``;

const Root = styled(Page)`
  background-image: url('${gridBackgroundImage}');
  background-size: 1024px 768px;
  background-repeat: repeat repeat;
  align-items: center;
  overflow: hidden;
`;

const Header = styled(Row)`
  width: 1008px;
  max-width: 100%;
  background-image: url('${lineImage}');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: -1.5rem bottom;
  padding-bottom: 1rem;
  margin: 5rem 0 2rem 0;
  justify-content: space-between;

  ${MathByExampleLogo} {
    width: 291px;
    height: 53px;
  }
`;

const Content = styled(Column)`
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin: 0 2rem 4rem 2rem;
  width: 1024px;
  max-width: 100%;
  border-radius: 3px;

  @media (max-width: 1366px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
