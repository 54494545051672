import React, { ReactNode, Fragment } from "react";

/**
 * Takes a string and converts each superscript number symbol to a regular number wrapped in a `<sup>` tag.
 * Needed to fix a bug where some superscript numbers aren't displayed with the correct font.
 */
export function fixSuperNumbers(input: string): ReactNode[] {
  const supers: Record<string, string> = {
    "¹": "1",
    "²": "2",
    "³": "3",
    "⁴": "4",
    "⁵": "5",
    "⁶": "6",
    "⁷": "7",
    "⁸": "8",
    "⁹": "9",
    "⁰": "0",
  };
  let results: ReactNode[] = [""];

  for (let c of input.split("")) {
    if (supers.hasOwnProperty(c)) {
      results.push(<sup>{supers[c]}</sup>, "");
    } else {
      results[results.length - 1] += c;
    }
  }

  return results.map((result, i) => <Fragment key={i}>{result}</Fragment>);
}
