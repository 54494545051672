import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Student from "shared/lib/types/Student";
import RouteProps from "../../RouteProps";
import StudentPage from "../../components/StudentPage";
import StudentEditProfileForm, {
  Value as StudentEditProfileFormValue
} from "../../components/StudentEditProfileForm";
import updateStudentProfile from "../../api/students/updateStudentProfile";
import { navigate } from "@reach/router";
import * as studentRoutes from "../../studentRoutes";
import getCurrentStudent from "../../api/students/getCurrentStudent";
import useAsyncEffect from "../../utils/useAsyncEffect";

interface Props extends RouteProps {
  studentId: number;
  reload(): any;
}

const StudentEditProfilePage: FC<Props> = props => {
  const { studentId, reload, ...rest } = props;
  const [student, setStudent] = useState<Student | null>(null);

  useAsyncEffect(async isCancelled => {
    const fetchedStudent = await getCurrentStudent();
    if (!isCancelled()) {
      setStudent(fetchedStudent);
    }
  }, []);

  const handleSubmit = useCallback(
    async (value: StudentEditProfileFormValue) => {
      await updateStudentProfile(studentId, value);
    },
    [studentId]
  );

  const handleClose = useCallback(() => {
    navigate(studentRoutes.dashboard);
  }, []);

  return (
    <StudentPage {...rest} reload={reload}>
      {student && (
        <StudentEditProfileForm
          student={student}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      )}
    </StudentPage>
  );
};

export default styled(StudentEditProfilePage)`
  align-items: center;
  padding: 4rem 0 4rem 0;
`;
