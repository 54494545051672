import styled from 'styled-components/macro';

const List = styled('ul')`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  list-style: none;
`;

export default List;
