import React, { FC, useMemo } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import Row from "./Row";
import CircleX from "./CircleX";
import getImageUrl from "../utils/getImageUrl";

interface Props {
  src: File | string;
  alt: string;
  onRemoveClick(): any;
}

const ImagePreview: FC<Props> = (props) => {
  const { src, alt, onRemoveClick, ...rest } = props;
  const srcUrl = useMemo<string>(() => getImageUrl(src), [src]);

  return (
    <Column {...rest}>
      <ImageContainer>
        {srcUrl && <Image src={srcUrl} alt={alt} />}
      </ImageContainer>
      <RemoveRow onClick={onRemoveClick}>
        Remove?
        <CircleX />
      </RemoveRow>
    </Column>
  );
};

export default styled(ImagePreview)`
  width: 140px;
`;

const ImageContainer = styled(Row)`
  width: 140px;
  height: auto;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
`;

const RemoveRow = styled(Row)`
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 1rem;
  margin-top: 0.25rem;
  cursor: pointer;

  ${CircleX} {
    margin-left: 0.5rem;
  }
`;
