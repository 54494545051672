import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import TopMenu, { Props as TopMenuProps } from "./TopMenu";
import TextButton from "./TextButton";
import PlusIcon from "./PlusIcon";

interface Props extends TopMenuProps {
  takeable: boolean;
  onTakeableChange(takeable: boolean): any;
}

const LessonPlanAssignmentTopMenu: FC<Props> = props => {
  const { takeable, onTakeableChange, ...rest } = props;

  const handleAssignClick = useCallback(() => {
    onTakeableChange(!takeable);
  }, [onTakeableChange, takeable]);

  return (
    <TopMenu {...rest}>
      <MenuButton onClick={handleAssignClick}>
        <PlusIcon size={24} />
        {takeable ? "Unassign Work" : "Assign Work"}
      </MenuButton>
    </TopMenu>
  );
};

export default styled(LessonPlanAssignmentTopMenu)``;

const MenuButton = styled(TextButton)`
  flex-direction: row;
  align-items: center;
`;
