import React, { FC, useCallback } from "react";
import styled, { css } from "styled-components/macro";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import BlankButton from "./BlankButton";
import Column from "./Column";
import { RosterImportType } from "shared/lib/types/import/RosterImportType";
import Row from "./Row";
import XButton from "./XButton";

const BASE_DROPZONE_CONFIG: DropzoneOptions = {
  maxFiles: 1,
  accept: { "text/csv": [".csv"] },
};

interface RosterFileFormProps {
  teacherRosterFile: File | null;
  onTeacherRosterSelected(file: File): void;
  onTeacherRosterFileRemoved(): void;
  studentRosterFile: File | null;
  onStudentRosterSelected(file: File): void;
  onStudentRosterFileRemoved(): void;
  classroomRosterFile: File | null;
  onClassroomRosterSelected(file: File): void;
  onClassroomRosterRemoved(): void;
  enrollmentRosterFile: File | null;
  onEnrollmentRosterSelected(file: File): void;
  onEnrollmentRosterFileRemoved(): void;
  onSubmit(): void;
  submitDisabled: boolean;
}

const RosterFileForm: FC<RosterFileFormProps> = (props) => {
  const {
    onStudentRosterSelected,
    onTeacherRosterSelected,
    studentRosterFile,
    teacherRosterFile,
    onTeacherRosterFileRemoved,
    onStudentRosterFileRemoved,
    onEnrollmentRosterFileRemoved,
    classroomRosterFile,
    onClassroomRosterSelected,
    onClassroomRosterRemoved,
    onEnrollmentRosterSelected,
    enrollmentRosterFile,
    onSubmit,
    submitDisabled,
    ...rest
  } = props;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      {...rest}
    >
      <h2>Import</h2>
      <RosterFileInput
        rosterFile={teacherRosterFile}
        onRosterFileSelected={onTeacherRosterSelected}
        onRosterFileRemoved={onTeacherRosterFileRemoved}
        rosterType="teacher"
      />
      <RosterFileInput
        rosterFile={studentRosterFile}
        onRosterFileSelected={onStudentRosterSelected}
        onRosterFileRemoved={onStudentRosterFileRemoved}
        rosterType="student"
      />
      <RosterFileInput
        rosterFile={enrollmentRosterFile}
        onRosterFileSelected={onEnrollmentRosterSelected}
        onRosterFileRemoved={onEnrollmentRosterFileRemoved}
        rosterType="enrollment"
      />
      <RosterFileInput
        rosterFile={classroomRosterFile}
        onRosterFileSelected={onClassroomRosterSelected}
        onRosterFileRemoved={onClassroomRosterRemoved}
        rosterType="classroom"
      />
      <FormButton disabled={submitDisabled}>Save</FormButton>
    </form>
  );
};

export default styled(RosterFileForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > :last-child {
    margin-top: 30px;
  }
  h2 {
    font-weight: normal;
    font-size: 1.3125rem;
    letter-spacing: 0;
    line-height: 1.5625rem;
    text-align: center;
    margin-bottom: 60px;
  }
`;

const FileDropzone = styled(Row)`
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  input {
    font-family: Lato;
  }
`;

const FileSelectContainer = styled(Column)`
  h3 {
    color: #000000;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
    margin-bottom: 10px;
  }
  width: 180px;
  flex-shrink: 0;
`;

const FileInfo = styled(Row)<{ hasFileSelected: boolean }>`
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 0;
  padding-top: 1.875rem;
  ${(props) =>
    props.hasFileSelected
      ? css`
          color: #7e7e7e;
          font-weight: 500;
        `
      : css`
          color: #000000;
        `}

  text-align: center;
  ${XButton} {
    height: 19px;
    width: 19px;
    background-color: rgba(216, 216, 215, 0.3);
    border-radius: 50%;
    margin-left: 10px;
    flex-shrink: 0;
  }
`;

const FileInputRoot = styled(Row)`
  align-items: center;
  margin-bottom: 30px;
  width: 305px;
`;

const FormButton = styled(BlankButton)`
  height: 40px;
  width: 150px;
  border-radius: 3px;
  transition: opacity 0.3s;
  background-color: #000001;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1875rem;
  text-align: center;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.6;
  }
`;

const RosterFileInput: FC<{
  rosterFile: File | null;
  onRosterFileSelected(file: File): void;
  onRosterFileRemoved(): void;
  rosterType: RosterImportType;
}> = ({
  onRosterFileSelected,
  onRosterFileRemoved,
  rosterFile,
  rosterType,
  ...rest
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const [rosterCSV] = acceptedFiles;
      if (rosterCSV) {
        onRosterFileSelected(rosterCSV);
      }
    },
    [onRosterFileSelected]
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    ...BASE_DROPZONE_CONFIG,
  });
  return (
    <FileInputRoot {...rest}>
      <FileSelectContainer>
        <h3>
          Import {rosterType === "classroom" ? "class" : rosterType}
          {rosterType === "classroom" ? "es" : "s"}
        </h3>
        <FileDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {/* No button onClick, the click is handled by <FileDropzone> */}
          <FormButton type="button">Browse</FormButton>
        </FileDropzone>
      </FileSelectContainer>
      <FileInfo hasFileSelected={rosterFile !== null}>
        {rosterFile ? (
          <Row>
            {rosterFile.name}
            <XButton
              iconSize={11}
              onClick={(event: any) => {
                event.preventDefault();
                onRosterFileRemoved();

                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            />
          </Row>
        ) : (
          "No file selected"
        )}
      </FileInfo>
    </FileInputRoot>
  );
};
