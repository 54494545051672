import React, {FC} from 'react';
import styled from 'styled-components/macro';
import Row from "./Row";
import BlockButton from "./BlockButton";
import {AssignmentSortOrder} from "shared/lib/constants/AssignmentSortOrder";

interface Props {
  currentSortOption: AssignmentSortOrder;
  onSortOrderClicked(sortOrder: AssignmentSortOrder): void;
}

const AssignmentSortRuleSelector: FC<Props> = props => {
  const {  currentSortOption, onSortOrderClicked, ...rest } = props;
  return (
      <Row {...rest}>
        <div>Sort By:</div>
        <Buttons>
          <SortButton
            selected={currentSortOption === AssignmentSortOrder.ASSIGNED_DATE}
            onClick={() => onSortOrderClicked(AssignmentSortOrder.ASSIGNED_DATE)}
          >
            Date Assigned
          </SortButton>
        <SortButton
            selected={currentSortOption === AssignmentSortOrder.UNIT_ORDER}
            onClick={() => onSortOrderClicked(AssignmentSortOrder.UNIT_ORDER)}
        >
            Program Order
        </SortButton>
        </Buttons>
      </Row>
  );
};

export default styled(AssignmentSortRuleSelector)<{hide?: boolean}>`
  align-items: center;
  justify-content: center;
  opacity: ${props => props.hide ? 0 : 1};
`;


const SortButton = styled(BlockButton)<{selected: boolean}>`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  height: 2rem;
  width: 12rem;
  color: #19579f;
  border: 2px solid #9ce0fd;
  background-color: ${props => props.selected ? "#9ce0fd" : "white"};
  font-size: 16px;
  font-weight: 900;

  &:first-of-type {
    border-radius: 7px 0 0 7px;
    border-right: none;
  }

  &:last-of-type {
    border-radius: 0 7px 7px 0;
    border-left: none;
  }
`;

const Buttons = styled(Row)`
  margin-left: 1rem;
`;
