import apiAxios from "../apiAxios";

interface Props {
  name: string;
  categoryId: number | null;
}

export default async function updateUnit(
  unitId: number,
  props: Props
): Promise<void> {
  await apiAxios.put(`/units/${unitId}`, props);
}
