import React, { FC } from "react";
import styled from "styled-components/macro";
import TopMenu, { Props as TopMenuProps } from "./TopMenu";
import TextButton from "./TextButton";
import PlusIcon from "./PlusIcon";
import MinusIcon from "./MinusIcon";
import DotDotDotIcon from "./DotDotDotIcon";

interface Props extends TopMenuProps {
  unitId: number;
  onCreateAssignmentClick(): any;
  onRemoveUnitClick(): any;
  onEditUnitClick(): any;
}

const UnitTopMenu: FC<Props> = props => {
  const {
    unitId,
    onRemoveUnitClick,
    onCreateAssignmentClick,
    onEditUnitClick,
    ...rest
  } = props;

  return (
    <TopMenu {...rest}>
      <MenuButton onClick={onEditUnitClick}>
        <DotDotDotIcon size={24} />
        Edit Unit
      </MenuButton>
      <MenuButton onClick={onCreateAssignmentClick}>
        <PlusIcon size={24} /> New Assignment
      </MenuButton>
      <MenuButton onClick={onRemoveUnitClick}>
        <MinusIcon size={24} />
        Remove Unit
      </MenuButton>
    </TopMenu>
  );
};

export default styled(UnitTopMenu)``;

const MenuButton = styled(TextButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & + & {
    margin-left: 2rem;
  }

  > svg {
    margin-right: 0.5rem;
  }
`;
