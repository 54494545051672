import styled from 'styled-components/macro';
import Row from './Row';

const LinkRow = styled(Row)`
  margin-top: 3rem;

  a {
    color: #888;
  }

  a:hover {
    text-decoration: underline;
    color: #333;
  }

  a + a {
    margin-left: 1rem;
  }
`;

export default LinkRow;
