import { MainScope, ZwibblerClass } from "zwibbler/lib/zwibbler2";
import copyIconSrc from "../images/zwibbler/icon-copy.svg";
import pasteIconSrc from "../images/zwibbler/icon-paste.svg";
import cutIconSrc from "../images/zwibbler/icon-cut.svg";
import duplicateIconSrc from "../images/zwibbler/icon-duplicate.svg";
import boldIconSrc from "../images/zwibbler/icon-bold.svg";
import italicIconSrc from "../images/zwibbler/icon-italic.svg";
import underlineIconSrc from "../images/zwibbler/icon-underline.svg";

/**
 * Loads tool properties.
 * This code was written by the Zwibbler author
 * and should probably be refactored and split
 * into React comoponents.
 */
export default function loadZwibblerToolProperties(Zwibbler: ZwibblerClass) {
  Zwibbler.controller("mycontroller", (scope: MainScope) => {
    let ctx = scope.ctx; // zwibbler object
    // handle expanded / collapsed property panel
    scope.showProperties = () => {
      let mobile = window.innerWidth < 1000;
      return !mobile || scope.showMenu;
    };
    // Handle custom arrow properties
    Object.defineProperty(scope, "myArrowSize", {
      get() {
        return ctx.summary.properties.arrowSize;
      },
      set(value: number) {
        ctx.setProperties({
          arrowSize: value,
          arrowXOffset: value * 2.5,
        });
      },
    });
  });

  Zwibbler.component("CheckButton", {
    style: `
            .CheckButton {
                background: #ccc;
                border: none;
                border-bottom: 4px solid #ccc;
            }
            
            .CheckButton.checked {
                border-bottom: 4px solid #9fb3e7;
            }`,
    template: `
        <button class="CheckButton" z-class="{checked:value}">
            <slot></slot>
        </button>`,

    controller(scope) {
      let value = false;
      Object.defineProperty(scope, "value", {
        get() {
          return value;
        },

        set(v: boolean) {
          value = v;
        },
      });
    },
  });

  Zwibbler.component("ClipboardButtons", {
    style: `
        .ClipboardButtons {
            display: flex;
        }
        .ClipboardButtons .lg-svg {
            max-width: 20px;
        }
        
        .ClipboardButtons .sm-svg {
            max-width: 35px;
        }
    
        .ClipboardButtons button[disabled] {
            opacity: 0.5;
        }
        `,
    template: `
        <div class="ClipboardButtons">
            <button z-disabled="!('AnyNode' in ctx.summary.types)" title="Cut"><img class="lg-svg" src="${cutIconSrc}" z-click="ctx.cut()"></button>
            <button z-disabled="!('AnyNode' in ctx.summary.types)" title="Copy" ><img class="lg-svg" src="${copyIconSrc}" z-click="ctx.copy()"></button>
            <button z-disabled="!canPaste()" title="Paste"><img src="${pasteIconSrc}" class="lg-svg" z-click="ctx.paste()"></button>
            <button z-disabled="!('AnyNode' in ctx.summary.types)" title="Duplicate"><img class="sm-svg" src="${duplicateIconSrc}" style="height: 35px; width: 280px;" z-click="ctx.duplicate()"></button>
        </div>`,

    controller: function (scope) {
      scope.ctx = scope.$parent.ctx;
      scope.canPaste = () => {
        return localStorage.getItem("clipboard") !== null;
      };
    },
  });

  Zwibbler.component("ZPropertyPanel", {
    style: `.zwibbler-property-panel {
            width: 100%;
            background: #f5f5f5;
            display:flex;
            flex-flow: column nowrap;
            overflow-y: scroll;
            padding: 10px;
            font-family: sans-serif;
            text-align: left;
            -webkit-touch-callout: none;
            -webkit-user-select: none; 
             -khtml-user-select: none; 
               -moz-user-select: none; 
                -ms-user-select: none; 
                    user-select: none;     
        }
        
        .zwibbler-property-panel h3 {
            margin-bottom: 0.5em;
        }
        
        .zwibbler-property-panel button {
            font-family: inherit;
            font-size: 100%;
            padding: 5px;
            display: block;
            background-color: white;
            color: black;
            border: none;
            border-radius: 2px;
            border-bottom: 2px solid #ddd;
            width: 100%;
        }
        
        .zwibbler-property-panel button[tool] {
            display: inline-block;
            width: 60px;
            height: 60px;
            font-size: 30px;
        }
        
        .zwibbler-property-panel button.zwibbler-option {
            border: 0;
            padding: 10px;
            border-radius: 3px;
            background: transparent;
            text-align: left;
        }
        
        .zwibbler-property-panel button.selected {
            background: #9fb3e7;
        }
        
        .zwibbler-property-panel button.hover {
            background: #ddd;
        }
        
        .zwibbler-property-panel hr {
            border: none;
            border-top: 1px solid #ccc;
        }
        
        .zwibbler-property-panel select {
            width: 100%;
        }
        
        .zwibbler-property-panel .colour-picker {
            padding: 10px 0;
        }
        
        .zwibbler-property-panel [swatch] {
            border: 1px solid black;
            display: inline-block;
            height: 2em;
            width: 4em;
            vertical-align:middle;
            margin-right: 10px;
        }
    
        .font-style-buttons {
            display: flex;
            align-items:stretch;
        }
    
        .font-style-buttons button {
            height: 30px;
            display: flex;
            justify-content: center;
        }
        .font-style-buttons button img {
            width: 20px;
        }    
    
        .zwibbler-property-panel .pad-top {
            margin-top: 10px;
        }
    
        `,

    template: `<div class="zwibbler-property-panel">
        <h3>Shape options</h3>
        <ClipboardButtons></ClipboardButtons>
    
        <div z-if="ctx.summary.empty">
            There are no selected shapes.
        </div>
        <div z-has="AnyNode">
            <button z-click="ctx.deleteNodes()">Delete</button>                    
            <button z-click="ctx.bringToFront()">
                Move to front
            </button>
            <button z-click="ctx.sendToBack()">
                Send to back
            </button>
        </div>
        <div z-custom-properties></div>
        <div z-has="fontName" z-if="ctx.getConfig('showFontNameProperty')">
            <h3>Font</h3>
            <select z-property="fontName">
                <option z-for="font in ctx.getConfig('fonts')" z-text="font" z-bind:value="font"></option>
            </select>
        </div>
        <div class="font-style-buttons" z-has="fontName">
            <CheckButton z-model="ctx.summary.properties.bold" z-click="ctx.setProperty('bold', !ctx.summary.properties.bold)">
                <img src="${boldIconSrc}">
            </CheckButton>
            <CheckButton z-model="ctx.summary.properties.italic" z-click="ctx.setProperty('italic', !ctx.summary.properties.italic)">
                <img src="${italicIconSrc}">
            </CheckButton>
            <CheckButton z-model="ctx.summary.properties.underline" z-click="ctx.setProperty('underline', !ctx.summary.properties.underline)">
                <img src="${underlineIconSrc}">
            </CheckButton>
        </div>    
        <div z-has="fontSize" z-if="ctx.getConfig('showFontSizeProperty')">
            <h3>Font size</h3>
            <select z-property="fontSize">
                <option value="8">8</option>
                <option value="10">10</option>
                <option value="12">12</option>
                <option value="16">16</option>
                <option value="20">20</option>
                <option value="24">24</option>
                <option value="50">50</option>
            </select>
        </div>
        <div z-has="sides">
            <h3>Sides</h3>
            <input type="number" min="3" max="12" z-property="sides" style="width:100%">
        </div>
        <div z-has="fillStyle|strokeStyle|background">
            <h3>Colors</h3>
            <div class="colour-picker" z-has="fillStyle">
                <div swatch z-property="fillStyle" z-colour></div>
                Fill style
            </div>
            <div class="colour-picker" z-has="strokeStyle">
                <div swatch z-property="strokeStyle" z-colour></div>
                Outline
            </div>
            <div class="colour-picker" z-has="background">
                <div swatch z-property="background" z-colour></div>
                Background
            </div>
        </div>
        <div z-has="arrowSize">
            <h3>Arrows</h3>
            <button class="zwibbler-option" z-selected="$parent.myArrowSize==0" z-on:click="$parent.myArrowSize=0">None</button>
            <button class="zwibbler-option" z-selected="$parent.myArrowSize==10" z-on:click="$parent.myArrowSize=10">Small</button>
            <button class="zwibbler-option" z-selected="$parent.myArrowSize==15" z-on:click="$parent.myArrowSize=15">Large</button>
            <hr>
            <button class="zwibbler-option" z-property="doubleArrow" z-value="false">Single side</button>
            <button class="zwibbler-option" z-property="doubleArrow" z-value="true">Both ends</button>
            <hr>        
            <button class="zwibbler-option" z-property="arrowStyle" z-value="solid">Solid</button>
            <button class="zwibbler-option" z-property="arrowStyle" z-value="open">Open</button>
        </div>
        <div z-has="dashes">
            <h3>Line style</h3>
            <button class="zwibbler-option" z-property="dashes" z-value="">Solid</button>
            <button class="zwibbler-option" z-property="dashes" z-value="3,3">Dots</button>
            <button class="zwibbler-option" z-property="dashes" z-value="8,2">Dashes</button>
        </div>
        <div z-has="opacity">
            <h3>Opacity</h3>
            <input z-property="opacity" type="range" min="0.1" max="1.0" step="0.05">
        </div>              
        `,

    controller(scope) {
      scope.ctx = scope.$parent.ctx;
    },
  });
}
