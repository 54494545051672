import apiAxios from '../apiAxios';

export default async function reorderUnitAssignments(
  unitId: number,
  assignmentIds: number[]
) {
  await apiAxios.post(`/units/${unitId}/reorder-assignments`, {
    assignmentIds
  });
}
