import React, { FC } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import Link from "./Link";
import * as studentRoutes from "../studentRoutes";
import Row from "./Row";

const SEGMENT_NAME_WIDTH = 200;

interface Props {
  takeableAssignmentId: number;
  index: number;
  total: number;
  segmentNames: readonly string[];
}

const ProgressSegmentColumn: FC<Props> = props => {
  const { takeableAssignmentId, index, total, segmentNames, ...rest } = props;

  const segments = [];
  for (let i = 0; i < total; i++) {
    segments.push(
      <SegmentLink
        key={i}
        selected={i === index}
        to={studentRoutes.assignmentSlide(takeableAssignmentId, i)}
      >
        <SegmentName>{segmentNames[i] ?? ''}</SegmentName>
      </SegmentLink>
    );
  }

  return <Column {...rest}>{segments}</Column>;
};

export default styled(ProgressSegmentColumn)`
  width: 7px;
  padding: 0.5rem 0 0.5rem 0;
  flex-shrink: 0;
  height: 685px;
`;

export const SegmentName = styled(Row)`
  position: absolute;
  flex: 1 0 auto;
  width: ${SEGMENT_NAME_WIDTH}px;
  justify-content: flex-end;
  font-weight: bold;
  top: 40%;
  opacity: 0;
  background-color: transparent;
  transform: translateX(-${SEGMENT_NAME_WIDTH - 50}px);
  transition: opacity 0.8s, transform 0.6s, background-color 0.6s;
  pointer-events: none;
`;

export const SegmentLink = styled(Link) <{ selected: boolean }>`
  flex: 1;
  position: relative;
  background-color: ${props => (props.selected ? "#A7E5FF" : "#ECECEC")};

  & + & {
    margin-top: 4px;
  }
  
  &:hover{
    ${SegmentName} {
      opacity: 1;
      transform: translateX(-${SEGMENT_NAME_WIDTH + 10}px);
      background-color: #FFFFFF;
    }
  }
`;
