import React, { FC } from 'react';
import RouteProps from '../../RouteProps';
import submitTeacherPasswordReset from '../../api/forgotPassword/submitTeacherPasswordReset';
import ResetPasswordPage from '../../components/ResetPasswordPage';

const TeacherResetPasswordPage: FC<RouteProps> = props => {
  return (
    <ResetPasswordPage
      {...props}
      title="Reset Password - Teacher"
      backPath="/teacher"
      onSubmit={submitTeacherPasswordReset}
    />
  );
};

export default TeacherResetPasswordPage;
