import styled from "styled-components/macro";

interface Props {
  checked: boolean;
  checkedColor?: string;
  borderColor?: string;
}

const RadioCircle = styled("div")<Props>`
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
  border: 1px solid
    ${props => props.borderColor || props.checkedColor || "#4a90e2"};
  flex-shrink: 0;
  background-color: ${props =>
    props.checked ? props.checkedColor || "#4a90e2" : "transparent"};
`;

export default RadioCircle;
