import React, { FC } from "react";
import styled from "styled-components/macro";
import List from "./List";
import Row from "./Row";
import PlusLink from "./PlusLink";
import FadedLink from "./FadedLink";
import {
  classroomAssignments,
  classroomStudents,
  addClassroomStudent
} from "../teacherRoutes";

interface Props {
  classroomId: number;
  showAddStudentButton?: boolean;
  onAddStudentClick?(): any;
}

const ClassroomNav: FC<Props> = props => {
  const {
    classroomId,
    showAddStudentButton,
    onAddStudentClick,
    ...rest
  } = props;

  return (
    <Row {...rest}>
      <LinkList>
        <li>
          <FadedLink to={classroomAssignments(classroomId)}>
            Assignments
          </FadedLink>
        </li>
        <li>
          <FadedLink to={classroomStudents(classroomId)} allowPartial>
            Students
          </FadedLink>
        </li>
      </LinkList>
      {showAddStudentButton && (
        <PlusLink to={addClassroomStudent()}>Add Student</PlusLink>
      )}
    </Row>
  );
};

export default styled(ClassroomNav)`
  justify-content: space-between;
  align-items: center;
  height: 6rem;
`;

const LinkList = styled(List)`
  flex-direction: row;

  li {
    font-size: 150%;
  }

  li + li {
    margin-left: 2rem;
  }
`;
