import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import LabeledRadio from "./LabeledRadio";

interface Props {
  title?: ReactNode;
}

const RadioColumn: FC<Props> = props => {
  const { title, children, ...rest } = props;

  return (
    <Column {...rest}>
      {title && <Title>{title}</Title>}
      {children}
    </Column>
  );
};

export default styled(RadioColumn)`
  ${LabeledRadio} + ${LabeledRadio} {
    margin-top: 0.5rem;
  }
`;

const Title = styled("h5")`
  color: #333333;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
