import React, {FC, useCallback, useMemo, useState} from "react";
import styled from "styled-components/macro";
import chunk from "lodash.chunk";
import uniqBy from "lodash.uniqby";
import RouteProps from "../../RouteProps";
import StudentPage from "../../components/StudentPage";
import bannerImage from "../../images/dashboard_banner.png";
import AssignmentTile from "../../components/AssignmentTile";
import AssignmentTabBar from "../../components/AssignmentTabBar";
import Row from "../../components/Row";
import Column from "../../components/Column";
import getOwnAssignments from "../../api/students/getOwnAssignments";
import useAsyncEffect from "../../utils/useAsyncEffect";
import getOwnTakeableAssignments from "../../api/students/getOwnTakeableAssignments";
import Assignment from "shared/lib/types/Assignment";
import TakeableAssignment from "shared/lib/types/TakeableAssignment";
import AssignmentTake from "shared/lib/types/AssignmentTake";
import * as studentRoutes from "../../studentRoutes";
import getOwnTakes from "../../api/assignmentTakes/getOwnTakes";
import assignedIcon from "../../images/blue_assigned_icon.png";
import inProgressIcon from "../../images/blue_in_progress_icon.png";
import completedIcon from "../../images/blue_completed_icon.png";
import AssignmentSortRuleSelector from "../../components/AssignmentSortRuleSelector";
import {useAssignmentSort} from "../../utils/assignment/useAssignmentSort";

export enum Tab {
  assigned,
  completed,
}

interface Props extends RouteProps {
  tab: Tab;
  studentId: number;
  reload(): any;
}

const StudentDashboardPage: FC<Props> = (props) => {
  const { tab, studentId, reload, children, location, ...rest } = props;
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [takeableAssignments, setTakeableAssignments] = useState<
    TakeableAssignment[]
  >([]);
  const [takes, setTakes] = useState<AssignmentTake[]>([]);

  const getTakeableId = useMemo(() => {
    const takeableAssignmentMap = new Map<number, number>(
      takeableAssignments.map((takeable) => [
        takeable.assignmentId,
        takeable.id,
      ])
    );
    return (assignmentId: number): number | undefined =>
      takeableAssignmentMap.get(assignmentId);
  }, [takeableAssignments]);

  const isTakeable = useCallback(
    (assignment: Assignment) => !!getTakeableId(assignment.id),
    [getTakeableId]
  );

  const getAssignmentTakes = useMemo(() => {
    const assignmentTakeMap = new Map<number, AssignmentTake[]>(
      takeableAssignments.map((takeable) => [
        takeable.assignmentId,
        takes.filter((take) => take.takeableAssignmentId === takeable.id),
      ])
    );
    return (assignment: Assignment) =>
      assignmentTakeMap.get(assignment.id) || [];
  }, [takeableAssignments, takes]);

  const isAssignmentComplete = useCallback(
    (assignment: Assignment) =>
      getAssignmentTakes(assignment).some((take) => !!take.completedAt),
    [getAssignmentTakes]
  );

  let tabAssignments: Assignment[];

  if (tab === Tab.assigned) {
    tabAssignments = assignments
      .filter(isTakeable)
      .filter((assignment) => !isAssignmentComplete(assignment));
  } else {
    tabAssignments = assignments.filter(isAssignmentComplete);
  }

  const {
    assignments: sortedTabAssignments,
    sortOrder,
    onSortOrderClicked,
  } = useAssignmentSort({
      assignments: tabAssignments,
      location,
      takeableAssignments
   });

  const tabAssignmentRows = chunk(sortedTabAssignments, 3);

  useAsyncEffect(async (isCancelled) => {
    const [
      fetchedAssignments,
      fetchedTakeableAssignments,
      fetchedTakes,
    ] = await Promise.all([
      getOwnAssignments(),
      getOwnTakeableAssignments(),
      getOwnTakes(),
    ]);

    if (isCancelled()) {
      return;
    }

    setAssignments(uniqBy(fetchedAssignments, (assignment) => assignment.id));
    setTakeableAssignments(
      uniqBy(
        fetchedTakeableAssignments,
        (assignment) => assignment.assignmentId
      )
    );
    setTakes(fetchedTakes);
  }, []);

  return (
    <StudentPage {...rest} reload={reload}>
      <BannerImage />
      <Title>My Assignments</Title>
      <AssignmentTabBar location={location} />
      <AssignmentSortRuleSelector
        currentSortOption={sortOrder}
        onSortOrderClicked={onSortOrderClicked}
      />
      <Tiles>
        {tabAssignmentRows.map((row, i) => (
          <TileRow key={`${tab}-${i}`}>
            {row.map((assignment) => {
              const takeableId = getTakeableId(assignment.id);
              const takes = getAssignmentTakes(assignment);

              let showButton;
              let buttonContent;
              let isReviewed = false;
              let backgroundIcon = assignedIcon;
              let link = null;

              if (tab === Tab.assigned) {
                if (takeableId) {
                  link = studentRoutes.assignment(takeableId);
                }
                if (takes.length > 0) {
                  const isReopened = takes.some(take => take.reopenedAt !== null);
                  buttonContent = isReopened ? "Make Revisions" : "";
                  showButton = isReopened;
                  backgroundIcon = inProgressIcon;
                } else {
                  buttonContent = "";
                  showButton = false;
                }
              } else if (tab === Tab.completed) {
                buttonContent = "View Your Work";
                backgroundIcon = completedIcon;
                showButton = true;
                isReviewed =
                  takes.length > 0 && takes.some((take) => take.gradedAt);
                if (takeableId) {
                  link = studentRoutes.assignmentReview(takeableId);
                }
              } else {
                // unassigned
                buttonContent = "";
                showButton = false;
              }

              return (
                <AssignmentTile
                  isReviewed={isReviewed}
                  key={`${tab}-${assignment.id}`}
                  showStar={false}
                  title={assignment.title}
                  linkTo={link}
                  showButton={showButton}
                  buttonContent={buttonContent}
                  backgroundIcon={backgroundIcon}
                />
              );
            })}
          </TileRow>
        ))}
      </Tiles>
    </StudentPage>
  );
};

export default styled(StudentDashboardPage)`
  padding: 2rem 0 2rem 0;

  ${AssignmentTabBar} {
    margin-bottom: 2rem;
    align-self: center;
  }
  
  ${AssignmentSortRuleSelector} {
    margin-bottom: 2rem;
  }
`;

const BannerImage = styled("img").attrs({
  src: bannerImage,
  alt: "",
})`
  height: 182px;
  width: 498px;
  align-self: center;
  margin-bottom: 0.5rem;
`;

const Title = styled("h1")`
  color: #19579f;
  font-size: 40px;
  font-weight: 900;
  line-height: 48px;
  text-align: center;
  margin-bottom: 1rem;
`;

const Tiles = styled(Column)`
  align-self: center;
  width: 830px;
  max-width: 100%;
`;

const TileRow = styled(Row)`
  justify-content: space-between;

  & + & {
    margin-top: 3.5rem;
  }
`;
