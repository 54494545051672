import React, { FC } from "react";
import styled from "styled-components/macro";
import ExpandableImage, { ExpandableImageProps } from "./ExpandableImage";

interface Props extends Omit<ExpandableImageProps, "src"> {
  imageKey: string;
}

const S3Image: FC<Props> = ({ imageKey, ...rest }) => (
  <ExpandableImage {...rest} src={imageKey} />
);

export default styled(S3Image)``;
