import React, { FC, useState, useCallback } from "react";
import styled, { css } from "styled-components/macro";
import Teacher from "shared/lib/types/Teacher";
import { Match } from "@reach/router";
import { navigate } from "@reach/router";
import useUrlState from "../../utils/useUrlState";
import capitalizeFirst from "shared/lib/utils/capitalizeFirst";
import Admin from "shared/lib/types/Admin";
import replaceWhere from "shared/lib/utils/replaceWhere";
import RouteProps from "../../RouteProps";
import * as adminRoutes from "../../adminRoutes";
import useAsyncEffect from "../../utils/useAsyncEffect";
import getTeachers from "../../api/teachers/getTeachers";
import createTeacher from "../../api/teachers/createTeacher";
import updateTeacher from "../../api/teachers/updateTeacher";
import deleteTeacher from "../../api/teachers/deleteTeacher";
import deleteClassroom from "../../api/classrooms/deleteClassroom";
import sendTeacherInvitation from "../../api/invitations/sendTeacherInvitation";
import AdminPage from "../../components/AdminPage";
import List from "../../components/List";
import FormOverlay from "../../components/FormOverlay";
import TeacherTopMenu from "../../components/TeacherTopMenu";
import CreateTeacherForm, {
  Value as CreateTeacherFormValue,
} from "../../components/CreateTeacherForm";
import EditTeacherForm, {
  Value as EditTeacherFormValue,
} from "../../components/EditTeacherForm";
import ExpandListItem, {
  Header as ExpandListItemHeader,
} from "../../components/ExpandListItem";
import ListItem from "../../components/ListItem";
import Row from "../../components/Row";
import PlusLink from "../../components/PlusLink";
import Column from "../../components/Column";
import RadioCircle from "../../components/RadioCircle";
import showConfirm from "../../utils/showConfirm";
import ReadonlyTeacherUnitList from "../../components/ReadonlyTeacherUnitList";
import ExpandTriangle from "../../components/ExpandTriangle";
import RadioCircleButton from "../../components/RadioCircleButton";
import AssignmentGuide from "../../components/AssignmentGuide";
import IconButton from "../../components/IconButton";
import DeleteIcon from "../../components/DeleteIcon";

interface Props extends RouteProps {
  admin: Admin;
  setAdmin(admin: Admin): any;
  reload(): any;
}

const AdminTeachersPage: FC<Props> = (props) => {
  const { location } = props;
  const [
    showCreateTeacherModal,
    setShowCreateTeacherModal,
  ] = useUrlState<boolean>(location, "create-teacher");
  const [assignmentGuideId, setAssignmentGuideId] = useUrlState<
    string | number
  >(location, "guide");
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [expandedClasses, setExpandedClasses] = useState<number[]>([]);

  useAsyncEffect(async (cancelled) => {
    const fetchedTeachers = await getTeachers();
    if (!cancelled()) {
      setTeachers(fetchedTeachers);
    }
  }, []);

  const handleTeacherMenuClose = useCallback(async () => {
    navigate(adminRoutes.teachers, { replace: true });
  }, []);

  const handleRemoveTeacherClick = useCallback(
    async (teacherId: number) => {
      const confirmed = await showConfirm({
        title: "Are you sure you want to delete this teacher?",
        message: `This action cannot be undone and will delete all of the teacher's classes and the students in those classes.`,
      });
      if (confirmed) {
        await deleteTeacher(teacherId);
        setTeachers(teachers.filter((teacher) => teacher.id !== teacherId));
        navigate(adminRoutes.teachers);
      }
    },
    [teachers]
  );

  const handleEditTeacherCancel = useCallback((teacherId: number) => {
    navigate(adminRoutes.teacher(teacherId));
  }, []);

  const handleEditTeacherSubmit = useCallback(
    async (value: EditTeacherFormValue) => {
      const { teacherId, ...rest } = value;
      await updateTeacher(teacherId, rest);
      setTeachers(await getTeachers());
      navigate(adminRoutes.teacher(teacherId));
    },
    []
  );

  const handleCreateTeacherCancel = useCallback(() => {
    setShowCreateTeacherModal();
  }, [setShowCreateTeacherModal]);

  const handleCreateTeacherSubmit = useCallback(
    async (value: CreateTeacherFormValue) => {
      const teacher = await createTeacher(value);
      setTeachers([...teachers, teacher]);
      setShowCreateTeacherModal();
    },
    [teachers, setShowCreateTeacherModal]
  );

  const handleResendInviteClick = useCallback(async (teacherId: number) => {
    await sendTeacherInvitation(teacherId);
    window.alert("Invitation sent successfully.");
  }, []);

  const handleClassClick = useCallback((classroomId: number) => {
    setExpandedClasses((expandedClasses) => {
      if (expandedClasses.includes(classroomId)) {
        return expandedClasses.filter((id) => id !== classroomId);
      } else {
        return [...expandedClasses, classroomId];
      }
    });
  }, []);

  const handleDeleteClass = useCallback(
    async (teacherId: number, classroomId: number) => {
      await deleteClassroom(classroomId);
      setTeachers((value) =>
        replaceWhere(
          value,
          (other) => other.id === teacherId,
          (other) => ({
            ...other,
            classrooms:
              other.classrooms &&
              other.classrooms.filter(
                (classroom) => classroom.id !== classroomId
              ),
          })
        )
      );
    },
    []
  );

  return (
    <AdminPage {...props} location={location}>
      <Match path=":teacherId">
        {({ match }) => {
          const params: Record<string, string | undefined> | null = match;
          const teacherId =
            params && params.teacherId && parseInt(params.teacherId, 10);
          const teacher =
            teacherId && teachers.find((teacher) => teacher.id === teacherId);

          if (!teacher) {
            return null;
          }

          return (
            <TeacherTopMenu
              isPending={!teacher.enrolledAt}
              teacherId={teacher.id}
              onClose={handleTeacherMenuClose}
              onRemoveTeacherClick={() => handleRemoveTeacherClick(teacher.id)}
              onSendInviteClick={() => handleResendInviteClick(teacher.id)}
            />
          );
        }}
      </Match>
      <Match path=":teacherId/edit">
        {({ match }) => {
          const params: Record<string, string | undefined> | null = match;
          const teacherId =
            params && params.teacherId && parseInt(params.teacherId, 10);
          const teacher = teachers.find((other) => other.id === teacherId);

          if (!teacherId || !teacher) {
            return null;
          }

          return (
            <FormOverlay onClose={() => handleEditTeacherCancel(teacherId)}>
              <EditTeacherForm
                hidePassword
                title="Edit Teacher"
                onSubmit={handleEditTeacherSubmit}
                teacher={teacher}
                canAssignProducts
              />
            </FormOverlay>
          );
        }}
      </Match>
      {showCreateTeacherModal && (
        <FormOverlay onClose={handleCreateTeacherCancel}>
          <CreateTeacherForm onSubmit={handleCreateTeacherSubmit} />
        </FormOverlay>
      )}
      {assignmentGuideId && (
        <AssignmentGuide
          assignmentId={+assignmentGuideId}
          onClose={() => setAssignmentGuideId()}
        />
      )}
      <Header>
        <Title>Teachers</Title>
        <AddTeacherRow>
          <PlusLink to={adminRoutes.createTeacher()}>Add Teacher</PlusLink>
        </AddTeacherRow>
      </Header>
      <TeacherListContainer>
        <TeacherListHeaders>
          <TeacherNameHeader>Name</TeacherNameHeader>
          <ClassCountHeader>Classes</ClassCountHeader>
          <StudentCountHeader>Students</StudentCountHeader>
        </TeacherListHeaders>
        <List>
          {teachers.map((teacher) => {
            const teacherLinkPath = adminRoutes.teacher(teacher.id);
            return (
              <TeacherListItem
                key={teacher.id}
                header={
                  <TeacherLink>
                    <Match path={teacherLinkPath}>
                      {(props) => (
                        <RadioCircleButton
                          onClick={(event) => {
                            event.stopPropagation();
                            navigate(teacherLinkPath);
                          }}
                        >
                          <RadioCircle checked={!!props.match} />
                        </RadioCircleButton>
                      )}
                    </Match>
                    <TeacherNameText>
                      {capitalizeFirst(teacher.firstName)}{" "}
                      {capitalizeFirst(teacher.lastName)}{" "}
                    </TeacherNameText>
                    <ClassCountText>
                      {teacher.classrooms && teacher.classrooms.length}
                    </ClassCountText>
                    <StudentCountText>
                      {teacher.classrooms &&
                        teacher.classrooms.reduce((count, classroom) => {
                          const { students } = classroom;
                          if (!students) {
                            return count;
                          }
                          return count + students.length;
                        }, 0)}
                    </StudentCountText>
                  </TeacherLink>
                }
              >
                {(teacher.classrooms || []).map((classroom, i) => {
                  const first = i === 0;
                  const last = !!(
                    teacher.classrooms && i === teacher.classrooms.length - 1
                  );
                  const expanded = expandedClasses.includes(classroom.id);

                  return (
                    <TeacherSubListItemWrapper key={classroom.id}>
                      <TeacherSubListItem
                        first={first}
                        last={last}
                        onClick={() => handleClassClick(classroom.id)}
                      >
                        <ClassroomNameRow>
                          {classroom.name}
                          <ClassroomDeleteButton
                            onClick={async (event) => {
                              event.stopPropagation();
                              if (
                                await showConfirm({
                                  title: `Delete class ${classroom.name}?`,
                                  message:
                                    "This action cannot be undone and will delete all students in the classroom as well as their assignment takes.",
                                })
                              ) {
                                handleDeleteClass(teacher.id, classroom.id);
                              }
                            }}
                          >
                            <DeleteIcon size={20} />
                          </ClassroomDeleteButton>
                        </ClassroomNameRow>

                        <ClassroomStudentCountText>
                          {classroom.students && classroom.students.length}
                        </ClassroomStudentCountText>
                        <ExpandTriangle turned={expanded} />
                      </TeacherSubListItem>
                      {expanded && (
                        <ReadonlyTeacherUnitList
                          teacherId={classroom.teacherId}
                          onAssignmentClick={setAssignmentGuideId}
                        />
                      )}
                    </TeacherSubListItemWrapper>
                  );
                })}
              </TeacherListItem>
            );
          })}
        </List>
      </TeacherListContainer>
    </AdminPage>
  );
};

export default styled(AdminTeachersPage)``;

const Title = styled("h1")`
  padding-bottom: 4rem;
  font-weight: normal;
`;

const Header = styled(Column)`
  width: 800px;
  max-width: 100%;
  align-self: center;
`;

const TeacherListContainer = styled(Column)`
  width: 660px;
  max-width: 100%;
  align-self: center;
`;

const AddTeacherRow = styled(Row)`
  border-bottom: 1px solid black;
  padding-bottom: 1rem;
  margin-bottom: 6rem;
  justify-content: flex-end;
`;

const TeacherLink = styled(Row)`
  height: 100%;
  position: relative;
  align-items: center;
  flex: 1 1 auto;
  cursor: pointer;
`;

const TeacherListItem = styled(ExpandListItem)`
  border-left: none;
  border-right: none;

  > ${ExpandListItemHeader} {
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
  }
`;

const TeacherNameHeader = styled("div")`
  flex: 1;
  padding-left: 4rem;
`;

const TeacherNameText = styled("div")`
  flex: 1;
`;

const ClassCountHeader = styled("div")`
  margin-right: 5.5rem;
`;

const ClassCountText = styled("div")`
  margin-right: 8rem;
`;

const StudentCountHeader = styled("div")`
  margin-right: 8rem;
`;

const StudentCountText = styled("div")`
  margin-right: 6.75rem;
`;

const TeacherListHeaders = styled(Row)`
  margin-bottom: 0.6rem;
  font-size: 0.9rem;
  font-weight: bold;
`;

const TeacherSubListItem = styled(ListItem)<{ first: boolean; last: boolean }>`
  padding: 0 1rem 0 0;
  cursor: pointer;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  ${(props) => {
    if (props.first) {
      return css`
        border-bottom: 1px solid #d8d8d8;
      `;
    }
    if (props.last) {
      return css`
        border-top: 1px solid #d8d8d8;
      `;
    }
    return "";
  }}
`;

const TeacherSubListItemWrapper = styled(Column)``;

const ClassroomNameRow = styled("div")`
  flex: 1;
  padding-left: 2.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ClassroomStudentCountText = styled("div")`
  margin-right: 8.2rem;
`;

const ClassroomDeleteButton = styled(IconButton)`
  margin-left: 2rem;
  opacity: 0.5;
  transition: opacity 0.2s, color 0.2s;

  &:hover {
    opacity: 1;
    color: #aa0000;
  }
`;
