import { useState, useCallback, FormEventHandler } from 'react';

export default function useInputState(
  initialValue?: string
): [string, FormEventHandler<HTMLInputElement>, (value: string) => void] {
  const [value, setValue] = useState<string>(initialValue || '');
  const handleEvent: FormEventHandler<HTMLInputElement> = useCallback(event => {
    setValue(event.currentTarget.value);
  }, []);

  return [value, handleEvent, setValue];
}
