import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Form from "./Form";
import BlockButton from "./BlockButton";
import UnderlinedInput from "./UnderlinedInput";
import getErrorMessage from "../utils/getErrorMessage";
import FormErrorText from "./FormErrorText";

export interface Props {
  title: string;
  submitButtonText: string;
  onSubmit(): any;
}

const FormLayout: FC<Props> = props => {
  const { title, submitButtonText, children, onSubmit, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await onSubmit();
      setSubmitting(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }, [onSubmit]);

  return (
    <Form {...rest} onSubmit={handleSubmit} disabled={submitting}>
      <h1>{title}</h1>
      <FormErrorText>{error ? getErrorMessage(error) : null}</FormErrorText>
      {children}
      <BlockButton>{submitButtonText}</BlockButton>
    </Form>
  );
};

export default styled(FormLayout)`
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;

  h1 {
    text-align: center;
    font-weight: normal;
    margin-bottom: 4rem;
  }

  ${UnderlinedInput} {
    margin-bottom: 3rem;
  }
`;
