import React, {
  FC,
  FormEvent,
  MouseEvent,
  useCallback,
  useState,
  useRef
} from "react";
import styled from "styled-components/macro";
import isBlank from "shared/lib/utils/isBlank";
import AssignmentSetForm, {
  getDefaultValue as getAssignmentSetFormDefaultValue,
  Value as AssignmentSetFormValue
} from "./AssignmentSetForm";
import Column from "./Column";
import replaceWhere from "shared/lib/utils/replaceWhere";
import Label from "./Label";
import OutlinedInput from "./OutlinedInput";
import BlockButton from "./BlockButton";
import Form from "./Form";
import Row from "./Row";
import getErrorMessage from "../utils/getErrorMessage";
import FormErrorText from "./FormErrorText";
import PlusCircleButton from "./PlusCircleButton";
import last from "shared/lib/SketchPad/utils/last";

export interface Props {
  title: string;
  secondTitle?: string;
  categoryName?: string | null;
  unitName?: string | null;
  submitButtonText: string;
  initialValue?: Value;
  onSubmit(value: Value): any;
}

export interface Value {
  assignment: {
    id?: number;
    title: string;
    subTitle: string;
  };
  sets: AssignmentSetFormValue[];
}

export function getDefaultValue(): Value {
  return {
    assignment: {
      title: "",
      subTitle: ""
    },
    sets: [getAssignmentSetFormDefaultValue()]
  };
}

const AssignmentForm: FC<Props> = props => {
  const {
    initialValue = getDefaultValue(),
    title,
    secondTitle = title,
    categoryName,
    unitName,
    submitButtonText,
    onSubmit,
    ...rest
  } = props;
  const ref = useRef<HTMLFormElement | null>(null);
  const [showingTitleForm, setShowingTitleForm] = useState(true);
  const [state, setState] = useState<Value>(initialValue);
  const [error, setError] = useState<Error | null>(null);
  const [expandedSetIds, setExpandedSetIds] = useState(() => {
    const lastSet = last(state.sets);
    if (lastSet) {
      return [lastSet.uuid];
    }
    return [];
  });
  const canSubmitTitleForm =
    !isBlank(state.assignment.title) && !isBlank(state.assignment.subTitle);

  const handleSubmit = useCallback(async () => {
    try {
      await onSubmit(state);
    } catch (error) {
      setError(error);
    }
  }, [state, onSubmit]);

  const handleTitleChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const title = event.currentTarget.value;
      setState(state => ({
        ...state,
        assignment: { ...state.assignment, title }
      }));
    },
    []
  );

  const handleSubTitleChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const subTitle = event.currentTarget.value;
      setState(state => ({
        ...state,
        assignment: { ...state.assignment, subTitle }
      }));
    },
    []
  );

  const handleSetChange = useCallback(
    (oldSet: AssignmentSetFormValue, newSet: AssignmentSetFormValue) => {
      setState(state => ({
        ...state,
        sets: replaceWhere(
          state.sets,
          other => other === oldSet,
          () => newSet
        )
      }));
    },
    []
  );

  const handleAddSetClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    const newSet = getAssignmentSetFormDefaultValue();
    setState(state => ({
      ...state,
      sets: [...state.sets, newSet]
    }));
    setExpandedSetIds([newSet.uuid]);

    // Scroll parent element to the top
    if (ref.current && ref.current.parentElement) {
      ref.current.parentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, []);

  const handleRemoveSet = useCallback(
    (setIndex: number, event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setState(state => {
        const newSets = [
          ...state.sets.slice(0, setIndex),
          ...state.sets.slice(setIndex + 1)
        ];
        return { ...state, sets: newSets };
      });
    },
    []
  );

  const handleSubmitTitleForm = useCallback(() => {
    if (!canSubmitTitleForm) {
      return;
    }
    setShowingTitleForm(false);
  }, [canSubmitTitleForm]);

  const handleTitleClick = useCallback(() => {
    setShowingTitleForm(true);
  }, []);

  const handleExpandSet = useCallback((setUuid: string) => {
    setExpandedSetIds(setIds => {
      if (setIds.includes(setUuid)) {
        return setIds.filter(other => other !== setUuid);
      }
      return [...setIds, setUuid];
    });
  }, []);

  if (showingTitleForm) {
    return (
      <TitleFormContainer>
        <h1>{title}</h1>
        <TitleForm onSubmit={handleSubmitTitleForm}>
          <Label>Assignment Title</Label>
          <OutlinedInput
            placeholder="Title"
            value={state.assignment.title}
            onChange={handleTitleChange}
          />
          <Label>Assignment Subtitle</Label>
          <OutlinedInput
            placeholder="Subtitle"
            value={state.assignment.subTitle}
            onChange={handleSubTitleChange}
          />
          <BlockButton disabled={!canSubmitTitleForm}>NEXT</BlockButton>
        </TitleForm>
      </TitleFormContainer>
    );
  }

  return (
    <Form {...rest} onSubmit={handleSubmit} innerRef={ref}>
      <Header>
        <h1>{secondTitle}</h1>
        {categoryName && <h2>Product: {categoryName}</h2>}
        {unitName && <h3>Unit: {unitName}</h3>}
      </Header>
      <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
      <TitleRow onClick={handleTitleClick} underlined={state.sets.length <= 1}>
        <AssignmentTitle>{state.assignment.title}</AssignmentTitle>
        <AssignmentSubTitle>{state.assignment.subTitle}</AssignmentSubTitle>
      </TitleRow>
      <Sets>
        {state.sets.map((set, i) => {
          const canExpand = i !== state.sets.length - 1;
          const expanded = !canExpand || expandedSetIds.includes(set.uuid);
          return (
            <AssignmentSetForm
              key={set.uuid}
              index={i}
              value={set}
              canExpand={canExpand}
              expanded={expanded}
              onExpandClick={() => handleExpandSet(set.uuid)}
              onChange={newSet => handleSetChange(set, newSet)}
              onRemove={event => handleRemoveSet(i, event)}
            />
          );
        })}
      </Sets>
      <AddSetButton onClick={handleAddSetClick}>ADD ANOTHER SET</AddSetButton>
      <SubmitButtonRow>
        <SubmitButton>{submitButtonText}</SubmitButton>
      </SubmitButtonRow>
    </Form>
  );
};

export default styled(AssignmentForm)`
  width: 650px;
  padding-bottom: 2rem;
`;

const Sets = styled(Column)``;

const AddSetButton = styled(PlusCircleButton)`
  margin-bottom: 2rem;
  font-weight: 400;
`;

const TitleFormContainer = styled(Column)`
  width: 100%;
  align-items: center;

  h1 {
    height: 84px;
    width: 100%;
    background-color: #333333;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 120px;
  }

  label {
    margin-bottom: 3px;
  }

  input {
    width: 477px;
    max-width: 100%;
    margin-bottom: 20px;
    height: 36px;
  }

  ${BlockButton} {
    margin-top: 10px;
    width: 227px;
    transition: opacity 0.2s;
  }

  ${BlockButton}:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const TitleForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 477px;
  max-width: 100%;
`;

const TitleRow = styled(Row)<{ underlined: boolean }>`
  position: relative;
  padding-bottom: 10px;
  color: #000000;
  margin-bottom: 6px;
  cursor: pointer;
  align-items: flex-end;

  &:after {
    display: ${props => (props.underlined ? "block" : "none")};
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 1px;
    width: 810px;
    background-color: #333333;
    max-width: 100vw;
  }
`;

const AssignmentTitle = styled("div")`
  font-size: 2.5rem;
  font-weight: 600;
`;

const AssignmentSubTitle = styled("div")`
  font-size: 1.5rem;
  margin-left: 10px;
  margin-bottom: 5px;
`;

const SubmitButtonRow = styled(Row)``;

const SubmitButton = styled(BlockButton)`
  width: 284px;
  font-weight: 400;
  text-align: left;
  padding-left: 3.5rem;
  padding-right: 3rem;
`;

const Header = styled(Column)`
  margin-top: 97px;

  h1,
  h2,
  h3 {
    color: #000000;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    font-weight: normal;
  }

  h1 {
    font-weight: bold;
  }
`;
