import React, { FC, ComponentProps } from "react";
import styled from "styled-components/macro";
import IconButton from "./IconButton";
import { MdClear } from "react-icons/md";

interface Props {
  iconSize?: string | number;
}

const XButton: FC<Props & ComponentProps<typeof IconButton>> = props => {
  const { iconSize, ...rest } = props;
  return (
    <IconButton {...rest}>
      <MdClear size={iconSize} />
    </IconButton>
  );
};

export default styled(XButton)`
  font-size: 1.5rem;
`;
