import apiAxios from "../apiAxios";
import TakeableAssignment from "shared/lib/types/TakeableAssignment";

interface Props {
  classroomId: number;
  assignmentId: number;
}

export default async function enableTakingAssignment(
  props: Props
): Promise<TakeableAssignment> {
  const { classroomId, assignmentId } = props;
  const response = await apiAxios.post(
    `/classrooms/${classroomId}/assignments`,
    {
      assignmentId
    }
  );

  return response.data;
}
