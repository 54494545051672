import 'react-datepicker/dist/react-datepicker.css';
import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import ReactDatepicker from 'react-datepicker';

interface Props {
  value?: string | null;
  onChange?: (value: string | null) => any;
  placeholder?: string;
  className?: string;
}

const DateInput: FC<Props> = props => {
  const { value, onChange, placeholder, className, ...rest } = props;
  const dateObject = useMemo(() => (value ? new Date(value) : undefined), [
    value
  ]);

  const handleChange = useCallback(
    (date: Date | null) => {
      if (onChange) {
        onChange(date ? date.toISOString() : null);
      }
    },
    [onChange]
  );

  return (
    <ReactDatepicker
      {...rest}
      wrapperClassName={className}
      selected={dateObject}
      onChange={handleChange}
      placeholderText={placeholder}
    />
  );
};

export default styled(DateInput)``;
