import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Uuid from "uuid/v4";
import AssignmentForm, {
  Props as AssignmentFormProps,
  Value as AssignmentFormValue
} from "./AssignmentForm";
import useAsyncEffect from "../utils/useAsyncEffect";
import getAssignmentById from "../api/assignments/getAssignmentById";
import doesAssignmentHaveTakes from "../api/assignmentTakes/doesAssignmentHaveTakes";
import ConfirmModal from "./ConfirmModal";
import Assignment from "shared/lib/types/Assignment";

interface Props extends AssignmentFormProps {
  assignmentId: number;
  onSubmit(value: Value): any;
  onCancel(): any;
}

export interface Value extends AssignmentFormValue {
  assignmentId: number;
}

const EditAssignmentForm: FC<Props> = props => {
  const { assignmentId, onSubmit, onCancel, ...rest } = props;
  const [showEditWarning, setShowEditWarning] = useState(false);
  const [assignment, setAssignment] = useState<Assignment | null>(null);
  const [initialValue, setInitialValue] = useState<AssignmentFormValue | null>(
    null
  );

  useAsyncEffect(
    async isCancelled => {
      const [fetchedAssignment, hasTakes] = await Promise.all([
        getAssignmentById(assignmentId),
        doesAssignmentHaveTakes(assignmentId)
      ]);

      if (!fetchedAssignment) {
        // TODO: Alert parent component?
        return;
      }

      const value: Value = {
        assignmentId: fetchedAssignment.id,
        assignment: {
          title: fetchedAssignment.title,
          subTitle: fetchedAssignment.subTitle
        },
        sets: fetchedAssignment.sets
          .map(set => ({
            id: set.id,
            uuid: Uuid(),
            index: set.index,
            questions: set.questions
              .map(question => ({
                id: question.id,
                uuid: Uuid(),
                index: question.index,
                isPractice: question.isPractice,
                questionText: question.questionText,
                inputType: question.inputType,
                questionImage: question.questionImage,
                sampleResponseText: question.sampleResponseText,
                sampleResponseImage: question.sampleResponseImage
              }))
              .sort((a, b) => a.index - b.index),
            learningTarget: set.learningTarget,
            isExampleCorrect: set.isExampleCorrect,
            exampleImage: set.exampleImage,
            character: set.character
          }))
          .sort((a, b) => a.index - b.index)
      };

      if (!isCancelled()) {
        setAssignment(fetchedAssignment);
        setInitialValue(value);
        setShowEditWarning(!!hasTakes);
      }
    },
    [assignmentId]
  );

  const handleSubmit = useCallback(
    async (value: AssignmentFormValue) => {
      await onSubmit({
        ...value,
        assignmentId
      });
    },
    [onSubmit, assignmentId]
  );

  const handleEditConfirm = useCallback(() => {
    setShowEditWarning(false);
  }, []);

  if (!initialValue || !assignment) {
    // Loading...
    return null;
  }

  const { unit } = assignment;
  const category = unit && unit.category;
  const unitName = unit && unit.name;
  const categoryName = category && category.name;

  return (
    <>
      <AssignmentForm
        {...rest}
        initialValue={initialValue}
        onSubmit={handleSubmit}
        unitName={unitName}
        categoryName={categoryName}
      />
      {showEditWarning && (
        <ConfirmModal
          message="You are editing an assignment that students have already started taking."
          onCancel={onCancel}
          onConfirm={handleEditConfirm}
        />
      )}
    </>
  );
};

export default styled(EditAssignmentForm)``;
