import {useCallback, useMemo} from "react";
import {ASSIGNMENT_SORT_ORDERS, AssignmentSortOrder} from "shared/lib/constants/AssignmentSortOrder";
import setSearchParam from "../setSearchParam";
import useSearchParams from "../useSearchParams";
import {WindowLocation} from "@reach/router";
import Assignment from "shared/lib/types/Assignment";
import TakeableAssignment from "shared/lib/types/TakeableAssignment";
import {
    assignedDateDescending,
    unitOrderAscending
} from "shared/lib/utils/assignmentComparators";
import useSort from "../useSort";

interface Params {
    location?: WindowLocation;
    assignments: readonly Assignment[];
    takeableAssignments: readonly TakeableAssignment[];
}


export function useAssignmentSort({ location, assignments, takeableAssignments }: Params) {
    const params = useSearchParams(location, ['sortOrder']);

    const sortOrder = useMemo(() =>
        ASSIGNMENT_SORT_ORDERS.find(order => order === params.sortOrder) ?? AssignmentSortOrder.ASSIGNED_DATE,
        [params]
    );

    const compareTakeableAssignments = useCallback((assignmentA: Assignment, assignmentB: Assignment): number => {
        return assignedDateDescending(assignmentA, assignmentB, takeableAssignments)
    }, [takeableAssignments])

    const compareAssignments = useCallback((assignmentA: Assignment, assignmentB: Assignment): number => {
        switch (sortOrder) {
            case AssignmentSortOrder.ASSIGNED_DATE:
                return compareTakeableAssignments(assignmentA, assignmentB);
            case AssignmentSortOrder.UNIT_ORDER:
                return unitOrderAscending(assignmentA, assignmentB);
        }
    }, [sortOrder, compareTakeableAssignments]);



    const onSortOrderClicked = useCallback((order: AssignmentSortOrder) => {
        if(sortOrder === order) {
            return;
        }
        setSearchParam(location, 'sortOrder', order, { }, false);
    }, [location, sortOrder]);

    return {
        assignments: useSort(assignments, compareAssignments),
        onSortOrderClicked,
        sortOrder,
    }
}