import React, { FC, useCallback } from "react";
import { Router, navigate, Redirect } from "@reach/router";
import AdminLoginPage from "../pages/admin/AdminLoginPage";
import AdminForgotPasswordPage from "../pages/admin/AdminForgotPasswordPage";
import AdminResetPasswordPage from "../pages/admin/AdminResetPasswordPage";
import TeacherLoginPage from "../pages/teacher/TeacherLoginPage";
import TeacherForgotPasswordPage from "../pages/teacher/TeacherForgotPasswordPage";
import TeacherResetPasswordPage from "../pages/teacher/TeacherResetPasswordPage";
import TeacherAcceptInvitePage from "../pages/teacher/TeacherAcceptInvitePage";
import StudentLoginPage from "../pages/student/StudentLoginPage";
import StudentForgotPasswordPage from "../pages/student/StudentForgotPasswordPage";
import StudentResetPasswordPage from "../pages/student/StudentResetPasswordPage";
import StudentAcceptInvitePage from "../pages/student/StudentAcceptInvitePage";
import AdminDrawingPreviewPage from "../pages/admin/AdminDrawingPreviewPage";

interface Props {
  reload(): Promise<void>;
}

const UnauthorizedRoot: FC<Props> = ({ reload }) => {
  const handleLogin = useCallback(async () => {
    await reload();
    await navigate("/");
  }, [reload]);

  return (
    <Router>
      <StudentLoginPage default path="/" onLogin={handleLogin} />
      <StudentForgotPasswordPage path="/student/forgot-password" />
      <StudentResetPasswordPage path="/student/reset-password" />
      <StudentAcceptInvitePage path="/student/accept-invite" />

      <TeacherLoginPage path="/teacher" onLogin={handleLogin} />
      <TeacherForgotPasswordPage path="/teacher/forgot-password" />
      <TeacherResetPasswordPage path="/teacher/reset-password" />
      <TeacherAcceptInvitePage path="/teacher/accept-invite" />

      <AdminLoginPage path="/admin" onLogin={handleLogin} />
      <AdminForgotPasswordPage path="/admin/forgot-password" />
      <AdminResetPasswordPage path="/admin/reset-password" />
      <Redirect from="/preview/:responseId" to="/drawing/:responseId" />
      <AdminDrawingPreviewPage
        path="/drawing/:responseId"
        responseId=""
        admin={null}
        reload={reload}
      />
    </Router>
  );
};

export default UnauthorizedRoot;
