import React, { FC } from 'react';
import styled from "styled-components/macro";
import BlockButton from "./BlockButton";
import { Tool } from '../constants/tool';
import ToolIcon from './ToolIcon';


interface Props {
  tool: Tool,
  isSelected?: boolean,
  onClick(tool: Tool): void,
}

const ToolButton: FC<Props> = ({ tool, isSelected, onClick }) => (
  <Root isSelected={isSelected} onClick={() => onClick(tool)} title={tool}>
    <ToolIcon tool={tool} />
  </Root>
);

const Root = styled(BlockButton) <{ isSelected?: boolean }>`
  box-sizing: border-box;
  height: 44px;
  width: 51px;
  border: 2px solid #ffffff;
  border-radius: 7px;
  background-color: ${props => (props.isSelected ? "#6e9fb2" : "#9ce0fd")};
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  & + & {
    margin-top: 2px;
  }
  &:active {
    background-color: #6e9fb2;
  }
`;

export default styled(ToolButton)``;

