import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import RouteProps from '../RouteProps';
import useInputState from '../utils/useInputState';
import getErrorMessage from '../utils/getErrorMessage';
import Link from './Link';
import Page from './Page';
import Form from './Form';
import BlockButton from './BlockButton';
import LinkRow from './LinkRow';
import OutlinedInput from './OutlinedInput';
import FormErrorText from './FormErrorText';
import isBlank from 'shared/lib/utils/isBlank';
import FormSuccessText from './FormSuccessText';

interface Props extends RouteProps {
  title: string;
  backPath: string;
  onSubmit: (email: string) => any;
}

const ForgotPasswordPage: FC<Props> = props => {
  const { title, backPath, onSubmit, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [email, handleEmailChange] = useInputState('');

  const handleSubmit = useCallback(async () => {
    try {
      if (isBlank(email)) {
        throw new Error('You must enter an email.');
      }
      setSubmitting(true);
      await onSubmit(email);
      setSubmitSuccess(true);
      setError(null);
    } catch (error) {
      setError(error);
      setSubmitSuccess(false);
    } finally {
      setSubmitting(false);
    }
  }, [email, onSubmit]);

  return (
    <Page {...rest}>
      <h1>{title}</h1>

      <Form onSubmit={handleSubmit}>
        {submitSuccess ? (
          <FormSuccessText>
            {submitSuccess && 'Password reset sent successfully.'}
          </FormSuccessText>
        ) : (
          <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
        )}

        <OutlinedInput
          placeholder="Email"
          autoComplete="email"
          value={email}
          onChange={handleEmailChange}
        />
        <BlockButton disabled={submitting}>Send Password Reset</BlockButton>
      </Form>
      <LinkRow>
        <Link to={backPath}>Back</Link>
      </LinkRow>
    </Page>
  );
};

export default styled(ForgotPasswordPage)`
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;

  h1 {
    margin-bottom: 2rem;
  }

  ${Form} {
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  ${OutlinedInput} {
    margin-bottom: 2rem;
  }
`;
