import apiAxios from "../apiAxios";

interface Props {
  classroomId: number;
  assignmentId: number;
}

export default async function disableTakingAssignment(
  props: Props
): Promise<void> {
  const { classroomId, assignmentId } = props;
  await apiAxios.delete(
    `/classrooms/${classroomId}/assignments/${assignmentId}`
  );
}
