import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import AssignmentCategory from "shared/lib/types/AssignmentCategory";
import List from "./List";
import useAsyncEffect from "../utils/useAsyncEffect";
import getAssignmentCategories from "../api/assignments/getAssignmentCategories";
import Switch from "./Switch";

interface Props {
  value: number[];
  onChange(value: number[]): any;
}

const CategoryListInput: FC<Props> = props => {
  const { value, onChange, ...rest } = props;
  const [categories, setCategories] = useState<AssignmentCategory[] | null>(
    null
  );

  useAsyncEffect(async isCancelled => {
    const fetchedCategories = await getAssignmentCategories();
    if (!isCancelled()) {
      setCategories(fetchedCategories);
    }
  }, []);

  const handleCategoryClick = useCallback(
    (categoryId: number) => {
      if (value.includes(categoryId)) {
        onChange(value.filter(id => id !== categoryId));
      } else {
        onChange([...value, categoryId]);
      }
    },
    [onChange, value]
  );

  if (!categories) {
    return null;
  }

  return (
    <List {...rest}>
      {categories.map(category => (
        <ListItem
          key={category.id}
          onClick={() => handleCategoryClick(category.id)}
        >
          <Switch checked={value.includes(category.id)} />
          <CategoryName>{category.name}</CategoryName>
        </ListItem>
      ))}
    </List>
  );
};

export default styled(CategoryListInput)``;

const ListItem = styled("li")`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & + & {
    margin-top: 10px;
  }
`;

const CategoryName = styled("div")`
  font-size: 1.25rem;
  margin-left: 2rem;
`;
