import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components/macro";
import Classroom from "shared/lib/types/Classroom";
import Row from "./Row";
import useSort from "../utils/useSort";
import * as teacherRoutes from "../teacherRoutes";
import PlusLink from "./PlusLink";
import writeIconSrc from "../images/write_icon.png";
import TextButton from "./TextButton";
import Dropdown from "./Dropdown";
import { navigate } from "@reach/router";

interface Props {
  classrooms: Classroom[];
  onEditClassClick(): any;
  onCreateClassClick(): any;
  selectedClassroom: Classroom | null;
}

const ClassesNav: FC<Props> = props => {
  const {
    classrooms,
    onEditClassClick,
    onCreateClassClick,
    selectedClassroom,
    ...rest
  } = props;
  const sortedClassrooms = useSort(classrooms, compareClassrooms);

  const classroomOptions = useMemo(
    () =>
      sortedClassrooms.map(({ id, name }) => ({
        value: id,
        label: (
          <ClassName selected={selectedClassroom?.id === id}>{name}</ClassName>
        )
      })),
    [sortedClassrooms, selectedClassroom]
  );

  const handleClassroomSelected = useCallback(
    (classroomId: number) => {
      const matchingClassroom = classrooms.find(
        classroom => classroom.id === classroomId
      );
      if (matchingClassroom) {
        navigate(teacherRoutes.classroom(matchingClassroom.id));
      }
    },
    [classrooms]
  );

  return (
    <Row {...rest}>
      <ClassList>
        {classroomOptions.length > 0 && (
          <>
            <Dropdown
              label={selectedClassroom?.name ?? ""}
              options={classroomOptions}
              onChange={handleClassroomSelected}
            />
            <EditClassButton
              disabled={!selectedClassroom}
              onClick={onEditClassClick}
            >
              <EditClassIcon />
            </EditClassButton>
          </>
        )}
      </ClassList>
      <PlusLink to={teacherRoutes.createClassroom()}>Create Class</PlusLink>
    </Row>
  );
};

export default styled(ClassesNav)`
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 1rem;
`;

const ClassList = styled(Row)`
  flex-direction: row;
  font-weight: bold;
`;

const EditClassButton = styled(TextButton)`
  position: absolute;
  right: -5px;
  transform: translate(100%, 2px);

  margin-left: 5px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const EditClassIcon = styled("img").attrs({
  src: writeIconSrc,
  alt: "Edit class"
})`
  height: 16px;
  width: 16px;
`;

function compareClassrooms(a: Classroom, b: Classroom) {
  return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
}

const ClassName = styled.div<{ selected: boolean }>`
  color: ${props => (props.selected ? "black" : "#b8b8b8")};
  transition: background-color 0.2s;
  &:hover {
    color: black;
  }
`;
