import React, { FC, ReactNode, Children } from "react";
import styled from "styled-components/macro";
import List from "./List";
import Row from "./Row";
import useToggleState from "../utils/useToggleState";
import ExpandTriangle from "./ExpandTriangle";

export interface Props {
  header: ReactNode | ReactNode[];
  expanded?: boolean;
  innerRef?: any;
}

const ExpandListItem: FC<Props> = props => {
  const {
    header,
    children,
    expanded: expandedByDefault,
    innerRef,
    ...rest
  } = props;
  const [expanded, handleToggle] = useToggleState(!!expandedByDefault);
  const canExpand = Children.count(children) > 0;

  return (
    <li {...rest} ref={innerRef}>
      <Header onClick={handleToggle}>
        {header}
        <ExpandButton aria-label="toggle">
          <ExpandTriangle turned={canExpand && expanded} faded={!canExpand} />
        </ExpandButton>
      </Header>

      {expanded && canExpand && (
        <ExpandableContent>{children}</ExpandableContent>
      )}
    </li>
  );
};

export const Header = styled(Row)`
  height: 2.5rem;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border-bottom: 1px solid #d8d8d8;
  border-top: none;
  color: black;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
`;

const ExpandableContent = styled(List)`
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #d8d8d8;
`;

const ExpandButton = styled("button")`
  border: none;
  outline: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  height: auto;
  width: 3rem;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

export default styled(ExpandListItem)`
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  &:first-of-type > ${Header} {
    border-top: 1px solid #d8d8d8;
  }
`;
