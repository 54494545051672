import React, { FC } from "react";
import styled from "styled-components/macro";
import Link from "./Link";

interface Props {
  to: string | null | undefined;
  replace?: boolean;
}

const MaybeLink: FC<Props> = props => {
  const { to, replace = false, ...rest } = props;

  if (to) {
    return <Link {...rest} to={to} replace={replace} />;
  } else {
    return <div {...rest} />;
  }
};

export default styled(MaybeLink)``;
