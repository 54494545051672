/**
 * Get and parse a URL search param.
 */
export default function getSearchParam<T>(key: string): T | null {
  const urlParams = new URLSearchParams(window.location.search);
  const rawValue = urlParams.get(key);

  if (rawValue) {
    try {
      return JSON.parse(rawValue);
    } catch (error) {
      console.error(`Failed to parse URL search param: "${key}"`, error);
    }
  }

  return null;
}
