import React, { FC, useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import Admin from "shared/lib/types/Admin";
import Unit from "shared/lib/types/Unit";
import CategoriesNav from "../../components/CategoriesNav";
import RouteProps from "../../RouteProps";
import useAsyncEffect from "../../utils/useAsyncEffect";
import AdminPage from "../../components/AdminPage";
import useUrlState from "../../utils/useUrlState";
import getAssignmentCategories from "../../api/assignments/getAssignmentCategories";
import getAdminUnits from "../../api/admins/getAdminUnits";
import AssignmentCategory from "shared/lib/types/AssignmentCategory";
import UnitList, { AssignmentMoveEvent } from "../../components/UnitList";
import UnitTopMenu from "../../components/UnitTopMenu";
import RadioCircle from "../../components/RadioCircle";
import TextButton from "../../components/TextButton";
import AssignmentTopMenu from "../../components/AssignmentTopMenu";
import deleteUnit from "../../api/units/deleteUnit";
import EditUnitForm, {
  Value as EditUnitFormValue,
} from "../../components/EditUnitForm";
import FormOverlay from "../../components/FormOverlay";
import replaceWhere from "shared/lib/utils/replaceWhere";
import updateUnit from "../../api/units/updateUnit";
import CreateUnitForm, {
  Value as CreateUnitFormValue,
} from "../../components/CreateUnitForm";
import createUnit from "../../api/units/createUnit";
import AssignmentForm, {
  Value as AssignmentFormValue,
} from "../../components/AssignmentForm";
import createAssignment from "../../api/assignments/createAssignment";
import deleteAssignment from "../../api/assignments/deleteAssignment";
import Row from "../../components/Row";
import PlusIcon from "../../components/PlusIcon";
import moveAssignmentToUnit from "../../api/units/moveAssignmentToUnit";
import reorderUnitAssignments from "../../api/units/reorderUnitAssignments";
import swapBetweenIndexedArrays from "../../utils/swapBetweenIndexedArrays";
import reorderIndexedArray from "../../utils/reorderIndexedArray";
import Column from "../../components/Column";
import EditAssignmentForm, {
  Value as EditAssignmentFormValue,
} from "../../components/EditAssignmentForm";
import uploadFile from "../../api/media/uploadFile";
import updateAssignment from "../../api/assignments/updateAssignment";
import CreateCategoryForm, {
  Value as CreateCategoryFormValue,
} from "../../components/CreateCategoryForm";
import createAssignmentCategory from "../../api/assignments/createAssignmentCategory";
import showConfirm from "../../utils/showConfirm";
import EditCategoryForm, {
  Value as EditCategoryFormValue,
} from "../../components/EditCategoryForm";
import deleteAssignmentCategory from "../../api/assignments/deleteAssignmentCategory";
import updateAssignmentCategory from "../../api/assignments/updateAssignmentCategory";
import RadioCircleButton from "../../components/RadioCircleButton";
import AssignmentGuide from "../../components/AssignmentGuide";

interface Props extends RouteProps {
  admin: Admin;
  setAdmin(admin: Admin): any;
  reload(): any;
}

const AdminAssignmentsPage: FC<Props> = (props) => {
  const { admin, location } = props;
  const [loaded, setLoaded] = useState(false);
  const [addingUnit, setAddingUnit] = useState(false);
  const [addingAssignment, setAddingAssignment] = useState(false);
  const [addAssignmentUnitId, setAddAssignmentUnitId] = useState<number | null>(
    null
  );
  const [categories, setCategories] = useState<AssignmentCategory[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [editCategoryId, setEditCategoryId] = useState<number | null>(null);
  const [assignmentGuideId, setAssignmentGuideId] = useUrlState<
    string | number
  >(location, "guide");
  const [addingCategory, setAddingCategory] = useUrlState<boolean>(
    location,
    "add-product"
  );
  const [categoryIdString, setCategoryId] = useUrlState<string | number>(
    location,
    "category"
  );
  const [unitIdString, setUnitId] = useUrlState<string | number>(
    location,
    "unit"
  );
  const [assignmentIdString, setAssignmentId] = useUrlState<string | number>(
    location,
    "assignment"
  );
  const [editUnitIdString, setEditUnitId] = useUrlState<string | number>(
    location,
    "edit-unit"
  );
  const [editAssignmentIdString, setEditAssignmentId] = useUrlState<
    string | number
  >(location, "edit-assignment");

  const categoryId = categoryIdString
    ? parseInt(categoryIdString as string, 10)
    : null;

  const selectedCategory = categoryId
    ? categories.find((category) => category.id === categoryId)
    : null;

  const unitId = unitIdString ? parseInt(unitIdString as string, 10) : null;

  const selectedUnit = unitId ? units.find((unit) => unit.id === unitId) : null;

  const assignmentId = assignmentIdString
    ? parseInt(assignmentIdString as string, 10)
    : null;

  const categoryUnits = categoryId
    ? units.filter((unit) => unit.categoryId === categoryId)
    : [];

  const editUnitId = editUnitIdString
    ? parseInt(editUnitIdString as string, 10)
    : null;

  const editUnit = editUnitId
    ? units.find((unit) => unit.id === editUnitId)
    : null;

  const editCategory = editCategoryId
    ? categories.find((category) => category.id === editCategoryId)
    : null;

  const editAssignmentId = editAssignmentIdString
    ? parseInt(editAssignmentIdString as string, 10)
    : null;

  useAsyncEffect(async () => {
    const [fetchedCategories, fetchedUnits] = await Promise.all([
      getAssignmentCategories(),
      getAdminUnits(admin.id),
    ]);

    setCategories(fetchedCategories);
    setUnits(fetchedUnits);
    setLoaded(true);
  }, [admin.id]);

  useEffect(() => {
    if (loaded && categories.length && !categoryId) {
      setCategoryId(categories[0].id, { replace: true });
    }
  }, [categories, loaded, categoryId, setCategoryId]);

  const handleCategoryClick = useCallback(
    (categoryId: number) => {
      setUnitId(undefined, { replace: true });
      setAssignmentId(undefined, { replace: true });
      setCategoryId(categoryId);
    },
    [setCategoryId, setAssignmentId, setUnitId]
  );

  const handleUnitClick = useCallback(
    (unitId: number) => {
      setUnitId(unitId, { replace: true });
      setAssignmentId(undefined, { replace: true });
    },
    [setAssignmentId, setUnitId]
  );

  const handleAssignmentSelect = useCallback(
    (assignmentId: number) => {
      setAssignmentId(assignmentId, { replace: true });
      setUnitId(undefined, { replace: true });
    },
    [setAssignmentId, setUnitId]
  );

  const handleAddUnitClick = useCallback(() => {
    setAddingUnit(true);
  }, []);

  const handleRemoveUnitClick = useCallback(async () => {
    if (!unitId) {
      return;
    }
    const confirmed = await showConfirm({
      title: "Are you sure you want to delete this unit?",
      message:
        "This action cannot be undone and will delete all assignments and assignment takes in the unit.",
    });
    if (confirmed) {
      await deleteUnit(unitId);
      setUnits(units.filter((unit) => unit.id !== unitId));
      setUnitId(undefined, { replace: true });
    }
  }, [units, unitId, setUnitId]);

  const handleRemoveAssignmentClick = useCallback(async () => {
    const confirmed = await showConfirm({
      title: "Are you sure you want to delete this assignment?",
      message:
        "This action cannot be undone and will delete all assignment takes for this assignment.",
    });
    if (confirmed && assignmentId) {
      await deleteAssignment(assignmentId);
      setUnits((units) =>
        replaceWhere(
          units,
          (unit) =>
            (unit.assignments || []).some(
              (assignment) => assignment.id === assignmentId
            ),
          (unit) => ({
            ...unit,
            assignments: (unit.assignments || []).filter(
              (assignment) => assignment.id !== assignmentId
            ),
          })
        )
      );
      setAssignmentId(undefined, { replace: true });
    }
  }, [assignmentId, setAssignmentId]);

  const handleEditUnitSubmit = useCallback(
    async (value: EditUnitFormValue) => {
      const { unitId, name } = value;
      if (!categoryId) {
        return;
      }
      await updateUnit(unitId, { name, categoryId });
      setUnits(
        replaceWhere(
          units,
          (unit) => unit.id === unitId,
          (unit) => ({ ...unit, name })
        )
      );
      setEditUnitId(undefined, { replace: true });
      setUnitId(unitId);
    },
    [categoryId, units, setEditUnitId, setUnitId]
  );

  const handleAddUnitSubmit = useCallback(
    async (value: CreateUnitFormValue) => {
      const unit = await createUnit({
        name: value.name,
        categoryId,
      });
      setUnits((units) => [...units, unit]);
      setAddingUnit(false);
    },
    [categoryId]
  );

  const handleAddAssignmentSubmit = useCallback(
    async (value: AssignmentFormValue) => {
      const unitIdToAdd = addAssignmentUnitId || unitId;
      const unit = units.find((other) => other.id === unitIdToAdd);
      const unitAssignments = (unit && unit.assignments) || [];

      if (!unitIdToAdd) {
        return;
      }

      const assignment = await createAssignment({
        unitId: unitIdToAdd,
        title: value.assignment.title,
        subTitle: value.assignment.subTitle,
        sets: value.sets.map((set) => ({
          ...set,
          isExampleCorrect: !!set.isExampleCorrect,
        })),
      });

      setUnits(
        replaceWhere(
          units,
          (other) => other.id === unitIdToAdd,
          (other) => ({
            ...other,
            assignments: [...unitAssignments, assignment],
          })
        )
      );
      setAddingAssignment(false);
      setAddAssignmentUnitId(null);
    },
    [units, unitId, addAssignmentUnitId]
  );

  const handleEditAssignmentSubmit = useCallback(
    async (value: EditAssignmentFormValue) => {
      const valueWithImages = {
        assignmentId: value.assignmentId,
        title: value.assignment.title,
        subTitle: value.assignment.subTitle,
        sets: await Promise.all(
          value.sets.map(async (set) => ({
            ...set,
            isExampleCorrect: !!set.isExampleCorrect,
            exampleImage:
              set.exampleImage instanceof File
                ? (await uploadFile(set.exampleImage)).key
                : set.exampleImage,
            questions: await Promise.all(
              set.questions.map(async (question) => ({
                ...question,
                questionImage:
                  question.questionImage instanceof File
                    ? (await uploadFile(question.questionImage)).key
                    : question.questionImage,
                sampleResponseImage:
                  question.sampleResponseImage instanceof File
                    ? (await uploadFile(question.sampleResponseImage)).key
                    : question.sampleResponseImage,
              }))
            ),
          }))
        ),
      };

      const updatedAssignment = await updateAssignment(valueWithImages);
      setUnits((units) =>
        replaceWhere(
          units,
          (unit) =>
            !!(
              unit.assignments &&
              unit.assignments.some(
                (assignment) => assignment.id === value.assignmentId
              )
            ),
          (unit) => ({
            ...unit,
            assignments: replaceWhere(
              unit.assignments || [],
              (assignment) => assignment.id === updatedAssignment.id,
              () => updatedAssignment
            ),
          })
        )
      );
      setEditAssignmentId();
    },
    [setEditAssignmentId]
  );

  // Handle moving assignments around in and between units.
  const handleAssignmentMove = useCallback(
    async (event: AssignmentMoveEvent) => {
      const { sourceUnitId, destUnitId, sourceIndex, destIndex } = event;

      const sourceUnit = units.find((unit) => unit.id === sourceUnitId);
      const destUnit = units.find((unit) => unit.id === destUnitId);

      if (!sourceUnit || !destUnit) {
        return;
      }

      const assignment = (sourceUnit.assignments || [])[sourceIndex];

      // Moving an assignment around in a unit
      if (sourceUnitId === destUnitId) {
        if (sourceIndex === destIndex) {
          // nothing changed
          return;
        }
        const newAssignments = reorderIndexedArray(
          sourceUnit.assignments || [],
          sourceIndex,
          destIndex
        );

        setUnits(
          replaceWhere(
            units,
            (unit) => unit.id === sourceUnitId,
            (unit) => ({
              ...unit,
              assignments: newAssignments,
            })
          )
        );

        // Fire and forget
        reorderUnitAssignments(
          sourceUnit.id,
          newAssignments.map((assignment) => assignment.id)
        ).catch((error) => {
          console.error(`Failed to save assignment reorder`, error);
        });
      } else {
        // Moving an assignment between assignments

        const sourceUnitAssignments = sourceUnit.assignments || [];
        const destUnitAssignments = destUnit.assignments || [];
        const [
          newSourceUnitAssignments,
          newDestUnitAssignments,
        ] = swapBetweenIndexedArrays(
          sourceUnitAssignments,
          destUnitAssignments,
          sourceIndex,
          destIndex
        );

        const newSourceUnit = {
          ...sourceUnit,
          assignments: newSourceUnitAssignments,
        };
        const newDestUnit = {
          ...destUnit,
          assignments: newDestUnitAssignments,
        };

        setUnits(
          replaceWhere(
            units,
            (unit) => unit.id === sourceUnitId || unit.id === destUnitId,
            (unit) => {
              if (unit.id === sourceUnitId) {
                return newSourceUnit;
              }
              if (unit.id === destUnitId) {
                return newDestUnit;
              }
              return unit;
            }
          )
        );

        // Fire and forget
        moveAssignmentToUnit({
          unitId: destUnitId,
          assignmentId: assignment.id,
        })
          .then(async () => {
            await reorderUnitAssignments(
              newDestUnit.id,
              (newDestUnit.assignments || []).map((assignment) => assignment.id)
            ).catch((error) => {
              console.error(`Failed to save assignment reorder`, error);
            });

            await reorderUnitAssignments(
              newSourceUnit.id,
              (newSourceUnit.assignments || []).map(
                (assignment) => assignment.id
              )
            ).catch((error) => {
              console.error(`Failed to save assignment reorder`, error);
            });
          })
          .catch((error) => {
            console.error("Failed to save assignment move", error);
          });
      }
    },
    [units]
  );

  const handleAddCategoryClick = useCallback(() => {
    setAddingCategory(true);
  }, [setAddingCategory]);

  const handleAddCategoryCancel = useCallback(() => {
    setAddingCategory();
  }, [setAddingCategory]);

  const handleAddCategorySubmit = useCallback(
    async (value: CreateCategoryFormValue) => {
      setAddingCategory();
      const category = await createAssignmentCategory(value);
      setCategories((categories) => [...categories, category]);
    },
    [setAddingCategory]
  );

  const handleDeleteCategory = useCallback(async () => {
    if (
      !editCategoryId ||
      !(await showConfirm({
        title: "Are you sure you want to delete this category?",
        message:
          "This action cannot be undone and will delete all units, assignments, and assignment takes in this category.",
      }))
    ) {
      return;
    }
    setCategories((categories) =>
      categories.filter((other) => other.id !== editCategoryId)
    );
    setEditCategoryId(null);
    await deleteAssignmentCategory(editCategoryId);
  }, [editCategoryId]);

  const handleEditCategorySubmit = useCallback(
    async (value: EditCategoryFormValue) => {
      if (!editCategoryId) {
        return;
      }
      setCategories((categories) =>
        replaceWhere(
          categories,
          (category) => category.id === editCategoryId,
          (category) => ({ ...category, ...value })
        )
      );
      setEditCategoryId(null);
      await updateAssignmentCategory({ categoryId: editCategoryId, ...value });
    },
    [editCategoryId]
  );

  return (
    <AdminPage {...props} location={location}>
      {unitId && (
        <UnitTopMenu
          unitId={unitId}
          onClose={() => setUnitId(undefined, { replace: true })}
          onCreateAssignmentClick={() => setAddingAssignment(true)}
          onRemoveUnitClick={handleRemoveUnitClick}
          onEditUnitClick={() => {
            setUnitId(undefined, { replace: true });
            setEditUnitId(unitId);
          }}
        />
      )}
      {assignmentId && (
        <AssignmentTopMenu
          hideAssignButton
          onClose={() => setAssignmentId(undefined, { replace: true })}
          onEditAssignmentClick={() => setEditAssignmentId(assignmentId)}
          onRemoveAssignmentClick={handleRemoveAssignmentClick}
        />
      )}
      {addingCategory && (
        <FormOverlay withLogo onClose={handleAddCategoryCancel}>
          <CreateCategoryForm onSubmit={handleAddCategorySubmit} />
        </FormOverlay>
      )}
      {editUnit && (
        <FormOverlay
          withLogo
          onClose={() => {
            setEditUnitId(undefined, { replace: true });
            setUnitId(editUnit.id);
          }}
        >
          <EditUnitForm unit={editUnit} onSubmit={handleEditUnitSubmit} />
        </FormOverlay>
      )}
      {addingUnit && (
        <FormOverlay withLogo onClose={() => setAddingUnit(false)}>
          <CreateUnitForm onSubmit={handleAddUnitSubmit} />
        </FormOverlay>
      )}
      {addingAssignment && (
        <FormOverlay withLogo onClose={() => setAddingAssignment(false)}>
          <AssignmentForm
            onSubmit={handleAddAssignmentSubmit}
            title="Create a New Assignment"
            secondTitle="Add Assignment"
            submitButtonText="FINISH AUTHORING THIS ASSIGNMENT"
            unitName={
              addAssignmentUnitId
                ? units.find((unit) => unit.id === addAssignmentUnitId)?.name ||
                  ""
                : selectedUnit && selectedUnit.name
            }
            categoryName={selectedCategory && selectedCategory.name}
          />
        </FormOverlay>
      )}
      {editAssignmentId && (
        <FormOverlay withLogo onClose={() => setEditAssignmentId()}>
          <EditAssignmentForm
            assignmentId={editAssignmentId}
            title="Edit Assignment"
            submitButtonText="Save"
            onSubmit={handleEditAssignmentSubmit}
            onCancel={() => setEditAssignmentId()}
          />
        </FormOverlay>
      )}
      {editCategory && (
        <FormOverlay withLogo onClose={() => setEditCategoryId(null)}>
          <EditCategoryForm
            category={editCategory}
            onSubmit={handleEditCategorySubmit}
            onDelete={handleDeleteCategory}
          />
        </FormOverlay>
      )}
      {assignmentGuideId && (
        <AssignmentGuide
          assignmentId={+assignmentGuideId}
          onClose={() => setAssignmentGuideId()}
        />
      )}
      <Header>
        <Title>Assignments</Title>
        <CategoriesNav
          selectedCategoryId={categoryId}
          categories={categories}
          onCategoryClick={handleCategoryClick}
          onAddCategoryClick={handleAddCategoryClick}
          onEditCategoryClick={setEditCategoryId}
        />
        <AddUnitRow>
          <AddUnitButton onClick={handleAddUnitClick}>
            <PlusIcon size={20} />
            Unit
          </AddUnitButton>
        </AddUnitRow>
      </Header>
      <UnitListContainer>
        <UnitList
          showAddAssignmentButtons
          selectedUnitId={unitId}
          units={categoryUnits}
          onAddAssignmentClick={(unitId) => {
            setAddAssignmentUnitId(unitId);
            setAddingAssignment(true);
          }}
          renderAssignmentListItem={(assignment) => (
            <AssignmentButton
              onClick={() => setAssignmentGuideId(assignment.id)}
            >
              <RadioCircleButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleAssignmentSelect(assignment.id);
                }}
              >
                <RadioCircle checked={assignment.id === assignmentId} />
              </RadioCircleButton>
              {assignment.title}
            </AssignmentButton>
          )}
          onSelectUnit={handleUnitClick}
          onAssignmentMove={handleAssignmentMove}
        />
      </UnitListContainer>
    </AdminPage>
  );
};

export default AdminAssignmentsPage;

const Title = styled("h1")`
  padding-bottom: 4rem;
  font-weight: normal;
`;

const Header = styled(Column)`
  width: 800px;
  max-width: 100%;
  align-self: center;
`;

const UnitListContainer = styled(Column)`
  width: 660px;
  max-width: 100%;
  align-self: center;
`;

const AssignmentButton = styled(TextButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 40px;

  ${RadioCircleButton} {
    height: 40px;
  }
`;

const AddUnitRow = styled(Row)`
  justify-content: flex-end;
  margin: 0.5rem 0 4rem 0;
`;

const AddUnitButton = styled(TextButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #c0c0c0;
  font-size: 14px;
  font-weight: bold;

  > *:first-child {
    margin-right: 0.5rem;
  }
`;
