import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import isBlank from "shared/lib/utils/isBlank";
import FormLayout from "./FormLayout";
import useInputState from "../utils/useInputState";
import UnderlinedInput from "./UnderlinedInput";
import autoFocus from "../utils/autoFocus";

interface Props {
  onSubmit: (value: Value) => any;
}

export interface Value {
  name: string;
}

const CreateUnitForm: FC<Props> = props => {
  const { onSubmit, ...rest } = props;
  const [name, handleNameChange] = useInputState("");

  const handleSubmit = useCallback(async () => {
    if (isBlank(name)) {
      throw new Error("Name is required.");
    }

    await onSubmit({
      name
    });
  }, [name, onSubmit]);

  return (
    <FormLayout
      {...rest}
      title="Create Unit"
      submitButtonText="Create Unit"
      onSubmit={handleSubmit}
    >
      <UnderlinedInput
        placeholder="Name"
        value={name}
        onChange={handleNameChange}
        ref={autoFocus}
      />
    </FormLayout>
  );
};

export default styled(CreateUnitForm)`
  padding-top: 200px;
`;
