export enum Tool {
  PICK = "pick",
  RECTANGLE = "rectangle",
  CIRCLE = "circle",
  TRIANGLE = "triangle",
  FREEHAND = "freehand",
  LINE = "line",
  ARROW = "arrow",
  TEXT = "text",
  ERASER = "eraser",
  UNDO = "undo",
  DUPLICATE = "duplicate",
  SHAPES = "shapes",
  REDO = "redo",
  IMAGE = "image",
  MATH_EQUATION = "math-equation",
  TRASH = "trash",
  CURVE = "curve",
}

export const NON_SELECT_TOOLS = [
  Tool.UNDO,
  Tool.REDO,
  Tool.DUPLICATE,
  Tool.IMAGE,
  Tool.TRASH,
];
