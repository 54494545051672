import React, { FC } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import { AssignmentSetWithQuestions } from "shared/lib/types/AssignmentSet";
import QuestionResponse from "shared/lib/types/QuestionResponse";
import QuestionInputType from "shared/lib/types/QuestionInputType";
import SketchpadPreview from "./SketchpadPreview";
import Row from "./Row";
import isPracticeQuestion, {
  isNotPracticeQuestion
} from "../utils/isPracticeQuestion";
import getS3ImageUrl from "../utils/getS3ImageUrl";
import MathInputPreview from "./MathInputPreview";

interface Props {
  takeableAssignmentId: number;
  setIndex: number;
  setCount: number;
  set: AssignmentSetWithQuestions;
  responses: QuestionResponse[];
}

const AssignmentSetPresent: FC<Props> = props => {
  const {
    takeableAssignmentId,
    setIndex,
    setCount,
    set,
    responses,
    ...rest
  } = props;
  const exampleImageSrc = set.exampleImage
    ? getS3ImageUrl(set.exampleImage)
    : null;

  const nonPracticeQuestions = set.questions.filter(isNotPracticeQuestion);
  const practiceQuestion = set.questions.find(isPracticeQuestion);
  const practiceResponse =
    practiceQuestion &&
    responses.find(response => response.questionId === practiceQuestion.id);

  return (
    <Column {...rest}>
      <TopRow>
        <ExampleColumn>
          <Title>
            SET {setIndex + 1} OF {setCount}
          </Title>
          {exampleImageSrc && <ExampleImage src={exampleImageSrc} />}
        </ExampleColumn>
        <ReviewColumn>
          <ReviewContent>
            <ReviewSectionTitle>
              1. Study the student’s{" "}
              {set.isExampleCorrect ? "correct" : "incorrect"} work.
            </ReviewSectionTitle>
            <ReviewSectionTitle>2. Answer these questions.</ReviewSectionTitle>
            {nonPracticeQuestions.map(question => {
              const response = responses.find(
                response => response.questionId === question.id
              );

              return (
                <QuestionReview key={question.id}>
                  <QuestionReviewContent>
                    <ReviewQuestionText>
                      <MathInputPreview value={question.questionText} />
                    </ReviewQuestionText>
                    {response && (
                      <StudentResponseContainer>
                        {question.inputType === QuestionInputType.textarea ? (
                          <StudentTextResponse>
                            {response.textResponse}
                          </StudentTextResponse>
                        ) : (
                          response.drawingResponse && (
                            <StudentSketchResponse
                              value={response.drawingResponse}
                              backgroundImage={question.questionImage}
                              width={300}
                            />
                          )
                        )}
                      </StudentResponseContainer>
                    )}
                  </QuestionReviewContent>
                </QuestionReview>
              );
            })}
          </ReviewContent>
        </ReviewColumn>
      </TopRow>
      {practiceQuestion && (
        <PracticeQuestionReview>
          <ReviewSectionTitle>3. Now Complete Your Own</ReviewSectionTitle>
          <QuestionReviewContent>
            <ReviewQuestionText>
              <MathInputPreview value={practiceQuestion.questionText} />
            </ReviewQuestionText>
            {practiceResponse && (
              <StudentResponseContainer>
                {practiceQuestion.inputType === QuestionInputType.textarea ? (
                  <StudentTextResponse>
                    {practiceResponse.textResponse}
                  </StudentTextResponse>
                ) : (
                  practiceResponse.drawingResponse && (
                    <StudentSketchResponse
                      value={practiceResponse.drawingResponse}
                      backgroundImage={practiceQuestion.questionImage}
                      width={750}
                    />
                  )
                )}
              </StudentResponseContainer>
            )}
          </QuestionReviewContent>
        </PracticeQuestionReview>
      )}
    </Column>
  );
};

export default styled(AssignmentSetPresent)`
  & + & {
    margin-top: 35px;
  }
`;

const ReviewColumn = styled(Column)`
  min-width: 344px;
`;

const TopRow = styled(Row)`
  justify-content: space-between;
`;

const Title = styled("h2")`
  color: #4a90e2;
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 18px;
`;

const ExampleColumn = styled(Column)`
  margin-right: 58px;
`;

const ExampleImage = styled("img")`
  width: 324px;
`;

const ReviewContent = styled(Column)`
  background-color: #f7f7f7;
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;
`;

const QuestionReview = styled(Column)`
  & + & {
    margin-top: 1.25rem;
  }
`;

const PracticeQuestionReview = styled(Column)`
  background-color: #f7f7f7;
  padding-top: 20px;

  > h3 {
    margin-left: 1.25rem;
  }
`;

const QuestionReviewContent = styled(Column)`
  padding-left: 1.25rem;
`;

const ReviewSectionTitle = styled("h3")`
  color: #000000;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ReviewQuestionText = styled("p")`
  color: #000000;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.6rem;
`;

const StudentResponseContainer = styled(Column)`
  margin-bottom: 1.25rem;
`;

const StudentTextResponse = styled("p")``;

const StudentSketchResponse = styled(SketchpadPreview)`
  background-color: #ffffff;

  canvas {
    border: 1px solid #979797;
  }
`;
