import React, { FC, useState } from "react";
import { navigate } from "@reach/router";
import styled from "styled-components/macro";
import Admin from "shared/lib/types/Admin";
import RouteProps from "../../RouteProps";
import * as adminRoutes from "../../adminRoutes";
import useAsyncEffect from "../../utils/useAsyncEffect";
import SketchpadPreview from "../../components/SketchpadPreview";
import Page from "../../components/Page";
import Column from "../../components/Column";
import { getDrawingPreview } from "../../api/assignmentTakes/getDrawingPreview";
import { DrawingPreviewData } from "shared/src/types/DrawingPreviewData";
import AdminNavRow from "../../components/AdminNavRow";
import NavRow from "../../components/NavRow";
import BlockButton from "../../components/BlockButton";
import MathByExampleLogo from "../../components/MathByExampleLogo";
import Row from "../../components/Row";
import isUrl from "../../utils/isUrl";
import getS3ImageUrl from "../../utils/getS3ImageUrl";
import MathInputPreview from "../../components/MathInputPreview";
import formatDate from "../../utils/formatDate";

interface Props extends RouteProps {
  admin: Admin | null;
  responseId: string;
  setAdmin?(admin: Admin): any;
  reload(): any;
}

const AdminDrawingPreviewPage: FC<Props> = (props) => {
  const { location, admin, reload, ...rest } = props;
  const [previewDrawing, setPreviewDrawing] =
    useState<DrawingPreviewData | null>(null);
  const responseId = parseInt(props.responseId, 10);

  useAsyncEffect(async () => {
    const fetchedResponse = await getDrawingPreview(responseId, admin);
    if (
      !fetchedResponse ||
      (!fetchedResponse.drawingResponse && !fetchedResponse.textResponse)
    ) {
      navigate(admin ? adminRoutes.home : "/");
      return;
    }

    setPreviewDrawing(fetchedResponse);
  }, [responseId, admin]);

  const images =
    previewDrawing?.images.map((image) =>
      isUrl(image) ? image : getS3ImageUrl(image)
    ) ?? [];

  return (
    <Page {...rest} location={location}>
      {admin ? (
        <AdminNavRow admin={admin} reload={reload} location={location} />
      ) : (
        <NavRow loggedIn={false} location={location} isAdmin={false} />
      )}
      <PrintableLogo />
      <Content>
        {previewDrawing && (
          <DrawingPreviewDataWrapper>
            {
              <>
                {previewDrawing.studentCode && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>
                      Student Code:
                    </DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {previewDrawing.studentCode}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.studentId && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>
                      Student Id:
                    </DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {previewDrawing.studentId}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.teacherId && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>Teacher:</DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {previewDrawing.teacherId}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.dateSubmitted && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>
                      Date Submitted:
                    </DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {formatDate(previewDrawing.dateSubmitted)}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.productName && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>Product:</DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {previewDrawing.productName}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.unitName && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>Unit:</DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {previewDrawing.unitName}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.assignmentTitle && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>
                      Assignment Title:
                    </DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {previewDrawing.assignmentTitle}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.setNumber && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>
                      Set Number:
                    </DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {previewDrawing.setNumber}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.questionIdentifier !== undefined && (
                  <DrawingPreviewDataRow>
                    <DrawingPreviewDataLabel>
                      Question Number:
                    </DrawingPreviewDataLabel>
                    <DrawingPreviewDataText>
                      {previewDrawing.questionIdentifier}
                    </DrawingPreviewDataText>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.questionText && (
                  <DrawingPreviewDataRow>
                    <Column>
                      <DrawingPreviewDataLabel>
                        Question Text:
                      </DrawingPreviewDataLabel>
                      <QuestionText>
                        <MathInputPreview value={previewDrawing.questionText} />
                      </QuestionText>
                    </Column>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.drawingResponse && (
                  <DrawingPreviewDataRow>
                    <Column>
                      <DrawingPreviewDataLabel>
                        Drawing Response:
                      </DrawingPreviewDataLabel>
                      <DrawingWrapper>
                        <SketchpadPreview
                          value={previewDrawing.drawingResponse}
                          width={600}
                          backgroundImage={previewDrawing.questionImage ?? null}
                        />
                      </DrawingWrapper>
                    </Column>
                  </DrawingPreviewDataRow>
                )}
                {previewDrawing.textResponse && (
                  <DrawingPreviewDataRow>
                    <Column>
                      <DrawingPreviewDataLabel>
                        Text Response:
                      </DrawingPreviewDataLabel>
                      <TextResponseWrapper>
                        <div>{previewDrawing.textResponse}</div>
                      </TextResponseWrapper>
                    </Column>
                  </DrawingPreviewDataRow>
                )}
                <DrawingPreviewDataRow>
                  <DrawingPreviewDataLabel>
                    Response Link:
                  </DrawingPreviewDataLabel>
                  <DrawingPreviewDataText>
                    <a
                      href={previewDrawing.previewUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {previewDrawing.previewUrl}
                    </a>
                  </DrawingPreviewDataText>
                </DrawingPreviewDataRow>
              </>
            }
            {images.length > 0 && (
              <DrawingPreviewDataRow>
                <Column>
                  <DrawingPreviewDataLabel>
                    Attached Images:
                  </DrawingPreviewDataLabel>
                  <AttachedImageWrapper>
                    <ul>
                      {images.map((image: string, index: number) => (
                        <li>
                          <img
                            key={image}
                            src={image}
                            alt={`Uploaded ${index + 1}`}
                          />
                        </li>
                      ))}
                    </ul>
                  </AttachedImageWrapper>
                </Column>
              </DrawingPreviewDataRow>
            )}
          </DrawingPreviewDataWrapper>
        )}
        <PrintButtonContainer>
          <PrintButton onClick={window.print}>Print</PrintButton>
        </PrintButtonContainer>
      </Content>
    </Page>
  );
};

export default AdminDrawingPreviewPage;

const DrawingWrapper = styled.div`
  align-items: center;
  max-width: 797px;
  margin-top: 32px;
  outline: 2px solid #333;
  @media print {
    display: block;
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

const Content = styled(Column)`
  padding: 3rem 0 3rem 0;
  min-height: 100%;
  max-width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;

const PrintButtonContainer = styled(Row)`
  width: 700px;
  max-width: 100%;
  align-items: center;
  align-self: center;
  padding: 0 2rem 0 3rem;
`;

const PrintButton = styled(BlockButton)`
  margin-top: 1rem;
  max-width: 200px;
  flex: 1 0 auto;

  @media print {
    display: none;
  }
`;

const PrintableLogo = styled(MathByExampleLogo)`
  display: none;
  width: 162px;
  height: 30px;
  @media print {
    display: block;
    position: absolute;
    left: 20px;
    top: 20px;
  }
`;

const TextResponseWrapper = styled(Column)`
  margin-top: 32px;
  width: 797px;
  align-items: center;
  > * {
    border: 3px solid #333;
    width: 600px;
    height: 300px;
  }
`;

const AttachedImageWrapper = styled(Column)`
  width: 797px;
  margin-top: 24px;
  align-items: center;
  ul {
    list-style-type: decimal;
    img {
      margin-top: 8px;
      height: auto;
      width: 600px;
    }
  }
  @media print {
    display: block;
  }
`;

const DrawingPreviewDataWrapper = styled.dl`
  align-items: center;
  padding-left: 80px;
`;

const DrawingPreviewDataRow = styled(Row)`
  align-items: center;
  margin-bottom: 24px;
  width: 797px;
  @media print {
    top: 24px;
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

const COMMON_DRAWING_PREVIEW_TEXT_STYLE = `
  color: #000000;
  font-family: Lato;
  font-size: 21px;
  line-height: 25px;
`;

const DrawingPreviewDataLabel = styled.dt`
  ${COMMON_DRAWING_PREVIEW_TEXT_STYLE}
`;

const DrawingPreviewDataText = styled.dd`
  ${COMMON_DRAWING_PREVIEW_TEXT_STYLE}
  font-weight: normal;
  margin-left: 8px;
`;

const QuestionText = styled(Row)`
  margin-top: 8px;
  margin-bottom: 10px;
  padding-left: 20px;
`;
