import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Row from "./Row";
import Column from "./Column";

interface Props {
  progress: number;
  total: number;
  toolTip?: ReactNode;
}

const ProgressBar: FC<Props> = props => {
  const { progress, total, toolTip, ...rest } = props;

  return (
    <Root {...rest}>
      {toolTip ? <ProgressBarTooltip>{toolTip}</ProgressBarTooltip> : null}
      <ProgressFillWrapper>
        <ProgressBarFill style={{ width: `${(100 * progress) / total}%` }} />
      </ProgressFillWrapper>
    </Root>
  );
};

export default styled(ProgressBar)``;

const ProgressBarFill = styled(Row)`
  position: relative;
  background-color: #4a90e2;
  height: 8px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
`;

const ProgressBarTooltip = styled(Column)`
  display: none;
  position: absolute;
  color: white;
  background-color: #333;
  padding: 1rem;
  font-size: 0.8rem;
  min-width: 130px;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, -100%);

  > h3 {
    font-size: inherit;
    margin-bottom: 0.5rem;
  }

  > ul {
    font-size: inherit;
    list-style: none;
  }

  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #333;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%) rotate(45deg);
  }
`;

const ProgressFillWrapper = styled(Row)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
`;

const Root = styled(Row)`
  width: 200px;
  height: 10px;
  border-radius: 5px;
  border: 1px solid #3a3a3a;
  background-color: white;
  margin-right: 3rem;
  overflow: visible;

  &:hover ${ProgressBarTooltip} {
    display: flex;
  }
`;
