import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import FadedLink from './FadedLink';
import PlusIcon from './PlusIcon';

const PlusLink: FC<ComponentProps<typeof FadedLink>> = props => {
  return (
    <FadedLink {...props}>
      <PlusIcon size={20} />
      {props.children}
    </FadedLink>
  );
};

export default styled(PlusLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  transition: color 0.2s;

  &:hover {
    color: black;
  }

  ${PlusIcon} {
    margin-right: 1rem;
  }
`;
