import apiAxios from "../apiAxios";
import { DetailedAssignmentTake } from "shared/lib/types/AssignmentTake";

export default async function createAssignmentTake(
  takeableAssignmentId: number
): Promise<DetailedAssignmentTake> {
  const response = await apiAxios.post("/takes", {
    takeableAssignmentId
  });

  return response.data;
}
