import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';
import isBlank from 'shared/lib/utils/isBlank';
import FormLayout from './FormLayout';
import useInputState from '../utils/useInputState';
import UnderlinedInput from './UnderlinedInput';
import Unit from 'shared/lib/types/Unit';

interface Props {
  unit: Unit;
  onSubmit: (value: Value) => any;
}

export interface Value {
  unitId: number;
  name: string;
}

const EditUnitForm: FC<Props> = props => {
  const { unit, onSubmit, ...rest } = props;
  const [name, handleNameChange] = useInputState(unit.name);
  const unitId = unit.id;

  const handleSubmit = useCallback(async () => {
    if (isBlank(name)) {
      throw new Error('Name is required.');
    }

    await onSubmit({
      unitId,
      name
    });
  }, [name, unitId, onSubmit]);

  return (
    <FormLayout
      {...rest}
      title="Edit Unit"
      submitButtonText="Done"
      onSubmit={handleSubmit}
    >
      <UnderlinedInput
        placeholder="Name"
        value={name}
        onChange={handleNameChange}
      />
    </FormLayout>
  );
};

export default styled(EditUnitForm)``;
