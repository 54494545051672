import { MutableRefObject, useEffect, useRef } from "react";

// Ported from @emberex/react-utils

export function useOnClickOutside<T extends HTMLElement>(
    handleClickOutside: (event: MouseEvent) => unknown,
    enabled = true
): MutableRefObject<T | null> {
    const elRef = useRef<T | null>(null);

    useEffect(() => {
        if (!enabled) {
            return;
        }
        function handleClick(event: MouseEvent) {
            const menuEl = elRef.current;
            if (!menuEl) {
                return;
            }
            const targetEl = event.target;
            const isInMenu =
                targetEl && targetEl instanceof Node && menuEl.contains(targetEl);
            if (!isInMenu) {
                handleClickOutside(event);
            }
        }

        document.body.addEventListener("click", handleClick);

        return () => {
            document.body.removeEventListener("click", handleClick);
        };
    }, [handleClickOutside, enabled]);

    return elRef;
}
