import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import { MdClose } from "react-icons/md";

interface Props {
  iconSize?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const CircleX: FC<Props> = (props) => {
  const { iconSize = 14, ...rest } = props;

  return (
    <Column {...rest}>
      <MdClose size={iconSize} />
    </Column>
  );
};

export default styled(CircleX)`
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  background-color: #d8d8d8;
  border-radius: 50%;
`;
