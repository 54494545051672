import styled from "styled-components/macro";

const FilledInput = styled("input")`
  height: 70px;
  padding: 0 0 0 1rem;
  margin: 0;
  color: white;
  background-color: #19579f;
  font-weight: bold;
  border: none;
  border-radius: 0.4rem;
  font-size: 1rem;

  &::placeholder {
    color: #72d0f4;
  }
`;

export default FilledInput;
