import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Row from "./Row";
import SketchpadDrawable from "shared/lib/SketchPad/Drawable";
import Sketch from "shared/lib/SketchPad/Sketch";
import Overlay from "./Overlay";
import ReadonlySketch from "./ReadonlySketch";

const OVERLAY_CANVAS_WIDTH = 600;

export type Drawable = SketchpadDrawable;

interface Props {
  width: number;
  value: Sketch;
  backgroundImage: string | null;
  withDownload?: boolean;
  downloadName?: string;
}

const SketchpadPreview: FC<Props> = props => {
  const { width, value, backgroundImage, withDownload, downloadName, ...rest } = props;
  const scale = width / value.width;
  const height = scale * value.height;
  const overlayScale = OVERLAY_CANVAS_WIDTH / value.width;
  const [overlayOpen, setOverlayOpen] = useState(false);
  const overlayCanvasHeight = overlayScale * value.height;
  const imageIsBlank = value.drawables.length === 0 && !backgroundImage;

  const handleToggleOverlay = useCallback(() => {
    setOverlayOpen(open => !open);
  }, []);
  return (
    <Root {...rest} width={width} height={height}>
      <CanvasWrapper
        width={width}
        height={height}
        onClick={imageIsBlank ? undefined : handleToggleOverlay}
      >
        <ReadonlySketch
          value={value}
          backgroundImage={backgroundImage}
          width={width}
          height={height}
          defaultZoom={scale}
          withDownload={withDownload}
          downloadName={downloadName}
        />
      </CanvasWrapper>
      {overlayOpen && (
        <CanvasOverlay
          inRoot
          fixed
          hideBodyScrollbar
          onClick={handleToggleOverlay}
        >
          <OverlayCanvasWrapper
            width={OVERLAY_CANVAS_WIDTH}
            height={overlayCanvasHeight}
          >
            <ReadonlySketch
              value={value}
              backgroundImage={backgroundImage}
              width={OVERLAY_CANVAS_WIDTH}
              height={overlayCanvasHeight}
              defaultZoom={overlayScale}
            />
          </OverlayCanvasWrapper>
        </CanvasOverlay>
      )}
    </Root>
  );
};

export default styled(SketchpadPreview)``;

const Root = styled(Row) <{ width: number; height: number }>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
`;

const CanvasWrapper = styled("div") <WithDimensions>`
  flex-shrink: 0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: white;
  ${props => props.onClick && 'cursor: pointer;'}
`;

const CanvasOverlay = styled(Overlay)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const OverlayCanvasWrapper = styled("div") <WithDimensions>`
  justify-content: center;
  flex-shrink: 0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: white;
`;

interface WithDimensions {
  width: number;
  height: number;
}
