import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import MultiSelect from "@khanacademy/react-multi-select";
import { IoIosCalendar } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import Form from "./Form";
import Row from "./Row";
import useAsyncEffect from "../utils/useAsyncEffect";
import getTeachers from "../api/teachers/getTeachers";
import Teacher from "shared/lib/types/Teacher";
import capitalizeFirst from "shared/lib/utils/capitalizeFirst";
import getAssignmentCategories from "../api/assignments/getAssignmentCategories";
import AssignmentCategory from "shared/lib/types/AssignmentCategory";
import getAllUnits from "../api/units/getAllUnits";
import Unit from "shared/lib/types/Unit";
import Assignment from "shared/lib/types/Assignment";
import getAllAssignments from "../api/assignments/getAllAssignments";
import LabeledSwitch from "./LabeledSwitch";
import DateInput from "./DateInput";
import Column from "./Column";
import BlockButton from "./BlockButton";
import ResponseCsvOptions from "shared/lib/types/ResponseCsvOptions";

interface Props {
  onSubmit(value: Value): any;
}

interface Data {
  teachers: Teacher[];
  categories: AssignmentCategory[];
  units: Unit[];
  assignments: Assignment[];
}

export type Value = Omit<ResponseCsvOptions, 'timezone'>;

const CsvExportForm: FC<Props> = props => {
  const { onSubmit, ...rest } = props;
  const [data, setData] = useState<Data | null>(null);
  const [value, setValue] = useState<Value>({
    startDate: null,
    endDate: null,
    teacherIds: [],
    unitIds: [],
    assignmentIds: [],
    categoryIds: [],
    includeTeacherUnits: false
  });

  useAsyncEffect(async () => {
    const teachers = await getTeachers();
    const categories = await getAssignmentCategories();
    const units = await getAllUnits();
    const assignments = await getAllAssignments();

    setData({
      teachers,
      categories,
      units,
      assignments
    });
  }, []);

  const handleTeachersChange = useCallback(
    (teacherIds: number[]) => {
      setValue(value => {
        // Deselect units that were created by the deselected teachers
        const newUnitIds = value.unitIds.filter(unitId => {
          const unit = data && data.units.find(unit => unit.id === unitId);
          if (!unit) {
            return false;
          }
          return !unit.teacherId || teacherIds.includes(unit.teacherId);
        });

        // Deselect assignments in automatically deselected units
        const newAssignmentIds = value.assignmentIds.filter(assignmentId => {
          const assignment =
            data &&
            data.assignments.find(assignment => assignment.id === assignmentId);
          return assignment && newUnitIds.includes(assignment.unitId);
        });

        return {
          ...value,
          teacherIds,
          assignmentIds: newAssignmentIds,
          unitIds: newUnitIds
        };
      });
    },
    [data]
  );

  const handleUnitsChange = useCallback(
    (unitIds: number[]) => {
      setValue(value => {
        // Deselect all assignments in deselected units
        const newAssignmentIds = value.assignmentIds.filter(assignmentId => {
          const assignment =
            data &&
            data.assignments.find(assignment => assignment.id === assignmentId);
          return assignment && unitIds.includes(assignment.unitId);
        });
        return { ...value, unitIds, assignmentIds: newAssignmentIds };
      });
    },
    [data]
  );

  const handleAssignmentsChange = useCallback((assignmentIds: number[]) => {
    setValue(value => ({ ...value, assignmentIds }));
  }, []);

  const handleCategoriesChange = useCallback(
    (categoryId: number) => {
      setValue(value => {
        if (!data) return value;
        const { categoryIds } = value;

        if (categoryIds.includes(categoryId)) {
          const newCategoryIds = categoryIds.filter(id => id !== categoryId);
          // Deselect units in the deselected category
          const newUnitIds = value.unitIds.filter(unitId => {
            const unit = data.units.find(unit => unit.id === unitId);
            if (!unit) {
              return false;
            }
            return !unit.categoryId || newCategoryIds.includes(unit.categoryId);
          });
          return {
            ...value,
            categoryIds: newCategoryIds,
            unitIds: newUnitIds
          };
        } else {
          return {
            ...value,
            categoryIds: [...categoryIds, categoryId]
          };
        }
      });
    },
    [data]
  );

  const handleTeacherUnitsChange = useCallback(
    (includeTeacherUnits: boolean) => {
      setValue(value => {
        // If unchecked, deselect all teacher created units
        const newUnitIds = includeTeacherUnits
          ? value.unitIds
          : value.unitIds.filter(unitId => {
              const unit = data && data.units.find(unit => unit.id === unitId);
              return unit && unit.adminId;
            });
        return { ...value, includeTeacherUnits, unitIds: newUnitIds };
      });
    },
    [data]
  );

  const handleStartDateChange = useCallback((startDate: string | null) => {
    setValue(value => ({ ...value, startDate }));
  }, []);

  const handleEndDateChange = useCallback((endDate: string | null) => {
    setValue(value => ({ ...value, endDate }));
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  if (!data) {
    return null;
  }

  const unitOptions = data.units
    .filter(unit => {
      const isTeacherUnit = !!unit.teacherId;
      // Include teacher authored units for selected teachers
      if (
        value.includeTeacherUnits &&
        isTeacherUnit &&
        unit.teacherId &&
        value.teacherIds.includes(unit.teacherId)
      ) {
        return true;
      }
      // Include units in selected chategories
      if (unit.categoryId && value.categoryIds.includes(unit.categoryId)) {
        return true;
      }
      return false;
    })
    .map(unit => ({
      value: unit.id,
      label: unit.name
    }));

  const assignmentOptions = data.assignments
    .filter(assignment => value.unitIds.includes(assignment.unitId))
    .map(assignment => ({
      value: assignment.id,
      label: `${assignment.title} (${assignment.subTitle})`
    }));

  const teacherOptions = data.teachers.map(teacher => ({
    value: teacher.id,
    label: `${capitalizeFirst(teacher.firstName)} ${capitalizeFirst(
      teacher.lastName
    )}`
  }));

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <SectionLabel>Date Range</SectionLabel>
      <Section>
        <DateInputColumn>
          <DateInputLabel>Start</DateInputLabel>
          <StyledDateInput
            placeholder="mm/dd/yyyy"
            value={value.startDate}
            onChange={handleStartDateChange}
          />
          <IoIosCalendar size={28} />
        </DateInputColumn>

        <DateInputColumn>
          <DateInputLabel>End</DateInputLabel>
          <StyledDateInput
            placeholder="mm/dd/yyyy"
            value={value.endDate}
            onChange={handleEndDateChange}
          />
          <IoIosCalendar size={28} />
        </DateInputColumn>
      </Section>

      <SectionLabel>Products</SectionLabel>
      <Section>
        {data.categories.map(category => (
          <LabeledSwitch
            key={category.id}
            checked={value.categoryIds.includes(category.id)}
            onChange={() => handleCategoriesChange(category.id)}
          >
            {category.name}
          </LabeledSwitch>
        ))}
        <LabeledSwitch
          checked={value.includeTeacherUnits}
          onChange={handleTeacherUnitsChange}
        >
          TEACHER AUTHORED
        </LabeledSwitch>
      </Section>

      <SectionLabel>Units</SectionLabel>
      <Section>
        <SelectWrapper disabled={unitOptions.length === 0}>
          <Select
            disabled={unitOptions.length === 0}
            selected={value.unitIds}
            options={unitOptions}
            onSelectedChanged={handleUnitsChange}
            overrideStrings={{
              selectSomeItems: "Select Units"
            }}
          />
        </SelectWrapper>
      </Section>

      <SectionLabel>Assignments</SectionLabel>
      <Section>
        <SelectWrapper disabled={assignmentOptions.length === 0}>
          <Select
            disabled={assignmentOptions.length === 0}
            selected={value.assignmentIds}
            options={assignmentOptions}
            onSelectedChanged={handleAssignmentsChange}
            overrideStrings={{
              selectSomeItems: "Select Assignments"
            }}
          />
        </SelectWrapper>
      </Section>

      <SectionLabel>Teachers</SectionLabel>
      <Section>
        <SelectWrapper>
          <Select
            selected={value.teacherIds}
            options={teacherOptions}
            onSelectedChanged={handleTeachersChange}
            overrideStrings={{
              selectSomeItems: "Select Teachers"
            }}
          />
        </SelectWrapper>
      </Section>

      <DownloadButton>
        <FiDownload size={20} />
        Download CSV
      </DownloadButton>
    </Form>
  );
};

export default styled(CsvExportForm)``;

const SelectWrapper = styled("div")<{ disabled?: boolean }>`
  .multi-select {
    width: 459px;
  }

  .dropdown-heading {
    border-radius: 8px !important;
  }

  .dropdown-heading-dropdown-arrow {
    background-color: ${props => (props.disabled ? "#AAA" : "#000000")};
    width: 35px !important;
    padding-right: 0 !important;
  }

  .dropdown-heading-dropdown-arrow > span {
    border-color: white transparent transparent !important;
  }

  .dropdown[aria-expanded="true"] .dropdown-heading-dropdown-arrow > span {
    border-color: transparent transparent white !important;
  }
`;

const Select = styled(MultiSelect)<{ disabled?: boolean }>``;

const SectionLabel = styled("h3")`
  color: #000000;
  font-family: Lato;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 20px;
  font-weight: normal;
`;

const Section = styled(Row)`
  padding-left: 23px;
  margin-bottom: 32px;

  ${LabeledSwitch} + ${LabeledSwitch} {
    margin-left: 40px;
  }
`;

const StyledDateInput = styled(DateInput)`
  input {
    height: 37px;
    width: 219px;
    border: 1px solid #979797;
    border-radius: 8px;
    background-color: #ffffff;
    padding-left: 8px;
  }

  input::placeholder {
    color: #000000;
    font-size: 14px;
    line-height: 17px;
  }
`;

const DateInputColumn = styled(Column)`
  position: relative;

  & + & {
    margin-left: 21px;
  }

  svg {
    pointer-events: none;
    position: absolute;
    right: 13px;
    top: 24px;
  }
`;

const DateInputLabel = styled("label")`
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 3px;
  margin-left: 8px;
`;

const DownloadButton = styled(BlockButton)`
  width: 160px;
  background-color: #000000;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-left: 23px;

  svg {
    margin-right: 8px;
  }
`;
