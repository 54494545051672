import styled from 'styled-components/macro';

const BlockButton = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 3px;
  border: 2px solid #3a3a3a;
  background-color: #3a3a3a;
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
`;

export default BlockButton;
