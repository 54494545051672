import styled from "styled-components/macro";

const OutlinedTextarea = styled("textarea")`
  height: 5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: 0;
  border-radius: 3px;
  border: 2px solid #3a3a3a;
  background-color: #ffffff;
  font-size: 1rem;
  resize: none;
  font-family: Lato;
`;

export default OutlinedTextarea;
