import axios from "axios";
import SignedUrl from "shared/lib/types/SignedUrl";
import getMediaUploadUrl from "./getMediaUploadUrl";
import getMediaDownloadUrl from "./getMediaDownloadUrl";

export default async function uploadFile(file: File): Promise<SignedUrl> {
  const { key, url } = await getMediaUploadUrl(file.name);

  await axios.put(url, file, {
    headers: {
      "Content-Type": file.type
    }
  });

  return getMediaDownloadUrl(key);
}
