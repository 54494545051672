import React, { FC } from "react";
import styled from "styled-components/macro";
import {
  AssignmentQuestionPart,
  AssignmentQuestionPartKind,
} from "shared/lib/types/AssignmentQuestion";
import { Node } from "@nteract/mathjax";

interface Props {
  value: AssignmentQuestionPart[];
}

const MathInputPreview: FC<Props> = (props) => {
  const { value, ...rest } = props;

  return (
    <div {...rest}>
      {value.map((part, i) =>
        part.kind === AssignmentQuestionPartKind.latex ? (
          <Node key={part.id} inline>
            {part.value}
          </Node>
        ) : (
          <span key={part.id}>{(i > 0 ? " " : "") + part.value + " "}</span>
        )
      )}
    </div>
  );
};

export default styled(MathInputPreview)`
  width: 480px;
  max-width: 100%;
  min-height: 19px;
`;
