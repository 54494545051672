import React, { FC } from "react";
import styled from "styled-components/macro";
import Overlay, { Props as OverlayProps } from "./Overlay";
import XButton from "./XButton";
import MathByExampleLogo from "./MathByExampleLogo";

export interface Props extends OverlayProps {
  withLogo?: boolean;
  onClose(): any;
}

const FormOverlay: FC<Props> = props => {
  const { withLogo, onClose, children, ...rest } = props;

  return (
    <Overlay {...rest} inRoot fixed hideBodyScrollbar color="#FFF">
      <CloseButton onClick={onClose} iconSize={32} />
      {withLogo && <CornerLogo />}
      {children}
    </Overlay>
  );
};

export default styled(FormOverlay)`
  align-items: center;
  z-index: 101;
`;

const CloseButton = styled(XButton)`
  position: absolute;
  right: 2rem;
  top: 1rem;
  height: 48px;
  width: 48px;
  background-color: rgba(216, 216, 216, 0.3);
  border-radius: 50%;
  z-index: 100;
`;

const CornerLogo = styled(MathByExampleLogo)`
  position: absolute;
  left: 40px;
  top: 30px;
  width: 174px;
  height: 31px;
`;
