import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Assignment from "shared/lib/types/Assignment";
import RadioCircle from "./RadioCircle";
import Row from "./Row";
import CompletedIcon from "./CompletedIcon";
import AssignedIcon from "./AssignedIcon";
import ProgressBar from "./ProgressBar";
import RadioCircleButton from "./RadioCircleButton";

interface Props {
  classroomId: number;
  selected: boolean;
  assignment: Assignment;
  takeable: boolean;
  graded: boolean;
  as?: FC;
  progress: null | {
    current: number;
    total: number;
    incompleteNames: string[];
  };
  onTitleClick(): any;
  onSelectClick(): any;
  disabled?: boolean;
}

const LessonPlanListItem: FC<Props> = props => {
  const {
    classroomId,
    selected,
    assignment,
    takeable,
    graded,
    progress,
    onSelectClick,
    onTitleClick,
    disabled = false,
    ...rest
  } = props;

  const isComplete =
    takeable && progress && progress.current === progress.total;

  let icon;
  if (isComplete) {
    icon = <CompletedIcon />;
  } else if (takeable) {
    icon = <AssignedIcon />;
  } else {
    icon = null;
  }

  const handleSelectRadioClick = useCallback(() => {
    if (!graded) {
      onSelectClick();
    }
  }, [graded, onSelectClick]);

  return (
    <Row {...rest}>
      <Content>
        <RadioCircleButton onClick={handleSelectRadioClick} disabled={disabled}>
          {!graded && <SelectedCircle checked={selected} invisible={graded} />}
        </RadioCircleButton>
        <AssignmentTitle onClick={onTitleClick} disabled={disabled}>
          {assignment.title}
        </AssignmentTitle>
        {progress && !isComplete && (
          <ProgressBar
            progress={progress.current}
            total={progress.total}
            toolTip={
              <>
                <h3>
                  {progress.current} of {progress.total} completed
                </h3>
                {progress.incompleteNames.length > 0 && (
                  <>
                    <h4>Not completed:</h4>
                    <ul>
                      {progress.incompleteNames.map((name, i) => (
                        <li key={i}>{name}</li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            }
          />
        )}
        <IconContainer>{icon}</IconContainer>
      </Content>
    </Row>
  );
};

export default styled(LessonPlanListItem)`
  overflow: visible;
  position: relative;
  height: 2.5rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  flex: 1;

  & + & {
    border-top: 1px solid #979797;
  }
`;

const Content = styled(Row)`
  align-items: center;
  height: 100%;
  flex: 1;
`;

const SelectedCircle = styled(RadioCircle)<{ invisible: boolean }>`
  opacity: ${props => (props.invisible ? 0 : 1)};
`;

const AssignmentTitle = styled("div")<{disabled: boolean}>`
  color: #000000;
  font-size: 0.6rem;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.5s;
  opacity: ${props => props.disabled ? 0.4 : 1};
`;

const IconContainer = styled(Row)`
  width: 20px;
  margin-right: 1rem;
`;
