import React, { FC, MouseEvent, useCallback } from "react";
import styled from "styled-components/macro";
import isBlank from "shared/lib/utils/isBlank";
import { GoTrashcan } from "react-icons/go";
import useInputState from "../utils/useInputState";
import UnderlinedInput from "./UnderlinedInput";
import autoFocus from "../utils/autoFocus";
import TextButton from "./TextButton";
import AssignmentCategory from "shared/lib/types/AssignmentCategory";
import Form from "./Form";
import BlockButton from "./BlockButton";

interface Props {
  category: AssignmentCategory;
  onSubmit(value: Value): any;
  onDelete(): any;
}

export interface Value {
  name: string;
}

const EditCategoryForm: FC<Props> = props => {
  const { category, onSubmit, onDelete, ...rest } = props;
  const [name, handleNameChange] = useInputState(category.name);

  const handleSubmit = useCallback(async () => {
    if (isBlank(name)) {
      throw new Error("Name is required.");
    }

    await onSubmit({
      name
    });
  }, [name, onSubmit]);

  const handleDeleteClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onDelete();
    },
    [onDelete]
  );

  return (
    <Form {...rest} title="Edit Product" onSubmit={handleSubmit}>
      <Title>Edit Product</Title>
      <UnderlinedInput
        placeholder="Name"
        value={name}
        onChange={handleNameChange}
        ref={autoFocus}
      />
      <BlockButton>Save</BlockButton>
      <DeleteClassButton onClick={handleDeleteClick}>
        <GoTrashcan />
        Delete {category.name}?
      </DeleteClassButton>
    </Form>
  );
};

export default styled(EditCategoryForm)`
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;

  ${UnderlinedInput} {
    margin-bottom: 5rem;
  }

  ${BlockButton} {
    width: 150px;
    align-self: center;
    margin-bottom: 37px;
  }
`;

const Title = styled("h1")`
  color: #000;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  margin: 125px 0 200px 0;
  font-weight: 400;
`;

const DeleteClassButton = styled(TextButton)`
  color: #7e7e7e;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  > svg {
    margin-right: 7px;
  }
`;
