import styled from "styled-components/macro";

interface Props {
  turned: boolean;
  faded?: boolean;
}

const ExpandTriangle = styled("div")<Props>`
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #d8d8d8;
  transform: rotate(${props => (props.turned ? 90 : 0)}deg);
  opacity: ${props => (props.faded ? 0.25 : 1)};
`;

export default ExpandTriangle;
