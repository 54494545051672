import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components/macro";
import { navigate } from "@reach/router";
import isBlank from "shared/lib/utils/isBlank";
import RouteProps from "../../RouteProps";
import useInputState from "../../utils/useInputState";
import getErrorMessage from "../../utils/getErrorMessage";
import StudentPage from "../../components/StudentPage";
import FilledInput from "../../components/FilledInput";
import Form from "../../components/Form";
import FilledButton from "../../components/FilledButton";
import FormErrorText from "../../components/FormErrorText";
import wireGuysImage from "../../images/wire_guys.png";
import acceptStudentInvitation from "../../api/invitations/acceptStudentInvitation";
import showAlert from "../../utils/showAlert";

const StudentLoginPage: FC<RouteProps> = props => {
  const inviteToken = useMemo(
    () => new URLSearchParams(window.location.search).get("invite_token"),
    []
  );
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [password, handlePasswordChange] = useInputState();
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState();

  const handleSubmit = useCallback(async () => {
    try {
      if (isBlank(password)) {
        throw new Error("You must enter a password.");
      }
      if (password !== passwordConfirm) {
        throw new Error(`Passwords don't match.`);
      }

      setSubmitting(true);
      await acceptStudentInvitation({
        password,
        inviteToken: inviteToken as string
      });
      await showAlert({ title: "Success!", message: "Password updated." });
      navigate("/");
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }, [password, passwordConfirm, inviteToken]);

  useEffect(() => {
    // Can't accept an invite if there's no token
    if (!inviteToken) {
      navigate("/");
    }
  }, [inviteToken]);

  return (
    <StudentPage {...props} hideMenu>
      <Form onSubmit={handleSubmit}>
        <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
        <FilledInput
          type="password"
          placeholder="Password"
          autoComplete="new-password"
          value={password}
          onChange={handlePasswordChange}
        />
        <FilledInput
          type="password"
          placeholder="Password Confirmed"
          autoComplete="new-password"
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
        />
        <SubmitButton disabled={submitting}>Set Password</SubmitButton>
      </Form>
      <WireGuys />
    </StudentPage>
  );
};

export default styled(StudentLoginPage)`
  align-items: center;
  overflow-y: visible;
  padding: 3rem 0 10rem;

  ${Form} {
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  ${FilledInput} + ${FilledInput} {
    margin-top: 1.25rem;
  }
`;

const SubmitButton = styled(FilledButton)`
  background-color: #9ce0fd;
  color: #19579f;
  margin-top: 3rem;
`;

const WireGuys = styled("img").attrs({ src: wireGuysImage, alt: "" })`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 25%);
  min-width: 100%;
  max-width: 1024px;
  height: auto;
`;
