import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { DEFAULT_STUDENT_USERNAME, DEFAULT_STUDENT_PASSWORD } from "../../env";
import RouteProps from "../../RouteProps";
import useInputState from "../../utils/useInputState";
import studentLogin from "../../api/auth/studentLogin";
import getErrorMessage from "../../utils/getErrorMessage";
import Link from "../../components/Link";
import StudentPage from "../../components/StudentPage";
import FilledInput from "../../components/FilledInput";
import Form from "../../components/Form";
import FilledButton from "../../components/FilledButton";
import FormErrorText from "../../components/FormErrorText";
import wireGuysImage from "../../images/wire_guys.png";
import LinkRow from "../../components/LinkRow";

interface Props extends RouteProps {
  onLogin(): any;
}

const StudentLoginPage: FC<Props> = props => {
  const { onLogin, navigate, location, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [username, handleUsernameChange] = useInputState(
    DEFAULT_STUDENT_USERNAME
  );
  const [password, handlePasswordChange] = useInputState(
    DEFAULT_STUDENT_PASSWORD
  );

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await studentLogin({ username, password });
      await onLogin();
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  }, [username, password, onLogin]);

  return (
    <StudentPage {...rest} hideMenu>
      <Form onSubmit={handleSubmit}>
        <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
        <FilledInput
          placeholder="Username"
          autoComplete="username"
          value={username}
          onChange={handleUsernameChange}
        />
        <FilledInput
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          value={password}
          onChange={handlePasswordChange}
        />
        <ForgotPasswordRow>
          <Link to="/student/forgot-password">Forgot password</Link>
        </ForgotPasswordRow>
        <SubmitButton disabled={submitting}>Sign In</SubmitButton>
      </Form>
      <LinkRow>
        <Link to="/teacher">Teacher login</Link>
        <Link to="/admin">Admin login</Link>
      </LinkRow>
      <WireGuys />
    </StudentPage>
  );
};

export default styled(StudentLoginPage)`
  align-items: center;
  overflow-y: visible;
  padding: 3rem 0 10rem;

  ${Form} {
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  ${FilledInput} + ${FilledInput} {
    margin-top: 1.25rem;
  }
`;

const ForgotPasswordRow = styled(LinkRow)`
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

const SubmitButton = styled(FilledButton)`
  background-color: #9ce0fd;
  color: #19579f;
  margin-top: 3rem;
`;

const WireGuys = styled("img").attrs({ src: wireGuysImage, alt: "" })`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 25%);
  min-width: 100%;
  max-width: 1024px;
  height: auto;
`;
