import React, { FC } from "react";
import styled, { css } from "styled-components/macro";
import { Draggable } from "react-beautiful-dnd";
import DragHandle from "./DragHandle";
import Row from "./Row";

interface Props {
  draggableId: string | number;
  index: number;
}

const DraggableListItem: FC<Props> = props => {
  const { draggableId, index, children, ...rest } = props;

  return (
    <Draggable draggableId={draggableId.toString()} index={index}>
      {(provided, snapshot) => (
        <Root
          {...rest}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          {...provided.draggableProps}
        >
          {children}
          <DragHandle {...provided.dragHandleProps} />
        </Root>
      )}
    </Draggable>
  );
};

export default styled(DraggableListItem)``;

const Root = styled(Row)<{ isDragging: boolean; ref: any }>`
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;

  ${props =>
    props.isDragging
      ? css`
          outline: 1px solid #d8d8d8;
          overflow: visible;
        `
      : css`
          & + & {
            border-top: 1px solid #d8d8d8;
          }
        `}
`;
