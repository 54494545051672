import { useMemo } from 'react';
import { WindowLocation } from '@reach/router';

export default function useSearchParams(
  location: WindowLocation | undefined,
  keys: string[]
): Record<string, string | null> {
  const searchParams = useMemo(() => {
    if (!location) {
      return {};
    }

    const { search } = location;
    const params = new URLSearchParams(search);
    const paramObject: Record<string, string | null> = {};

    for (const key of keys) {
      paramObject[key] = params.get(key);
    }

    return paramObject;
  }, [location, keys]);

  return searchParams;
}
