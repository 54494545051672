import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { validate as isValidEmail } from "email-validator";
import UnderlinedInput from "./UnderlinedInput";
import useInputState from "../utils/useInputState";
import isBlank from "shared/lib/utils/isBlank";
import Student from "shared/lib/types/Student";
import FormLayout from "./FormLayout";

export interface Props {
  student: Student;
  onSubmit(value: Value): any;
}

export interface Value {
  username: string;
  studentCode: string | null;
  firstName: string;
  lastName: string;
  email: string | null;
  password?: string;
}

const EditStudentForm: FC<Props> = props => {
  const { onSubmit, student, ...rest } = props;
  const [username, handleUsernameChange] = useInputState(student.username);
  const [email, handleEmailChange] = useInputState(student.email || "");
  const [firstName, handleFirstNameChange] = useInputState(student.firstName);
  const [lastName, handleLastNameChange] = useInputState(student.lastName);
  const [studentCode, handleStudentIdChange] = useInputState(
    student.studentCode || ""
  );
  const [password, handlePasswordChange] = useInputState("");
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState("");

  const handleSubmit = useCallback(async () => {
    const value: Value = {
      username,
      studentCode: studentCode.trim() || null,
      firstName,
      lastName,
      email: null
    };

    if (isBlank(username)) {
      throw new Error("Username is required.");
    }
    if (isBlank(firstName)) {
      throw new Error("First name is required.");
    }
    if (isBlank(lastName)) {
      throw new Error("last name is required.");
    }
    if (!isBlank(email)) {
      if (!isValidEmail(email)) {
        throw new Error("Invalid email address.");
      }
      value.email = email;
    }
    if (!isBlank(password)) {
      if (password !== passwordConfirm) {
        throw new Error(`Passwords don't match`);
      }
      value.password = password;
    }

    await onSubmit(value);
  }, [
    username,
    email,
    firstName,
    lastName,
    studentCode,
    password,
    passwordConfirm,
    onSubmit
  ]);

  return (
    <FormLayout
      {...rest}
      title="Edit Student"
      submitButtonText="Done"
      onSubmit={handleSubmit}
    >
      <UnderlinedInput
        required
        placeholder="First Name"
        autoComplete="given-name"
        value={firstName}
        onChange={handleFirstNameChange}
      />
      <UnderlinedInput
        required
        placeholder="Last Name"
        autoComplete="family-name"
        value={lastName}
        onChange={handleLastNameChange}
      />
      <UnderlinedInput
        required
        placeholder="Username"
        autoComplete="username"
        value={username}
        onChange={handleUsernameChange}
      />
      <UnderlinedInput
        placeholder="Email (optional)"
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
      />
      <UnderlinedInput
        placeholder="Student ID # (optional)"
        autoComplete="organization-title"
        value={studentCode}
        onChange={handleStudentIdChange}
      />
      <UnderlinedInput
        required={!!passwordConfirm}
        type="password"
        autoComplete="new-password"
        placeholder="Password"
        value={password}
        onChange={handlePasswordChange}
      />
      <UnderlinedInput
        required={!!password}
        type="password"
        autoComplete="new-password"
        placeholder="Password confirmed"
        value={passwordConfirm}
        onChange={handlePasswordConfirmChange}
      />
    </FormLayout>
  );
};

export default styled(EditStudentForm)``;
