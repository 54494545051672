import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { navigate } from "@reach/router";
import RouteProps from "../../RouteProps";
import TeacherPage from "../../components/TeacherPage";
import Teacher from "shared/lib/types/Teacher";
import getTakeFromId from "../../api/assignmentTakes/getTakeFromId";
import useAsyncEffect from "../../utils/useAsyncEffect";
import { DetailedAssignmentTake } from "shared/lib/types/AssignmentTake";
import AssignmentTakeReview from "shared/lib/types/AssignmentTakeReview";
import * as teacherRoutes from "../../teacherRoutes";
import BackArrowButton from "../../components/BackArrowButton";
import AssignmentSetPresent from "../../components/AssignmentSetPresent";

interface Props extends RouteProps {
  takeId: string;
  studentId: string;
  teacher: Teacher;
  reload(): any;
  setTeacher(teacher: Teacher): any;
}

const TeacherTakePresentPage: FC<Props> = props => {
  const takeId = parseInt(props.takeId, 10);
  const { teacher, location, ...rest } = props;
  const [take, setTake] = useState<DetailedAssignmentTake | null>(null);
  const [review, setReview] = useState<AssignmentTakeReview | null>(null);

  useAsyncEffect(
    async isCancelled => {
      const fetchedTake = await getTakeFromId(takeId);

      if (!fetchedTake) {
        navigate(teacherRoutes.classrooms);
        return;
      }

      const questions = fetchedTake.takeableAssignment.assignment.sets.flatMap(
        set => set.questions
      );
      const responses = fetchedTake.responses || [];

      if (!isCancelled()) {
        setTake(fetchedTake);
        setReview({
          assignmentTakeId: fetchedTake.id,
          comment: fetchedTake.comment || "",
          grade: fetchedTake.grade || "",
          questionReviews: questions.map(question => {
            const response = responses.find(
              response => response.questionId === question.id
            );

            return {
              questionId: question.id,
              responseId: (response && response.id) || null,
              comment: (response && response.comment) || "",
              grade: (response && response.grade) || ""
            };
          })
        });
      }
    },
    [takeId]
  );

  const navigateBack = useCallback(() => {
    window.history.back();
  }, []);

  if (!take || !review) {
    // Loading
    return null;
  }

  const { takeableAssignment, responses = [] } = take;
  const { assignment } = takeableAssignment;
  const { sets } = assignment;

  return (
    <TeacherPage {...rest} teacher={teacher} location={location}>
      <BackArrowButton onClick={navigateBack} />
      <PresentBanner>Presentation Mode</PresentBanner>
      <AssignmentTitle>
        {assignment.title}{" "}
        <AssignmentSubTitle>{assignment.subTitle}</AssignmentSubTitle>
      </AssignmentTitle>
      {sets.map((set, index) => (
        <AssignmentSetPresent
          key={set.id}
          takeableAssignmentId={takeableAssignment.id}
          setIndex={index}
          setCount={sets.length}
          set={set}
          responses={responses}
        />
      ))}
    </TeacherPage>
  );
};

export default styled(TeacherTakePresentPage)`
  ${BackArrowButton} {
    margin-bottom: 10px;
  }
`;

const PresentBanner = styled("h1")`
  height: 54px;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
`;

const AssignmentTitle = styled("h2")`
  color: #4a4a4a;
  font-size: 16px;
  margin-bottom: 32px;
  font-weight: normal;
`;

const AssignmentSubTitle = styled("span")`
  font-size: 10px;
`;
