import React, { FC, FormEvent, ReactNode, useCallback } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import Row from "./Row";

interface Props {
  gradeLabel: ReactNode;
  comment: string;
  grade: string;
  onCommentChange(comment: string): any;
  onGradeChange(grade: string): any;
  onFocus?(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>): any;
}

const TeacherCommentForm: FC<Props> = props => {
  const {
    gradeLabel,
    comment,
    grade,
    onCommentChange,
    onGradeChange,
    onFocus,
    ...rest
  } = props;

  const handleCommentChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      onCommentChange(event.currentTarget.value);
    },
    [onCommentChange]
  );

  const handleGradeChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      onGradeChange(event.currentTarget.value);
    },
    [onGradeChange]
  );

  return (
    <Column {...rest}>
      <Title>Teacher Comments</Title>
      <CommentTextArea
        value={comment}
        onChange={handleCommentChange}
        onFocus={onFocus}
      />
      <GradeRow>
        <GradeLabel>{gradeLabel}</GradeLabel>
        <GradeInput
          value={grade}
          onChange={handleGradeChange}
          onFocus={onFocus}
        />
      </GradeRow>
    </Column>
  );
};

export default styled(TeacherCommentForm)`
  @media print {
    page-break-after: always;
  }
`;

const Title = styled("h4")`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 9px;
`;

const CommentTextArea = styled("textarea")`
  height: 60px;
  margin-bottom: 6px;
  padding: 10px;
  width: 100%;
  border: 1px solid #979797;
  background-color: #ededed;
  resize: vertical;
`;

const GradeRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
`;

const GradeLabel = styled("label")`
  color: #000000;
  font-size: 14px;
  margin-right: 12px;
`;

const GradeInput = styled("input")`
  height: 31px;
  width: 43px;
  border: 1px solid #979797;
  background-color: #ededed;
  text-align: center;
`;
