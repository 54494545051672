import Mathquill, { addStyles as addMathQuillStyles } from "react-mathquill";

addMathQuillStyles();

export default class FixedMathquill extends Mathquill {
  componentDidUpdate(prevProps: { latex: string }) {
    if (prevProps.latex !== this.props.latex) {
      if ((this as any).mathField.latex() !== this.props.latex) {
        (this as any).mathField.latex(this.props.latex);
      }
    }
  }
}
