import React, { FC } from "react";
import styled from "styled-components/macro";
import Overlay from "./Overlay";
import Column from "./Column";
import useOnline from "../utils/useOnline";

const AssignmentSyncModal: FC = props => {
  const online = useOnline();

  if (online) {
    return null;
  }

  return (
    <Overlay {...props} hideBodyScrollbar fixed inRoot>
      <Window>
        <Title>Could not connect to server</Title>
        <Message>Waiting for an internet connection.</Message>
      </Window>
    </Overlay>
  );
};

export default styled(AssignmentSyncModal)`
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  z-index: 102;
`;

const Window = styled(Column)`
  width: 451px;
  max-width: 100%;
  padding: 57px 78px 65px 78px;
  border-radius: 3px;
  align-items: center;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.95);
`;

const Title = styled("h1")`
  color: #ffffff;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Message = styled("p")`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  max-width: 257px;
  text-align: center;
`;
