import React, { FC } from 'react';
import RouteProps from '../../RouteProps';
import sendTeacherPasswordReset from '../../api/forgotPassword/sendTeacherPasswordReset';
import ForgotPasswordPage from '../../components/ForgotPasswordPage';

const TeacherForgotPasswordPage: FC<RouteProps> = props => {
  return (
    <ForgotPasswordPage
      {...props}
      title="Forgot Password - Teacher"
      backPath="/teacher"
      onSubmit={sendTeacherPasswordReset}
    />
  );
};

export default TeacherForgotPasswordPage;
