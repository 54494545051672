import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import star from "../images/star.png";
import Link from "./Link";
import Row from "./Row";
import chevronRight from "../images/chevron_right.png";
import ReviewedIcon from "./ReviewedIcon";

interface Props {
  title: string;
  showStar: boolean;
  linkTo: string | null;
  showButton: boolean;
  buttonContent?: ReactNode;
  backgroundIcon: string;
  isReviewed: boolean;
}

const AssignmentTile: FC<Props> = props => {
  const {
    title,
    showStar,
    linkTo,
    showButton,
    buttonContent,
    backgroundIcon,
    isReviewed,
    ...rest
  } = props;

  const content = (
    <>
      {showStar && <Star />}
      {isReviewed && (
        <ReviewedIconContainer>
          <ReviewedIcon />
        </ReviewedIconContainer>
      )}
      <TileIcon src={backgroundIcon} />
      <Title>{title}</Title>
      {showButton && (
        <BottomButton>
          {buttonContent} {buttonContent === "View Your Work" && <Chevron />}
        </BottomButton>
      )}
    </>
  );

  return (
    <Column {...rest}>
      {linkTo ? <Link to={linkTo}>{content}</Link> : content}
    </Column>
  );
};

export default styled(AssignmentTile)`
  width: 230px;
  height: 230px;
  border-radius: 7px;
  background-color: #19579f;
  color: white;
  text-align: center;
  padding: 1.25rem 1rem 0 1rem;

  > ${Link} {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const TileIcon = styled("img").attrs({ alt: "Assignment Progress Icon" })`
  height: 90px;
  width: 90px;
  align-self: center;
  margin-bottom: 1.25rem;
`;

const Star = styled("div")`
  position: absolute;
  left: 50%;
  top: 0;
  width: 37px;
  height: 37px;
  padding: 7px;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 3px solid #19579f;
  border-radius: 50%;
  background-image: url('${star}');
  background-size: 75% 75%;
  background-repeat: no-repeat;
  background-position: 50% 40%;
`;

const Title = styled("h2")`
  color: #ffffff;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.25rem;
  text-align: center;
`;

const BottomButton = styled(Row)`
  position: absolute;
  height: 2rem;
  width: 100%;
  color: #19579f;
  background-color: #9ce0fd;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  bottom: 0;
  left: 0;
  font-size: 1rem;
  font-weight: 900;
`;

const Chevron = styled("img").attrs({
  src: chevronRight,
  alt: "Assignment Progress Icon"
})`
  width: 9px;
  height: auto;
  margin-left: 10px;
`;

const ReviewedIconContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 2px solid #19579f;
  background-color: #fff;

  > img {
    width: 20.5px;
    height: 23px;
  }
`;
