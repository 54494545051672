import { navigate, WindowLocation, NavigateOptions } from "@reach/router";

/**
 * Stringifies and sets a URL search param.
 */
export default function setSearchParam<T>(
  location: WindowLocation | Location | undefined,
  key: string,
  value?: T,
  navigateOptions?: NavigateOptions<any>,
  jsonifyString = true,
): void {
  if (!location) {
    return;
  }

  const urlParams = new URLSearchParams(location.search);


  if(value) {
    let urlValue: string;
    if(typeof value === 'string' && !jsonifyString) {
      urlValue = value;
    } else {
      urlValue = JSON.stringify(value)
    }
    urlParams.set(key, urlValue);
  } else {
    urlParams.delete(key);
  }


  const urlParamsString = urlParams.toString();
  navigate(
    `${location.pathname}${
      urlParamsString.length > 0 ? `?${urlParamsString}` : ""
    }`,
    navigateOptions
  );
}
