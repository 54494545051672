import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import ImageInput from "./ImageInput";

interface Props {
  title: ReactNode;
}

const InputWindow: FC<Props> = props => {
  const { title, children, ...rest } = props;

  return (
    <Column {...rest}>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Column>
  );
};

export default styled(InputWindow)`
  margin-bottom: 2rem;
  border-radius: 3px;
  overflow: hidden;
  ${ImageInput} {
    margin: 0.5rem 0 0 0;
  }
`;

const Title = styled("h4")`
  color: #3a3a3a;
  background-color: #b8b8b8;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  height: 1.5rem;
  padding-left: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Content = styled(Column)`
  padding: 1rem 0.75rem 1rem 0.75rem;
  background-color: #f9f9f9;
`;
