import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { AssignmentQuestionPart } from "shared/lib/types/AssignmentQuestion";
import AssignmentSlide, {
  Props as AssignmentSlideProps
} from "./AssignmentSlide";
import AssignmentSlideTitle from "./AssignmentSlideTitle";
import AssignmentSlideText from "./AssignmentSlideText";
import AssignmentArrowButton from "./AssignmentArrowButton";
import Column from "./Column";
import Sketch from "shared/lib/SketchPad/Sketch";
import ExampleImagePullout from "./ExampleImagePullout";
import MultiImageInput from "./MultiImageInput";
import MathInputPreview from "./MathInputPreview";
import AssessmentImageUploadLabel from "./AssessmentImageUploadLabel";
import ZwibblerSketchpad from "./ZwibblerSketchpad";
import { BLANK_PRACTICE_SKETCH, BLANK_PRACTICE_SKETCH_CONTAINER_WIDTH } from "../constants/sketch/blankSketches";

export interface Value {
  sketch: Sketch;
  images: Array<File | string>;
}

interface Props extends AssignmentSlideProps {
  title: string;
  question: AssignmentQuestionPart[];
  buttonText: string;
  buttonLink: string;
  backgroundImage: string | null;
  value: Value;
  isExampleCorrect: boolean;
  onChange(value: Value): any;
  onNextClick(nextPath: string): any;
}

const PracticeAssignmentSlide: FC<Props> = props => {
  const {
    title,
    question,
    buttonText,
    buttonLink,
    backgroundImage,
    value,
    isExampleCorrect,
    assignmentImageSrc,
    onChange,
    onNextClick,
    ...rest
  } = props;
  const [exampleOpen, setExampleOpen] = useState(false);

  const handleExampleClick = useCallback(() => {
    setExampleOpen(open => !open);
  }, []);

  const handleSketchChange = useCallback(
    (sketch: Sketch) => {
      onChange({
        ...value, sketch
      });
    },
    [onChange, value]
  );

  const handleImagesChange = useCallback(
    (images: Array<File | string>) => {
      onChange({ ...value, images });
    },
    [onChange, value]
  );

  const handleNextClick = useCallback(() => {
    onNextClick(buttonLink);
  }, [onNextClick, buttonLink]);

  return (
    <AssignmentSlide {...rest} assignmentImageSrc={null}>
      {assignmentImageSrc && (
        <ExampleImagePullout
          correct={isExampleCorrect}
          open={exampleOpen}
          onClick={handleExampleClick}
          imageSrc={assignmentImageSrc}
        />
      )}
      <Column>
        <Title>{title}</Title>
        <AssignmentSlideText>
          <MathInputPreview value={question} />
        </AssignmentSlideText>
      </Column>
      <ZwibblerSketchpad
        value={value.sketch}
        onChange={handleSketchChange}
        backgroundImage={backgroundImage}
        canvasHeight={BLANK_PRACTICE_SKETCH.height}
        canvasWidth={BLANK_PRACTICE_SKETCH.width}
        containerWidth={BLANK_PRACTICE_SKETCH_CONTAINER_WIDTH}
      />
      <ImageUploadColumn>
        <MultiImageInput
          minimal
          value={value.images}
          onFilesChange={handleImagesChange}
          buttonLabel={<AssessmentImageUploadLabel />}
        />
      </ImageUploadColumn>
      <Column>
        <NextLink onClick={handleNextClick}>
          {buttonText}
        </NextLink>
      </Column>
    </AssignmentSlide>
  );
};

export default styled(PracticeAssignmentSlide)`
  justify-content: space-between;
  ${ExampleImagePullout} {
    margin-bottom: 1rem;
  }
`;

const Title = styled(AssignmentSlideTitle)`
  color: #f5a623;
`;

const NextLink = styled(AssignmentArrowButton)`
  width: 369px;
  max-width: 100%;
  background-color: #19579f;
  color: #FFFFFF;
  align-self: center;
`;

const ImageUploadColumn = styled(Column)`
  visibility: hidden; /* Hiding for now */
  align-items: flex-end;
  margin-top: 5px; 
  margin-bottom: 30px;  
  max-width: ${BLANK_PRACTICE_SKETCH_CONTAINER_WIDTH}px;
`;
