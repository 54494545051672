import AssignmentCategory from "shared/lib/types/AssignmentCategory";
import apiAxios from "../apiAxios";

interface Props {
  name: string;
}

export default async function createAssignmentCategory(
  props: Props
): Promise<AssignmentCategory> {
  const response = await apiAxios.post("/categories", props);
  return response.data;
}
