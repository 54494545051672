import React, { FC } from "react";
import styled from "styled-components/macro";
import Overlay from "./Overlay";
import Column from "./Column";

interface Props {
  title: string;
  message: string;
  onClose(): any;
}

const AlertModal: FC<Props> = props => {
  const { title, message, onClose, ...rest } = props;

  return (
    <Overlay {...rest} hideBodyScrollbar fixed inRoot>
      <Window>
        <Title>{title}</Title>
        <Message>{message}</Message>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </Window>
    </Overlay>
  );
};

export default styled(AlertModal)`
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  z-index: 102;
`;

const Window = styled(Column)`
  width: 451px;
  max-width: 100%;
  padding: 57px 78px 65px 78px;
  border-radius: 3px;
  align-items: center;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.95);
`;

const Title = styled("h1")`
  color: #ffffff;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Message = styled("p")`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 65px;
  max-width: 257px;
  text-align: center;
`;

const CloseButton = styled("button")`
  height: 39px;
  width: 138px;
  border-radius: 3px;
  background-color: #ffffff;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }
`;
