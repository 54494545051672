import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import TextButton from "./TextButton";
import PlusIcon from "./PlusIcon";

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const PlusButton: FC<Props> = props => {
  return (
    <TextButton {...props}>
      <PlusIcon size={20} />
      {props.children}
    </TextButton>
  );
};

export default styled(PlusButton)`
  color: #c0c0c0;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    margin-right: 0.5rem;
  }
`;
