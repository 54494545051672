import React from "react";
import ReactDOM from "react-dom";
import ConfirmModal from "../components/ConfirmModal";

export interface ConfirmOptions {
  title: string;
  message: string;
}

export default async function showConfirm({
  title,
  message,
}: ConfirmOptions): Promise<boolean> {
  const root = document.createElement("div");
  root.classList.add("confirm-root");
  document.body.appendChild(root);

  try {
    const confirmed = await new Promise<boolean>((resolve) => {
      ReactDOM.render(
        <ConfirmModal
          title={title}
          message={message}
          onConfirm={() => resolve(true)}
          onCancel={() => resolve(false)}
        />,
        root
      );
    });
    return confirmed;
  } finally {
    ReactDOM.unmountComponentAtNode(root);
    document.body.removeChild(root);
  }
}
