import styled from "styled-components/macro";
import {
  Link as ReachLink,
  LinkGetProps as ReachLinkGetProps
} from "@reach/router";

const Link = styled(ReachLink)`
  text-decoration: none;
  color: inherit;
  font-size: 100%;
  font-family: inherit;
  font-weight: inherit;
`;

export type LinkGetProps = ReachLinkGetProps;

export default Link;
