import React, { FC } from "react";
import styled from "styled-components/macro";
import TopMenu, { Props as TopMenuProps } from "./TopMenu";
import TextButton from "./TextButton";
import noop from "shared/lib/utils/noop";
import PlusIcon from "./PlusIcon";
import MinusIcon from "./MinusIcon";
import DotDotDotIcon from "./DotDotDotIcon";

interface Props extends TopMenuProps {
  assigned?: boolean;
  multipleAssignments?: boolean;
  hideAssignButton?: boolean;
  hideEditButton?: boolean;
  hideRemoveButton?: boolean;
  onAssignClick?(): any;
  onEditAssignmentClick?(): any;
  onRemoveAssignmentClick?(): any;
}

const AssignmentTopMenu: FC<Props> = props => {
  const {
    assigned,
    hideAssignButton,
    hideEditButton,
    hideRemoveButton,
    multipleAssignments,
    onEditAssignmentClick = noop,
    onRemoveAssignmentClick = noop,
    onAssignClick = noop,
    ...rest
  } = props;

  return (
    <TopMenu {...rest}>
      {hideEditButton ? null : (
        <MenuButton onClick={onEditAssignmentClick}>
          <DotDotDotIcon size={24} />
          Edit Assignment
        </MenuButton>
      )}
      {hideRemoveButton ? null : (
        <MenuButton onClick={onRemoveAssignmentClick}>
          <MinusIcon size={24} />
          Remove Assignment{multipleAssignments ? "s" : ""}
        </MenuButton>
      )}
      {hideAssignButton ? null : (
        <MenuButton onClick={onAssignClick}>
          <PlusIcon size={24} />
          {assigned ? "Unassign Work" : "Assign Work"}
        </MenuButton>
      )}
    </TopMenu>
  );
};

export default styled(AssignmentTopMenu)``;

const MenuButton = styled(TextButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    margin-right: 0.5rem;
  }

  & + & {
    margin-left: 2rem;
  }
`;
