import React, { FC } from "react";
import styled from "styled-components/macro";
import Teacher from "shared/lib/types/Teacher";
import capitalizeFirst from "shared/lib/utils/capitalizeFirst";
import RouteProps from "../../RouteProps";
import TeacherPage from "../../components/TeacherPage";
import AssignmentProgressReport from "../../components/AssignmentProgressReport";
import RecentlySubmittedTakeReport from "../../components/RecentlySubmittedTakeReport";
import Row from "../../components/Row";
import Link from "../../components/Link";
import * as teacherRoutes from "../../teacherRoutes";

interface Props extends RouteProps {
  teacher: Teacher;
  reload(): any;
  setTeacher(teacher: Teacher): any;
}

const TeacherHomePage: FC<Props> = props => {
  const { teacher, location, ...rest } = props;

  return (
    <TeacherPage {...rest} teacher={teacher} location={location}>
      <Title>Welcome back, {capitalizeFirst(teacher.firstName)}!</Title>
      <ReportRow>
        <AssignmentProgressReport />
        <RecentlySubmittedTakeReport />
      </ReportRow>
      <DashboardLink to={teacherRoutes.classrooms}>
        Teacher Dashboard
      </DashboardLink>
    </TeacherPage>
  );
};

export default styled(TeacherHomePage)`
  color: #000000;

  h1 {
    padding-bottom: 4rem;
    font-weight: normal;
  }
`;

const Title = styled("h1")`
  font-size: 30px;
  line-height: 38px;
  padding-bottom: 61px;
  margin: 0 0 55px 0;
  border-bottom: 1px solid #979797;
`;

const ReportRow = styled(Row)`
  justify-content: center;
  margin-bottom: 22px;

  ${RecentlySubmittedTakeReport} {
    margin-left: 20px;
  }
`;

const DashboardLink = styled(Link)`
  height: 50px;
  width: 224px;
  border: 2px solid #3a3a3a;
  border-radius: 3px;
  background-color: #3a3a3a;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
`;
