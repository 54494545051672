import apiAxios from "../apiAxios";

interface Props {
  resetToken: string;
  password: string;
}

export default async function submitStudentPasswordReset(
  props: Props
): Promise<void> {
  await apiAxios.post("/password-reset/student/submit", props);
}
