import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import FormLayout from "./FormLayout";
import useInputState from "../utils/useInputState";
import isBlank from "shared/lib/utils/isBlank";
import UnderlinedInput from "./UnderlinedInput";
import Teacher from "shared/lib/types/Teacher";
import CategoryListInput from "./CategoryListInput";

interface Props {
  title: string;
  teacher: Teacher;
  hidePassword?: boolean;
  onSubmit(value: Value): any;
  canAssignProducts: boolean;
}

export interface Value {
  teacherId: number;
  firstName: string;
  lastName: string;
  email: string;
  assignedCategoryIds: number[];
  password?: string;
}

const EditTeacherForm: FC<Props> = props => {
  const { title, teacher, hidePassword, onSubmit, canAssignProducts, ...rest } = props;
  const [firstName, handleFirstNameChange] = useInputState(teacher.firstName);
  const [lastName, handleLastNameChange] = useInputState(teacher.lastName);
  const [email, handleEmailChange] = useInputState(teacher.email);
  const [assignedCategoryIds, setAssignedCategoryIds] = useState<number[]>(() =>
    teacher.assignedCategories
      ? teacher.assignedCategories.map(
          assignedCategory => assignedCategory.categoryId
        )
      : []
  );
  const [password, handlePasswordChange] = useInputState("");
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState("");
  const teacherId = teacher.id;

  const handleSubmit = useCallback(async () => {
    const value: Value = {
      teacherId,
      firstName,
      lastName,
      email,
      assignedCategoryIds
    };

    if (isBlank(firstName)) {
      throw new Error("First name is required.");
    }
    if (isBlank(lastName)) {
      throw new Error("Last name is required.");
    }
    if (isBlank(email)) {
      throw new Error("Email is required.");
    }
    if (!isBlank(password)) {
      if (password !== passwordConfirm) {
        throw new Error(`Passwords don't match.`);
      }
      value.password = password;
    }

    await onSubmit(value);
  }, [
    teacherId,
    firstName,
    lastName,
    email,
    password,
    assignedCategoryIds,
    passwordConfirm,
    onSubmit
  ]);

  return (
    <FormLayout
      {...rest}
      onSubmit={handleSubmit}
      title={title}
      submitButtonText="Done"
    >
      <UnderlinedInput
        placeholder="First Name"
        autoComplete="given-name"
        value={firstName}
        onChange={handleFirstNameChange}
      />
      <UnderlinedInput
        placeholder="Last Name"
        autoComplete="family-name"
        value={lastName}
        onChange={handleLastNameChange}
      />
      <UnderlinedInput
        placeholder="Email"
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
      />
      {!hidePassword && (
        <>
          <UnderlinedInput
            placeholder="Password"
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={handlePasswordChange}
          />
          <UnderlinedInput
            placeholder="Password confirm"
            type="password"
            autoComplete="new-password"
            value={passwordConfirm}
            onChange={handlePasswordConfirmChange}
          />
        </>
      )}
      {canAssignProducts &&
        <>
          <h3>Assigned Products</h3>
          <CategoryListInput
              value={assignedCategoryIds}
              onChange={setAssignedCategoryIds}
          />
        </>
      }

    </FormLayout>
  );
};

export default styled(EditTeacherForm)`
  padding-top: 100px;

  > h3 {
    margin-bottom: 2rem;
  }

  ${CategoryListInput} {
    margin-bottom: 2rem;
  }
`;
