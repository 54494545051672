import React, { FC, MouseEventHandler, useEffect } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import Column from "./Column";

const defaultColor = "rgba(0, 0, 0, 0.7)";

/**
 * Used to keep track of the number of overlays open that need the body scrollbars hidden.
 * When this number drops to `0`, the body scroll styles are reset to allow scrolling.
 */
let hideBodyScrollbarCount = 0;

export interface Props {
  /**
   * Whether or not to hide the root scrollbar on mount.
   */
  hideBodyScrollbar?: boolean;

  /**
   * Whether or not to used fixed positioning.
   */
  fixed?: boolean;

  /**
   * Whether or not to render the overlay directly inside the `body`.
   */
  inRoot?: boolean;

  /**
   * Root element to render the overlay into. Defaults to `document.body`.
   */
  rootElement?: HTMLElement;

  /**
   * The background color of the overlay. Defaults to `rgba(255, 255, 255, 0.7)`.
   */
  color?: string;

  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Overlay: FC<Props> = props => {
  const { inRoot, hideBodyScrollbar, color, rootElement, ...rest } = props;

  useEffect(() => {
    if (hideBodyScrollbar) {
      hideBodyScrollbarCount += 1;
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "hidden";

      return () => {
        hideBodyScrollbarCount = Math.max(0, hideBodyScrollbarCount - 1);
        if (hideBodyScrollbarCount === 0) {
          document.body.style.overflowX = "hidden";
          document.body.style.overflowY = "auto";
        }
      };
    }
    return;
  }, [hideBodyScrollbar]);

  if (inRoot) {
    return ReactDOM.createPortal(
      <Column {...rest} data-overlay />,
      rootElement || document.body
    );
  } else {
    return <Column {...rest} data-overlay />;
  }
};

export function isOverlay(target: HTMLElement) {
  return target && target.dataset && target.dataset.overlay;
}

export default styled(Overlay)<Props>`
  position: ${props => (props.fixed ? "fixed" : "absolute")};
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;

  ${props => {
    const backgroundColor = props.color || defaultColor;
    return props.inRoot
      ? css`
          width: 100vw;
          height: 100vh;
          background-color: ${backgroundColor};
        `
      : css`
          width: 100%;
          height: 100%;
          background-color: ${backgroundColor};
        `;
  }}
`;
