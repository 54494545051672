import React, { FC, MouseEvent, useCallback, useState } from "react";
import styled from "styled-components/macro";
import uuid from "uuid/v4";
import replaceWhere from "shared/lib/utils/replaceWhere";
import {
  AssignmentQuestionPart,
  AssignmentQuestionPartKind
} from "shared/lib/types/AssignmentQuestion";
import Column from "./Column";
import Row from "./Row";
import XButton from "./XButton";
import MathInputPreview from "./MathInputPreview";
import FixedMathquill from "./FixedMathQuill";
import { MathKeys as mathSymbols } from "../utils/MathSymbols";
import chunk from "lodash.chunk";

const symbolRows = chunk(mathSymbols, 10);

export type Value = AssignmentQuestionPart[];

interface Props {
  value: Value;
  onChange(value: Value): any;
}

const MathInput: FC<Props> = props => {
  const { value, onChange, ...rest } = props;
  const [symbolMenuPartId, setSymbolMenuPartId] = useState<string | null>(null);

  const handlePartChange = useCallback(
    (partId: string, text: string) => {
      onChange(
        replaceWhere(
          value,
          part => part.id === partId,
          part => ({ ...part, value: text })
        )
      );
    },
    [onChange, value]
  );

  const handleRemovePartClick = useCallback(
    (partId: string) => {
      onChange(value.filter(part => part.id !== partId));
    },
    [onChange, value]
  );

  const HandleAddTextClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setSymbolMenuPartId(null);
      onChange([
        ...value,
        { kind: AssignmentQuestionPartKind.text, id: uuid(), value: "" }
      ]);
    },
    [onChange, value]
  );

  const HandleAddLatexClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setSymbolMenuPartId(null);
      onChange([
        ...value,
        { kind: AssignmentQuestionPartKind.latex, id: uuid(), value: "" }
      ]);
    },
    [onChange, value]
  );

  const handleMathSymbolClick = useCallback(
    (valuePart: AssignmentQuestionPart, symbolLatex: string) => {
      handlePartChange(valuePart.id, valuePart.value + symbolLatex);
      setSymbolMenuPartId(null);
    },
    [handlePartChange]
  );

  const handleCloseSymbolMenu = useCallback((event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setSymbolMenuPartId(null);
  }, []);

  return (
    <Column {...rest}>
      <MathInputPreview value={value} />
      {value.map(valuePart => (
        <InputRow>
          {valuePart.kind === AssignmentQuestionPartKind.latex ? (
            <>
              <FixedMathquill
                key={valuePart.id}
                latex={valuePart.value}
                onFocus={() => setSymbolMenuPartId(valuePart.id)}
                onChange={mathField => {
                  const value = mathField.latex();
                  handlePartChange(valuePart.id, value);
                }}
              />
              {symbolMenuPartId === valuePart.id && (
                <SymbolMenu>
                  <SymbolButtonColumn>
                    {symbolRows.map((row, i) => (
                      <SymbolButtonRow key={i}>
                        {row.map(symbol => (
                          <SymbolButton
                            key={symbol.name}
                            onClick={event => {
                              event.preventDefault();
                              event.stopPropagation();
                              handleMathSymbolClick(
                                valuePart,
                                symbol.latexCommand
                              );
                            }}
                          >
                            <img src={symbol.icon} alt={symbol.name} />
                          </SymbolButton>
                        ))}
                      </SymbolButtonRow>
                    ))}
                  </SymbolButtonColumn>
                  <SymbolMenuCloseButton onClick={handleCloseSymbolMenu} />
                </SymbolMenu>
              )}
            </>
          ) : (
            <input
              key={valuePart.id}
              value={valuePart.value}
              onFocus={() => setSymbolMenuPartId(null)}
              onChange={event =>
                handlePartChange(valuePart.id, event.currentTarget.value)
              }
            />
          )}
          <RemoveButtonContainer>
            <XButton onClick={() => handleRemovePartClick(valuePart.id)} />
          </RemoveButtonContainer>
          {valuePart.kind === AssignmentQuestionPartKind.latex ? (
            <TypeLabel color="#79bb43">equation</TypeLabel>
          ) : (
            <TypeLabel color="#4A90E2">text</TypeLabel>
          )}
        </InputRow>
      ))}
      <ButtonRow>
        <AddButton color="#4A90E2" onClick={HandleAddTextClick}>
          Add text
        </AddButton>
        <AddButton color="#79bb43" onClick={HandleAddLatexClick}>
          Add equation
        </AddButton>
      </ButtonRow>
    </Column>
  );
};

export default styled(MathInput)`
  width: 535px;
  max-width: 100%;
  margin: 20px 0 20px 0;
`;

const RemoveButtonContainer = styled(Column)`
  justify-content: center;
`;

const InputRow = styled(Row)`
  align-items: center;
  margin-bottom: 5px;

  > .mq-editable-field,
  > input {
    flex: 1;
    margin: 0 0 0 0;
    font-size: inherit;
    border: 2px solid #3a3a3a;
    border-radius: 3px;
    min-height: 40px;
    padding-left: 20px;
  }

  > .mq-editable-field {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  input {
    height: 22px;
  }
`;

const TypeLabel = styled("div")<{ color: string }>`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translate(0, -50%);
  width: 50px;
  height: 14px;
  border-radius: 7px;
  font-size: 10px;
  text-align: center;
  color: white;
  transition: opacity 0.2s;
  background-color: ${props => props.color};

  &:hover {
    opacity: 0.2;
  }
`;

const ButtonRow = styled(Row)`
  padding-right: 24px;
  margin-top: 12px;
  position: relative;
`;

const AddButton = styled("button")`
  width: 120px;
  height: 30px;
  color: white;
  border: none;
  background-color: ${props => props.color};
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }
`;

const SymbolMenu = styled(Row)`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 100%);
  height: 110px;
  align-items: center;
  background-color: #dbdbdb;
  z-index: 2;
  padding: 0 10px 0 10px;
  border-radius: 3px;
`;

const SymbolButtonColumn = styled(Column)``;

const SymbolButtonRow = styled(Row)`
  & + & {
    margin-top: 10px;
  }
`;

const SymbolButton = styled("button")`
  height: 40px;
  width: 40px;
  border: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #79bb43;
  border-radius: 3px;
  cursor: pointer;

  & + & {
    margin-left: 10px;
  }

  > img {
    max-height: 80%;
    width: 80%;
    object-fit: contain;
  }
`;

const SymbolMenuCloseButton = styled(XButton)`
  margin-left: 10px;
  color: white;
`;
