import { ParseError, parse } from "papaparse";

export async function parseImportCSV(
  file: File
): Promise<{ data: unknown[]; errors: ParseError[] }> {
  return new Promise((resolve, reject) => {
    parse(file, {
      complete: resolve, // Includes any errors in parsing the CSV rows
      error: reject, // Rejects for an error in reading the file
      header: true,
      transformHeader: (header) => header.trim(),
      transform: (value) => value.trim(),
      skipEmptyLines: true,
    });
  });
}
