import React, { FC, ComponentProps } from "react";
import styled from "styled-components/macro";
import TextButton from "./TextButton";

const MinusButton: FC<ComponentProps<typeof TextButton>> = props => {
  const { children, ...rest } = props;

  return (
    <TextButton {...rest}>
      <MinusIcon />
      {children}
    </TextButton>
  );
};

export default styled(MinusButton)`
  color: #333333;
  font-size: 18px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MinusIcon = styled("div")`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  flex-shrink: 0;
  background-color: #d8d8d8;
  position: relative;
  margin-right: 6px;

  &:after {
    content: "";
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    transform: translate(-50%, -50%);
    background-color: #111;
  }
`;
