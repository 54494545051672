import apiAxios from '../apiAxios';

interface Props {
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
}

export default async function updateAdmin(
  adminId: number,
  props: Props
): Promise<void> {
  await apiAxios.put(`/admins/${adminId}`, props);
}
