import sqrt from "../images/math_icons/square_root.png";
import exponent from "../images/math_icons/exponent.png";
import division from "../images/math_icons/divide.png";
import multiply from "../images/math_icons/multiply.png";
import infinity from "../images/math_icons/infinity.png";
import pi from "../images/math_icons/pi.png";
import degree from "../images/math_icons/degree.png";
import theta from "../images/math_icons/theta.png";
import nthroot from "../images/math_icons/nth_root.png";
import congruent from "../images/math_icons/congruent.png";
import less_than_equal from "../images/math_icons/less_than_equal.png";
import greater_than_equal from "../images/math_icons/greater_than_equal.png";
import lineSegment from "../images/math_icons/line-segment.png";
import line from "../images/math_icons/line.png";
import angle from "../images/math_icons/angle.png";
import delta from "../images/math_icons/delta.png";
import similar from "../images/math_icons/similar.png";
import plusMinus from "../images/math_icons/plus-minus.png";
import tilde from "../images/math_icons/tilde.png";

export const MathKeys = [
  {
    name: "line segment",
    icon: lineSegment,
    latexCommand: "\\overline{}"
  },
  {
    name: "line",
    icon: line,
    latexCommand: "\\overleftrightarrow{}"
  },
  {
    name: "angle",
    icon: angle,
    latexCommand: "\\angle"
  },
  {
    name: "delta",
    icon: delta,
    latexCommand: "\\Delta"
  },
  {
    name: "similar",
    icon: similar,
    latexCommand: "\\approx"
  },
  {
    name: "plus minus",
    icon: plusMinus,
    latexCommand: "\\pm"
  },
  {
    name: "tilde",
    icon: tilde,
    latexCommand: "~"
  },

  {
    name: "square root",
    icon: sqrt,
    latexCommand: "\\sqrt{}"
  },
  {
    name: "exponent",
    icon: exponent,
    latexCommand: "^{}"
  },
  {
    name: "nthroot",
    icon: nthroot,
    latexCommand: "\\sqrt[]{}"
  },
  {
    name: "multiply",
    icon: multiply,
    latexCommand: "\\times"
  },
  {
    name: "division",
    icon: division,
    latexCommand: "\\div"
  },
  {
    name: "less than equal to",
    icon: less_than_equal,
    latexCommand: "\\leq"
  },
  {
    name: "greater than equal to",
    icon: greater_than_equal,
    latexCommand: "\\geq"
  },
  {
    name: "pi",
    icon: pi,
    latexCommand: "\\pi"
  },
  {
    name: "infinity",
    icon: infinity,
    latexCommand: "\\infty"
  },
  {
    name: "congruent",
    icon: congruent,
    latexCommand: "\\cong"
  },
  {
    name: "theta",
    icon: theta,
    latexCommand: "\\theta"
  },
  {
    name: "degrees",
    icon: degree,
    latexCommand: "{}^{\\circ}"
  }
];
