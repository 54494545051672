import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import styled from "styled-components/macro";
import { navigate } from "@reach/router";
import Sketch from "shared/lib/SketchPad/Sketch";
import RouteProps from "../../RouteProps";
import StudentPage from "../../components/StudentPage";
import useAsyncEffect from "../../utils/useAsyncEffect";
import * as studentRoutes from "../../studentRoutes";
import getTakeFromTakeableId from "../../api/assignmentTakes/getTakeFromTakeableId";
import createAssignmentTake from "../../api/assignmentTakes/createAssignmentTake";
import IntroAssignmentSlide from "../../components/IntroAssignmentSlide";
import { DetailedAssignmentTake } from "shared/lib/types/AssignmentTake";
import AssignmentSlide, {
  AssignmentSlideType,
  isIntroSlide,
  isQuestionSlide
} from "shared/lib/types/AssignmentSlide";
import TextAreaAssignmentSlide, {
  Value as TextSlideValue
} from "../../components/TextAreaAssignmentSlide";
import DrawingAssignmentSlide, {
  Value as DrawingSlideValue
} from "../../components/DrawingAssignmentSlide";
import PracticeAssignmentSlide from "../../components/PracticeAssignmentSlide";
import { isTextareaQuestion } from "shared/lib/types/AssignmentQuestion";
import replaceWhere from "shared/lib/utils/replaceWhere";
import createOrUpdateQuestionResponse from "../../api/assignmentTakes/createOrUpdateQuestionResponse";
import indexSorter from "../../utils/indexSorter";
import isPracticeQuestion, {
  isNotPracticeQuestion
} from "../../utils/isPracticeQuestion";
import AssignmentSyncModal from "../../components/AssignmentSyncModal";
import wait from "../../utils/wait";
import { getAssignmentSlideName } from "../../utils/assignment/getAssignmentSlideName";
import { BLANK_PRACTICE_SKETCH, BLANK_SKETCH } from "../../constants/sketch/blankSketches";


interface Props extends RouteProps {
  studentId: number;
  takeableAssignmentId: string;
  slideIndex: string;
  reload(): any;
}

interface QuestionResponseDraft {
  id: number;
  saved: boolean;
  questionId: number;
  responseId: number | null;
  textResponse: string | null;
  drawingResponse: Sketch | null;
  images: Array<string | File>;
  timeSpent: number;
}

function getNextButtonText(currentSlide: AssignmentSlide, nextSlide: AssignmentSlide | null): string {
  const currentlyPracticing = isQuestionSlide(currentSlide) && currentSlide.question.isPractice;
  return !currentlyPracticing && nextSlide && isQuestionSlide(nextSlide) && nextSlide.question.isPractice
    ? 'Go to the practice problem'
    : 'Go to next question';
}

const StudentAssignmentPage: FC<Props> = props => {
  const { studentId, reload, children, ...rest } = props;
  const takeableAssignmentId = parseInt(props.takeableAssignmentId, 10);
  const slideIndex = parseInt(props.slideIndex, 10);
  const [saving, setSaving] = useState(false);
  const [take, setTake] = useState<DetailedAssignmentTake | null>(null);
  const slides = useMemo(() => (take ? getTakeSlides(take) : null), [take]);
  const slideCount = slides ? slides.length : 0;
  const slide: AssignmentSlide | null = (slides && slides[slideIndex]) || null;
  const [responseDrafts, setResponseDrafts] = useState<QuestionResponseDraft[]>(
    []
  );
  const responseDraft =
    slide && isQuestionSlide(slide)
      ? responseDrafts.find(draft => draft.questionId === slide.question.id)
      : null;

  useAsyncEffect(
    async isCancelled => {
      let take = await getTakeFromTakeableId(takeableAssignmentId);
      if (!take) {
        take = await createAssignmentTake(takeableAssignmentId);
      }
      // If the take is already completed, redirect to the review page.
      if (take.completedAt) {
        navigate(studentRoutes.assignmentReview(take.takeableAssignmentId), {
          replace: true
        });
        return;
      }
      if (!isCancelled()) {
        const takeResponseDrafts = getTakeResponseDrafts(take);
        setResponseDrafts(takeResponseDrafts);
        setTake(take);
      }
    },
    [takeableAssignmentId]
  );

  // If the slide index is out of bounds, redirect to the nearest valid slide
  useEffect(() => {
    if (!slides) {
      return;
    }
    if (slideIndex < 0) {
      navigate(studentRoutes.assignment(takeableAssignmentId), {
        replace: true
      });
    } else if (slideIndex >= slides.length) {
      navigate(
        studentRoutes.assignmentSlide(takeableAssignmentId, slides.length - 1),
        { replace: true }
      );
    }
  }, [takeableAssignmentId, slides, slideIndex]);

  // Save response drafts automatically after they change (debounced)
  useEffect(() => {
    let cancelled = false; // Used to avoid updating state if a more recent save is taking place

    async function saveResponses() {
      if (!take || saving) {
        return;
      }
      console.time("Saved responses");

      setSaving(true);
      let changed = false; // used to avoid unnecessarily state changes

      const savedResponseDrafts = await Promise.all(
        responseDrafts.map(async draft => {
          if (draft.saved) {
            return draft; // ignore drafts that are already saved
          }
          changed = true;

          while (true) {
            // Loop until it saves successfully
            try {
              const {
                responseId,
                images
              } = await createOrUpdateQuestionResponse({
                takeId: take.id,
                questionId: draft.questionId,
                drawingResponse: draft.drawingResponse,
                textResponse: draft.textResponse,
                images: draft.images,
                timeSpent: draft.timeSpent
              });

              return {
                ...draft,
                responseId,
                images,
                saved: true
              };
            } catch (error) {
              console.error("Save failed", error);
              await wait(1000);
            }
          }
        })
      );

      setSaving(false);
      if (changed && !cancelled) {
        setResponseDrafts(savedResponseDrafts);
      }

      console.timeEnd("Saved responses");
    }

    const timeout = setTimeout(saveResponses, 1000);

    return () => {
      cancelled = true;
      clearTimeout(timeout);
    };
  }, [take, responseDrafts, saving]);

  useEffect(() => {
    if (!responseDraft) {
      return;
    }
    const startTime = Date.now();
    const initialTimeSpent = responseDraft.timeSpent;

    const timeout = setTimeout(updateTimeSpent, 3000);

    function updateTimeSpent() {
      if (!responseDraft) {
        return;
      }
      const now = Date.now();
      const elapsed = now - startTime;
      const timeSpent = initialTimeSpent + elapsed;

      setResponseDrafts(drafts =>
        replaceWhere(
          drafts,
          draft => draft.id === responseDraft.id,
          draft => ({ ...draft, timeSpent, saved: false })
        )
      );
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [responseDraft]);

  const handleTextResponseChange = useCallback(
    async (draft: QuestionResponseDraft, value: TextSlideValue) => {
      const { textResponse, images } = value;
      const updatedDraft = { ...draft, textResponse, images, saved: false };
      setResponseDrafts(drafts =>
        replaceWhere(
          drafts,
          other => other.id === draft.id,
          () => updatedDraft
        )
      );
    },
    []
  );

  const handleDrawingResponseChange = useCallback(
    (draft: QuestionResponseDraft, value: DrawingSlideValue) => {
      const { images, sketch } = value;
      const updatedDraft = {
        ...draft,
        drawingResponse: sketch,
        images,
        saved: false
      };

      setResponseDrafts(drafts =>
        replaceWhere(
          drafts,
          other => other.id === draft.id,
          () => updatedDraft
        )
      );
    },
    []
  );

  // Save the current response when the next button is clicked
  const handleNextClick = useCallback(
    async (nextPath: string) => {
      if (!responseDraft || !take) {
        navigate(nextPath);
        return;
      }
      const { responseId, images } = await createOrUpdateQuestionResponse({
        takeId: take.id,
        questionId: responseDraft.questionId,
        drawingResponse: responseDraft.drawingResponse,
        textResponse: responseDraft.textResponse,
        images: responseDraft.images,
        timeSpent: responseDraft.timeSpent
      });
      setResponseDrafts(responseDrafts =>
        replaceWhere(
          responseDrafts,
          draft => draft === responseDraft,
          draft => ({
            ...draft,
            responseId,
            images
          })
        )
      );
      navigate(nextPath);
    },
    [responseDraft, take]
  );

  const segmentNames = useMemo(() => {
    if (!slides) {
      return [];
    }
    return slides.map(getAssignmentSlideName);
  }, [slides]);

  if (!slides || !slide) {
    // Loading
    return <StudentPage {...rest} reload={reload} />;
  }

  const nextSlide = slides[slideIndex + 1] ?? null;
  const nextSlideUrl =
    slideIndex < slideCount - 1
      ? studentRoutes.assignmentSlide(takeableAssignmentId, slideIndex + 1)
      : studentRoutes.assignmentReview(takeableAssignmentId);
  let slideContent;
  const assessmentSetProgress = {
    currentSet: (slide?.set.index ?? 0) + 1,
    totalSets: take?.takeableAssignment.assignment.sets.length ?? 0
  }

  if (isIntroSlide(slide)) {
    const { set } = slide;
    slideContent = (
      <IntroAssignmentSlide
        key={slideIndex}
        takeableAssignmentId={takeableAssignmentId}
        slideCount={slides.length}
        slideIndex={slideIndex}
        buttonLink={nextSlideUrl}
        buttonText="Answer questions about this example"
        assignmentImageSrc={set.exampleImage}
        character={set.character}
        isExampleCorrect={set.isExampleCorrect}
        segmentNames={segmentNames}
        assessmentSetProgress={assessmentSetProgress}
      />
    );
  } else if (isTextareaQuestion(slide.question)) {
    const { set, question } = slide;
    const responseDraft = responseDrafts.find(
      draft => draft.questionId === question.id
    ) as QuestionResponseDraft;

    slideContent = (
      <TextAreaAssignmentSlide
        key={slideIndex}
        takeableAssignmentId={takeableAssignmentId}
        slideCount={slides.length}
        slideIndex={slideIndex}
        buttonLink={nextSlideUrl}
        value={{
          textResponse: responseDraft.textResponse || "",
          images: responseDraft.images
        }}
        question={question.questionText}
        onChange={value => handleTextResponseChange(responseDraft, value)}
        title={
          question.isPractice
            ? `Now complete your own.`
            : `Question ${slide.questionIndex + 1} of ${set.questions.length -
            1}`
        }
        buttonText={getNextButtonText(slide, nextSlide)}
        assignmentImageSrc={set.exampleImage}
        onNextClick={handleNextClick}
        segmentNames={segmentNames}
        assessmentSetProgress={assessmentSetProgress}
      />
    );
  } else if (slide.question.isPractice) {
    const { set, question } = slide;
    const responseDraft = responseDrafts.find(
      draft => draft.questionId === question.id
    ) as QuestionResponseDraft;

    slideContent = (
      <PracticeAssignmentSlide
        key={slideIndex}
        takeableAssignmentId={takeableAssignmentId}
        slideCount={slides.length}
        slideIndex={slideIndex}
        buttonLink={nextSlideUrl}
        value={{
          sketch: responseDraft.drawingResponse || BLANK_PRACTICE_SKETCH,
          images: responseDraft.images
        }}
        isExampleCorrect={set.isExampleCorrect}
        question={question.questionText}
        onChange={value => handleDrawingResponseChange(responseDraft, value)}
        title={`Now complete your own.`}
        buttonText={
          slideIndex === slides.length - 1
            ? "Review your work"
            : `Begin set ${set.index + 2}`
        }
        assignmentImageSrc={set.exampleImage}
        backgroundImage={question.questionImage}
        onNextClick={handleNextClick}
        segmentNames={segmentNames}
        assessmentSetProgress={assessmentSetProgress}
      />
    );
  } else {
    const { set, question } = slide;
    const responseDraft = responseDrafts.find(
      draft => draft.questionId === question.id
    ) as QuestionResponseDraft;

    slideContent = (
      <DrawingAssignmentSlide
        key={slideIndex}
        takeableAssignmentId={takeableAssignmentId}
        slideCount={slides.length}
        slideIndex={slideIndex}
        buttonLink={nextSlideUrl}
        value={{
          sketch: responseDraft.drawingResponse || BLANK_SKETCH,
          images: responseDraft.images
        }}
        question={question.questionText}
        onChange={drawingResponse =>
          handleDrawingResponseChange(responseDraft, drawingResponse)
        }
        title={
          question.isPractice
            ? `Now complete your own.`
            : `Question ${slide.questionIndex + 1} of ${set.questions.length -
            1}`
        }
        buttonText={getNextButtonText(slide, nextSlide)}
        assignmentImageSrc={set.exampleImage}
        backgroundImage={question.questionImage}
        onNextClick={handleNextClick}
        segmentNames={segmentNames}
        assessmentSetProgress={assessmentSetProgress}
      />
    );
  }

  const assignmentIndicator = slide && take && (
    <AssignmentName>
      {take.takeableAssignment.assignment.title}
    </AssignmentName>
  );

  return (
    <StudentPage {...rest} reload={reload}>
      <AssignmentSyncModal />
      {assignmentIndicator}
      {slideContent}
    </StudentPage>
  );
};

export default styled(StudentAssignmentPage)``;

function getTakeSlides(take: DetailedAssignmentTake): AssignmentSlide[] {
  const slides: AssignmentSlide[] = [];
  const sortedSets = [...take.takeableAssignment.assignment.sets].sort(
    indexSorter
  );

  for (const set of sortedSets) {
    const practiceQuestion = set.questions.find(isPracticeQuestion);
    const nonPracticeQuestions = set.questions.filter(isNotPracticeQuestion);
    const sortedQuestions = nonPracticeQuestions.sort(indexSorter);

    if (practiceQuestion) {
      sortedQuestions.push(practiceQuestion);
    }

    slides.push({
      type: AssignmentSlideType.intro,
      set
    });

    let i = 0;
    for (const question of sortedQuestions) {
      slides.push({
        type: AssignmentSlideType.question,
        set,
        question,
        questionIndex: i
      });
      i++;
    }
  }

  return slides;
}

function getTakeResponseDrafts(
  take: DetailedAssignmentTake
): QuestionResponseDraft[] {
  const responseDrafts: QuestionResponseDraft[] = [];
  const { responses } = take;
  const idOffset = Date.now();
  const sortedSets = [...take.takeableAssignment.assignment.sets].sort(
    indexSorter
  );

  let i = 0;
  for (const set of sortedSets) {
    const sortedQuestions = [...set.questions].sort(indexSorter);
    for (const question of sortedQuestions) {
      const existingResponse = (responses || []).find(
        response => response.questionId === question.id
      );

      if (existingResponse) {
        responseDrafts.push({
          id: idOffset + i,
          saved: true,
          responseId: existingResponse.id,
          questionId: question.id,
          textResponse: existingResponse.textResponse,
          drawingResponse: existingResponse.drawingResponse,
          images: existingResponse.images,
          timeSpent: existingResponse.timeSpent
        });
      } else {
        responseDrafts.push({
          id: idOffset + i,
          saved: true,
          responseId: null,
          questionId: question.id,
          textResponse: null,
          drawingResponse: null,
          images: [],
          timeSpent: 0
        });
      }
      i++;
    }
  }

  return responseDrafts;
}

const AssignmentName = styled("div")`
  color: #4a4a4a;
  font-size: 1rem;
  font-weight: 900;
  text-align: left;
  line-height: 15px;
  margin-top: -30px;
  padding-left: 1.25rem;
  padding-bottom: 0.75rem;
`;
