import Unit from "shared/lib/types/Unit";
import apiAxios from "../apiAxios";

interface Props {
  name: string;
  categoryId: number | null;
}

export default async function createUnit(props: Props): Promise<Unit> {
  const response = await apiAxios.post("/units", props);
  return response.data;
}
