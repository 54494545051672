import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import AssignmentCharacter, {
  correctAssignmentCharacters,
  incorrectAssignmentCharacters
} from "shared/lib/types/AssignmentCharacter";
import AssignmentCharacterImage from "./AssignmentCharacterImage";
import Row from "./Row";
import Column from "./Column";
import RadioCircle from "./RadioCircle";

interface Props {
  isCorrect: boolean;
  value: AssignmentCharacter | null;
  onChange(value: AssignmentCharacter | null): any;
}

const AssignmentCharacterInput: FC<Props> = props => {
  const { isCorrect, value, onChange, ...rest } = props;
  const displayedCharacters = isCorrect
    ? correctAssignmentCharacters
    : incorrectAssignmentCharacters;

  const handleCharacterClick = useCallback(
    (character: AssignmentCharacter | null) => {
      onChange(character === value ? null : character);
    },
    [onChange, value]
  );

  return (
    <Column {...rest}>
      <Title>Choose a Character (optional)</Title>
      <CharactersRow>
        {displayedCharacters.map(character => (
          <CharacterColumn
            key={character}
            onClick={() => handleCharacterClick(character)}
          >
            <AssignmentCharacterImage character={character} />
            <SelectedCircle checked={value === character} />
          </CharacterColumn>
        ))}
      </CharactersRow>
    </Column>
  );
};

export default styled(AssignmentCharacterInput)`
  margin-bottom: 2rem;
`;

const Title = styled("h3")`
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
`;

const CharactersRow = styled(Row)`
  height: 110px;
`;

const CharacterColumn = styled(Column)`
  align-items: center;
  justify-content: flex-end;
  width: 64px;
  cursor: pointer;

  & + & {
    margin-left: 2rem;
  }
`;

const SelectedCircle = styled(RadioCircle).attrs({
  borderColor: "#333333",
  checkedColor: "#D8D8D8"
})`
  margin: 0.5rem 0 0.25rem 0;
`;
