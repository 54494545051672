import { useEffect } from "react";

/**
 * Hook for running a function when the current component unmounts.
 *
 * Example:
 *
 * ```ts
 * useUnmount(() => {
 *   doCleanupStuff();
 * })
 * ```
 */
export default function useUnmount(onUnmount: () => any): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onUnmount, []);
}
