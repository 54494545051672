import React, { FC, useState } from "react";
import { Router, Redirect } from "@reach/router";
import Admin from "shared/lib/types/Admin";
import AdminDashboardPage from "../pages/admin/AdminDashboardPage";
import AdminTeachersPage from "../pages/admin/AdminTeachersPage";
import useAsyncEffect from "../utils/useAsyncEffect";
import getCurrentAdmin from "../api/admins/getCurrentAdmin";
import AdminAssignmentsPage from "../pages/admin/AdminAssignmentsPage";
import AdminDataPage from "../pages/admin/AdminDataPage";
import AdminDrawingPreviewPage from "../pages/admin/AdminDrawingPreviewPage";
import AdminRosterImportPage from "../pages/admin/AdminRosterImportPage";

interface Props {
  adminId: number;
  reload(): Promise<void>;
}

const AdminRoot: FC<Props> = props => {
  const { adminId, reload } = props;
  const [admin, setAdmin] = useState<Admin | null>(null);

  useAsyncEffect(
    async isCancelled => {
      const currentAdmin = await getCurrentAdmin();
      if (!isCancelled()) {
        setAdmin(currentAdmin);
      }
    },
    [adminId]
  );

  if (!admin) {
    // Loading...
    return null;
  }

  return (
    <Router>
      <AdminDashboardPage
        default
        path="/"
        admin={admin}
        setAdmin={setAdmin}
        reload={reload}
      />
      <AdminTeachersPage
        path="/teachers/*"
        admin={admin}
        setAdmin={setAdmin}
        reload={reload}
      />
      <AdminAssignmentsPage
        path="/assignments/*"
        admin={admin}
        setAdmin={setAdmin}
        reload={reload}
      />
      <AdminDataPage
        path="/data"
        admin={admin}
        setAdmin={setAdmin}
        reload={reload}
      />
      {/* Keep for legacy URLs */}
      <Redirect
        from="/drawing/:responseId"
        to="/preview/:responseId"
      />
      <AdminDrawingPreviewPage
        path="/preview/:responseId"
        responseId=""
        admin={admin}
        setAdmin={setAdmin}
        reload={reload}
      />
      <AdminRosterImportPage
        path="/roster-import"
        admin={admin}
        setAdmin={setAdmin}
        reload={reload}
      />
    </Router>
  );
};

export default AdminRoot;
