import styled from "styled-components/macro";
import imageSrc from "../images/completed_icon.png";

const CompletedIcon = styled("img").attrs({ src: imageSrc, alt: "Completed" })`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export default CompletedIcon;
