import styled from 'styled-components/macro';
import TextButton from './TextButton';

const IconButton = styled(TextButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default IconButton;
