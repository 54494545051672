import apiAxios from '../apiAxios';

interface Options {
  inviteToken: string;
  password: string;
}

export default async function acceptTeacherInvitation(
  options: Options
): Promise<void> {
  const { inviteToken, password } = options;
  await apiAxios.post('/invitations/teacher/accept', { inviteToken, password });
}
