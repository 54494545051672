import { useEffect } from 'react';

type IsCancelled = () => boolean;
type Body = (isCancelled: IsCancelled) => Promise<any>;

/**
 * Like `useEffect`, but allows an async function to be passed.
 * The body function can call its first argument to check whether
 * not the component has unmounted before updating state.
 *
 * Example:
 *
 * ```ts
 * useAsyncEffect(async isCancelled => {
 *   const post = await getPost(postId);
 *
 *   if(!isCancelled()) {
 *     setPost(post);
 *   }
 * }, [postId]);
 * ```
 */
export default function useAsyncEffect(
  body: Body,
  dependencies: ReadonlyArray<any> | undefined
): void {
  useEffect(() => {
    let cancelled = false;

    body(() => cancelled).catch(handleError);

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

function handleError(error: any) {
  console.error(error);
}
