import styled from 'styled-components/macro';

const AssignmentSlideTitle = styled('h1')`
  color: #4a90e2;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.1rem;
  margin-bottom: 0.25rem;
`;

export default AssignmentSlideTitle;
