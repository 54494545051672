import apiAxios from "../apiAxios";

interface Props {
  categoryId: number;
  name: string;
}

export default async function updateAssignmentCategory(
  props: Props
): Promise<void> {
  const { categoryId, ...rest } = props;
  await apiAxios.put(`/categories/${categoryId}`, rest);
}
