export const dashboard = "/";
export const completedAssignments = "/completed";

export const assignmentSlide = (
  takeableAssignmentId: number,
  slideIndex: number
) => `/assignment/${takeableAssignmentId}/${slideIndex}`;

export const assignment = (takeableAssignmentId: number) =>
  assignmentSlide(takeableAssignmentId, 0);

export const assignmentReview = (takeableAssignmentId: number) =>
  `/assignment/${takeableAssignmentId}/review`;

export const editProfile = "/profile";
