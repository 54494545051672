import React, { FC, MouseEventHandler } from "react";
import styled, { keyframes, css } from "styled-components/macro";
import arrowImageSrc from "../images/white_chevron.png";
import Row from "./Row";
import getS3ImageUrl from "../utils/getS3ImageUrl";

interface Props {
  correct: boolean;
  open: boolean;
  imageSrc: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const ExampleImagePullout: FC<Props> = props => {
  const { open, correct, imageSrc, ...rest } = props;
  const imageUrl = getS3ImageUrl(imageSrc);

  return (
    <Row {...rest}>
      <ButtonText>
        Click to review
        <br />
        the student’s
        <br />
        {correct ? "correct" : "incorrect"} work.
      </ButtonText>

      {open && imageUrl && <ImageContainer imageSrc={imageUrl} />}

      <ArrowImage flipped={open} />
    </Row>
  );
};

export default styled(ExampleImagePullout)<Props>`
  height: 70px;
  transition: width 0.5s;
  width: ${getWidth}px;
  background-color: ${getBackgroundColor};
  cursor: pointer;
`;

const ButtonText = styled("div")`
  color: #ffffff;
  width: 136px;
  font-size: 1rem;
  font-weight: 900;
  line-height: 18px;
  flex-shrink: 0;
  height: 100%;
  padding: 0.5rem 0 0.5rem 1.5rem;
`;

const ImageContainer = styled(Row)<{ imageSrc: string }>`
  ${props => css`background-image: url('${props.imageSrc}');`}
  height: 457px;
  width: 364px;
  margin-left: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
  opacity: 0;
  animation-duration: 0.2s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-name: ${keyframes`
    from {
      opacity: 0;
    } to {
      opacity: 1;
    }
  `};
`;

const ArrowImage = styled("img").attrs({
  src: arrowImageSrc,
  alt: "toggle example image"
})<{ flipped: boolean }>`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translate(0, -50%)
    ${props => (props.flipped ? "rotate(180deg)" : "")};
  transition: transform 0.5s;
  width: 30px;
  height: 60px;
  flex-shrink: 0;
`;

function getWidth(props: Props) {
  if (props.open) {
    return 740;
  }
  return 224;
}

function getBackgroundColor(props: Props) {
  if (props.correct) {
    return "#7EBA4F";
  }
  return "#E02729";
}
