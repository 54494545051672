import apiAxios from "../apiAxios";

interface Props {
  firstName: string;
  lastName: string;
  password?: string;
}

/**
 * Used when a student wants to update their own info.
 */
export default async function updateStudentProfile(
  studentId: number,
  props: Props
): Promise<void> {
  await apiAxios.put(`/students/${studentId}/profile`, props);
}
