import styled from 'styled-components/macro';

export default styled("div")<{ faded?: boolean; flipped?: boolean }>`
  position: absolute;
  right: 20px;
  top: ${props => (props.flipped ? 50 : 30)}%;
  transform: translate(0, -20%)
    rotate(${props => (props.flipped ? 45 : 225)}deg);
  border-left: 2px solid black;
  border-top: 2px solid black;
  width: 8px;
  height: 8px;
  z-index: 11;
  opacity: ${props => (props.faded ? 0.2 : 1)};
`;