import React, { FC } from "react";
import { Tool } from "../constants/tool";
import ArrowIcon from './tool-icons/ArrowIcon';
import CircleIcon from './tool-icons/CircleIcon';
import EraserIcon from './tool-icons/EraserIcon';
import LineIcon from './tool-icons/LineIcon';
import PenIcon from './tool-icons/PenIcon';
import RectangleIcon from './tool-icons/RectangleIcon';
import TextIcon from './tool-icons/TextIcon';
import UndoIcon from './tool-icons/UndoIcon';
import MoveIcon from "./tool-icons/MoveIcon";
import DuplicateIcon from "./tool-icons/DuplicateIcon";
import ShapesIcon from "./tool-icons/ShapesIcon";
import TriangleIcon from "./tool-icons/TriangleIcon";
import styled from "styled-components";
import RedoIcon from "./tool-icons/RedoIcon";
import TrashIcon from "./tool-icons/TrashIcon";
import ImageIcon from "./tool-icons/ImageIcon";
import MathEquationIcon from "./tool-icons/MathEquationIcon";
import CurveIcon from "./tool-icons/CurveIcon";

const ToolIcon: FC<{ tool: Tool }> = ({ tool }) => {
    switch (tool) {
        case Tool.PICK:
            return <MoveIcon />;
        case Tool.RECTANGLE:
            return <RectangleIcon />;
        case Tool.CIRCLE:
            return <CircleIcon />;
        case Tool.TRIANGLE:
            return <TriangleIcon />;
        case Tool.FREEHAND:
            return <PenIcon />;
        case Tool.LINE:
            return <LineIcon />;
        case Tool.ARROW:
            return <ArrowIcon />;
        case Tool.TEXT:
            return <TextIcon />;
        case Tool.ERASER:
            return <EraserIcon />;
        case Tool.UNDO:
            return <UndoIcon />;
        case Tool.DUPLICATE:
            return <DuplicateIcon />;
        case Tool.SHAPES:
            return <ShapesIcon />;
        case Tool.REDO:
            return <RedoIcon />;
        case Tool.TRASH:
            return <TrashIcon />;
        case Tool.IMAGE:
            return <ImageIcon />;
        case Tool.MATH_EQUATION:
            return <MathEquationIcon />;
        case Tool.CURVE:
            return <CurveIcon />;
        default:
            return <PenIcon />;
    }
}

export default styled(ToolIcon)``; 