import styled from "styled-components";

/**
 * Plain text button with all default button styles removed.
 */
const BlankButton = styled("button")`
  border: none;
  color: inherit;
  font-size: 100%;
  font-family: inherit;
  font-weight: inherit;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  display: inline-block;
  width: auto;
  height: auto;
  text-align: left;
  background-color: inherit;
  font-family: inherit;
  box-sizing: border-box;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export default BlankButton;