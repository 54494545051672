import React, { FC, useState } from "react";
import { Router } from "@reach/router";
import Teacher from "shared/lib/types/Teacher";
import useAsyncEffect from "../utils/useAsyncEffect";
import getCurrentTeacher from "../api/teachers/getCurrentTeacher";
import TeacherClassesPage from "../pages/teacher/TeacherClassesPage";
import TeacherTakeReviewPage from "../pages/teacher/TeacherTakeReviewPage";
import TeacherTakePresentPage from "../pages/teacher/TeacherTakePresentPage";
import TeacherHomePage from "../pages/teacher/TeacherHomePage";

interface Props {
  teacherId: number;
  reload(): Promise<void>;
}

const TeacherRoot: FC<Props> = ({ teacherId, reload }) => {
  const [teacher, setTeacher] = useState<Teacher | null>(null);

  useAsyncEffect(
    async isCancelled => {
      const currentTeacher = await getCurrentTeacher();
      if (!isCancelled()) {
        setTeacher(currentTeacher);
      }
    },
    [teacherId]
  );

  if (!teacher) {
    // Loading...
    return null;
  }

  return (
    <Router>
      <TeacherHomePage
        default
        path="/"
        teacher={teacher}
        setTeacher={setTeacher}
        reload={reload}
      />
      <TeacherClassesPage
        path="/classes"
        teacher={teacher}
        setTeacher={setTeacher}
        reload={reload}
      />
      <TeacherClassesPage
        path="/classes/:classroomId/*"
        teacher={teacher}
        setTeacher={setTeacher}
        reload={reload}
      />
      <TeacherTakePresentPage
        path="/students/:studentId/takes/:takeId/present"
        takeId=""
        studentId=""
        teacher={teacher}
        setTeacher={setTeacher}
        reload={reload}
      />
      <TeacherTakeReviewPage
        path="/students/:studentId/takes/:takeId"
        takeId=""
        studentId=""
        teacher={teacher}
        setTeacher={setTeacher}
        reload={reload}
      />
    </Router>
  );
};

export default TeacherRoot;
