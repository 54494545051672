import React, { FC, ReactNode, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Column from "./Column";
import chevronSrc from "../images/blue_chevron_down.png";

interface Props {
  title: ReactNode;
  comment: string;
  grade: string;
  gradeLabel: ReactNode;
  hideGrade: boolean;
}

const TeacherFeedback: FC<Props> = props => {
  const [open, setOpen] = useState(false);
  const { title, grade, gradeLabel, comment, hideGrade, ...rest } = props;

  const handleHeaderClick = useCallback(() => {
    setOpen(open => !open);
  }, []);

  return (
    <Column {...rest}>
      <Header onClick={handleHeaderClick} open={open}>
        <Title>{title}</Title>
        <ArrowImage flipped={open} />
      </Header>
      {open && (
        <Content>
          <MessageLabel>Teacher Comments:</MessageLabel>
          <TeacherResponse>{comment}</TeacherResponse>
          {!hideGrade && (
            <>
              <MessageLabel>{gradeLabel}</MessageLabel>
              <TeacherResponse>{grade}</TeacherResponse>
            </>
          )}
        </Content>
      )}
    </Column>
  );
};

export default styled(TeacherFeedback)`
  width: 401px;
`;

const Header = styled("button")<{ open: boolean }>`
  height: 30px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 0;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: ${props => (props.open ? "7px 7px 0 0" : "7px 7px 7px 7px")};
  background-color: #4a90e2;
  cursor: pointer;
`;

const ArrowImage = styled("img").attrs({ alt: "", src: chevronSrc })<{
  flipped: boolean;
}>`
  height: 12px;
  width: 22px;
  margin-left: 16px;
  transform: rotate(${props => (props.flipped ? "180deg" : "0deg")});
`;

const Title = styled("h3")`
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
`;

const Content = styled(Column)`
  border: 2px solid #4a90e2;
  padding: 25px 32px 25px 32px;
`;

const MessageLabel = styled("h4")`
  color: #4a90e2;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 8px;

  * + & {
    margin-top: 24px;
  }
`;

const TeacherResponse = styled("p")`
  color: #000000;
  font-size: 14px;
  line-height: 17px;
  padding-left: 20px;
`;
