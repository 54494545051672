import React, { FC, useCallback, MouseEvent, FormEvent } from "react";
import styled from "styled-components/macro";
import Uuid from "uuid/v4";
import Column from "./Column";
import getNumberText from "../utils/getNumberText";
import InputWindow from "./InputWindow";
import OutlinedInput from "./OutlinedInput";
import PracticeAssignmentStepForm, {
  Value as PracticeAssignmentStepFormValue,
  getDefaultValue as getPracticeAssignmentStepFormDefaultValue
} from "./PracticeAssignmentStepForm";
import AssignmentCharacter from "shared/lib/types/AssignmentCharacter";
import ImageInput from "./ImageInput";
import LabeledRadio from "./LabeledRadio";
import QuestionForm, {
  Value as QuestionFormValue,
  getDefaultValue as getQuestionFormDefaultValue
} from "./QuestionForm";
import replaceWhere from "shared/lib/utils/replaceWhere";
import RadioColumn from "./RadioColumn";
import AssignmentCharacterInput from "./AssignmentCharacterInput";
import Row from "./Row";
import MinusButton from "./MinusButton";
import PlusCircleButton from "./PlusCircleButton";
import TextButton from "./TextButton";
import downChevronSrc from "../images/down_chevron_circle.png";

interface Props {
  index: number;
  value: Value;
  canExpand: boolean;
  expanded: boolean;
  onExpandClick(): any;
  onChange(value: Value): any;
  onRemove(event: MouseEvent<HTMLButtonElement>): any;
}

export interface Value {
  id?: number;
  uuid: string;
  questions: QuestionFormValue[];
  learningTarget: string;
  isExampleCorrect: boolean | null;
  exampleImage: File | string | null;
  character: AssignmentCharacter | null;
}

export function getDefaultValue(): Value {
  return {
    questions: [
      getQuestionFormDefaultValue(),
      getPracticeAssignmentStepFormDefaultValue()
    ],
    uuid: Uuid(),
    learningTarget: "",
    isExampleCorrect: null,
    exampleImage: null,
    character: null
  };
}

const AssignmentSetForm: FC<Props> = props => {
  const {
    index,
    value,
    canExpand,
    expanded,
    onExpandClick,
    onChange,
    onRemove,
    ...rest
  } = props;
  const practiceQuestion = value.questions.find(
    question => question.isPractice
  );
  const nonPracticeQuestions = value.questions.filter(
    question => !question.isPractice
  );

  const handleLearningTargetChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const learningTarget = event.currentTarget.value;
      onChange({ ...value, learningTarget });
    },
    [onChange, value]
  );

  const handleCharacterChange = useCallback(
    (character: AssignmentCharacter | null) => {
      onChange({ ...value, character });
    },
    [onChange, value]
  );

  const handleCorrectClicked = useCallback(() => {
    onChange({ ...value, isExampleCorrect: true });
  }, [onChange, value]);

  const handleIncorrectClicked = useCallback(() => {
    onChange({ ...value, isExampleCorrect: false });
  }, [onChange, value]);

  const handleExampleImageChange = useCallback(
    (file: File | null) => {
      onChange({ ...value, exampleImage: file });
    },
    [onChange, value]
  );

  const handlePracticeChange = useCallback(
    (question: PracticeAssignmentStepFormValue) => {
      onChange({ ...value, questions: [...nonPracticeQuestions, question] });
    },
    [onChange, value, nonPracticeQuestions]
  );

  const handleQuestionChange = useCallback(
    (questionUuid: string, newQuestion: QuestionFormValue) => {
      const questions = replaceWhere(
        value.questions,
        other => other.uuid === questionUuid,
        other => ({ ...other, ...newQuestion })
      );

      onChange({ ...value, questions });
    },
    [onChange, value]
  );

  const handleAddQuestionClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onChange({
        ...value,
        questions: [...value.questions, getQuestionFormDefaultValue()]
      });
    },
    [onChange, value]
  );

  const handleRemoveQuestion = useCallback(
    (questionUuid: string, event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();

      onChange({
        ...value,
        questions: value.questions
          .filter(other => other.uuid !== questionUuid)
          .map((question, index) => ({ ...question, index }))
      });
    },
    [onChange, value]
  );

  const handleExpandButtonClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onExpandClick();
    },
    [onExpandClick]
  );

  return (
    <Column {...rest}>
      <TitleRow light={!canExpand}>
        {canExpand && (
          <ExpandButton onClick={handleExpandButtonClick}>
            <ExpandIcon flipped={expanded} />
          </ExpandButton>
        )}
        <Title>SET {getNumberText(index + 1).toUpperCase()}</Title>
        <MinusButton onClick={onRemove}>REMOVE SET</MinusButton>
      </TitleRow>
      {expanded && (
        <>
          <InputWindow title="TEACHER’S GUIDE: LEARNING TARGET">
            <OutlinedInput
              value={value.learningTarget}
              onChange={handleLearningTargetChange}
            />
          </InputWindow>
          <Steps>
            <ImageInput
              alwaysShowLabel
              label={
                <span>
                  A. Upload Image of Worked Example -{" "}
                  <a
                    download
                    href="https://serpinstitute.box.com/shared/static/j943le9v5qa8p9efo04y3zj0d871fpis.pptx"
                  >
                    download template
                  </a>
                </span>
              }
              value={value.exampleImage}
              onFileChange={handleExampleImageChange}
              alt="Example"
            />
            <ExampleTypeRadioGroup title="This is a (choose one):">
              <GrayLabeledRadio
                checked={value.isExampleCorrect === true}
                onClick={handleCorrectClicked}
              >
                Correct Example
              </GrayLabeledRadio>
              <GrayLabeledRadio
                checked={value.isExampleCorrect === false}
                onClick={handleIncorrectClicked}
              >
                Incorrect Example
              </GrayLabeledRadio>
            </ExampleTypeRadioGroup>
            {value.isExampleCorrect !== null && (
              <AssignmentCharacterInput
                isCorrect={value.isExampleCorrect}
                value={value.character}
                onChange={handleCharacterChange}
              />
            )}
            <QuestionTitle>B. Enter Questions for Students</QuestionTitle>

            <QuestionSection>
              <Questions>
                {nonPracticeQuestions.map((question, i) => (
                  <QuestionForm
                    key={question.uuid}
                    index={i}
                    value={question}
                    onChange={newQuestion =>
                      handleQuestionChange(question.uuid, newQuestion)
                    }
                    onRemove={event =>
                      handleRemoveQuestion(question.uuid, event)
                    }
                  />
                ))}
              </Questions>

              <AddQuestionButton onClick={handleAddQuestionClick}>
                ADD ANOTHER QUESTION
              </AddQuestionButton>
            </QuestionSection>
            {practiceQuestion && (
              <PracticeAssignmentStepForm
                index={2}
                value={practiceQuestion}
                onChange={handlePracticeChange}
              />
            )}
          </Steps>
        </>
      )}
    </Column>
  );
};

export default styled(AssignmentSetForm)``;

const TitleRow = styled(Row)<{ light: boolean }>`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  background-color: ${props => (props.light ? "white" : "#333")};
  width: calc(100% + 227px);
  margin-left: -83px;
  padding-left: 83px;
  padding-right: 39px;
  height: 40px;
  color: ${props => (props.light ? "#333" : "white")};

  ${MinusButton} {
    color: inherit;
  }
`;

const Title = styled("h2")`
  color: inherit;
  font-size: 22px;
  font-weight: 900;
`;

const Steps = styled(Column)``;

const QuestionTitle = styled("h5")`
  color: #333333;
  font-size: 18px;
  line-height: 23px;
  font-weight: 900;
  margin-bottom: 1.25rem;
`;

const QuestionSection = styled(Column)`
  padding-left: 1rem;
`;

const Questions = styled(Column)``;

const AddQuestionButton = styled(PlusCircleButton)`
  margin-bottom: 2rem;
  font-weight: 400;
`;

const ExampleTypeRadioGroup = styled(RadioColumn)`
  margin-bottom: 2rem;
`;

const GrayLabeledRadio = styled(LabeledRadio).attrs({
  borderColor: "#333333",
  checkedColor: "#D8D8D8"
})``;

const ExpandButton = styled(TextButton)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 300px;
  display: flex;
  align-items: center;
  padding-left: 42px;
  background-color: transparent;
`;

const ExpandIcon = styled("img").attrs({
  src: downChevronSrc,
  alt: "Expand"
})<{ flipped: boolean }>`
  width: 18px;
  height: 18px;
  transform: rotate(${props => (props.flipped ? "180deg" : "0deg")});
`;
