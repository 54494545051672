const env = process.env;

export const DEFAULT_TEACHER_USERNAME =
  env.REACT_APP_DEFAULT_TEACHER_USERNAME || '';
export const DEFAULT_TEACHER_PASSWORD =
  env.REACT_APP_DEFAULT_TEACHER_PASSWORD || '';

export const DEFAULT_ADMIN_USERNAME =
  env.REACT_APP_DEFAULT_ADMIN_USERNAME || '';
export const DEFAULT_ADMIN_PASSWORD =
  env.REACT_APP_DEFAULT_ADMIN_PASSWORD || '';

export const DEFAULT_STUDENT_USERNAME =
  env.REACT_APP_DEFAULT_STUDENT_USERNAME || '';
export const DEFAULT_STUDENT_PASSWORD =
  env.REACT_APP_DEFAULT_STUDENT_PASSWORD || '';
