import apiAxios from '../apiAxios';

interface Props {
  resetToken: string;
  password: string;
}

export default async function submitAdminPasswordReset(
  props: Props
): Promise<void> {
  await apiAxios.post('/password-reset/admin/submit', props);
}
