import React, { FC, ReactNode, useCallback } from "react";
import styled from "styled-components/macro";
import AddImageButton from "./AddImageButton";
import Label from "./Label";
import Column from "./Column";
import CircleX from "./CircleX";
import TextButton from "./TextButton";
import ExpandableImage from "./ExpandableImage";

interface Props {
  label?: ReactNode;
  buttonLabel?: ReactNode;
  minimal?: boolean;
  value: Array<File | string>;
  onFilesChange(files: Array<File | string>): any;
}

const MultiImageInput: FC<Props> = (props) => {
  const { label, value, buttonLabel, onFilesChange, minimal, ...rest } = props;

  const handleFileChange = useCallback(
    (files: Array<string | File>) => {
      if (files && files.length > 0) {
        onFilesChange([...value, ...files]);
      }
    },
    [value, onFilesChange]
  );

  const handleRemoveFileClick = useCallback(
    (file: File | string) => {
      onFilesChange(value.filter((other) => other !== file));
    },
    [value, onFilesChange]
  );

  return (
    <Column {...rest}>
      {label && <Label>{label}</Label>}
      <AddImageButton
        multi
        onFileChange={handleFileChange}
        minimal={minimal}
        label={buttonLabel}
      />
      <ImageList>
        {value.map((file) => {
          const fileName = typeof file === "string" ? file : file.name;

          return (
            <li key={fileName}>
              <ImageListButton>
                <CircleX onClick={() => handleRemoveFileClick(file)} />
                <ImageListPreviewImage previewable src={file} alt={fileName} />
              </ImageListButton>
            </li>
          );
        })}
      </ImageList>
    </Column>
  );
};

export default styled(MultiImageInput)`
  ${Label} {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  align-items: flex-end;
`;

const ImageList = styled("ul")`
  list-style: none;
  padding: 0 0 0 0;
  margin: 0.5rem 0 0 0;

  li {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
`;

const ImageListButton = styled(TextButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 900;

  > ${CircleX} {
    margin-right: 0.5rem;
  }

  & + & {
    margin-top: 0.5rem;
  }
`;

const ImageListPreviewImage = styled(ExpandableImage)`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;
