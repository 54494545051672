import React, { FC } from "react";
import styled from "styled-components/macro";

interface Props {}

const DragHandle: FC<Props> = props => {
  const { ...rest } = props;

  return (
    <div {...rest}>
      <div />
      <div />
      <div />
    </div>
  );
};

export default styled(DragHandle)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 3rem;

  > div {
    width: 17px;
    height: 1px;
    background-color: black;
  }

  > div + div {
    margin-top: 3px;
  }
`;
