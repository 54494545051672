import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import RouteProps from '../RouteProps';

const Page: FC<RouteProps> = props => {
  const { location, navigate, ...rest } = props;
  return <Column {...rest} />;
};

export default styled(Page)`
  min-height: 100vh;
`;
