import { ZwibblerClass } from "zwibbler/lib/zwibbler2";

export default function waitForZwibblerToLoad(): Promise<ZwibblerClass> {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if ("Zwibbler" in window) {
        clearInterval(interval);
        resolve(window["Zwibbler"]);
      } else {
        clearInterval(interval);
        reject(new Error("Unable to load sketchpad. Please reload the page."));
      }
    }, 100);
  });
}
