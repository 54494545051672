import apiAxios from "../apiAxios";

interface Props {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  assignedCategoryIds?: number[];
}

export default async function updateTeacher(
  teacherId: number,
  props: Props
): Promise<void> {
  await apiAxios.put(`/teachers/${teacherId}`, props);
}
