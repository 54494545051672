import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import { MdArrowBack } from "react-icons/md";
import TextButton from "./TextButton";
import hideInPrint from "../utils/hideInPrint";

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const BackArrowButton: FC<Props> = props => {
  return (
    <TextButton {...props}>
      <MdArrowBack size={26} />
      <span>Back</span>
    </TextButton>
  );
};

export default styled(BackArrowButton)`
  ${hideInPrint}
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin-left: 6px;
    margin-top: -4px;
  }
`;
