import React, { FC, ReactNode, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Row from "./Row";
import Column from "./Column";
import Chevron from "./Chevron";
import {useOnClickOutside} from "../utils/useOnClickOutside";

interface Props {
  label: ReactNode;
  options: Array<{ label: ReactNode; value: number }>;
  onChange(value: number): any;
  disabled?: boolean;
}

const Dropdown: FC<Props> = props => {
  const { label, options, onChange,  disabled = false, ...rest } = props;
  const [open, setOpen] = useState(false);

  const handleHeaderClick = useCallback(() => {
    if (options.length === 0 || disabled) {
      return;
    }
    setOpen(open => !open);
  }, [options, disabled]);

  const handleOptionClick = useCallback(
    (value: number) => {
      onChange(value);
      setOpen(false);
    },
    [onChange]
  );

  const onClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  const ref = useOnClickOutside<HTMLDivElement>(onClickOutside)

  return (
    <Column ref={ref} {...rest}>
      <Header onClick={handleHeaderClick}>
        {label}
        <Chevron faded={options.length === 0 || disabled} flipped={open} />
      </Header>
      {open && (
        <Menu>
          {options.map(option => (
            <Option
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </Option>
          ))}
        </Menu>
      )}
    </Column>
  );
};

export default styled(Dropdown)`
  min-width: 191px;
  position: relative;
  cursor: pointer;
`;

const Header = styled(Row)`
  color: #000;
  font-size: 14px;
  font-weight: 900;
  position: relative;
  padding-right: 40px;
`;


const Menu = styled(Column)`
  overflow: visible;
  width: calc(100% + 30px);
  background-color: #ececec;
  padding: 14px;
  position: absolute;
  bottom: 0;
  left: -15px;
  transform: translate(0, 100%);
  z-index: 11;
  border: 1px solid black;

  &::after {
    content: "";
    background-color: #ececec;
    border-top: 1px solid black;
    border-left: 1px solid black;
    transform: rotate(45deg);
    position: absolute;
    top: -12px;
    right: 27px;
    width: 21px;
    height: 21px;
    pointer-events: none;
  }
`;

const Option = styled(Row)`
  & + & {
    margin-top: 10px;
  }
`;
