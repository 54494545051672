import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { AssignmentQuestionPart } from "shared/lib/types/AssignmentQuestion";
import ZwibblerSketchpad from "./ZwibblerSketchpad";
import Sketch from "shared/lib/SketchPad/Sketch";
import AssignmentSlide, {
  Props as AssignmentSlideProps
} from "./AssignmentSlide";
import AssignmentSlideTitle from "./AssignmentSlideTitle";
import AssignmentSlideText from "./AssignmentSlideText";
import AssignmentArrowLink from "./AssignmentArrowLink";
import Column from "./Column";
import MultiImageInput from "./MultiImageInput";
import MathInputPreview from "./MathInputPreview";
import AssessmentImageUploadLabel from "./AssessmentImageUploadLabel";
import { BLANK_SKETCH, BLANK_SKETCH_CONTAINER_WIDTH } from "../constants/sketch/blankSketches";

export interface Value {
  sketch: Sketch;
  images: Array<File | string>;
}

interface Props extends AssignmentSlideProps {
  title: string;
  question: AssignmentQuestionPart[];
  buttonText: string;
  buttonLink: string;
  backgroundImage: string | null;
  value: Value;
  onChange(value: Value): any;
  onNextClick(nextPath: string): any;
}

const DrawingAssignmentSlide: FC<Props> = props => {
  const {
    title,
    question,
    buttonText,
    buttonLink,
    backgroundImage,
    value,
    onChange,
    onNextClick,
    ...rest
  } = props;

  const handleSketchChange = useCallback(
    (sketch: Sketch) => {
      onChange({
        ...value, sketch
      });
    },
    [onChange, value]
  );

  const handleImagesChange = useCallback(
    (images: Array<File | string>) => {
      onChange({ ...value, images });
    },
    [onChange, value]
  );

  const handleNextClick = useCallback(() => {
    onNextClick(buttonLink);
  }, [onNextClick, buttonLink]);

  return (
    <AssignmentSlide {...rest}>
      <Column>
        <AssignmentSlideTitle>{title}</AssignmentSlideTitle>
        <AssignmentSlideText>
          <MathInputPreview value={question} />
        </AssignmentSlideText>
      </Column>
      <ZwibblerSketchpad
        value={value.sketch}
        onChange={handleSketchChange}
        backgroundImage={backgroundImage}
        canvasHeight={BLANK_SKETCH.height}
        canvasWidth={BLANK_SKETCH.width}
        containerWidth={BLANK_SKETCH_CONTAINER_WIDTH}
      />
      <ImageUploadColumn>
        <MultiImageInput
          minimal
          value={value.images}
          onFilesChange={handleImagesChange}
          buttonLabel={<AssessmentImageUploadLabel />}
        />
      </ImageUploadColumn>
      <Column>
        <AssignmentArrowLink to={buttonLink} onClick={handleNextClick}>
          {buttonText}
        </AssignmentArrowLink>
      </Column>
    </AssignmentSlide>
  );
};

export default styled(DrawingAssignmentSlide)`
  justify-content: space-between;
`;

const ImageUploadColumn = styled(Column)`
  visibility: hidden; /* Hiding for now */
  position: relative;
  align-items: flex-end;
  margin-top: 4px; 
  margin-bottom: 30px;
  max-width: ${BLANK_SKETCH_CONTAINER_WIDTH}px;
`;
