import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { WindowLocation } from "@reach/router";
import RouteProps from "../RouteProps";
import AdminNavRow from "./AdminNavRow";
import Page from "./Page";
import Column from "./Column";
import FormOverlay from "./FormOverlay";
import EditAdminForm, { Value as EditAdminFormValue } from "./EditAdminForm";
import updateAdmin from "../api/admins/updateAdmin";
import useUrlState from "../utils/useUrlState";
import Admin from "shared/lib/types/Admin";

interface Props extends RouteProps {
  admin: Admin;
  location: WindowLocation | undefined;
  setAdmin(admin: Admin): any;
  reload(): any;
}

const AdminPage: FC<Props> = props => {
  const { admin, setAdmin, location, reload } = props;
  const [editProfileOpen, setEditProfileOpen] = useUrlState<boolean>(
    location,
    "edit-profile"
  );

  const closeEditProfileModal = useCallback(() => {
    setEditProfileOpen();
  }, [setEditProfileOpen]);

  const handleEditProfileSubmit = useCallback(
    async (value: EditAdminFormValue) => {
      await updateAdmin(admin.id, value);
      setAdmin({ ...admin, ...value });
      closeEditProfileModal();
    },
    [closeEditProfileModal, admin, setAdmin]
  );

  return (
    <Page {...props}>
      {editProfileOpen && admin && (
        <FormOverlay onClose={closeEditProfileModal}>
          <EditAdminForm
            title="Edit Profile"
            admin={admin}
            onSubmit={handleEditProfileSubmit}
          />
        </FormOverlay>
      )}
      <AdminNavRow location={props.location} admin={admin} reload={reload} />
      <Content>{props.children}</Content>
    </Page>
  );
};

export default styled(AdminPage)``;

const Content = styled(Column)`
  padding: 3rem 0 3rem 0;
  min-height: 100%;
  max-width: 100%;
  flex: 1;
`;
