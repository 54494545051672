import React, { FC } from "react";
import styled from "styled-components/macro";
import { AssignmentSetWithQuestions } from "shared/lib/types/AssignmentSet";
import QuestionResponse from "shared/lib/types/QuestionResponse";
import Row from "./Row";
import Column from "./Column";
import getS3ImageUrl from "../utils/getS3ImageUrl";
import SketchpadPreview from "./SketchpadPreview";
import * as studentRoutes from "../studentRoutes";
import MaybeLink from "./MaybeLink";
import TeacherFeedback from "./TeacherFeedback";
import isPracticeQuestion, {
  isNotPracticeQuestion,
} from "../utils/isPracticeQuestion";
import indexSorter from "../utils/indexSorter";
import isBlank from "shared/lib/utils/isBlank";
import MathInputPreview from "./MathInputPreview";
import ExpandableImage from "./ExpandableImage";

interface Props {
  takeId: number;
  isCompleted: boolean;
  takeableAssignmentId: number;
  setIndex: number;
  setCount: number;
  set: AssignmentSetWithQuestions;
  responses: QuestionResponse[];
  overallFeedback: {
    comment: string;
    grade: string;
  } | null;
  slideIndex?: number;
}

const AssignmentSetReview: FC<Props> = (props) => {
  const {
    takeId,
    isCompleted,
    takeableAssignmentId,
    set,
    responses,
    slideIndex,
    setIndex,
    setCount,
    overallFeedback,
    ...rest
  } = props;
  const assignmentImageUrl = set.exampleImage
    ? getS3ImageUrl(set.exampleImage)
    : null;
  const nonPracticeQuestions = set.questions.filter(isNotPracticeQuestion);
  const practiceQuestion = set.questions.find(isPracticeQuestion);
  const sortedQuestions = [...nonPracticeQuestions].sort(indexSorter);
  const hideOverallFeedbackGrade =
    overallFeedback && isBlank(overallFeedback.grade);
  const overallFeedbackTitle = hideOverallFeedbackGrade
    ? "View Assignment Comments"
    : "View Assignment Score";

  if (practiceQuestion) {
    sortedQuestions.push(practiceQuestion);
  }

  return (
    <Column {...rest}>
      <MaybeLink
        to={
          !isCompleted && typeof slideIndex === "number"
            ? studentRoutes.assignmentSlide(takeableAssignmentId, slideIndex)
            : null
        }
      >
        <Title>
          SET {setIndex + 1} OF {setCount}
        </Title>
      </MaybeLink>
      <ContentRow>
        <ExampleImageContainer>
          {assignmentImageUrl && (
            <ExampleImage
              src={assignmentImageUrl}
              alt={`Set ${setIndex + 1} example`}
            />
          )}
        </ExampleImageContainer>
        <QuestionColumn>
          {sortedQuestions.map((question, questionIndex) => {
            const questionUrl =
              !isCompleted && typeof slideIndex === "number"
                ? studentRoutes.assignmentSlide(
                  takeableAssignmentId,
                  slideIndex + questionIndex + 1
                )
                : null;
            const questionResponse = responses.find(
              (response) => response.questionId === question.id
            );
            const responseText =
              questionResponse && questionResponse.textResponse;
            const responseDrawing =
              questionResponse && questionResponse.drawingResponse;
            const hasTeacherFeedback =
              questionResponse &&
              (questionResponse.grade || questionResponse.comment);
            const hideGrade =
              questionResponse &&
              (!questionResponse.grade ||
                isBlank(questionResponse.grade || ""));

            let scoreTitle = "";
            if (hideGrade) {
              if (question.isPractice) {
                scoreTitle = "View Practice Problem Comments";
              } else {
                scoreTitle = `View Question ${questionIndex + 1} Comments`;
              }
            } else {
              if (question.isPractice) {
                scoreTitle = "View Practice Problem Score";
              } else {
                scoreTitle = `View Question ${questionIndex + 1} Score`;
              }
            }

            return (
              <Column key={question.id}>
                <MaybeLink to={questionUrl}>
                  {question.isPractice ? (
                    <PracticeTitle>Now complete your own.</PracticeTitle>
                  ) : (
                    <QuestionTitle>
                      <b>Question {questionIndex + 1}</b> of{" "}
                      <b>{nonPracticeQuestions.length}</b>
                    </QuestionTitle>
                  )}
                </MaybeLink>
                <QuestionText>
                  <MathInputPreview value={question.questionText} />
                </QuestionText>
                {!responseDrawing &&
                  <QuestionResponseText hasResponseText={!!responseText}>
                    {responseText}
                  </QuestionResponseText>
                }
                {responseDrawing &&
                  <QuestionResponseDrawing
                    value={responseDrawing}
                    backgroundImage={question.questionImage}
                    width={490}
                  />
                }
                {hasTeacherFeedback && (
                  <QuestionFeedbackContainer>
                    <TeacherFeedback
                      grade={questionResponse?.grade ?? ""}
                      comment={questionResponse?.comment ?? ""}
                      hideGrade={!!hideGrade}
                      title={scoreTitle}
                      gradeLabel={
                        question.isPractice
                          ? "Practice Problem Score:"
                          : `Question ${questionIndex + 1} Score:`
                      }
                    />
                  </QuestionFeedbackContainer>
                )}
                {!!(
                  questionResponse &&
                  questionResponse.images &&
                  questionResponse.images.length > 0
                ) && (
                    <StudentUploadColumn>
                      <StudentUploadTitle>Attached Files</StudentUploadTitle>
                      {questionResponse.images.map((image) => (
                        <StudentUploadImage key={image} src={image} />
                      ))}
                    </StudentUploadColumn>
                  )}
              </Column>
            );
          })}
          {overallFeedback && (
            <OverallFeedbackContainer>
              <TeacherFeedback
                grade={overallFeedback.grade}
                comment={overallFeedback.comment}
                hideGrade={!!hideOverallFeedbackGrade}
                title={overallFeedbackTitle}
                gradeLabel="Assignment Score:"
              />
            </OverallFeedbackContainer>
          )}
        </QuestionColumn>
      </ContentRow>
    </Column>
  );
};

export default styled(AssignmentSetReview)`
  & + & {
    margin-top: 42px;
  }
`;

const Title = styled("h2")`
  position: relative;
  left: -20px;
  color: #4a90e2;
  font-size: 22px;
  font-weight: 900;
  line-height: 27px;
  margin-bottom: 20px;
  text-align: center;
`;

const ContentRow = styled(Row)``;

const ExampleImageContainer = styled(Column)`
  width: 444px;
  height: 532px;
  margin-right: 28px;
  margin-left: 38px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;

const ExampleImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
  margin: 0;
`;

const QuestionColumn = styled(Column)`
  border-left: 2px solid #d8d8d8;
  padding: 6px 20px 6px 20px;
`;

const QuestionTitle = styled("h3")`
  color: #4a90e2;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const QuestionText = styled("div")`
  color: #787878;
  font-size: 19px;
  line-height: 23px;
  max-width: 500px;
`;

const QuestionResponseText = styled("div") <{ hasResponseText?: boolean }>`
  margin-bottom: 2rem;
  margin-left: 1rem;
  visibility: ${props => props.hasResponseText ? '' : 'hidden'};
`;

const QuestionResponseDrawing = styled(SketchpadPreview)`
  margin-bottom: 2rem;
`;

const PracticeTitle = styled(QuestionTitle)`
  color: #f5a623;
  font-weight: bold;
`;

const QuestionFeedbackContainer = styled(Column)`
  margin: 35px 0 35px 0;
`;

const OverallFeedbackContainer = styled(Column)`
  margin: 20px 0 35px 0;
`;

const StudentUploadColumn = styled(Column)`
  align-items: center;
  padding-bottom: 20px;
`;

const StudentUploadTitle = styled("h3")`
  margin-bottom: 10px;
`;

const StudentUploadImage = styled(ExpandableImage)`
  width: 100px;
  height: 100px;
  object-fit: contain;

  & + & {
    margin-top: 10px;
  }
`;
