import styled from "styled-components/macro";
import chevronDownImageSrc from "../images/chevron_down.png";
import TextButton from "./TextButton";

interface Props {
  hideArrow?: boolean;
}

const AssignmentArrowButton = styled(TextButton)<Props>`
  height: 5rem;
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 900;
  text-align: center;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: #19579f;
  background-image: ${props =>
    props.hideArrow ? "none" : `url('${chevronDownImageSrc}')`};
  background-repeat: no-repeat;
  background-position: center 80%;
  background-size: 40px auto;
  padding-bottom: ${props => (props.hideArrow ? "0" : "30px")};
  cursor: pointer;
`;

export default AssignmentArrowButton;
