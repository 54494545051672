import React, { FC, MouseEvent, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { GoTrashcan } from "react-icons/go";
import UnderlinedInput, { UnderlinedDateInput } from "./UnderlinedInput";
import Form from "./Form";
import BlockButton from "./BlockButton";
import Row from "./Row";
import TextButton from "./TextButton";
import Classroom from "shared/lib/types/Classroom";
import useInputState from "../utils/useInputState";
import ConfirmModal from "./ConfirmModal";
import isBlank from "shared/lib/utils/isBlank";
import FormErrorText from "./FormErrorText";
import getErrorMessage from "../utils/getErrorMessage";
import isDateAfter from "shared/lib/utils/isDateAfter";

export interface Value {
  id: number;
  name: string;
  classroomCode: string;
  startDate: string;
  endDate: string;
}

interface Props {
  classroom: Classroom;
  onDelete(): any;
  onSubmit(value: Value): any;
}

const EditClassForm: FC<Props> = props => {
  const { classroom, onDelete, onSubmit, ...rest } = props;
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [name, handleNameChange] = useInputState(classroom.name);
  const [classroomCode, handleClassroomCodeChange] = useInputState(
    classroom.classroomCode
  );
  const [startDate, setStartDate] = useState<string | null>(
    classroom.startDate
  );
  const [endDate, setEndDate] = useState<string | null>(classroom.endDate);
  const classroomId = classroom.id;

  const handleDeleteClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setShowDeleteModal(true);
  }, []);

  const handleDeleteCancel = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      if (isBlank(name)) {
        throw new Error("Title cannot be blank.");
      }
      if (isBlank(classroomCode)) {
        throw new Error("Class Id cannot be blank.");
      }
      if (!startDate) {
        throw new Error("Start date is required.");
      }
      if (!endDate) {
        throw new Error("End date is required.");
      }
      if (isDateAfter(startDate, endDate)) {
        throw new Error("Start date must be before end date.");
      }
      await onSubmit({
        id: classroomId,
        name,
        classroomCode,
        startDate,
        endDate
      });
    } catch (error) {
      setSubmitError(error);
    }
  }, [onSubmit, classroomId, name, classroomCode, startDate, endDate]);

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <Title>Edit Class</Title>
      <FormErrorText>
        {submitError && getErrorMessage(submitError)}
      </FormErrorText>
      <UnderlinedInput
        placeholder="Class Title"
        value={name}
        onChange={handleNameChange}
      />
      <UnderlinedInput
        placeholder="Class Id"
        value={classroomCode}
        onChange={handleClassroomCodeChange}
      />
      <DateInputRow>
        <UnderlinedDateInput
          placeholder="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <UnderlinedDateInput
          placeholder="End Date"
          value={endDate}
          onChange={setEndDate}
        />
      </DateInputRow>
      <BlockButton>Done</BlockButton>
      <DeleteClassButton onClick={handleDeleteClick}>
        <GoTrashcan />
        Delete {classroom.name}?
      </DeleteClassButton>
      {showDeleteModal && (
        <ConfirmModal
          message="All classroom students and assignment progress will be lost."
          onCancel={handleDeleteCancel}
          onConfirm={onDelete}
        />
      )}
    </Form>
  );
};

export default styled(EditClassForm)`
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;

  ${UnderlinedInput} {
    margin-bottom: 3rem;
  }

  ${BlockButton} {
    width: 150px;
    align-self: center;
    margin-bottom: 37px;
  }
`;

const Title = styled("h1")`
  color: #000;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  margin: 125px 0 40px 0;
  font-weight: 400;
`;

const DateInputRow = styled(Row)`
  margin-bottom: 60px;

  ${UnderlinedDateInput} + ${UnderlinedDateInput} {
    margin-left: 30px;
  }
`;

const DeleteClassButton = styled(TextButton)`
  color: #7e7e7e;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  > svg {
    margin-right: 7px;
  }
`;
