import apiAxios from "../apiAxios";
import Sketch from "shared/lib/SketchPad/Sketch";
import uploadFile from "../media/uploadFile";

interface Props {
  takeId: number;
  questionId: number;
  textResponse: string | null;
  drawingResponse: Sketch | null;
  images: Array<string | File>;
  timeSpent: number;
}

export default async function createOrUpdateQuestionResponse(
  props: Props
): Promise<{
  responseId: number;
  images: string[];
}> {
  const { takeId, questionId, images, ...rest } = props;

  // Upload images
  const uploadedImages = await Promise.all(
    images.map(async image => {
      if (typeof image === "string") {
        return image;
      } else {
        const signedUrl = await uploadFile(image);
        return signedUrl.key;
      }
    })
  );

  const response = await apiAxios.post(
    `/takes/${takeId}/questions/${questionId}/response`,
    { ...rest, images: uploadedImages }
  );

  const responseId: number = response.data;
  return { responseId, images: uploadedImages };
}
