import styled from 'styled-components/macro';
import mathByExampleLogo from '../images/mbe_logo.png';

const MathByExampleLogo = styled('img').attrs({
  alt: 'Math by Example',
  src: mathByExampleLogo
})`
  width: 581px;
  height: 105px;
  flex-shrink: 0;
`;

export default MathByExampleLogo;
