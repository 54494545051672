import React, { FC } from "react";
import styled from "styled-components/macro";
import Dropdown from "./Dropdown";

interface Props {
  showingCompleted: boolean;
  onChange(): any;
}

const ClassHistoryDropdown: FC<Props> = props => {
  const { showingCompleted, onChange, ...rest } = props;

  return (
    <Dropdown
      {...rest}
      label={showingCompleted ? "Completed Classes" : "Current Classes"}
      options={[
        showingCompleted
          ? { label: "Current Classes", value: 1 }
          : { label: "Completed Classes", value: 2 }
      ]}
      onChange={onChange}
    />
  );
};

export default styled(ClassHistoryDropdown)`
  width: 191px;
`;
