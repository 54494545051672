import React, { FC } from "react";
import styled from "styled-components/macro";
import AssignmentCharacter from "shared/lib/types/AssignmentCharacter";
import correct1 from "../images/characters/correct_1.png";
import correct2 from "../images/characters/correct_2.png";
import incorrect1 from "../images/characters/incorrect_1.png";
import incorrect2 from "../images/characters/incorrect_2.png";

interface Props {
  scale?: number;
  character: AssignmentCharacter;
}

const AssignmentCharacterImage: FC<Props> = props => {
  const { character, scale = 1, ...rest } = props;
  const { src, width, height, alt } = getCharacterImage(character);

  return (
    <Root
      {...rest}
      src={src}
      alt={alt}
      width={width * scale}
      height={height * scale}
    />
  );
};

export default styled(AssignmentCharacterImage)``;

const Root = styled("img")<{ width: number; height: number }>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

function getCharacterImage(
  character: AssignmentCharacter
): { src: string; width: number; height: number; alt: string } {
  switch (character) {
    case AssignmentCharacter.correct1:
      return { src: correct1, width: 41, height: 85, alt: "correct" };
    case AssignmentCharacter.correct2:
      return { src: correct2, width: 54, height: 85, alt: "correct" };
    case AssignmentCharacter.incorrect1:
      return { src: incorrect1, width: 43, height: 83, alt: "incorrect" };
    case AssignmentCharacter.incorrect2:
      return { src: incorrect2, width: 64, height: 86, alt: "incorrect" };
  }
}
