import styled from "styled-components/macro";
import imageSrc from "../images/in_progress_icon.png";

const InProgressIcon = styled("img").attrs({
  src: imageSrc,
  alt: "In Progress"
})`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export default InProgressIcon;
