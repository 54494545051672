import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { LinkProps } from "@reach/router";
import Link, { LinkGetProps } from "./Link";

interface Props extends LinkProps<{}> {
  to: string;
  allowPartial?: boolean;
}

const FadedLink: FC<Props> = props => {
  const { allowPartial, getProps, ref, ...rest } = props;

  const getLinkProps = useCallback(
    (linkProps: LinkGetProps) => {
      const matches = allowPartial
        ? linkProps.isPartiallyCurrent
        : linkProps.isCurrent;
      return {
        "data-matches": matches ? "true" : "false",
        ...(getProps && getProps(linkProps))
      };
    },
    [allowPartial, getProps]
  );

  return <Link {...rest} getProps={getLinkProps} />;
};

export default styled(FadedLink)`
  transition: background-color 0.2s;
  color: #b8b8b8;

  &[data-matches="true"] {
    color: black;
  }

  &:hover {
    color: black;
  }
`;
