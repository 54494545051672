import React, { FC, useEffect, useState, useRef, LegacyRef, useCallback } from "react";
import { MainScope, ZwibblerClass, ZwibblerContext } from 'zwibbler/lib/zwibbler2';
import { getDrawablesArray, isDrawableArray, renderDrawable } from 'shared/lib/SketchPad/Drawable';
import SketchpadDrawable from "shared/lib/SketchPad/Drawable";
import Sketch from "shared/lib/SketchPad/Sketch";
import isUrl from "../utils/isUrl";
import getS3ImageUrl from "../utils/getS3ImageUrl";
import waitForZwibblerToLoad from "../utils/waitForZwibblerToLoad";
import useAsyncEffect from "../utils/useAsyncEffect";
import getErrorMessage from "../utils/getErrorMessage";
import FormErrorText from "./FormErrorText";
import loadZwibblerMathLive from "../utils/loadZwibblerMathLive";
import { getBlankDrawable } from "../constants/sketch/blankSketches";
import Column from "./Column";
import styled from "styled-components/macro";
import AssignmentArrowButton from "./AssignmentArrowButton";
import { DrawingExportFormat } from "shared/lib/SketchPad/drawingExportFormat";

export type Drawable = SketchpadDrawable;

interface Props {
    value: Sketch;
    backgroundImage: string | null;
    height: number;
    width: number;
    defaultZoom?: number;
    /**
     * The name of the resulting downloaded image.
     * Do not include the extension
     */
    downloadName?: string;
    /**
     * Whether to offer a download button for the sketch
     */
    withDownload?: boolean;

    /**
     * The export format.
     * Defaults to png
     */
    exportFormat?: DrawingExportFormat;
}

let Zwibbler: ZwibblerClass = window.Zwibbler;

const ReadonlySketch: FC<Props> = ({ value, backgroundImage, height, width, defaultZoom, downloadName, withDownload, exportFormat = 'png' }) => {
    const [zwibblerInitialized, setZwibblerInitialized] = useState(false);
    const [loadInitialData, setLoadInitialData] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    let backgroundImageUrl = '';
    if (backgroundImage) {
        if (isUrl(backgroundImage)) {
            backgroundImageUrl = backgroundImage;
        } else {
            backgroundImageUrl = getS3ImageUrl(backgroundImage);
        }
    }

    const zwibblerConfig = {
        zwibbler: '',
        background: 'white',
        backgroundimage: backgroundImage,
        readonly: 'true',
        showtoolbar: 'false',
        showcolourpanel: 'false',
        scrollbars: 'false',
        defaultzoom: defaultZoom ?? null
    };

    const zwibblerRoot = useRef<HTMLDivElement | string>('');
    const ctx = useRef<ZwibblerContext | null>(null);

    useAsyncEffect(async (isCancelled) => {
        if (!zwibblerRoot.current) {
            return;
        }
        let scope: MainScope;

        const initializeZwibbler = (image?: HTMLImageElement) => {
            scope = Zwibbler.attach(zwibblerRoot.current, {});
            const context = scope.ctx;
            context.setDocumentSize(width, height);

            // These calculations allow background image to scale with the sketches
            const scaleWidthDiff = Math.abs(width - value.width);
            const scaleHeightDiff = Math.abs(height - value.height);
            const backgroundImageWidth = value.width > width
                ? width + scaleWidthDiff
                : width - scaleWidthDiff;

            const backgroundImageHeight = value.height > height
                ? height + scaleHeightDiff
                : height - scaleHeightDiff;

            // Draw background image
            context.setCustomBackgroundFn((context => {
                if (image && image.complete) {
                    context.drawImage(
                        image,
                        0,
                        0,
                        backgroundImageWidth,
                        backgroundImageHeight
                    );
                }
            }));

            if (!isCancelled()) {
                ctx.current = scope.ctx;
                setZwibblerInitialized(true);
            }
        }

        try {
            Zwibbler = await waitForZwibblerToLoad();
            loadZwibblerMathLive(Zwibbler);
            if (!isCancelled()) {
                if (backgroundImageUrl) {
                    const image = new Image();
                    image.src = backgroundImageUrl;
                    image.onload = () => {
                        initializeZwibbler(image);
                    }
                } else {
                    initializeZwibbler();
                }
            }
        } catch (error) {
            if (!isCancelled()) {
                setError(error);
            }
        }

        return () => {
            if (scope) {
                scope.ctx.destroy();
            }
        };
        // eslint-disable-next-line
    }, []);

    // Load inital data
    useEffect(() => {
        if (zwibblerInitialized && value && !loadInitialData && ctx.current) {
            const drawableStr = renderDrawable(value.drawables);
            const drawablesArray = getDrawablesArray(drawableStr);
            /**
             * Load sketch using new Zwibbler data format
             * Legacy sketches will show an empty sketchpad
             * preview
             */
            if (drawableStr && isDrawableArray(drawablesArray)) {
                ctx.current.load(drawableStr);
            } else {
                const blankDrawable = renderDrawable(getBlankDrawable(width, height));
                ctx.current.load(blankDrawable);
            }
            setLoadInitialData(true);
        }
        // eslint-disable-next-line
    }, [zwibblerInitialized]);

    const onDownloadClicked= useCallback(() => {
        if(ctx.current) {
            ctx.current.download(`${downloadName}.${exportFormat}`, { format: exportFormat })
        }
    }, [downloadName, exportFormat]);

    return (
      <Root>
        <div
          {...zwibblerConfig}
          ref={zwibblerRoot as LegacyRef<HTMLDivElement>}
          className="zwibbler"
        >
          {error ? (
            <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
          ) : (
            <>
               <CanvasOverlay canvasHeight={height} canvasWidth={width}>
                   {/* Zwibbler adds odd scrolling behavior, this prevents it */}
               </CanvasOverlay>
               <div z-canvas="" className="" style={{ width, height }} />
            </>
          )}
        </div>
        {withDownload && (
          <AssignmentArrowButton onClick={onDownloadClicked}>
            Download as {exportFormat}
          </AssignmentArrowButton>
        )}
      </Root>
    );
};

export default ReadonlySketch;


const Root = styled(Column)`
    ${AssignmentArrowButton} {
        margin-top: 1rem;
        max-width: 200px;
    }
`;

const CanvasOverlay = styled.div<{canvasWidth: number, canvasHeight: number}>`
    height: ${props => props.canvasHeight}px;
    width: ${props => props.canvasWidth}px;
    z-index: 100; 
    position: absolute;
`;
