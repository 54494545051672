import React, { FC, FormEvent, useCallback, MouseEvent } from "react";
import styled from "styled-components/macro";
import Uuid from "uuid/v4";
import {
  AssignmentQuestionPart,
  AssignmentQuestionPartKind
} from "shared/lib/types/AssignmentQuestion";
import Column from "./Column";
import ImageInput from "./ImageInput";
import InputWindow from "./InputWindow";
import LabeledRadio from "./LabeledRadio";
import QuestionInputType from "shared/lib/types/QuestionInputType";
import RadioColumn from "./RadioColumn";
import Row from "./Row";
import MinusButton from "./MinusButton";
import OutlinedTextarea from "./OutlinedTextarea";
import MathInput from "./MathInput";

interface Props {
  index: number;
  value: Value;
  onChange(value: Value): any;
  onRemove(event: MouseEvent<HTMLButtonElement>): any;
}

export interface Value {
  id?: number;
  uuid: string;
  isPractice: boolean;
  questionText: AssignmentQuestionPart[];
  inputType: QuestionInputType;
  questionImage: File | string | null;
  sampleResponseText: string;
  sampleResponseImage: File | string | null;
}

export function getDefaultValue(): Value {
  return {
    uuid: Uuid(),
    isPractice: false,
    questionText: [
      { id: Uuid(), kind: AssignmentQuestionPartKind.text, value: "" }
    ],
    inputType: QuestionInputType.textarea,
    questionImage: null,
    sampleResponseText: "",
    sampleResponseImage: null
  };
}

const QuestionForm: FC<Props> = props => {
  const { value, onChange, onRemove, index, ...rest } = props;

  const handleQuestionTextChange = useCallback(
    (questionText: AssignmentQuestionPart[]) => {
      onChange({ ...value, questionText });
    },
    [onChange, value]
  );

  const handleTextEntryClick = useCallback(() => {
    onChange({
      ...value,
      inputType: QuestionInputType.textarea,
      questionImage: null
    });
  }, [onChange, value]);

  const handleSketchPadEntryClick = useCallback(() => {
    onChange({
      ...value,
      inputType: QuestionInputType.sketchpad,
      questionImage: null
    });
  }, [onChange, value]);

  const handleSketchPadWithImageEntryClick = useCallback(() => {
    onChange({ ...value, inputType: QuestionInputType.sketchpadWithImage });
  }, [onChange, value]);

  const handleQuestionImageChange = useCallback(
    (questionImage: File | null) => {
      onChange({ ...value, questionImage });
    },
    [onChange, value]
  );

  const handleSampleResponsesChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      const sampleResponseText = event.currentTarget.value;
      onChange({ ...value, sampleResponseText });
    },
    [onChange, value]
  );

  const handleSampleResponsesImageChange = useCallback(
    (sampleResponseImage: File | null) => {
      onChange({ ...value, sampleResponseImage });
    },
    [onChange, value]
  );

  return (
    <Column {...rest}>
      <Column>
        <TitleRow>
          <Title>Question {index + 1}</Title>
          <MinusButton onClick={onRemove}>REMOVE QUESTION</MinusButton>
        </TitleRow>

        <MathInput
          value={value.questionText}
          onChange={handleQuestionTextChange}
        />
      </Column>
      <QuestionTypeRadioGroup title="Student will respond using:">
        <GrayLabeledRadio
          checked={value.inputType === QuestionInputType.textarea}
          onClick={handleTextEntryClick}
        >
          text field entry
        </GrayLabeledRadio>
        <GrayLabeledRadio
          checked={value.inputType === QuestionInputType.sketchpad}
          onClick={handleSketchPadEntryClick}
        >
          blank sketchpad entry
        </GrayLabeledRadio>
        <GrayLabeledRadio
          checked={value.inputType === QuestionInputType.sketchpadWithImage}
          onClick={handleSketchPadWithImageEntryClick}
        >
          sketchpad with image
        </GrayLabeledRadio>
      </QuestionTypeRadioGroup>

      {value.inputType === QuestionInputType.sketchpadWithImage && (
        <>
          <DownloadTemplateLink
            download
            href="https://serpinstitute.box.com/shared/static/g7e1qkm1051uyrw3240mcj5s5fsqhijq.pptx"
          >
            download template
          </DownloadTemplateLink>
          <ImageInput
            value={value.questionImage}
            onFileChange={handleQuestionImageChange}
            alt="Question"
          />
        </>
      )}
      <InputWindow title="TEACHER’S GUIDE: SAMPLE ANSWER OR TEACHER NOTES">
        <OutlinedTextarea
          value={value.sampleResponseText}
          onChange={handleSampleResponsesChange}
        />
        <ImageInput
          value={value.sampleResponseImage}
          onFileChange={handleSampleResponsesImageChange}
          alt="Sample responses"
        />
      </InputWindow>
    </Column>
  );
};

export default styled(QuestionForm)``;

const TitleRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding-left: 0.5rem;
`;

const Title = styled("h3")`
  color: #333333;
  font-size: 1rem;
  font-weight: 800;
`;

const QuestionTypeRadioGroup = styled(RadioColumn)`
  margin-bottom: 2rem;
`;

const GrayLabeledRadio = styled(LabeledRadio).attrs({
  borderColor: "#333333",
  checkedColor: "#D8D8D8"
})``;

const DownloadTemplateLink = styled("a")`
  margin-bottom: 10px;
`;
