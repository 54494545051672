import styled from 'styled-components/macro';
import DateInput from './DateInput';

const UnderlinedInput = styled('input')`
  height: 33px;
  padding: 0 0 0 10px;
  margin: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #7e7e7e;
  background-color: inherit;
  font-size: 1rem;
`;

export const UnderlinedDateInput = styled(DateInput)`
  input {
    height: 33px;
    width: 100%;
    padding: 0 0 0 10px;
    margin: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #7e7e7e;
    background-color: inherit;
    font-size: 1rem;
  }
`;

export default UnderlinedInput;
