import React, { FC, ComponentProps } from "react";
import styled from "styled-components/macro";
import PlusIcon from "./PlusIcon";
import BlockButton from "./BlockButton";

const PlusCircleButton: FC<ComponentProps<typeof BlockButton>> = props => {
  const { children } = props;

  return (
    <BlockButton {...props}>
      <IconWrapper>
        <PlusIcon color="black" />
      </IconWrapper>
      {children}
    </BlockButton>
  );
};

export default styled(PlusCircleButton)`
  width: 284px;
  max-width: 100%;
`;

const IconWrapper = styled("div")`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin-right: 6px;
`;
