import apiAxios from "../apiAxios";
import Student from "shared/lib/types/Student";

interface Props {
  classroomId: number;
  username: string;
  email: string | null;
  password: string;
  studentCode: string;
  firstName: string;
  lastName: string;
}

export default async function createStudent(props: Props): Promise<Student> {
  const { classroomId, ...rest } = props;
  const response = await apiAxios.post(
    `/classrooms/${classroomId}/students`,
    rest
  );
  return response.data;
}
