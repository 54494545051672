import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import isValidDate from "shared/lib/utils/isValidDate";
import isBlank from "shared/lib/utils/isBlank";
import isDateAfter from "shared/lib/utils/isDateAfter";
import Row from "./Row";
import UnderlinedInput, { UnderlinedDateInput } from "./UnderlinedInput";
import useInputState from "../utils/useInputState";
import FormLayout from "./FormLayout";
import BlockButton from "./BlockButton";

export interface Props {
  onSubmit: (value: Value) => any;
}

export interface Value {
  name: string;
  classroomCode: string;
  startDate: string;
  endDate: string;
}

const CreateClassForm: FC<Props> = props => {
  const { onSubmit, ...rest } = props;
  const [name, handleTitleChange] = useInputState("");
  const [classroomCode, handleRoomNumberChange] = useInputState("");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const handleSubmit = useCallback(async () => {
    if (isBlank(name)) {
      throw new Error("Title is required.");
    }
    if (isBlank(classroomCode)) {
      throw new Error("Class Id is required.");
    }
    if (!startDate) {
      throw new Error("Start date is required.");
    }
    if (!isValidDate(startDate)) {
      throw new Error("Invalid start date.");
    }
    if (!endDate) {
      throw new Error("End date is required.");
    }
    if (!isValidDate(endDate)) {
      throw new Error("Invalid end date.");
    }
    if (isDateAfter(startDate, endDate)) {
      throw new Error("Start date must be before end date.");
    }

    await onSubmit({
      name,
      classroomCode,
      startDate,
      endDate
    });
  }, [name, classroomCode, startDate, endDate, onSubmit]);

  return (
    <FormLayout
      title="Create a Class"
      submitButtonText="Create Class"
      {...rest}
      onSubmit={handleSubmit}
    >
      <UnderlinedInput
        placeholder="Name of Class"
        value={name}
        onChange={handleTitleChange}
      />
      <UnderlinedInput
        placeholder="Class Id"
        value={classroomCode}
        onChange={handleRoomNumberChange}
      />
      <Row>
        <UnderlinedDateInput
          placeholder="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <UnderlinedDateInput
          placeholder="End Date"
          value={endDate}
          onChange={setEndDate}
        />
      </Row>
    </FormLayout>
  );
};

export default styled(CreateClassForm)`
  h1 {
    color: #000;
    font-size: 21px;
    text-align: center;
    margin-top: 125px;
    font-weight: 400;
  }

  ${UnderlinedDateInput} {
    width: 135px;
  }

  ${UnderlinedDateInput}:first-child {
    margin-right: 2rem;
  }

  ${BlockButton} {
    margin-top: 3rem;
  }
`;
