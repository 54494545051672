export const home = "/";

export const login = "/admin";

export const teachers = "/teachers";

export const teacher = (teacherId: number) => `${teachers}/${teacherId}`;

export const editTeacher = (teacherId: number) => `${teacher(teacherId)}/edit`;

export const createTeacher = () =>
  `${window.location.pathname}?create-teacher=true`;

export const editProfile = () =>
  `${window.location.pathname}?edit-profile=true`;

export const assignments = "/assignments";

export const assignmentCategory = (categoryId: number) =>
  `${assignments}/${categoryId}`;

export const assignmentCategoryUnit = (categoryId: number, unitId: number) =>
  `${assignmentCategory(categoryId)}/${unitId}`;

export const assignment = (
  categoryId: number,
  unitId: number,
  assignmentId: number
) => `${assignmentCategoryUnit(categoryId, unitId)}/${assignmentId}`;

export const data = "/data";

export const rosterImport = "/roster-import";
