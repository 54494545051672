import apiAxios from "../apiAxios";
import SignedUrl from "shared/lib/types/SignedUrl";
import isInFuture from "shared/lib/utils/isInFuture";

const cache = new Map<string, SignedUrl>();

export default async function getMediaDownloadUrl(
  key: string
): Promise<SignedUrl> {
  const cachedUrl = cache.get(key);
  if (cachedUrl && isInFuture(cachedUrl.expiresAt)) {
    return cachedUrl;
  }

  const response = await apiAxios.get(`/media/download-url/${key}`);
  const signedUrl = response.data;
  cache.set(key, signedUrl);

  return signedUrl;
}
