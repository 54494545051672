import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { navigate, WindowLocation } from "@reach/router";
import Teacher from "shared/lib/types/Teacher";
import Link from "./Link";
import NavRow from "./NavRow";
import logout from "../api/auth/logout";
import * as teacherRoutes from "../teacherRoutes";

interface Props {
  location: WindowLocation | undefined;
  teacher: Teacher;
  reload(): any;
}

const TeacherNavRow: FC<Props> = props => {
  const { teacher, location, reload, ...rest } = props;

  const handleLogoutClick = useCallback(async () => {
    await logout();
    await reload();
    navigate("/teacher");
  }, [reload]);

  return (
    <NavRow
      {...rest}
      isAdmin={false}
      location={location}
      onLogoutClick={handleLogoutClick}
    >
      <li>
        <Link to={teacherRoutes.classrooms}>Teacher Dashboard</Link>
      </li>
    </NavRow>
  );
};

export default styled(TeacherNavRow)``;
