import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";

export interface Props {
  checked: boolean;
  onChange?(checked: boolean): any;
}

const Switch: FC<Props> = props => {
  const { checked, onChange, ...rest } = props;

  const handleClick = useCallback(() => {
    if (onChange) {
      onChange(!checked);
    }
  }, [onChange, checked]);

  return (
    <Root
      {...rest}
      onClick={handleClick}
      checked={checked}
      role="checkbox"
      aria-checked={checked}
    >
      <Handle checked={checked} />
    </Root>
  );
};

export default styled(Switch)``;

const Root = styled("div")<{ checked: boolean }>`
  position: relative;
  height: 18px;
  width: 43px;
  border: 1px solid #979797;
  border-radius: 8.5px;
  transition: background-color 0.2s;
  background-color: ${props => (props.checked ? "#000" : "#d8d8d8")};
  flex-shrink: 0;
  cursor: pointer;
`;

const Handle = styled("div")<{ checked: boolean }>`
  height: 17px;
  width: 17px;
  border: 1px solid #979797;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  transition: left 0.2s;
  left: ${props => (props.checked ? 25 : -1)}px;
  top: 50%;
  transform: translate(0, -50%);
`;
