import apiAxios from '../apiAxios';

interface Props {
  unitId: number;
  assignmentId: number;
}

export default async function moveAssignmentToUnit(props: Props) {
  const { assignmentId, unitId } = props;
  await apiAxios.put(`/assignments/${assignmentId}/unit`, { unitId });
}
