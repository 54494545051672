import React, { FC } from 'react';
import RouteProps from '../../RouteProps';
import submitAdminPasswordReset from '../../api/forgotPassword/submitAdminPasswordReset';
import ResetPasswordPage from '../../components/ResetPasswordPage';

const AdminResetPasswordPage: FC<RouteProps> = props => {
  return (
    <ResetPasswordPage
      {...props}
      title="Reset Password - Admin"
      backPath="/admin"
      onSubmit={submitAdminPasswordReset}
    />
  );
};

export default AdminResetPasswordPage;
