import styled from "styled-components/macro";
import TextButton from "./TextButton";

const RadioCircleButton = styled(TextButton)<{disabled?: boolean}>`
  padding-right: 1rem;
  padding-left: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s;
  opacity: ${props => props.disabled ? 0.6 : 1}
`;

export default RadioCircleButton;
