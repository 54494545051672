import apiAxios from "../apiAxios";
import { DrawingPreviewData } from "shared/lib/types/DrawingPreviewData";
import Admin from "shared/lib/types/Admin";

export async function getDrawingPreview(
  responseId: number,
  admin: Admin | null
): Promise<DrawingPreviewData | null> {
  const response = admin
    ? await apiAxios.get(`/responses/${responseId}`)
    : await apiAxios.get(`/responses/preview/${responseId}`);
  return response.data;
}
