import React, { FC } from "react";
import styled from "styled-components/macro";
import { WindowLocation } from "@reach/router";
import List from "./List";
import TextButton from "./TextButton";
import MathByExampleLogo from "./MathByExampleLogo";
import Link from "./Link";
import Row from "./Row";
import * as teacherRoutes from "../teacherRoutes";
import * as adminRoutes from "../adminRoutes";

interface Props {
  location: WindowLocation | undefined;
  isAdmin: boolean;
  onLogoutClick?(): any;
  loggedIn?: boolean;
}

const NavRow: FC<Props> = props => {
  const { isAdmin, location, onLogoutClick, children, loggedIn = true, ...rest } = props;
  const editProfilePath = isAdmin
    ? adminRoutes.editProfile()
    : teacherRoutes.editProfile();

  return (
    <Row {...rest}>
      <LogoLink to="/">
        <MathByExampleLogo />
      </LogoLink>
      <Content>
        <LinkList>
          {children}
          {loggedIn && (
            <>
              <li>
                <EditProfileLink to={editProfilePath}>Profile</EditProfileLink>
              </li>
              <li>
              <SignoutButton onClick={onLogoutClick}>Sign out</SignoutButton>
              </li>
            </>
          )}
        </LinkList>
      </Content>
    </Row>
  );
};

export default styled(NavRow)`
  width: 950px;
  max-width: 100%;
  height: 4rem;
  flex-shrink: 0;
  font-size: 0.75rem;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 0 2rem 0 2rem;

  li + li {
    margin-left: 2.5rem;
  }

  li {
    color: black;
    font-size: 0.75rem;
    font-weight: bold;
  }

  @media print {
    display: none;
    margin: 0;
    padding: 0;
  }
`;

const Content = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

const EditProfileLink = styled(Link)`
  display: flex;
  flex-direction: row;
`;

const LinkList = styled(List)`
  display: flex;
  flex-direction: row;
`;

const LogoLink = styled(Link)`
  padding-left: 1rem;
  ${MathByExampleLogo} {
    width: 162.68px;
    height: 29.4px;
  }
`;

const SignoutButton = styled(TextButton)``;
