import styled from 'styled-components/macro';
import serpLogo from '../images/serp_logo.png';

const SerpLogo = styled('img').attrs({
  alt: 'SERP',
  src: serpLogo
})`
  width: 213px;
  height: 100px;
  flex-shrink: 0;
`;

export default SerpLogo;
