/**
 * Adds a zero to the left of a number if the number is less than 10.
 */
export default function padZero(value: string | number): string {
  value = value.toString();
  if (value.length < 2) {
    return "0" + value;
  }
  return value;
}
