import apiAxios from '../apiAxios';
import Student from 'shared/lib/types/Student';

interface Props {
  username?: string;
  password?: string;
  studentCode?: string | null;
  firstName?: string;
  lastName?: string;
}

/**
 * Used when a teacher updates a student.
 */
export default async function updateStudent(
  studentId: number,
  props: Props
): Promise<Student> {
  const response = await apiAxios.put(`/students/${studentId}`, props);
  return response.data;
}
