import React, { FC, FormEvent, LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import styled from "styled-components/macro";
import { MainScope, ZwibblerClass, ZwibblerContext } from 'zwibbler/lib/zwibbler2';
import { getDrawablesArray, isDrawableArray, renderDrawable } from 'shared/lib/SketchPad/Drawable';
import Sketch from 'shared/lib/SketchPad/Sketch';
import Column from './Column';
import Row from './Row';
import Slider from './Slider';
import ToolButton from './ToolButton';
import { NON_SELECT_TOOLS, Tool } from '../constants/tool';
import isUrl from "../utils/isUrl";
import getS3ImageUrl from "../utils/getS3ImageUrl";
import { useOnClickOutside } from "../utils/useOnClickOutside";
import waitForZwibblerToLoad from '../utils/waitForZwibblerToLoad';
import useAsyncEffect from '../utils/useAsyncEffect';
import FormErrorText from './FormErrorText';
import getErrorMessage from '../utils/getErrorMessage';
import loadZwibblerToolProperties from '../utils/loadZwibblerToolProperties';
import BlockButton from './BlockButton';
import stampsIconSrc from "../images/tool_icons/stamps.png";
import loadZwibblerMathLive from '../utils/loadZwibblerMathLive';

const DEFAULT_FILL_COLOR = '#000000';
const MIN_LINE_WIDTH = 1;
const MAX_LINE_WIDTH = 10;

interface Props {
    backgroundImage: string | null;
    value: Sketch;
    onChange(value: Sketch): void;
    canvasWidth?: number;
    canvasHeight?: number;
    containerWidth?: number;
}

let Zwibbler: ZwibblerClass = window.Zwibbler;

const ZwibblerSketchpad: FC<Props> = React.memo(
    ({ value, backgroundImage, onChange, canvasWidth, canvasHeight, containerWidth }) => {
        const [lineWidth, setLineWidth] = useState(MIN_LINE_WIDTH);
        const [fillColor, setFillColor] = useState(DEFAULT_FILL_COLOR);
        const [selectedTool, setSelectedTool] = useState<Tool>();
        const [zwibblerInitialized, setZwibblerInitialized] = useState(false);
        const [loadInitialData, setLoadInitialData] = useState(false);
        const [showShapeTools, setShowShapeTools] = useState(false);
        const [error, setError] = useState<Error | null>(null);
        const [showToolProperties, setShowToolProperties] = useState(false);

        let backgroundImageUrl = '';
        if (backgroundImage) {
            if (isUrl(backgroundImage)) {
                backgroundImageUrl = backgroundImage;
            } else {
                backgroundImageUrl = getS3ImageUrl(backgroundImage);
            }
        }

        const zwibblerConfig = {
            zwibbler: '',
            "z-controller": "mycontroller",
            keydragduplicate: "alt",
            keypan: " ",
            multilinetext: 'true',
            showtoolbar: 'false',
            showcolourpanel: 'false',
            defaultarrowstye: 'solid',
            defaultbrushwidth: MIN_LINE_WIDTH,
            defaultlinewidth: MIN_LINE_WIDTH,
            adaptivebrushwidth: 'false',
            defaultfillstyle: 'white',
            background: 'white',
            scrollbars: 'false',
        };

        const zwibblerRoot = useRef<HTMLDivElement | string>('');
        const ctx = useRef<ZwibblerContext | null>(null);

        const onClickOutsideShapes = useCallback(() => {
            setShowShapeTools(false);
        }, []);

        const shapeToolsRef = useOnClickOutside<HTMLDivElement>(onClickOutsideShapes);

        const handleStrokeWidthChange = useCallback(
            (lineWidth: number) => {
                if (!ctx.current) {
                    return;
                }
                const context = ctx.current;
                context.setProperty('lineWidth', lineWidth);
                setLineWidth(lineWidth);
            },
            [ctx]
        );

        const handleFillColorChange = useCallback(
            (event: FormEvent<HTMLInputElement>) => {
                if (!ctx.current) {
                    return;
                }
                const context = ctx.current;
                const color = event.currentTarget.value;

                context.setProperties({
                    fillStyle: color,
                    textFillStyle: color,
                    textStrokeStyle: color,
                });

                setFillColor(color);
            },
            []
        );

        const handleToolClicked = useCallback((tool: Tool) => {
            if (!ctx.current) {
                return;
            }
            const context = ctx.current;
            switch (tool) {
                case Tool.PICK:
                    context.usePickTool();
                    break;
                case Tool.UNDO:
                    context.undo();
                    break;
                case Tool.REDO:
                    context.redo();
                    break;
                case Tool.ERASER:
                    context.useBrushTool({
                        strokeStyle: 'erase',
                        layer: 'erased-layer',
                        lineWidth
                    });
                    break;
                case Tool.RECTANGLE:
                    context.useRectangleTool({ strokeStyle: DEFAULT_FILL_COLOR });
                    setShowShapeTools(false);
                    break;
                case Tool.TRIANGLE:
                    context.usePolygonTool(3, 0, 1.0, {
                        strokeStyle: DEFAULT_FILL_COLOR
                    });
                    setShowShapeTools(false);
                    break;
                case Tool.CIRCLE:
                    context.useCircleTool({ strokeStyle: DEFAULT_FILL_COLOR });
                    setShowShapeTools(false);
                    break;
                case Tool.FREEHAND:
                    context.useFreehandTool({ lineWidth });
                    break;
                case Tool.LINE:
                    context.useLineTool({ strokeStyle: fillColor });
                    break;
                case Tool.CURVE:
                    context.useCurveTool({ strokeStyle: fillColor });
                    break;
                case Tool.TEXT:
                    context.useTextTool();
                    break;
                case Tool.SHAPES:
                    setShowShapeTools(true);
                    break;
                case Tool.TRASH:
                    context.deleteNodes(context.getLayerNodes("default"));
                    break;
                case Tool.IMAGE:
                    context.insertImage();
                    break;
                case Tool.MATH_EQUATION:
                    context.useCustomTool({
                        onMouseClick: (x: number, y: number, event: Event) => {
                            event.preventDefault();
                            if (x < 0 || y < 0) {
                                return;
                            }
                            let id = context.createHTMLNode("MathLive", {
                                style: {
                                    // Move the node to where the mouse was clicked
                                    top: `${Math.max(0, y)}px`,
                                    left: `${Math.max(0, x)}px`,
                                }
                            });
                            context.onComplete(() => {
                                let obj = context.getNodeObject(id) as any;
                                if (obj) {
                                    // Move the node to where the mouse was clicked
                                    obj.startEditing();
                                }
                            });

                            // Math live
                            context.on("selected-nodes", () => {
                                let nodes = context.getSelectedNodes();
                                if (nodes.length === 0) {
                                    context.globals.clearAllMasks();
                                }
                            });

                            context.globals.clearAllMasks = () => {
                                for (let node of context.getAllNodes()) {
                                    if (context.getNodeProperty(node, "$component") !== "MathLive") continue;
                                    let obj = context.getNodeObject(node) as any;
                                    obj.showMask(false);
                                }
                            };

                            // Immediately switch to move/pick tool (the mathlive keyboard will still be up).
                            context.usePickTool();
                        }
                    });
                    break;
            }

            if (!NON_SELECT_TOOLS.includes(tool)) {
                setSelectedTool(tool);
            }
        }, [lineWidth, fillColor]);

        useAsyncEffect(async (isCancelled) => {
            if (!zwibblerRoot.current) {
                return;
            }
            let scope: MainScope;

            const initializeZwibbler = (image?: HTMLImageElement) => {
                scope = Zwibbler.attach(zwibblerRoot.current, {});
                const context = scope.ctx;
                context.setDocumentSize(value.width, value.height);

                // Draw background image
                context.setCustomBackgroundFn((context => {
                    if (image && image.complete) {
                        context.drawImage(
                            image,
                            0,
                            0,
                            canvasWidth ?? value.width,
                            canvasHeight ?? value.height
                        );
                    }
                }));

                // Zwibbler events
                context.on("document-changed", () => {
                    if (context.dirty() && ctx.current) {
                        const { width, height } = ctx.current.getDocumentSize();
                        const drawables = ctx.current.save();
                        onChange({
                            drawables: getDrawablesArray(drawables),
                            width,
                            height
                        });
                    }
                });

                context.on("tool-changed", () => {
                    if (ctx.current) {
                        if (ctx.current.getCurrentTool() === Tool.PICK) {
                            setSelectedTool(Tool.PICK);
                        }
                    }
                });

                ctx.current = context;
                setZwibblerInitialized(true);
            }

            try {
                Zwibbler = await waitForZwibblerToLoad();
                loadZwibblerMathLive(Zwibbler);
                loadZwibblerToolProperties(Zwibbler);
                if (!isCancelled()) {
                    if (backgroundImageUrl) {
                        const image = new Image();
                        image.src = backgroundImageUrl;
                        image.onload = () => {
                            initializeZwibbler(image);
                        }
                    } else {
                        initializeZwibbler();
                    }
                }
            } catch (error) {
                setError(error);
            }

            return () => {
                if (scope) {
                    scope.ctx.destroy();
                }
            };
            // eslint-disable-next-line
        }, []);

        // Load inital data
        useEffect(() => {
            if (zwibblerInitialized && value && !loadInitialData && ctx.current) {
                const drawableStr = renderDrawable(value.drawables);
                const drawablesArray = getDrawablesArray(drawableStr);
                const context = ctx.current;
                // Load existing sketch if one exists
                if (drawableStr && isDrawableArray(drawablesArray)) {
                    // Check if are valid drawables
                    context.load(drawableStr);
                } else {
                    // Reset drawables
                    const { width, height } = context.getDocumentSize();
                    onChange({
                        drawables: [],
                        width,
                        height
                    });
                }

                // Default selected tool to freehand
                context.useFreehandTool({ lineWidth: MIN_LINE_WIDTH });

                setSelectedTool(Tool.FREEHAND);
                setLoadInitialData(true);
            }
            // eslint-disable-next-line
        }, [zwibblerInitialized]);

        const isToolShapeSelected =
            selectedTool === Tool.TRIANGLE ||
            selectedTool === Tool.CIRCLE ||
            selectedTool === Tool.RECTANGLE;

        const onClickOutside = useCallback(() => {
            setShowToolProperties(false);
            ctx.current?.stopEditingText(true);
        }, []);

        const rootRef = useOnClickOutside<HTMLDivElement>(onClickOutside);

        useEffect(() => {
            if (ctx.current) {
                const zwibbler = ctx.current;
                // When the text tool is selected, change showing these to true/false
                const showFontOptions = selectedTool === Tool.TEXT ? 'true' : 'false';
                zwibbler.setConfig('showFontNameProperty', showFontOptions);
                zwibbler.setConfig('showFontSizeProperty', showFontOptions);
            }
        }, [selectedTool])

        return (
            <div
                {...zwibblerConfig}
                ref={zwibblerRoot as LegacyRef<HTMLDivElement>}
                className="zwibbler sketchpad"
                style={{ width: containerWidth ?? undefined }}
            >
                <Root width={canvasWidth ?? value.width} height={canvasHeight ?? value.height} ref={rootRef}>
                    <PropertyPanel showPropertyPanel={showToolProperties} showTextOptions={selectedTool === Tool.TEXT}>
                        <div z-use-component="ZPropertyPanel" className="propMenu"></div>
                    </PropertyPanel>
                    <Row>
                        <TopLeftTools>
                            <ToolPropertiesButton
                                title="options"
                                onClick={() => setShowToolProperties(prevState => !prevState)}
                                isSelected={showToolProperties}
                            >
                                <img src={stampsIconSrc} alt="Properties" />
                            </ToolPropertiesButton>
                            <ToolButton
                                tool={Tool.PICK}
                                onClick={handleToolClicked}
                                isSelected={selectedTool === Tool.PICK}
                            />
                            <ShapeToolsContainer>
                                <ToolButton
                                    tool={Tool.SHAPES}
                                    onClick={handleToolClicked}
                                    isSelected={isToolShapeSelected}
                                />
                                {showShapeTools &&
                                    <ShapeToolsRow ref={shapeToolsRef}>
                                        <ToolButton
                                            tool={Tool.TRIANGLE}
                                            onClick={handleToolClicked}
                                            isSelected={selectedTool === Tool.TRIANGLE}
                                        />
                                        <ToolButton
                                            tool={Tool.RECTANGLE}
                                            onClick={handleToolClicked}
                                            isSelected={selectedTool === Tool.RECTANGLE}
                                        />
                                        <ToolButton
                                            tool={Tool.CIRCLE}
                                            onClick={handleToolClicked}
                                            isSelected={selectedTool === Tool.CIRCLE}
                                        />
                                    </ShapeToolsRow>
                                }
                            </ShapeToolsContainer>
                            <ToolButton
                                tool={Tool.FREEHAND}
                                onClick={handleToolClicked}
                                isSelected={selectedTool === Tool.FREEHAND}
                            />
                            <ToolButton
                                tool={Tool.LINE}
                                onClick={handleToolClicked}
                                isSelected={selectedTool === Tool.LINE}
                            />
                            <ToolButton
                                tool={Tool.CURVE}
                                onClick={handleToolClicked}
                                isSelected={selectedTool === Tool.CURVE}
                            />
                            <ToolButton
                                tool={Tool.TEXT}
                                onClick={handleToolClicked}
                                isSelected={selectedTool === Tool.TEXT}
                            />
                            <ToolButton
                                tool={Tool.MATH_EQUATION}
                                onClick={handleToolClicked}
                                isSelected={selectedTool === Tool.MATH_EQUATION}
                            />
                            <ToolButton
                                tool={Tool.IMAGE}
                                onClick={handleToolClicked}
                                isSelected={selectedTool === Tool.IMAGE}
                            />
                        </TopLeftTools>
                        {error ? <FormErrorText>{error && getErrorMessage(error)}</FormErrorText> :
                            <div
                                z-canvas=""
                                style={{
                                    width: `${canvasWidth}px` ?? '',
                                    height: `${canvasHeight}px` ?? '',
                                }}
                            />
                        }
                    </Row>
                    <BottomToolRow>
                        <ToolRow>
                            <ColorInput onChange={handleFillColorChange} title='Change Fill Color' />
                            <SliderRow>
                                <SliderLeftIcon />
                                <Slider
                                    min={MIN_LINE_WIDTH}
                                    max={MAX_LINE_WIDTH}
                                    value={lineWidth}
                                    onChange={handleStrokeWidthChange}
                                    title='Change Line Width'
                                />
                                <SliderRightIcon />
                            </SliderRow>
                        </ToolRow>
                        <BottomRightTools>
                            <ToolButton
                                tool={Tool.ERASER}
                                onClick={handleToolClicked}
                                isSelected={selectedTool === Tool.ERASER}
                            />
                            <ToolButton
                                tool={Tool.UNDO}
                                onClick={handleToolClicked}
                            />
                            <ToolButton
                                tool={Tool.REDO}
                                onClick={handleToolClicked}
                            />
                            <ToolButton
                                tool={Tool.TRASH}
                                onClick={handleToolClicked}
                            />
                        </BottomRightTools>
                    </BottomToolRow>
                </Root>
            </div >
        );
    });

export default styled(ZwibblerSketchpad)``;

const Root = styled(Column) <{ width: number, height: number }>`
    flex: 1;  
    position: relative; 
    .zwibbler-canvas-holder{
        width: ${({ width }) => width}px;
        height: ${({ height }) => height}px;
    }
`;

const ToolRow = styled(Row)`
  align-items: center;
  z-index: 2;
`;

const BottomToolRow = styled(Row)`
  margin: 5px 0; 
  justify-content: space-between;
`;

const BottomRightTools = styled(Row)`
  align-items: center ;
  column-gap: 3px;
  margin: 0;
  button{ 
    margin: 0 !important; 
  }
`;

const TopLeftTools = styled(Column)`
  margin-right: 8px;
  width: 51px;
`;

const ShapeToolsContainer = styled('div')`
  position: relative; 
  margin: 2px 0; 
`;

const ShapeToolsRow = styled(Row)` 
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: absolute; 
  top: 0; 
  margin-left: 54px; 
  column-gap: 3px;
  button{ 
    margin: 0 !important; 
  }
`;

const ColorInput = styled("input").attrs({ type: "color" })`
  width: 51px;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  border: 2px solid #ffffff;
  margin-right: 8px;
`;

const SliderRow = styled(Row)`
  align-items: center;
`;

const SliderIcon = styled("div")`
  background-color: #19579f;
  border-radius: 50%;
  display: inline-block;
`;

const SliderLeftIcon = styled(SliderIcon)`
  margin: 2px 2px 0 0;
  height: 7px;
  width: 7px;
`;

const SliderRightIcon = styled(SliderIcon)`
  margin: 1px 0 0 9px;
  height: 20px;
  width: 20px;
`;

const PropertyPanel = styled('div') <{ showPropertyPanel?: boolean; showTextOptions: boolean }>`
    position: absolute; 
    flex: 1;
    width: 100%;
    height: 300px; 
    top: -300px; 
    overflow: scroll;
    background: #f5f5f5; 
    display: ${props => !props.showPropertyPanel ? 'none' : ''};
    
    // This is a workaround for not having a config to hide/show font styles like it does for family and size
    .font-style-buttons {
        display: ${props => props.showTextOptions ? 'flex' : 'none'}
    }
`
const ToolPropertiesButton = styled(BlockButton) <{ isSelected?: boolean }>`
  box-sizing: border-box;
  height: 24px;
  width: 51px;
  border: 2px solid #ffffff;
  border-radius: 7px;
  background-color: ${props => (props.isSelected ? "#6e9fb2" : "#9ce0fd")};
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 2px;
  img {
    width: 26px; 
    height: auto; 
  }
  &:active {
    background-color: #6e9fb2;
  }
`;
