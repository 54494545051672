import React, { FC, useCallback, ReactNode } from "react";
import styled from "styled-components/macro";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import Unit from "shared/lib/types/Unit";
import Assignment from "shared/lib/types/Assignment";
import List from "./List";
import ExpandListItemDroppable from "./ExpandListItemDroppable";
import DraggableListItem from "./DraggableListItem";
import Row from "./Row";
import RadioCircle from "./RadioCircle";
import PlusButton from "./PlusButton";
import RadioCircleButton from "./RadioCircleButton";

interface Props {
  selectedUnitId: number | null;
  units: Unit[];
  showAddAssignmentButtons?: boolean;
  renderAssignmentListItem(assignment: Assignment, index: number): ReactNode;
  onSelectUnit(unitId: number): any;
  onAssignmentMove(event: AssignmentMoveEvent): any;
  onAddAssignmentClick?(unitId: number): any;
}

export interface AssignmentMoveEvent {
  sourceUnitId: number;
  destUnitId: number;
  sourceIndex: number;
  destIndex: number;
}

const UnitList: FC<Props> = props => {
  const {
    selectedUnitId,
    onAssignmentMove,
    onSelectUnit,
    onAddAssignmentClick,
    renderAssignmentListItem,
    showAddAssignmentButtons,
    units,
    ...rest
  } = props;

  const handleAssignmentDrop = useCallback(
    (dropResult: DropResult) => {
      const { source, destination } = dropResult;

      if (!destination) {
        return;
      }

      const sourceUnitId = +source.droppableId;
      const sourceIndex = source.index;
      const destUnitId = +destination.droppableId;
      const destIndex = destination.index;

      const sourceUnit = units.find(unit => unit.id === sourceUnitId);
      const destUnit = units.find(unit => unit.id === destUnitId);

      if (!sourceUnit || !destUnit) {
        return;
      }

      const assignment = (sourceUnit.assignments || [])[sourceIndex];

      if (!assignment) {
        return;
      }

      onAssignmentMove({
        sourceUnitId,
        destUnitId,
        sourceIndex,
        destIndex
      });
    },
    [onAssignmentMove, units]
  );

  return (
    <DragDropContext onDragEnd={handleAssignmentDrop}>
      <List {...rest}>
        {units.map(unit => {
          const assignmentCount = unit.assignments
            ? unit.assignments.length
            : 0;

          return (
            <ExpandListItemDroppable
              key={unit.id}
              droppableId={unit.id}
              header={
                <UnitHeader>
                  <RadioCircleButton
                    onClick={event => {
                      event.stopPropagation();
                      onSelectUnit(unit.id);
                    }}
                  >
                    <RadioCircle checked={selectedUnitId === unit.id} />
                  </RadioCircleButton>
                  <UnitNameLabel>{unit.name}</UnitNameLabel>
                  <AssignmentCountLabel>
                    {assignmentCount} Assignment
                    {assignmentCount === 1 ? "" : "s" /* Fix plural */}
                  </AssignmentCountLabel>
                  {showAddAssignmentButtons && (
                    <AddAssignmentButtonContainer>
                      <PlusButton
                        onClick={event => {
                          event.stopPropagation();
                          onAddAssignmentClick && onAddAssignmentClick(unit.id);
                        }}
                      >
                        New Assignment
                      </PlusButton>
                    </AddAssignmentButtonContainer>
                  )}
                </UnitHeader>
              }
            >
              {(unit.assignments || []).map((assignment, index) => {
                return (
                  <DraggableListItem
                    key={assignment.id}
                    draggableId={assignment.id}
                    index={index}
                  >
                    {renderAssignmentListItem(assignment, index)}
                  </DraggableListItem>
                );
              })}
            </ExpandListItemDroppable>
          );
        })}
      </List>
    </DragDropContext>
  );
};

export default styled(UnitList)``;

const ListItemRow = styled(Row)`
  align-items: center;
  flex: 1;
`;

const UnitHeader = styled(ListItemRow)`
  height: 100%;
  cursor: pointer;
`;

const UnitNameLabel = styled("div")``;

const AssignmentCountLabel = styled("div")`
  color: #7e7e7e;
  font-size: 1rem;
  font-style: italic;
  margin-left: 1.25rem;
`;

const AddAssignmentButtonContainer = styled(Row)`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
`;
