import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import { MdPrint } from "react-icons/md";
import TextButton from "./TextButton";

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const PrintIconButton: FC<Props> = props => {
  return (
    <TextButton {...props}>
      <MdPrint size={26} />
    </TextButton>
  );
};

export default styled(PrintIconButton)`
  @media print {
    display: none;
  }
`;
