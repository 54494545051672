import React, { FC, FormEvent, useCallback } from "react";
import styled from "styled-components/macro";
import { AssignmentQuestionPart } from "shared/lib/types/AssignmentQuestion";
import Column from "./Column";
import AssignmentSlide, {
  Props as AssignmentSlideProps
} from "./AssignmentSlide";
import AssignmentSlideTitle from "./AssignmentSlideTitle";
import AssignmentSlideText from "./AssignmentSlideText";
import AssignmentArrowButton from "./AssignmentArrowButton";
import MultiImageInput from "./MultiImageInput";
import MathInputPreview from "./MathInputPreview";
import {tabletMax} from "../constants/responsiveBreakpoints";
import AssessmentImageUploadLabel from "./AssessmentImageUploadLabel";

export interface Value {
  textResponse: string;
  images: Array<File | string>;
}

interface Props extends AssignmentSlideProps {
  title: string;
  question: AssignmentQuestionPart[];
  buttonText: string;
  buttonLink: string;
  value: Value;
  onChange: (value: Value) => any;
  onNextClick(nextPath: string): any;
}

const TextAreaAssignmentSlide: FC<Props> = props => {
  const {
    title,
    question,
    buttonText,
    buttonLink,
    value,
    onChange,
    onNextClick,
    ...rest
  } = props;

  const handleTextChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      onChange({ ...value, textResponse: event.currentTarget.value });
    },
    [onChange, value]
  );

  const handleImagesChange = useCallback(
    (images: Array<File | string>) => {
      onChange({ ...value, images });
    },
    [onChange, value]
  );

  const handleNextClick = useCallback(() => {
    onNextClick(buttonLink);
  }, [onNextClick, buttonLink]);

  return (
    <AssignmentSlide {...rest}>
      <Column>
        <AssignmentSlideTitle>{title}</AssignmentSlideTitle>
        <AssignmentSlideText>
          <MathInputPreview value={question} />
        </AssignmentSlideText>
      </Column>
      <Textarea
        placeholder="Enter your response here."
        value={value.textResponse}
        onChange={handleTextChange}
      />
      <ImageUploadColumn>
        <MultiImageInput
          minimal
          value={value.images}
          onFilesChange={handleImagesChange}
          buttonLabel={<AssessmentImageUploadLabel />}
        />
      </ImageUploadColumn>
      <Column>
        <AssignmentArrowButton onClick={handleNextClick}>
          {buttonText}
        </AssignmentArrowButton>
      </Column>
    </AssignmentSlide>
  );
};

export default styled(TextAreaAssignmentSlide)`
  justify-content: space-between;
`;

const Textarea = styled("textarea")`
  resize: none;
  flex: 1 0 auto;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  color: #4a4a4a;
  font-family: inherit;
  font-size: 14px;
  line-height: 17px;
  padding: 1rem;

  @media screen and ${tabletMax} {
    // roughly matches the height of the sketchpad
    height: 412px;
  }
`;

const ImageUploadColumn = styled(Column)`
  flex-shrink: 0;
  align-items: flex-end;
  margin-bottom: 2.5rem;
`;
