import { useState, useCallback } from 'react';

export default function useToggleState(
  initialValue = false
): [boolean, () => void, (value: boolean) => void] {
  const [value, setValue] = useState(initialValue);
  const handleToggle = useCallback(() => {
    setValue(toggle);
  }, []);
  return [value, handleToggle, setValue];
}

function toggle(value: boolean): boolean {
  return !value;
}
