import React, { FC, useCallback, ComponentProps, FormEvent, Ref } from "react";
import styled from "styled-components/macro";

type Props = ComponentProps<"form"> & {
  disabled?: boolean;
  innerRef?: Ref<HTMLFormElement>;
};

const Form: FC<Props> = props => {
  const { disabled, onSubmit, innerRef } = props;

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (onSubmit && !disabled) {
        onSubmit(event);
      }
    },
    [onSubmit, disabled]
  );

  return <form {...props} onSubmit={handleSubmit} ref={innerRef} />;
};

export default styled(Form)``;
