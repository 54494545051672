import apiAxios from '../apiAxios';

interface Props {
  resetToken: string;
  password: string;
}

export default async function submitTeacherPasswordReset(
  props: Props
): Promise<void> {
  await apiAxios.post('/password-reset/teacher/submit', props);
}
